// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SearchBox.css */

.search1-box {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .search1-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 60%;
  }
  .search1-icon-left{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .search1-box input {
    padding: 10px 0px 10px 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
  }
  
  @media (max-width: 600px) {
    .search1-icon {
      left: 5px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/component/SubComponent/Search/Search1.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,2BAA2B;IAC3B,YAAY;EACd;EACA;IACE,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;EAC7B;EACA;IACE,2BAA2B;IAC3B,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,sBAAsB;EACxB;;EAEA;IACE;MACE,SAAS;IACX;EACF","sourcesContent":["/* SearchBox.css */\n\n.search1-box {\n    position: relative;\n    display: flex;\n    align-items: center;\n    width: 100%;\n  }\n  \n  .search1-icon {\n    position: absolute;\n    left: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n    opacity: 60%;\n  }\n  .search1-icon-left{\n    position: absolute;\n    right: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n  .search1-box input {\n    padding: 10px 0px 10px 40px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    width: 100%;\n    box-sizing: border-box;\n  }\n  \n  @media (max-width: 600px) {\n    .search1-icon {\n      left: 5px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
