// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.secondsection{
    margin: 5rem 0 0 0 ;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

.para{
    width: 42.9375rem;
    height: 1.75rem;
    opacity:80%;
    margin-bottom: 3.75rem;
    font-family: Poppins;
font-size: 1rem;
font-weight: 400;
line-height: 1.75rem;
letter-spacing: 0em;
text-align: center;

}

.list{
    margin-bottom: 7.875rem;
    padding: auto 0rem;
}
.secondsection-bottom{
    margin-top: 2rem;
    background: linear-gradient(to right,white 0%, white 50%, #F5F5F5 50%,#F5F5F5 100% );
}

@media (max-width: 767px) {
    .secondsection {
      margin-top: 3rem;
    }

    .para {
      width: 100%;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 2.5rem;
    }
  
    .list {
      margin-bottom: 5rem;
    }
  
    .secondsection-bottom {
      margin-top: 1rem;
    }
  }`, "",{"version":3,"sources":["webpack://./src/component/HomePage/SecondPage/Second.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,sBAAsB;IACtB,oBAAoB;AACxB,eAAe;AACf,gBAAgB;AAChB,oBAAoB;AACpB,mBAAmB;AACnB,kBAAkB;;AAElB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,oFAAoF;AACxF;;AAEA;IACI;MACE,gBAAgB;IAClB;;IAEA;MACE,WAAW;MACX,mBAAmB;MACnB,oBAAoB;MACpB,qBAAqB;IACvB;;IAEA;MACE,mBAAmB;IACrB;;IAEA;MACE,gBAAgB;IAClB;EACF","sourcesContent":[".secondsection{\n    margin: 5rem 0 0 0 ;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    flex-wrap: wrap;\n}\n\n.para{\n    width: 42.9375rem;\n    height: 1.75rem;\n    opacity:80%;\n    margin-bottom: 3.75rem;\n    font-family: Poppins;\nfont-size: 1rem;\nfont-weight: 400;\nline-height: 1.75rem;\nletter-spacing: 0em;\ntext-align: center;\n\n}\n\n.list{\n    margin-bottom: 7.875rem;\n    padding: auto 0rem;\n}\n.secondsection-bottom{\n    margin-top: 2rem;\n    background: linear-gradient(to right,white 0%, white 50%, #F5F5F5 50%,#F5F5F5 100% );\n}\n\n@media (max-width: 767px) {\n    .secondsection {\n      margin-top: 3rem;\n    }\n\n    .para {\n      width: 100%;\n      font-size: 0.875rem;\n      line-height: 1.25rem;\n      margin-bottom: 2.5rem;\n    }\n  \n    .list {\n      margin-bottom: 5rem;\n    }\n  \n    .secondsection-bottom {\n      margin-top: 1rem;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
