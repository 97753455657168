// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listofservice-maindiv{
    margin-top: 2rem;
    padding: 0px;
    display: flex;
    justify-content: center;
}

@media screen and (min-width:320px) and (max-width:480px) {
    .listofservice-maindiv{
        flex-wrap: wrap;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/HomePage/SecondPage/ListofService.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".listofservice-maindiv{\n    margin-top: 2rem;\n    padding: 0px;\n    display: flex;\n    justify-content: center;\n}\n\n@media screen and (min-width:320px) and (max-width:480px) {\n    .listofservice-maindiv{\n        flex-wrap: wrap;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
