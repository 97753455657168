// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lookingtobuy{
    margin-top: 2rem;
}
/* .lookingtobuy-heading{
    font-family: Poppins;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.9375rem;
    letter-spacing: 0em;
    text-align: left;
    
} */
/* .lookingtobuy-message{
    opacity: 60%;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75rem;
    letter-spacing: 0em;
    text-align: left;
    
} */
.buttonAndLearnBox{
   margin-top: 6.625rem;
   /* width: 17.68rem; */
   display: flex;
   align-items: center;
   gap: 1rem;
}
/* .buttonAndLearnBox p{
    margin: 0;
    padding: 0;
font-family: Poppins;
font-size: 0.875rem;
font-weight: 600;
line-height: 1.5rem;
letter-spacing: 0em;
text-align: left;

} */
`, "",{"version":3,"sources":["webpack://./src/component/HomePage/SecondPage/LokkingBuySell/LookingForBuySellLeft/LookingToBuy/LookingToBuy.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;;;;;;;;GAQG;AACH;;;;;;;;;GASG;AACH;GACG,oBAAoB;GACpB,qBAAqB;GACrB,aAAa;GACb,mBAAmB;GACnB,SAAS;AACZ;AACA;;;;;;;;;;GAUG","sourcesContent":[".lookingtobuy{\n    margin-top: 2rem;\n}\n/* .lookingtobuy-heading{\n    font-family: Poppins;\n    font-size: 1.5rem;\n    font-weight: 600;\n    line-height: 1.9375rem;\n    letter-spacing: 0em;\n    text-align: left;\n    \n} */\n/* .lookingtobuy-message{\n    opacity: 60%;\n    font-family: Poppins;\n    font-size: 1rem;\n    font-weight: 400;\n    line-height: 1.75rem;\n    letter-spacing: 0em;\n    text-align: left;\n    \n} */\n.buttonAndLearnBox{\n   margin-top: 6.625rem;\n   /* width: 17.68rem; */\n   display: flex;\n   align-items: center;\n   gap: 1rem;\n}\n/* .buttonAndLearnBox p{\n    margin: 0;\n    padding: 0;\nfont-family: Poppins;\nfont-size: 0.875rem;\nfont-weight: 600;\nline-height: 1.5rem;\nletter-spacing: 0em;\ntext-align: left;\n\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
