// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legalfluidcontainer {
    display: flex;
    flex-direction: column;
}

.routescontainer {
    margin-top: 2rem;
    padding-bottom: 5px;
    margin-bottom: 2rem !important;
}

.routescontainer-navlink,
.routescontainer-navlink:hover {
    text-decoration: none;
    margin-right: 0.8rem;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #211E24;
    opacity: 60%;
    padding-bottom: 5px;
}

.routescontainer-navlink.active {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    opacity: 100%;
    margin-bottom: 3px;
    border-bottom: 3px solid #9BA24C;
}

@media only screen and (max-width: 600px) {

    .routescontainer-navlink,
    .routescontainer-navlink:hover {
        display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/Legal/Legal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;;IAEI,qBAAqB;IACrB,oBAAoB;IACpB,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;;IAEI;;QAEI,cAAc;IAClB;AACJ","sourcesContent":[".legalfluidcontainer {\n    display: flex;\n    flex-direction: column;\n}\n\n.routescontainer {\n    margin-top: 2rem;\n    padding-bottom: 5px;\n    margin-bottom: 2rem !important;\n}\n\n.routescontainer-navlink,\n.routescontainer-navlink:hover {\n    text-decoration: none;\n    margin-right: 0.8rem;\n    font-family: Poppins;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    letter-spacing: 0px;\n    text-align: left;\n    color: #211E24;\n    opacity: 60%;\n    padding-bottom: 5px;\n}\n\n.routescontainer-navlink.active {\n    font-size: 16px;\n    font-weight: 600;\n    color: #000;\n    opacity: 100%;\n    margin-bottom: 3px;\n    border-bottom: 3px solid #9BA24C;\n}\n\n@media only screen and (max-width: 600px) {\n\n    .routescontainer-navlink,\n    .routescontainer-navlink:hover {\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
