// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bi-popup-wrap .inner .heading-600-20 {color:#211E24;margin:0 0 20px;}
.bi-popup-wrap .inner .heading-400-14-12{color:#211E24;opacity: 0.8;text-align: center;max-width:392px;}
.bi-popup-wrap .inner .report-icon{margin: 0 0 30px;}
.bi-popup-wrap .inner .offer-valid{display: flex;gap:10px;}
.bi-popup-wrap .inner .price {margin:15px 0 8px;}
.bi-popup-wrap .inner .price span{font-weight:500;}
.bi-popup-wrap .inner .awail-btn{flex:54px 0;background:#73509E;color:#fff;border-radius:12px;border:none;width:max-content;padding:0 40px;margin:30px auto 0;}
@media(max-width:767px){
    .bi-popup-wrap .inner .report-icon{margin: 0 0 20px;}
    .bi-popup-wrap .inner .price {margin:10px 0 5px;}
    .bi-popup-wrap .inner .awail-btn{flex:50px 0;padding:0 30px;}
}
@media(max-width:576px){
    .bi-popup-wrap .inner .head-wrap{row-gap:1px;}
    .bi-popup-wrap .inner .field-group{margin: 10px 0 0;}
    .bi-popup-wrap .inner .awail-btn{margin:25px auto 0;}
    .bi-popup-wrap .inner .heading-600-24{text-align: center;}
    .bi-popup-wrap .inner .awail-btn{flex:45px 0;padding:0 30px;}
}`, "",{"version":3,"sources":["webpack://./src/component/Buying/Modals/DetailedInspection.css"],"names":[],"mappings":"AAAA,uCAAuC,aAAa,CAAC,eAAe,CAAC;AACrE,yCAAyC,aAAa,CAAC,YAAY,CAAC,kBAAkB,CAAC,eAAe,CAAC;AACvG,mCAAmC,gBAAgB,CAAC;AACpD,mCAAmC,aAAa,CAAC,QAAQ,CAAC;AAC1D,8BAA8B,iBAAiB,CAAC;AAChD,kCAAkC,eAAe,CAAC;AAClD,iCAAiC,WAAa,CAAC,kBAAkB,CAAC,UAAU,CAAC,kBAAkB,CAAC,WAAW,CAAC,iBAAiB,CAAC,cAAc,CAAC,kBAAkB,CAAC;AAChK;IACI,mCAAmC,gBAAgB,CAAC;IACpD,8BAA8B,iBAAiB,CAAC;IAChD,iCAAiC,WAAa,CAAC,cAAc,CAAC;AAClE;AACA;IACI,iCAAiC,WAAW,CAAC;IAC7C,mCAAmC,gBAAgB,CAAC;IACpD,iCAAiC,kBAAkB,CAAC;IACpD,sCAAsC,kBAAkB,CAAC;IACzD,iCAAiC,WAAa,CAAC,cAAc,CAAC;AAClE","sourcesContent":[".bi-popup-wrap .inner .heading-600-20 {color:#211E24;margin:0 0 20px;}\n.bi-popup-wrap .inner .heading-400-14-12{color:#211E24;opacity: 0.8;text-align: center;max-width:392px;}\n.bi-popup-wrap .inner .report-icon{margin: 0 0 30px;}\n.bi-popup-wrap .inner .offer-valid{display: flex;gap:10px;}\n.bi-popup-wrap .inner .price {margin:15px 0 8px;}\n.bi-popup-wrap .inner .price span{font-weight:500;}\n.bi-popup-wrap .inner .awail-btn{flex:54px 0 0;background:#73509E;color:#fff;border-radius:12px;border:none;width:max-content;padding:0 40px;margin:30px auto 0;}\n@media(max-width:767px){\n    .bi-popup-wrap .inner .report-icon{margin: 0 0 20px;}\n    .bi-popup-wrap .inner .price {margin:10px 0 5px;}\n    .bi-popup-wrap .inner .awail-btn{flex:50px 0 0;padding:0 30px;}\n}\n@media(max-width:576px){\n    .bi-popup-wrap .inner .head-wrap{row-gap:1px;}\n    .bi-popup-wrap .inner .field-group{margin: 10px 0 0;}\n    .bi-popup-wrap .inner .awail-btn{margin:25px auto 0;}\n    .bi-popup-wrap .inner .heading-600-24{text-align: center;}\n    .bi-popup-wrap .inner .awail-btn{flex:45px 0 0;padding:0 30px;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
