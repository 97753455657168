// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fourthImg {
    /* width: 584px; */
    height: 193px;
}

.fouthContainer {
    overflow: hidden;
    border-radius: 12px;
    background: #fff;
}

.headingpara {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }

.fourthHoverEffect{
    background-color: #9B9E51;
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: none;
    transition: all 0.1s; */

}

.bar2Fourth::after {
    content: "";
    background-color: white;
    display: block;
    width: 83px;
    height: 2px;
    margin: 5px 0;
  }
.hoverEffectImgdiv{
    height: 100%;
    width:100%;
}
.fouthBar::after{
    background-color: #fff !important;
}

@media screen and (min-width:320px) and (max-width:480px) {
    .svgsection svg {
        width: 35px !important;
    }
    .hoverEffectImgdiv{
        height: 197.601px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/HomePage/FourthPage/fourthpagecss.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;EACjB;;AAEF;IACI,yBAAyB;IACzB;;;;;2BAKuB;;AAE3B;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,cAAc;IACd,WAAW;IACX,WAAW;IACX,aAAa;EACf;AACF;IACI,YAAY;IACZ,UAAU;AACd;AACA;IACI,iCAAiC;AACrC;;AAEA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,4BAA4B;IAChC;AACJ","sourcesContent":[".fourthImg {\n    /* width: 584px; */\n    height: 193px;\n}\n\n.fouthContainer {\n    overflow: hidden;\n    border-radius: 12px;\n    background: #fff;\n}\n\n.headingpara {\n    margin-top: 5rem;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    flex-wrap: wrap;\n  }\n\n.fourthHoverEffect{\n    background-color: #9B9E51;\n    /* position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%,-50%);\n    display: none;\n    transition: all 0.1s; */\n\n}\n\n.bar2Fourth::after {\n    content: \"\";\n    background-color: white;\n    display: block;\n    width: 83px;\n    height: 2px;\n    margin: 5px 0;\n  }\n.hoverEffectImgdiv{\n    height: 100%;\n    width:100%;\n}\n.fouthBar::after{\n    background-color: #fff !important;\n}\n\n@media screen and (min-width:320px) and (max-width:480px) {\n    .svgsection svg {\n        width: 35px !important;\n    }\n    .hoverEffectImgdiv{\n        height: 197.601px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
