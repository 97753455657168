// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sixthsection-maindiv {
    margin-top: 10rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.sixthsection-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.sixthsection-maindiv h1 {
    font-family: Poppins;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.75rem;
    letter-spacing: 0em;
    text-align: left;
}

.sixthsection-heading p {
    opacity: 60%;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
}

.sixthSectionSlider {
    display: flex;
    gap: 1rem;
    overflow-x: scroll;
    scroll-behavior: smooth;
    white-space: nowrap;
    padding: 10px;
    overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./src/component/Annual/slider/slider.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".sixthsection-maindiv {\n    margin-top: 10rem;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    overflow: hidden;\n}\n\n.sixthsection-heading {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 3rem;\n}\n\n.sixthsection-maindiv h1 {\n    font-family: Poppins;\n    font-size: 2rem;\n    font-weight: 600;\n    line-height: 2.75rem;\n    letter-spacing: 0em;\n    text-align: left;\n}\n\n.sixthsection-heading p {\n    opacity: 60%;\n    font-family: Poppins;\n    font-size: 1rem;\n    font-weight: 400;\n    line-height: 28px;\n    letter-spacing: 0em;\n    text-align: left;\n}\n\n.sixthSectionSlider {\n    display: flex;\n    gap: 1rem;\n    overflow-x: scroll;\n    scroll-behavior: smooth;\n    white-space: nowrap;\n    padding: 10px;\n    overflow-x: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
