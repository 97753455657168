// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.seventhConatinerProduct {
    border-right: 1px solid rgba(115, 80, 158, 0.10);
    border-left: 1px solid rgba(115, 80, 158, 0.10);
    background: var(--White, #FFF);
}

.seventhConatinerProduct:hover{
    background-color: #9B9E51;
    box-shadow: 0px 32px 64px 0px rgba(101, 105, 4, 0.24);

}

.availservice{
    opacity: 0;
}
.seventhConatinerProduct:hover .availservice{
    opacity: 1;
}
.seventhConatinerProduct:hover p{
    color: #FFF !important;
}
.seventhConatinerProduct:hover .bar2::after{
    background-color: #FFF;
}`, "",{"version":3,"sources":["webpack://./src/component/HomePage/WishlistPage/seventhsection.css"],"names":[],"mappings":"AAAA;IACI,gDAAgD;IAChD,+CAA+C;IAC/C,8BAA8B;AAClC;;AAEA;IACI,yBAAyB;IACzB,qDAAqD;;AAEzD;;AAEA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,sBAAsB;AAC1B","sourcesContent":[".seventhConatinerProduct {\n    border-right: 1px solid rgba(115, 80, 158, 0.10);\n    border-left: 1px solid rgba(115, 80, 158, 0.10);\n    background: var(--White, #FFF);\n}\n\n.seventhConatinerProduct:hover{\n    background-color: #9B9E51;\n    box-shadow: 0px 32px 64px 0px rgba(101, 105, 4, 0.24);\n\n}\n\n.availservice{\n    opacity: 0;\n}\n.seventhConatinerProduct:hover .availservice{\n    opacity: 1;\n}\n.seventhConatinerProduct:hover p{\n    color: #FFF !important;\n}\n.seventhConatinerProduct:hover .bar2::after{\n    background-color: #FFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
