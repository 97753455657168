// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bofsfu {
    width: 100%;
    height: 100%;
    margin:0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.maidvsn {
    width:30%;
    margin: 20px;
}
.sidebarsfdvvdd {
    width: 20%;
    margin: 20px;
}
.maidvsn div {
    width:100%;
    height:200px;
    background:linear-gradient(45deg, #feeeab 0%, #fb7eb3 60%);
    margin-bottom:20px;
    border-radius: 10px;
}
.sidebarsfdvvdd .content-wrapper div {
    width:200px;
    height:250px;
    border-radius: 10px;
    background:linear-gradient(45deg, #8fd0ff 0%, #79ebe0 60%);
    margin-bottom:20px;
}
`, "",{"version":3,"sources":["webpack://./src/component/Addonservice/AllServices/ScrollableColumnLayout.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,SAAS;IACT,YAAY;AAChB;AACA;IACI,UAAU;IACV,YAAY;AAChB;AACA;IACI,UAAU;IACV,YAAY;IACZ,0DAA0D;IAC1D,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,0DAA0D;IAC1D,kBAAkB;AACtB","sourcesContent":[".bofsfu {\r\n    width: 100%;\r\n    height: 100%;\r\n    margin:0;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n}\r\n.maidvsn {\r\n    width:30%;\r\n    margin: 20px;\r\n}\r\n.sidebarsfdvvdd {\r\n    width: 20%;\r\n    margin: 20px;\r\n}\r\n.maidvsn div {\r\n    width:100%;\r\n    height:200px;\r\n    background:linear-gradient(45deg, #feeeab 0%, #fb7eb3 60%);\r\n    margin-bottom:20px;\r\n    border-radius: 10px;\r\n}\r\n.sidebarsfdvvdd .content-wrapper div {\r\n    width:200px;\r\n    height:250px;\r\n    border-radius: 10px;\r\n    background:linear-gradient(45deg, #8fd0ff 0%, #79ebe0 60%);\r\n    margin-bottom:20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
