import React, { useState } from 'react';
import ManageAddressForPopup from '../../Account/ManageAddress/ManageAddressForPopup';
import MyAccAddressforAddressPopup from '../../Account/ManageAddress/MyAccAddressforAddressPopup';
const AddressPopup = ({ selectedLocation,closePopupForAddress, handleSaveAfterAddressSelection ,planToAdd}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [showManageAddressPopup, setShowManageAddressPopup] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
// console.log(selectedAddress)

  console.log("Selected Location in Address Popup",selectedLocation);
  const handleEditClick = (data) => {
    setEditData({
      ...data,
      selectedLocation, // Ensure selectedLocation is passed to the editData
    });
    setIsEditing(true);
  };

  const handleBackClick = ({ closePopup }) => {
    if (closePopup) {
      closePopupForAddress(); // Close the entire AddressPopup if "Add Address" was clicked
    } else {
      setIsEditing(false); // Go back to ManageAddressForPopup if "Edit Address" was clicked
    }
  };
  const handleDeliverHere = () => {
    closePopupForAddress();
    handleSaveAfterAddressSelection(selectedAddress, planToAdd); // Pass the plan to handleSave

};
  const handleSaveAndGoToManageAddress = () => {
    setShowManageAddressPopup(true);
    setIsEditing(false); // Reset editing state to false after saving
  };

  return (
    <>
      {/* Manage Address Popup */}
      {!isEditing && !showManageAddressPopup ? (
        <ManageAddressForPopup
        selectedLocation={selectedLocation}
          onDeliverHere={handleDeliverHere}
          onEdit={handleEditClick}
        />
      ) : isEditing ? (
        <MyAccAddressforAddressPopup
        selectedLocation={selectedLocation}
          editData={editData}
          handleSaveAndGoToManageAddress={handleSaveAndGoToManageAddress}
          onBack={handleBackClick}
        />
      ) : (
        <ManageAddressForPopup
        selectedLocation={selectedLocation}
          onDeliverHere={handleDeliverHere}
          onEdit={handleEditClick}
        />
      )}
    </>
  );
};

export default AddressPopup;
