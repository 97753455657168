import React from "react";

const ReactStepperExample = ({ stepperLogic }) => {
  // Determine the line class
  const getLineClass = () => {
    const firstStepLabel = stepperLogic?.[0].step1 ? stepperLogic?.[0].step1.label : "";
    if (firstStepLabel === "Quotation Raised") {
      return "line-stepperLogic1";
    } else if (firstStepLabel === "Ordered") {
      return "line-stepperLogic4";
    } else if (firstStepLabel === "Proforma Invoice") {
      return "line-stepperLogic3";
    } else {
      return "line-stepperLogic2";
    }
  };

  // Determine the class for the step based on status
  const getStepClass = (status) => {
    return status === "active" ? "step-active" : "step-inactive";
  };

  // Determine the color class for the icon-wrapper
  const getColor = (status) => {
    return status === "active" ? "icon-activesdbf" : "icon-inactiveseddd";
  };

  return (
    <div className="stepper-containedddr">
    <div className="bottompartof-individual-order-details-div">
      <div className="stepper d-flex align-items-center justify-content-between">
        {Object.entries(stepperLogic[0]).map(([key, step], index) => (
          <div key={key} className="step-coeentainer">
            <div className="step-text">{step.label}</div>
            <div 
              className={`icon-containeeeer ${step.status}`} 
              style={{ 
                backgroundColor: step.status === "active" ? "green" : "gray",
                color:step.status === "active" ?  "white" : "gray"
              }} 
            >
              {step.icon}
            </div>
            {index < Object.entries(stepperLogic[0]).length - 1 && (
             <div className={`connectorhere ${step.status}`} /> 
            )}
          </div>
        ))}
      </div>
    </div>
  </div>
  
  );
};

export default ReactStepperExample;