import {DetailsIcon} from "../../../../../helpers/IconsforNewPage";
import "./TTHeadingIconTextTDivider.css";
const TTHeadingIconTextTDivider = ({
    className = "",
    text = "Details",
  }) => {
  return (
    <div
    className={`${className} tt-heading-icon-text-tdivider-tt-heading-icon-text-tdivider`}
  >
    <div className="tt-heading-icon-text-tdivider-t-heading-icon">
      <div className="tt-heading-icon-text-tdivider-heading-1">{text}</div>
      <DetailsIcon className="tt-heading-icon-text-tdivider-icon-6" />
    </div>
    <div className="tt-heading-icon-text-tdivider-text-3">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ultricies purus ac ex tincidunt, ut gravida nunc fermentum.
      </p>
    </div>
    <div className="tt-heading-icon-text-tdivider-t-divider">
      <div className="tt-heading-icon-text-tdivider-divider" />
    </div>
  </div>
  )
}

export default TTHeadingIconTextTDivider