import React, { useEffect, useRef, useState } from "react";
import recommend from "@algolia/recommend";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { leftArrowIcon, rightArrowIcon } from "../../../../helpers/Icons";
import Loader from "../../../SubComponent/Loader";

const ALGOLIA_APP_ID = "WMFH4GQ1HA";
const ALGOLIA_API_KEY = "26d776e16554d71413cb70ee9bc30fb6";
const ALGOLIA_INDEX_NAME = "zohoinventory_index";

const AlgoliaTry = () => {
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const objectID = localStorage.getItem("ObjectIdToolforSimilar") || "1833180000000319550";


  useEffect(() => {
    const recommendClient = recommend(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

    recommendClient
      .getLookingSimilar([
        {
          indexName: ALGOLIA_INDEX_NAME,
          objectID: objectID,
        },
      ])
      .then(({ results }) => {
        setResults(results[0].hits); 
      })
      .catch((err) => {
        setError(err.message);
        console.error("Error fetching similar products:", err);
      });
  }, []);

  

  const sliderRef = useRef(null);
    const next = () => {
        sliderRef.current.slickNext();
    };
    const previous = () => {
        sliderRef.current.slickPrev();
    };


  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    // nextArrow: (
    //   <IoIosArrowForward
    //     fontSize={20}
    //     color="black"
    //     className="forwardiconintoolssimilar"
    //   />
    // ),
    // prevArrow: (
    //   <IoIosArrowBack
    //     fontSize={20}
    //     color="black"
    //     className="backwardiconintoolssimilar"
    //   />
    // ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const navigate = useNavigate();
  

  const handleIconClick = () => {
    navigate("/store");
  };

  const [loading, setLoading] = useState(false);

  const handleClick = (hit) => {
    localStorage.setItem("ObjectIdToolforSimilar", hit.objectID);
   
  
    const state = {
      group_id: hit.group_id,
      objectID: hit.objectID || hit.id,
      details: hit,
    };
  
    setTimeout(() => {
      
      if (hit?.group_id) {
        navigate(`/buy/spares-revised?group_id=${hit.group_id}`, { state });
      } else {
        navigate(`/buy/spares-revised?id=${hit.objectID}`, { state });
      }
  
      console.log(`Redirecting to ViewSpareProduct page: Group Id: ${hit.group_id}, Item Id: ${state.objectID}`);
    }, 1000); 
  };

  return (
    <> {loading && <Loader />}
    
    <div className="similar-products-container">
      <div className="d-flex justify-content-between align-items-center similar-oils-container">
        <div>
          <h3 className="similar-products-heading">
            Similar Oils you may like
          </h3>
        </div>
        <div className="arrowviewoils mb-3 d-flex align-items-center">
          {/* <a
            href="/store"
            className="arrowicontext box-btn heading-600-14 heading-600-14-12"
          >
            View Oils&nbsp;&nbsp;
          </a> */}
            <span
            className="arrow-iconfor"
            style={{ cursor: "pointer" }}
            // onClick={handleIconClick}
            onClick={previous}
          >
            {leftArrowIcon({ width: 24, height: 24 })}
          </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span
            className="arrow-iconfor"
            style={{ cursor: "pointer" }}
            // onClick={handleIconClick}
            onClick={next}
          >
            {rightArrowIcon({ width: 24, height: 24 })}
          </span>
        </div>
      </div>
      {error && <p style={{ color: "red" }}>Error: {error}</p>}
      {!error && results.length === 0 && <p>Loading similar products...</p>}
      {!error && results.length > 0 && (
        <div>
           {/* <IoIosArrowBack
            className="custom-arrow custom-prev-arrow"
            onClick={() => document.querySelector(".slick-prev").click()}
          />
          <IoIosArrowForward
            className="custom-arrow custom-next-arrow"
            onClick={() => document.querySelector(".slick-next").click()}
          /> */}
        <Slider {...sliderSettings} ref={sliderRef}>
          {results.map((hit) => {
            const grossPrice = hit.grossPrice || "N/A";
            const formattedGrossPrice =
              grossPrice !== "N/A"
                ? new Intl.NumberFormat("en-IN").format(grossPrice)
                : "N/A";

            return (
              <div
                key={hit.objectID}
                className="each-individual-card-for-similarmachines"
                onClick={() => handleClick(hit)}  
                style={{ cursor: "pointer" }}
              >
                <img
                  src={hit.thumbnail || "https://via.placeholder.com/150"}
                  alt={hit.productName || "Unnamed Product"}
                />
                <div className="product-infofor">
                  <h4 className="beforeofproductname">
                    {hit.productName || hit.title}
                  </h4>
                  <div className="heading-underline"></div>
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <div style={{ fontSize: "14px" }}>{hit.group_name}</div>
                    <div style={{ fontSize: "14px" }}>
                      ₹{formattedGrossPrice}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
        </div>
      )}
    </div>
    
    </>
   
  );
};

export default AlgoliaTry;
