import React from "react";

const ColumnThree = ({ cartItems, onAddToCart, onRemoveFromCart }) => {
  console.log("Cart Items : ", cartItems);
  return (
    <>
      <div className="service-column service-column-three">
        {cartItems.length === 0 ? (
          <div className="cart-empty-message">
            <p>Your cart is empty.</p>
          </div>
        ) : (
          <>
            <ul>
              {cartItems.map((item, index) => (
                <li key={index}>
                  <div>{item.name}</div>
                  <div className="service-quantity-controls">
                    <button onClick={() => onRemoveFromCart(item)}>-</button>
                    <span>{item.quantity}</span>
                    <button onClick={() => onAddToCart(item)}>+</button>
                  </div>
                </li>
              ))}
            </ul>
            <div className="desktop-request-buttons">
              <button className="service-request-button">Request for call back</button>
              <button className="service-request-button">Request for quote</button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ColumnThree;
