// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_maindiv__0t7Fz {
  display: flex;
  flex-direction: column;
}

.Home_homeCardBorder__xI5-e {
  border-right: 1px solid rgba(115, 80, 158, 0.10);
  border-left: 1px solid rgba(115, 80, 158, 0.10);
  border-radius: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/component/HomePage/Home.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gDAAgD;EAChD,+CAA+C;EAC/C,mBAAmB;AACrB","sourcesContent":[".maindiv {\n  display: flex;\n  flex-direction: column;\n}\n\n.homeCardBorder {\n  border-right: 1px solid rgba(115, 80, 158, 0.10);\n  border-left: 1px solid rgba(115, 80, 158, 0.10);\n  border-radius: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"maindiv": `Home_maindiv__0t7Fz`,
	"homeCardBorder": `Home_homeCardBorder__xI5-e`
};
export default ___CSS_LOADER_EXPORT___;
