
import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import {
  secondClient
} from "../OrigaExtentionAPI/mutations";
import LoginModel from "../Authentication/LoginModel/LoginModel";
import "../CartPagefolder/CartPageSampleStyles.css";
import {
  ellipsePurpleIcon,
  ellipseWhiteIcon,
  vectorLineIcon,
} from "../../helpers/Icons";
import CartAndSummaryLoggedIn from "../CartPagefolder/CartAndSummaryLoggedIn";
import CartAndSummaryNotLoggedIn from "../CartPagefolder/CartAndSummaryNotLoggedIn";
const client = secondClient;
const CartPageTotal = () => {
//to scroll to the top  : 
useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [productDETAILS, setProductDETAILS] = useState({});
  const productId = localStorage.getItem("productIdtoCart");
  const groupId = localStorage.getItem("groupIdtoCart");

  const [subtotal, setSubtotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [totalprice, setTotalPrice] = useState(0);
  const [total, setTotal] = useState(0);

  const [loginPortal, setLoginPortal] = useState(false);
  const [loggedin, setLoggedin] = useState(!!localStorage.getItem("userToken"));

  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems")) || []
  );

  const [quantity, setQuantity] = useState("");
    const onHidePortal = () => {
        setLoginPortal(false);
      };

      const handleCreateOrder = async () => {
        const userToken = localStorage.getItem("userToken");
        if (!userToken) {
          setLoginPortal(true);
          return;
        }
        console.log('cartItems --------------------------------------------------------------- > :', cartItems);
        const localCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        console.log("localCartItems  --------------------------------------------------------------- > :' ", localCartItems);
    
        var itemList = []; //create an array to represent items in the cart
        let bucketId = null;
          for (var item of localCartItems) { // Use 'of' to iterate over array elements
            var obj = {};
            
            obj["item_id"] = item["productId"];
            console.log("Cart Items While Loggin in handleCreateOrder Function in CartitemSampleLoggedin.jsx")
            console.log("Quantity in localCartitems :  --------------> ", item["quantity"]);
            obj["quantity"] = item["quantity"];
            obj["bucket_id"] = item["bucketId"]; // Use bucket_id
      
            console.log("Bucketid in localCartitems :  --------------> ", item["bucketId"]);
      
            if (bucketId === null) {
              bucketId = item["bucketId"]; // Set bucketId if it's null
            }
      
            itemList.push(obj);
          }
        console.log("itemList -------------------->  :: ", itemList);
        //Get all this localStorage
        const ompuserId = localStorage.getItem("id");
        const customer_first_name = localStorage.getItem("firstName");
        const customer_last_name = localStorage.getItem("lastName") ;
        const customer_contact_no = localStorage.getItem("number") ;
        const customer_email = localStorage.getItem("emailId");
      
            const requestBody = {
              items: [], // Initially empty as we'll fetch from API
              ompuserId,
              customer_first_name,
              customer_last_name,
              customer_contact_no,
              order_status: "Draft",
              order_ref_number: "",
              customer_email,
              input: {
                city: "",
                cityArea: "",
                companyName: "",
                country: "",
                countryArea: "",
                firstName: "",
                lastName: "  ",
                phone: "",
                postalCode: "",
                streetAddress1: " ",
                streetAddress2: "",
              },
              businessaddress: {
                city: "",
                cityArea: "",
                companyName: " ",
                country: "",
                countryArea: "",
                firstName: "",
                lastName: "",
                phone: "",
                postalCode: "",
                streetAddress1: "",
                streetAddress2: "",
              },
              gst_no: "",
            };
      
        try {
          console.log(
            "requestBody  ------------------------------------------------------------- > :: ",
            requestBody
          );
          // Create order request for the same api
          const createOrderResponse = await fetch(
            "https://devextension.origa.market/sparesapi/create_order",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBody),
            }
          );
    
          if (!createOrderResponse.ok) {
            throw new Error("Failed to create order");
          }
    
          const createOrderData = await createOrderResponse.json();
          const draftItems = createOrderData.line_items || []; 
          console.log("Created order with items:", draftItems);
    
          // Clear local storage cartItems after successful order creation
          // localStorage.removeItem('cartItems');
          setCartItems([]); // clear the cart items from the component’s state after a successful order creation.
        } catch (error) {
          console.error("Error creating order:", error);
        }
      };
  return (
   <>
   <div className="totalcartpagebuttop">
        <div className="centertotaldivcart">
          <div className="icon-containere">
            <span className="dilevery-address-1">
              {ellipsePurpleIcon({ width: 32, height: 32 })}
            </span>
            <span className="icon-number">1</span>
          </div>
          <span className="add-to-cart-text">Add to Cart</span>
          <div className="line lineedbr">
            {vectorLineIcon({ width: 88, height: 1 })}
          </div>
          <div className="icon-containere rsbbref">
            <span className="order-sumary-2">
              {ellipseWhiteIcon({ width: 32, height: 32 })}
            </span>
            <span className="icon-number">2</span>
          </div>
          <span className="add-to-cart-text">Delivery Address</span>
          <div className="line lineedbr">
            {vectorLineIcon({ width: 88, height: 1 })}
          </div>
          <div className="icon-containere ">
            <span className="order-sumary-2">
              {ellipseWhiteIcon({ width: 32, height: 32 })}
            </span>
            <span className="icon-number">3</span>
          </div>
          <span className="add-to-cart-text">Payment</span>
        </div>
      </div>

      <div className="totalcartpage">
        <div className="leftsideBordertotaldiv">
          <div className="toppartofcartpage row justify-content-between align-items-center">
            <div className="leftheadingcart col-4">
              <h1 className="leftheadingcarttext">My Cart</h1>
            </div>
            <div className="rightheadingcart col-4">
              <h1 className="rightheadingcarttext">Price</h1>
            </div>
          </div>

          <div className="horizontallineofcartpage">
            <hr className="lineofcartunder" />
          </div>

          <div className="">
            {/* {loggedin ? (
              <CartItemSampleLoggedIn cartItems={cartItems}    />
            ) : (
              cartItems.map((item, index) => (
                <CartItemSample
                  key={index}
                  productId={item.productId}
                  groupId={item.groupId}
                  productName={item.productName}
                  productBrand={item.productBrand}
                  productImage={item.productImage}
                  AvailableStock={item.AvailableStock}
                  quantity={item.quantity}
                  totalCartPriceocvf={item.totalCartPriceocvf}
                  bucketPrice={item.bucketPrice}
                  bucketId={item.bucketId}
                  salePrice={item.salePrice}
                  handleDecreaseQuantity={() => {}}
                  handleIncreaseQuantity={() => {}}
                />
              ))
            )} */}


            {loggedin ? (
              <CartAndSummaryLoggedIn   />
            ) : (
                <CartAndSummaryNotLoggedIn
                 
                />
              )
            }
            
          </div>

          {/* <div className="horizontallineofcartpage">
            <hr className="lineofcartbottom" />
          </div> */}
        </div>

        {/* <div className="rightsideBordertotaldiv">
          {loggedin ? <SummaryCartLoggedIn 
           /> : <SummaryCartLoggedNot
         

          />}
         
        </div> */}
      </div>
      {loginPortal && (
        <LoginModel onHide={onHidePortal} onLoginSuccess={handleCreateOrder} />
      )}
      <Footer />
      </>
  )
}

export default CartPageTotal