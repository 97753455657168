// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block-home{
    border:2px solid #F6F6F6;
    width: 100%;
    max-width: 285px;
    height: 220px;
    margin-top: 2rem;
  
}
.block-home div
{
    width: 52px;
    height: 52px;
    border-radius: 26px;
    background-color: #E7E7E7;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}
@media (max-width:768px){
    .block-home{
    background-color: #FFFFFF;
}
}
@media (max-width:576px) {
    .block-home{
        max-width: 241px;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/Block/Block.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,gBAAgB;;AAEpB;AACA;;IAEI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI;IACA,yBAAyB;AAC7B;AACA;AACA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".block-home{\n    border:2px solid #F6F6F6;\n    width: 100%;\n    max-width: 285px;\n    height: 220px;\n    margin-top: 2rem;\n  \n}\n.block-home div\n{\n    width: 52px;\n    height: 52px;\n    border-radius: 26px;\n    background-color: #E7E7E7;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 1rem;\n}\n@media (max-width:768px){\n    .block-home{\n    background-color: #FFFFFF;\n}\n}\n@media (max-width:576px) {\n    .block-home{\n        max-width: 241px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
