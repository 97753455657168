// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LookingForBuySellLeft-maindiv{
    background-color:white;
    /* padding: 0; */
    margin: 3rem 0rem 0rem 0rem ;
}
.LookingForBuySellLeft-headingdiv{
    width: 25.5rem;
    height: 2rem;
    display: flex;
    gap: 1rem;
}
.LookingForBuySellLeft-navbar {
    /* width: 9.1875rem; */
    height: 2rem;
    text-decoration: none;
font-size: 1rem;
font-weight: 400;
line-height: 1.5rem;
letter-spacing: 0px;
text-align: left;
color: #211E24;
opacity: 60%;
}
.LookingForBuySellLeft-navbar:hover{
    text-decoration: none;
    color: #211E24;
}
.LookingForBuySellLeft-navbar.active{
font-size: 1.25rem;
font-weight: 600;
opacity: 100%;
border-bottom: 3px solid #9BA24C;
}
`, "",{"version":3,"sources":["webpack://./src/component/HomePage/SecondPage/LokkingBuySell/LookingForBuySellLeft/LookingForBuySellLeft.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,4BAA4B;AAChC;AACA;IACI,cAAc;IACd,YAAY;IACZ,aAAa;IACb,SAAS;AACb;AACA;IACI,sBAAsB;IACtB,YAAY;IACZ,qBAAqB;AACzB,eAAe;AACf,gBAAgB;AAChB,mBAAmB;AACnB,mBAAmB;AACnB,gBAAgB;AAChB,cAAc;AACd,YAAY;AACZ;AACA;IACI,qBAAqB;IACrB,cAAc;AAClB;AACA;AACA,kBAAkB;AAClB,gBAAgB;AAChB,aAAa;AACb,gCAAgC;AAChC","sourcesContent":[".LookingForBuySellLeft-maindiv{\n    background-color:white;\n    /* padding: 0; */\n    margin: 3rem 0rem 0rem 0rem ;\n}\n.LookingForBuySellLeft-headingdiv{\n    width: 25.5rem;\n    height: 2rem;\n    display: flex;\n    gap: 1rem;\n}\n.LookingForBuySellLeft-navbar {\n    /* width: 9.1875rem; */\n    height: 2rem;\n    text-decoration: none;\nfont-size: 1rem;\nfont-weight: 400;\nline-height: 1.5rem;\nletter-spacing: 0px;\ntext-align: left;\ncolor: #211E24;\nopacity: 60%;\n}\n.LookingForBuySellLeft-navbar:hover{\n    text-decoration: none;\n    color: #211E24;\n}\n.LookingForBuySellLeft-navbar.active{\nfont-size: 1.25rem;\nfont-weight: 600;\nopacity: 100%;\nborder-bottom: 3px solid #9BA24C;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
