import React, { useEffect, useState } from "react";
import EditIcon from "../../SubComponent/EditIcon";
import { CiCircleMinus } from "react-icons/ci";
import LocationPopup from "./LocationPopup";
import EditLocationPopup from "./EditLocationPopup"; // Make sure to import this component
import { closeIcon } from "../../../helpers/Icons";
import AddressPopup from "./AddressPopup";
import axios from "axios";

const AllServiceColumnThree = ({
  cartItems,
  onAddToCart,
  selectedServiceTypeMachineName,
  savedLocations,
  selectedServiceType,
  setSavedLocations,
  multiplelocations,
  currentLocation,
  onEditLocation,
  onAddressPopup,
  draftItems,
  addresses,
  selectedAddress,
  handleDeleteitem,
  handleRequestCallback,
  handleIncreaseItem,
  handleDecreaseItem,
  handleAddressPopupforedit
  ,handleQuotationRequest
}) => {
  // const savedLocation = savedLocations[selectedServiceType] || "";
  const [showLocationPopup, setShowLocationPopup] = useState();
  const [hoveredButton, setHoveredButton] = useState(null);
  const [showEditLocationPopup, setShowEditLocationPopup] = useState(false);
  const [currentEditingLocation, setCurrentEditingLocation] = useState("");
  const [currentEditingItemId, setCurrentEditingItemId] = useState(null);
  const [AddressPopupf, setAddresspopupFor] = useState(false);


  
  useEffect(() => {
    if (showEditLocationPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [showEditLocationPopup]);

  const multipleLocations = [
    {
      title: "Chennai",
      img: "https://thumbs.dreamstime.com/b/puratchi-thalaivar-dr-mgr-central-railway-station-chennai-india-tamilnadu-beautiful-view-day-light-blue-say-183608328.jpg",
    },
    {
      title: "Mumbai",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuOwjKtpRcPyzXGhHWx4UHDw1Z84k-hhYL8Q&s",
    },
    {
      title: "Bangalore",
      img: "https://www.fabhotels.com/blog/wp-content/uploads/2018/06/Shopping_1000x650_SubHeadImages_210618.jpg",
    },
    {
      title: "Pune",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQt_bJoDMPdiupRKe42R2q1xFVyRpzuESDshQ&s",
    },
  ];

  const handleMouseEnter = (button) => setHoveredButton(button);
  const handleMouseLeave = () => setHoveredButton(null);

  // const handleEditLocation = (item) => {
  //   setShowEditLocationPopup(true);
  //   setCurrentEditingLocation(item.location);
  //   setCurrentEditingItemId(item.amc_plan_id);

  //   console.log("Editing Item ID:", item.amc_plan_id);
  //   console.log("Item Name:", item.type);
  //   console.log("Item Description:", item.description);

  // };


  const deleteitem = (item) => {
    handleDeleteitem(item)
  }
  const handleEditLocation = (item) => {
    // Call the parent function to open the popup
    onEditLocation(item);
  };

  const handleLocationSelectinEdit = () => {};

  const handleLocationUpdate = (location) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item.amc_plan_id === currentEditingItemId) {
        return { ...item, location };
      }
      return item;
    });
    onAddToCart(updatedCartItems);

    setSavedLocations((prev) => ({
      ...prev,
      [selectedServiceType]: location,
    }));
    localStorage.setItem("savedLocations", JSON.stringify(savedLocations));
  };

  const closeEditLocationPopup = () => {
    setAddresspopupFor(false);
    setCurrentEditingLocation("");
    setCurrentEditingItemId(null);
  };

  const handleOpenAddressPopup = () => {
    onAddressPopup(); // This will call the function passed from AllService
  };
  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://devextension.origa.market/api/fetchcustomeraddress",
          {
            id: localStorage.getItem("id"),
            mobileno: localStorage.getItem("number"),
          }
        );
        setData(response?.data?.response || []);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // console.log("Draft items  : -----------> ",draftItems);


  return (
    <>
     <div className="ColumnThreeKarthik">
  <div className={`insidecolummnnone ${cartItems.length === 0 && draftItems.length === 0 ? "" : "jsfhbbsffs"}`}>
    {cartItems.length === 0 && draftItems.length === 0 ? (
      <div className="cart-empty-full-container">
        <img
          src="https://cdn-icons-png.flaticon.com/512/11329/11329060.png"
          className="imagesrbentzdg"
          alt="empty-cart"
        />
        <div className="cart-empty-message">
          <p className="cartttfsd">Your cart is empty.</p>
        </div>
      </div>
    ) : (
      <>
        <div className="cart-details">
          <span className="boldheadingfor">Cart</span>
          <div className="mt-3"></div>
          {draftItems.map((item, index) => {
            const address = item.address || {}; // Use address from item directly

            const renderPriceText = (serviceType) => {
              switch (serviceType) {
                case "Preventive Maintenance":
                  return (
                    <div className="price-text">
                      <div>Rs. 5000 onwards</div>
                    </div>
                  );
                case "Breakdown Service":
                case "Annual Maintenance Contract":
                  return (
                    <div className="price-text">
                      <div>Price will be decided</div>
                      <div>after inspection</div>
                    </div>
                  );
                default:
                  return null; // or any default message if needed
              }
            };

            return (
              <div key={index}>
                <span className="boldheadingfor">
                  {item.service_type || "Service Type"}
                </span>{" "}
                &nbsp;-&nbsp;&nbsp;
                <span className="boldheadingfor">
                  {item.machine_category || "Machine Category"}
                </span>
                <div className="cart-item-details">
                  <div className="description-price-container">
                    <div className="clean-description">
                      {item.description}
                    </div>
                    {renderPriceText(item?.service_type)}
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div>
                    <span style={{ fontWeight: "bold", cursor: "pointer" }}>
                      <span>Quantity : {item.quantity}</span>
                    </span>
                  </div>
                </div>

                {address && ( // Check if the address exists
                  <div>
                    <div className="d-flex">
                      {/* Additional address details can go here */}
                    </div>
                    {address.input?.firstName && (
                      <div>
                        <span className="headingindelivery">Name: </span>
                        {address.input.firstName} {address.input.lastName}
                        <br />
                      </div>
                    )}
                    {address.input?.phone && (
                      <div>
                        <span className="headingindelivery">Mobile Number: </span>
                        {address.input.phone}
                        <br />
                      </div>
                    )}
                    {address.businessaddress?.streetAddress1 && (
                      <div>
                        <span className="headingindelivery">Address:</span>
                        <span onClick={() => handleAddressPopupforedit(item)} style={{ cursor: "pointer" }}>
                          <EditIcon />
                        </span>
                        {address.businessaddress.streetAddress1},{" "}
                        {address.businessaddress.streetAddress2 && (
                          <>{address.businessaddress.streetAddress2},</>
                        )}
                        {address.businessaddress.countryArea},{" "}
                        {address.businessaddress.city},{" "}
                        {address.businessaddress.postalCode}
                        <br />
                      </div>
                    )}
                  </div>
                )}

                <br />
              </div>
            );
          })}

          <div className="Disclaimer">
            <span>
              Disclaimer: Final price will be determined post inspection
            </span>
          </div>
        </div>
        <div className="button-container mt-2">
          <button
            className={`callbackfbdj ${hoveredButton === "button1" ? "hover-effect" : ""}`}
            onMouseEnter={() => handleMouseEnter("button2")}
            onMouseLeave={handleMouseLeave}
            onClick={handleRequestCallback}
          >
            Request for call back
          </button>
          <button
            className={`callbackfbdj2 ${hoveredButton === "button2" ? "hover-effect" : ""}`}
            onMouseEnter={() => handleMouseEnter("button1")}
            onMouseLeave={handleMouseLeave}
            onClick={handleQuotationRequest}
          >
            Request for quote
          </button>
        </div>
        <div className="Disclaimer mt-3">
          <span>
            For any assistance call&nbsp;
            <a
              className="gszrbfdjkbdg"
              href="tel:8828099099"
              style={{ textDecoration: "none", color: "blue" }}
            >
              8828099099
            </a>
            , Mon - Fri, 10 am - 5 pm
          </span>
        </div>

        <div className="Disclaimer mt-2">
          <span style={{ fontWeight: "bold" }}>View Service Scope:</span>
          <br></br>
          <span>
            Disclaimer: Final service scope will be determined post inspection
          </span>
        </div>
      </>
    )}
  </div>
</div>


      {showEditLocationPopup && (
        <div className="popup-overlayeddd">
          <div className="popup-banneredd">
            <div className="popup-content">
              <EditLocationPopup
                multiplelocations={multiplelocations}
                currentLocation={currentLocation}
                selectedServiceType={selectedServiceType}
              />
              <div
                className="close-popupeddd"
                onClick={() => setShowEditLocationPopup(false)}
              >
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}

      {AddressPopupf && (
        <div className="popup-overlay">
          <div className="popup-banner">
            <div className="popup-contenteeee">
              <AddressPopup />
              <div className="close-popup" onClick={closeEditLocationPopup}>
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AllServiceColumnThree;
