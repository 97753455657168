import { connectHits } from "react-instantsearch-dom";
import { heartIcon, botIcon } from "../../../../helpers/Icons";
import { useNavigate, useLocation } from "react-router-dom";
// import CustomPagination from "../CustomPagination";
import { ALGOLIA_ID, ALGOLIA_KEY, ALGOLIA_SELLER_INDEX, ALGOLIA_SPARE_INDEX } from '../../../../constants'
import React, { useState, useEffect } from "react";
import useWishListAddOrUpdate from "../../../SubComponent/useWishListAddOrUpdate";
import CustomPagination from "../CustomPagination";
import { secondClient, GET_SPARE_DETAILS } from '../../../OrigaExtentionAPI/mutations'
import LoginModel from "../../../Authentication/LoginModel/LoginModel";

//----------------------------------------Showing Information Of the Product Based On Result Hits-------------------------------------------------------

const client = secondClient
const CustomHitse = ({ setLoading, hits, Sorting }) => {
    setLoading(false)
    
    const [productDETAILS, setProductDETAILS] = useState({});
    const [quantity, setQuantity] = useState(1);
    const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
    const queryParams = new URLSearchParams();
    
    const searchInput = searchParams.get('searchInput');
    const [loginPortal, setLoginPortal] = useState(false);
    const productId = queryParams.get('id');//to get the id from url
    const groupId = queryParams.get('group_id');//to get the id from url
    const ompuserId = localStorage.getItem("id");
    const customer_first_name = localStorage.getItem("firstName");
    const customer_last_name = localStorage.getItem("lastName");
    const customer_contact_no = localStorage.getItem("number");
    const customer_email = localStorage.getItem("emailId");
    // console.log('productId---->', productId);
    const [deliveryLocation, setDeliveryLocation] = useState("");
    const [buymachineId, setBuymachineId] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [infokey, setinfokey] = useState(0);
    const productIdtoCart = productDETAILS?.[infokey]?.id;

    
    const [combinedResults, setCombinedResults] = useState({ sellerResults: [], spareResults: [] });

  useEffect(() => {
    const fetchResults = async () => {
      try {
        // Split the search input by spaces into individual terms
        const searchTerms = searchInput.split(" ");
        
        // Create an array of queries for each term in both indices
        const queries = searchTerms.flatMap(term => [
          { indexName: ALGOLIA_SELLER_INDEX, query: term },
          { indexName: ALGOLIA_SPARE_INDEX, query: term }
        ]);

        // Execute all the queries
        const { results } = await client.multipleQueries(queries);

        // Combine results from both indices
        const sellerResults = results.filter(result => result.index === ALGOLIA_SELLER_INDEX && result.hits.length > 0);
        const spareResults = results.filter(result => result.index === ALGOLIA_SPARE_INDEX && result.hits.length > 0);

        setCombinedResults({
          sellerResults: sellerResults.flatMap(result => result.hits),
          spareResults: spareResults.flatMap(result => result.hits)
        });
      } catch (error) {
        console.error("Error fetching combined results:", error);
      }
    };

    if (searchInput) {
      fetchResults();
    }
  }, [searchInput]);
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the window to the top on component mount or update
    const fetchData = async () => {
      let id = localStorage.getItem("id");
      if (id === null) {
        id = "";
      }
      try {
        // Performs a GraphQL query using the Apollo client 'client'
        const { data } = await client.query({
          query: GET_SPARE_DETAILS,
          variables: { groupId: groupId || "", itemId: productId || "" },
        });
        // Sets the fetched data to state variable 'productDETAILS'
        setProductDETAILS(data?.inventoryItem?.response);
        // Sets 'loading' state to false after data is fetched
        setLoading(false);
        // Updates formatted price
        updateFormattedPrice();
      } catch (error) {}
    };
    fetchData();
  }, [groupId, productId]);
  

  const productname = productDETAILS?.[infokey]?.name;
  const productbrand = productDETAILS?.[infokey]?.brand || "N/A";
  const productimage =productDETAILS[infokey]?.images?.productImages[infokey]?.url || "N/A";
  const saleprice = productDETAILS?.[infokey]?.saleprice ;

  const handleBuyNow = async (productIdtoCart, quantity, productName, salePrice) => {
    // Log the details of the clicked product
    console.log("Product ID:", productIdtoCart);
    console.log("Quantity:", quantity);
    console.log("Product Name:", productName);
    console.log("Sale Price:", salePrice);

    const loggedin = !!localStorage.getItem('userToken');
    
    if (loggedin) {
        // Extract user details from localStorage
        const userToken = localStorage.getItem("userToken");
        const ompuserId = localStorage.getItem("id");
        const customer_first_name = localStorage.getItem("firstName");
        const customer_last_name = localStorage.getItem("lastName");
        const customer_contact_no = localStorage.getItem("number");
        const customer_email = localStorage.getItem("emailId");

        const itemList = [{
            item_id: productIdtoCart,
            quantity: quantity
        }];
        
        console.log("Item list for API:", itemList);

        try {
            const response = await fetch("https://devextension.origa.market/sparesapi/create_order", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    
                },
                body: JSON.stringify({
                    items: itemList,
                    ompuserId,
                    customer_first_name,
                    customer_last_name,
                    customer_contact_no,
                    order_status: "Draft",
                    order_ref_number: "",
                    customer_email,
                    input: {
                        city: "",
                        cityArea: "",
                        companyName: "",
                        country: "IN",
                        countryArea: "",
                        firstName: "",
                        lastName: "",
                        phone: "",
                        postalCode: "",
                        streetAddress1: "",
                        streetAddress2: "",
                    },
                    businessaddress: {
                        city: "",
                        cityArea: "",
                        companyName: "",
                        country: "IN",
                        countryArea: "",
                        firstName: "",
                        lastName: "",
                        phone: "",
                        postalCode: "",
                        streetAddress1: "",
                        streetAddress2: "",
                    },
                    gst_no: "",
                }),
            });
            console.log("Now HandleBuyNow in the store page is ongoing and itemid is added  : ",productIdtoCart);
            console.log(`Item added to API cart. Product ID: ${productIdtoCart}`);

            // Check if response is successful
            if (response.ok) {
                console.log("Item successfully added to API cart.");
            } else {
                console.log("Failed to add item to API cart:", await response.text());
            }

            // Navigate to the next page
            navigate(`/manageaddresspart`);
        } catch (error) {
            console.error("Error adding item to API cart:", error);
        }
    } else {
        // If not logged in, show the login portal
        setLoginPortal(true);
    }
};


      
    const navigate = useNavigate();
    const [filteredHitsToRender, setFilteredHitsToRender] = useState(hits);
    const { onWishlistHandler, heartColor } = useWishListAddOrUpdate();

    const updateFormattedPrice = () => {//is used to update how the price of a product is displayed on a webpage
        setProductDETAILS((prevDetails) => ({//it takes the current details of the product(prevdetails)and creates a new set of details with an updatted formatted price
            ...prevDetails,
            price_details: {
                ...prevDetails.price_details,
                //amc_cost: formatCurrency(prevDetails.price_details.amc_cost),
                //delivery_charges: formatCurrency(prevDetails.price_details.delivery_charges),
                machine_price: formatCurrency(prevDetails.sales_rate),
            },

        }));
    };
    
    const formatCurrency = (value) => { //like to show in indian rupees it adds commas where needed and shows the right number of decimal place
        const fractionDigits = value % 1 !== 0 ? 2 : 0;
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        }).format(value || 0);
    };

    const findYearDifference = (targetYear) => {
        const currentYear = new Date().getFullYear();
        const yearDifference = currentYear - targetYear;
        return yearDifference;
    }
    const priceConvert = (price) => {
        price = typeof price === 'string' ? price : String(price);


        let count = 1;
        let comma = 3;
        let formatedPrice = ""
        for (let i = price.length - 1; i >= 0; i--) {
            formatedPrice = price[i] + formatedPrice
            if (count === comma) {
                formatedPrice = "," + formatedPrice
                comma = 2;
                count = 0;
            } count++;

        }
        //console.log("==>>", formatedPrice)
        if (formatedPrice[0] === ",") {
            formatedPrice = formatedPrice.slice(1, formatedPrice.length)
        }
        return formatedPrice;



    };
    // console.log('filteredHitsToRender---->', filteredHitsToRender);
    useEffect(() => {
        let sortedHits = [...hits];

        if (Sorting !== null) {
            sortedHits = [...hits].sort((a, b) => {
                if (Sorting === "Pricelow_High") {
                    return a["grossPrice"] - b["grossPrice"];
                } else if (Sorting === "PriceHigh_low") {
                    return b["grossPrice"] - a["grossPrice"];
                } else if (Sorting === "AlphabeticallyAtoZ") {
                    return a["productName"].localeCompare(b["productName"]);
                }
                return 0; // Default case
            });
        }

        setFilteredHitsToRender(sortedHits);
    }, [Sorting, hits]);

    const handleFavorite = (index, value) => {
        const updatedHits = [...filteredHitsToRender];
        updatedHits[index]["favorite"] = value;
        setFilteredHitsToRender(updatedHits);
    };

    const onSubmitHandler = (props) => {
        console.log("whish list working", props?.categories.lvl2?.split('>')[2].trim());
        const loggedin = !!localStorage.getItem('userToken');
        if (loggedin) {
            onWishlistHandler(props?.productId, props?.thumbnail, props?.grossPrice, props?.productName, props?.attributes?.Brands, props?.categories?.lvl1?.split('>')[1].trim(), props?.categories.lvl2?.split('>')[2].trim())
        }
    }

    const handleAvailService = (product) => {
        //SetQuationModel(true)
        if(product?.group_id){
            navigate(`/buy/sparetools?group_id=${product.group_id}`);
        }
        else{
            navigate(`/buy/sparetools?id=${product.objectID}`);
        }
    };

    const handleModal = async (status, obj) => {
        // Check if the user is logged in
        const loggedin = !!localStorage.getItem('userToken');
        if (!loggedin) {
            // If not logged in, set login portal to true and stop further execution
            setLoginPortal(true);
            return;
        }
    
        // If status is 'phone-modal', handle specific logic
        if (status === 'phone-modal') {
            // Check logged in status again (though redundant as already checked above)
            const loggedin = !!localStorage.getItem('userToken');
            if (!loggedin) {
                // If not logged in, set login portal to true and stop further execution
                setLoginPortal(true);
                return;
            } else {
                // Navigate to a specific route with query parameters
                navigate(`/manageaddresspart`);
            }
        }
    
        // If status is truthy (not 'phone-modal'), show modal
        if (status) {
            setShowModal(status);
        } else {
            // If status is falsy, hide modal and perform additional actions
            setShowModal(false);
            const id = localStorage.getItem('id');
            console.log('obj------------>', obj);
            setBuymachineId(obj?.buymachine_id); // Set buy machine ID from obj parameter
            try {
                // Fetch product details using GraphQL query based on productId
                const { data } = await client.query({ query: GET_SPARE_DETAILS, variables: { itemId: productId } });
                setProductDETAILS(data?.inventoryItem?.response); // Update product details state
                updateFormattedPrice(); // Update formatted price based on new product details
            } catch (error) {
                // Handle errors if GraphQL query fails
                console.error('Error fetching product details:', error);
            }
        }
    }

    const onHidePortal = () => {
        setLoginPortal(false);
    }
    



    return (
        <>
        {combinedResults?.sellerResults?.length > 0 ? (
            <div className="product-wrap">
                <div className="products">
                    {combinedResults.sellerResults.map((result, index) => (
                        <div className="product" key={index}>
                            <div className="product-inner">
                                <img className="product-img" src={result.thumbnail} alt={result.name} />
                                <div className="content">
                                    <div className="name">
                                        {result?.name?.length > 18
                                            ? `${result?.name?.substring(0, 18)}...`
                                            : result?.name}
                                    </div>
                                    <div className="location">
                                        {(() => {
                                            const lvl1Category = result?.categories?.lvl1 || '';
                                            const parts = lvl1Category.split(' > ');
                                            const lastPart = parts[parts.length - 1];
                                            return lastPart;
                                        })()}
                                    </div>
                                    <div className="price-wrap">
                                        <div className="time">{result?.Phase}</div>
                                        <div className="price">₹{result["grossPrice"]}</div>
                                    </div>
                                    <button className={`heart ${result?.favorite ? "favorite" : ""}`} type="button" onClick={() => onSubmitHandler(result)}>
                                        {heartIcon({ width: 25, onClick: () => handleFavorite(index, !result.favorite) })}
                                    </button>
                                    <div className="buttonsinoneline">
                                        <button className="purchasef pbtnsb" onClick={() => handleBuyNow(result.id, quantity, result.name, result["grossPrice"])}>Buy Now</button>
                                        <button className="btnsb" onClick={() => handleAvailService(result)}>Avail Service</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <CustomPagination />
            </div>
        ) : (
            <div>No Results</div>
        )}
        {showModal && <div>Modal Content</div>}
        {loginPortal && <LoginModel closeModal={onHidePortal} />}
    </>
    );
};

export default connectHits(CustomHitse);
