import { connectRefinementList } from "react-instantsearch-dom";
import React, { useState, useEffect } from "react";

const ConnectedSpareRefinementList = ({
  attribute,
  items,
  refine,
  currentRefinement,
  IndustryCheckBoxValue,
  setIndustryCheckBoxValue,
  CategoryCheckBoxValue,
  setCategoryCheckBoxValue,
  GradeCheckBoxValue,
  setGradeCheckBoxValue,
  setIsGradeSelected,
  setIsGridView,
}) => {
  const regex = /.*?>\s*/g;
  const [selectedValues, setSelectedValues] = useState([]);
  const [visibleItemsCount, setVisibleItemsCount] = useState(2); // Default to 2 for brands and grade
  const [isExpanded, setIsExpanded] = useState(false);

  const handleCheckboxChange = (event) => {
    const value = event.currentTarget.value;
    const newSelectedValues = [...selectedValues];
  
    // console.log(`Item checked: ${value}, Attribute: ${attribute}`);
  
    // Handling changes for Industrial Lubricants
    if (event.target.id === "categories.lvl0") {
      setIndustryCheckBoxValue(newSelectedValues);
    }
  
    // Handling changes for Categories (level 1)
    if (event.target.id === "categories.lvl1") {
      if (event.currentTarget.checked) {
        setCategoryCheckBoxValue([value]);
        refine(value);
      } else {
        setCategoryCheckBoxValue([]);
        refine([]);
      }
      return;
    }
  
    // Handling changes for Grade
    if (event.target.id === "grade") {
      if (event.currentTarget.checked) {
        newSelectedValues.push(value);
        setGradeCheckBoxValue(newSelectedValues);
      } else {
        const index = newSelectedValues.indexOf(value);
        newSelectedValues.splice(index, 1);
        setGradeCheckBoxValue(newSelectedValues);
      }
      setSelectedValues(newSelectedValues);
      refine(newSelectedValues);
      setIsGradeSelected(newSelectedValues.length > 0);
    }
  
    // Handling changes for Brands
    if (event.target.id === "brands") {
      if (event.currentTarget.checked) {
        newSelectedValues.push(value);
      } else {
        const index = newSelectedValues.indexOf(value);
        newSelectedValues.splice(index, 1);
      }
  
      setSelectedValues(newSelectedValues);
      refine(newSelectedValues);

      setIsGradeSelected(newSelectedValues.length > 0);
      setIndustryCheckBoxValue(newSelectedValues); 
      setCategoryCheckBoxValue(newSelectedValues); 
    }
  
    setSelectedValues(newSelectedValues);
    refine(newSelectedValues);
  };
  

  const handleReset = () => {
    setSelectedValues([]);
    setIndustryCheckBoxValue([]);
    setCategoryCheckBoxValue([]);
    setGradeCheckBoxValue([]);
    refine([]);
  };

  useEffect(() => {
    // console.log(`Selected Category Checkboxes: ${CategoryCheckBoxValue}`);
    // console.log(`Selected Brand Checkboxes: ${IndustryCheckBoxValue}`);
    // console.log(`Selected Grade Checkboxes: ${GradeCheckBoxValue}`);
  }, [CategoryCheckBoxValue, IndustryCheckBoxValue, GradeCheckBoxValue]);
  
  // In handleCheckboxChange:
  // console.log(`Filter changed. Selected values: ${selectedValues}`);
  

  useEffect(() => {
    const resetBtn = document.getElementById("ResetBtn");

    if (selectedValues.length > 0 || CategoryCheckBoxValue.length > 0) {
      resetBtn.style.display = "block";
    }
    if (selectedValues.length === 0 && CategoryCheckBoxValue.length === 0) {
      resetBtn.style.display = "none";
    }

    resetBtn.addEventListener("click", handleReset);

    return () => {
      resetBtn.removeEventListener("click", handleReset);
    };
  }, [selectedValues]);

  // Update visible items count dynamically based on attribute
  useEffect(() => {
    if (attribute === "categories.lvl1") {
      setVisibleItemsCount(6); // For categories, show 6 items
    } else {
      setVisibleItemsCount(2); // For other attributes (brands, grade), show  2  items
    }
  }, [attribute]);

  const filteredItems = items.filter((item) => {
    if (item.count === 1) {
      return true;
    }
    if (
      selectedValues.length > 0 ||
      CategoryCheckBoxValue.length > 0 ||
      GradeCheckBoxValue.length > 0 ||
      IndustryCheckBoxValue.length > 0
    ) {
      return true;
    }
    return item.count > 1;
  });

  const handleShowMore = () => {
    setVisibleItemsCount(items.length);
    setIsExpanded(true);
  };

  const handleShowLess = () => {
    setVisibleItemsCount(attribute === "categories.lvl0" ? 6 : 2);
    setIsExpanded(false);
  };

  return (
    <>
      {filteredItems.length === 0 ? (
        <p>No records found.</p>
      ) : (
        <>
          {filteredItems
            .filter((item) => item.label !== "0" && item.label !== "1900")
            .slice(0, visibleItemsCount)
            .map((item) => (
              <div className="checkbox-item" key={item.id}>
                <label className="heading-400-16">
                  {item.label.replace(regex, "")} ({item.count})
                </label>
                <input
                  id={attribute}
                  type="checkbox"
                  name={item.name}
                  value={item.label}
                  checked={
                    attribute === "categories.lvl1"
                      ? CategoryCheckBoxValue.includes(item.label)
                      : selectedValues.includes(item.label)
                  }
                  onChange={handleCheckboxChange}
                />
              </div>
            ))}
          {filteredItems.length > visibleItemsCount && (
            <div className="loadmorebuttondiv">
              <button onClick={handleShowMore} className="load-more-button">
                + {filteredItems.length - visibleItemsCount} more
              </button>
            </div>
          )}
          {isExpanded && (
            <div className="loadmorebuttondiv">
              <button onClick={handleShowLess} className="load-more-button">
                Show less
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default connectRefinementList(ConnectedSpareRefinementList);
