import React, { useState, useEffect } from "react";
import "./ProductListing.css";
import LoginModel from '../../Authentication/LoginModel/LoginModel';
import Breadcrumbs from "../../SubComponent/Breadcrumbs";
import DeliveryLocation from "../Modals/DeliveryLocation";
import Footer from '../../Footer/Footer';
import { shortIcon, filterIcon, heartIcon } from "../../../helpers/Icons";
import { useNavigate, useLocation } from "react-router-dom";
import { ALGOLIA_ID, ALGOLIA_KEY, ALGOLIA_SELLER_INDEX, ALGOLIA_SPARE_INDEX } from '../../../constants'
import algoliasearch from "algoliasearch/lite";
import {
    InstantSearch,
    Configure,
    connectRange,
    connectStateResults
} from "react-instantsearch-dom";
import FilterSection from "./FilterSection";
import Loader from "../../SubComponent/Loader";
import CategoryPopup from "../../Account/Popup/CategoryPopup";
import CustomHits from "./CustomHits"
import SpareHits from "./SpareHits"
import { filterAttribute } from './FilterAttribute';
import Slider from '@mui/material/Slider';
import CustomerInfo from './CustomerInformation/CustomerInfoModel';
import SpareHitse from "./SpareHits/SpareHitse";
import useWishListAddOrUpdate from  "../../SubComponent/useWishListAddOrUpdate"
import { secondClient, GET_SPARE_DETAILS } from '../../OrigaExtentionAPI/mutations'
import { gql } from '@apollo/client';

const GET_WISHLIST_DATA = gql`
query userWishlists($userid:String!) {
    userWishlists(userId: $userid) {
      message
      code
      response
    }
  }
  
`

const client = algoliasearch(ALGOLIA_ID,  ALGOLIA_KEY);

const CombinedHits = () => {
  const location = useLocation();
  const userId = localStorage.getItem('id');
  const searchParams = new URLSearchParams(location.search);
  const searchInput = searchParams.get('searchInput');
  const [combinedResults, setCombinedResults] = useState({ sellerResults: [], spareResults: [] });
  const [productDETAILS, setProductDETAILS] = useState({});
  const [quantity, setQuantity] = useState(1);
  const queryParams = new URLSearchParams();
  const [loginPortal, setLoginPortal] = useState(false);
  const productId = queryParams.get('id');//to get the id from url
  const groupId = queryParams.get('group_id');//to get the id from url
  const ompuserId = localStorage.getItem("id");
  const customer_first_name = localStorage.getItem("firstName");
  const customer_last_name = localStorage.getItem("lastName");
  const customer_contact_no = localStorage.getItem("number");
  const customer_email = localStorage.getItem("emailId");
  const [WishListData, setWishListData] = useState([]);
  // console.log('productId---->', productId);

  useEffect(() => {
    const fetchWishListData = async () => {
        try {
            const { data } = await client.mutate({
                mutation: GET_WISHLIST_DATA,
                variables: {
                    "userid": userId
                }
            });
            if (data) {
                const wishlistItems = data.userWishlists.response[0]?.items;
                //console.log('wishlistdata---->', wishlistItems);
                if (wishlistItems) {
                    const productIds = wishlistItems.map(item => item.product_id);
                    setWishListData(productIds);

                }
            }
        } catch (error) {
            console.error('Error fetching wishlist data:', error);
        }
    };

    fetchWishListData();
}, [userId]);


  const [buymachineId, setBuymachineId] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [infokey, setinfokey] = useState(0);
  const productIdtoCart = productDETAILS?.[infokey]?.id;

  
  useEffect(() => {
    const fetchResults = async () => {
      try {
        // Split the search input by spaces into individual terms
        const searchTerms = searchInput.split(" ");
        
        // Create an array of queries for each term in both indices
        const queries = searchTerms.flatMap(term => [
          { indexName: ALGOLIA_SELLER_INDEX, query: term },
          { indexName: ALGOLIA_SPARE_INDEX, query: term }
        ]);

        // Execute all the queries
        const { results } = await client.multipleQueries(queries);

        // Combine results from both indices
        const sellerResults = results.filter(result => result.index === ALGOLIA_SELLER_INDEX && result.hits.length > 0);
        const spareResults = results.filter(result => result.index === ALGOLIA_SPARE_INDEX && result.hits.length > 0);

        setCombinedResults({
          sellerResults: sellerResults.flatMap(result => result.hits),
          spareResults: spareResults.flatMap(result => result.hits)
        });
      } catch (error) {
        console.error("Error fetching combined results:", error);
      }
    };

    if (searchInput) {
      fetchResults();
    }
  }, [searchInput]);


  const findYearDifference = (targetYear) => {
    const currentYear = new Date().getFullYear();
    const yearDifference = currentYear - targetYear;
    return yearDifference;
}
const priceConvert = (price) => {
    price = typeof price === 'string' ? price : String(price);


    let count = 1;
    let comma = 3;
    let formatedPrice = ""
    for (let i = price.length - 1; i >= 0; i--) {
        formatedPrice = price[i] + formatedPrice
        if (count === comma) {
            formatedPrice = "," + formatedPrice
            comma = 2;
            count = 0;
        } count++;

    }
    //console.log("==>>", formatedPrice)
    if (formatedPrice[0] === ",") {
        formatedPrice = formatedPrice.slice(1, formatedPrice.length)
    }
    return formatedPrice;



};
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the window to the top on component mount or update
    const fetchData = async () => {
      let id = localStorage.getItem("id");
      if (id === null) {
        id = "";
      }
      try {
        // Performs a GraphQL query using the Apollo client 'client'
        const { data } = await client.query({
          query: GET_SPARE_DETAILS,
          variables: { groupId: groupId || "", itemId: productId || "" },
        });
        // Sets the fetched data to state variable 'productDETAILS'
        setProductDETAILS(data?.inventoryItem?.response);
        // Sets 'loading' state to false after data is fetched
        setLoading(false);
        // Updates formatted price
        updateFormattedPrice();
      } catch (error) {}
    };
    fetchData();
  }, [groupId, productId]);
  

  
  const updateFormattedPrice = () => {//is used to update how the price of a product is displayed on a webpage
    setProductDETAILS((prevDetails) => ({//it takes the current details of the product(prevdetails)and creates a new set of details with an updatted formatted price
        ...prevDetails,
        price_details: {
            ...prevDetails.price_details,
            //amc_cost: formatCurrency(prevDetails.price_details.amc_cost),
            //delivery_charges: formatCurrency(prevDetails.price_details.delivery_charges),
            machine_price: formatCurrency(prevDetails.sales_rate),
        },

    }));
};

const formatCurrency = (value) => { //like to show in indian rupees it adds commas where needed and shows the right number of decimal place
    const fractionDigits = value % 1 !== 0 ? 2 : 0;
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    }).format(value || 0);
};


  const productname = productDETAILS?.[infokey]?.name;
  const productbrand = productDETAILS?.[infokey]?.brand || "N/A";
  const productimage =productDETAILS[infokey]?.images?.productImages[infokey]?.url || "N/A";
  const saleprice = productDETAILS?.[infokey]?.saleprice ;

  const handleBuyNow = async (productIdtoCart, quantity, productName, salePrice) => {
    // Log the details of the clicked product
    console.log("Product ID:", productIdtoCart);
    console.log("Quantity:", quantity);
    console.log("Product Name:", productName);
    console.log("Sale Price:", salePrice);

    const loggedin = !!localStorage.getItem('userToken');
    
    if (loggedin) {
        // Extract user details from localStorage
        const userToken = localStorage.getItem("userToken");
        const ompuserId = localStorage.getItem("id");
        const customer_first_name = localStorage.getItem("firstName");
        const customer_last_name = localStorage.getItem("lastName");
        const customer_contact_no = localStorage.getItem("number");
        const customer_email = localStorage.getItem("emailId");

        const itemList = [{
            item_id: productIdtoCart,
            quantity: quantity
        }];
        
        console.log("Item list for API:", itemList);

        try {
            const response = await fetch("https://devextension.origa.market/sparesapi/create_order", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    
                },
                body: JSON.stringify({
                    items: itemList,
                    ompuserId,
                    customer_first_name,
                    customer_last_name,
                    customer_contact_no,
                    order_status: "Draft",
                    order_ref_number: "",
                    customer_email,
                    input: {
                        city: "",
                        cityArea: "",
                        companyName: "",
                        country: "IN",
                        countryArea: "",
                        firstName: "",
                        lastName: "",
                        phone: "",
                        postalCode: "",
                        streetAddress1: "",
                        streetAddress2: "",
                    },
                    businessaddress: {
                        city: "",
                        cityArea: "",
                        companyName: "",
                        country: "IN",
                        countryArea: "",
                        firstName: "",
                        lastName: "",
                        phone: "",
                        postalCode: "",
                        streetAddress1: "",
                        streetAddress2: "",
                    },
                    gst_no: "",
                }),
            });
            console.log("Now HandleBuyNow in the store page is ongoing and itemid is added  : ",productIdtoCart);
            console.log(`Item added to API cart. Product ID: ${productIdtoCart}`);

            // Check if response is successful
            if (response.ok) {
                console.log("Item successfully added to API cart.");
            } else {
                console.log("Failed to add item to API cart:", await response.text());
            }

            // Navigate to the next page
            navigate(`/manageaddresspart`);
        } catch (error) {
            console.error("Error adding item to API cart:", error);
        }
    } else {
        // If not logged in, show the login portal
        setLoginPortal(true);
    }
};


const threesellerhits = combinedResults.sellerResults.slice(0, 3);
const threesparehits = combinedResults.spareResults.slice(0, 3);



const [showAllSeller, setShowAllSeller] = useState(false);

const [showAllSpare, setShowAllSpare] = useState(false);


const viewAllforSeller = () => {
    setShowAllSeller(true); // Set to true to show all results
  };
  
  const { onWishlistHandler, heartColor } = useWishListAddOrUpdate();
 
  const  viewAllforSpare = () => {
    setShowAllSpare(true); // Set to true to show all results
  };
  
  const handleFavorite = (index, value) => {
    const updatedHits = [...combinedResults.spareResults];
    updatedHits[index]["favorite"] = value;
    setCombinedResults(updatedHits);
};

  const onSubmitHandler = (props) => {
    console.log("whish list working", props?.categories.lvl2?.split('>')[2].trim());
    const loggedin = !!localStorage.getItem('userToken');
    if (loggedin) {
        onWishlistHandler(props?.productId, props?.thumbnail, props?.grossPrice, props?.productName, props?.attributes?.Brands, props?.categories?.lvl1?.split('>')[1].trim(), props?.categories.lvl2?.split('>')[2].trim())
    }
}



const handleAvailServiceseller = (result) => {
    const UserId = localStorage.getItem('id');
    //console.log('UserId---->', UserId);
    if (UserId === null) {
        const existingProducts = JSON.parse(localStorage.getItem("SelectedProducts")) || [];
        const isProductInList = existingProducts.some((existingProduct) => existingProduct.productId === result.productId);

        if (!isProductInList) {
            existingProducts.push(result);
            localStorage.setItem("SelectedProducts", JSON.stringify(existingProducts));
        }
    } else if (UserId !== null) {
        const existingProducts = JSON.parse(localStorage.getItem("SelectedProductsWithUser")) || [];
        const isProductInList = existingProducts.some((existingProduct) => existingProduct.productId === result.productId);

        const productWithUserId = { ...result, userId: UserId };

        if (!isProductInList) {
            existingProducts.push(productWithUserId);
            localStorage.setItem("SelectedProductsWithUser", JSON.stringify(existingProducts));
        }
    }



    localStorage.setItem("objectID", result.objectID);
    // Navigate to the service page
    navigate(`/buy/cnc-machine?slug=${result.slug}&id=${result.productId}`);
};

  const handleAvailService = (result) => {
    //SetQuationModel(true)
    if(result?.group_id){
        navigate(`/buy/sparetools?group_id=${result.group_id}`);
    }
    else{
        navigate(`/buy/sparetools?id=${result.objectID}`);
    }
};


  
  const [loading, setLoading] = useState(true);
    const client = algoliasearch(ALGOLIA_ID, ALGOLIA_KEY);
    const Seller_index = client.initIndex(ALGOLIA_SELLER_INDEX);
    const [filteredHits, setFilteredHits] = useState([]);
   
    const [isMobCategoryShow, setIsMobCategoryShow] = useState(window.innerWidth <= 767);
    const [activeTab, setActiveTab] = useState("machines");
    const [MainFilters, setMainFilters] = useState("");
    const [products, setProducts] = useState([]);
    const [filters, setFilters] = useState({
        industry: [],
        category: [],
        subcategory: [],
        brand: [],
        Model: [],
        Price: [],
        Year: [],
        Controller: [],
        XAXIS: [],
        YAXIS: [],
        ZAXIS: [],
        spindlespeed: [],
        spindletaper: [],
        tailstock: [],
        barcapacity: [],
        chucksize: [],
        maxturninglength: [],
        swingoverbed: [],
        swingovercrossslide: [],
        subSpindle: [],
        liveTooling: [],
        orderBy: "",
        page: 1,
    });
    const token = localStorage.getItem('userToken');
    const [IndustryCheckBoxValue, setIndustryCheckBoxValue] = useState([]);
    const [CategoryCheckBoxValue, setCategoryCheckBoxValue] = useState([]);
    const [showShortList, setShowShortList] = useState(false);
    const [activeFilters, setActiveFilters] = useState({
        industry: true, category: false, brand: false, model: false, Price: false, year: false, Controller: false,
        XAXIS: false, YAXIS: false, ZAXIS: false, spindlespeed: false, spindletaper: false, tailstock: false, barcapacity: false, chucksize: false, maxturninglength: false,
        swingoverbed: false, swingovercrossslide: false, subSpindle: false, liveTooling: false
    });
   
    const [Sorting, setSorting] = useState(null);
    
    const [deliveryLocation, setDeliveryLocation] = useState("");
    const storedLocation = localStorage.getItem("deliveryLocation");
    if (storedLocation && !deliveryLocation) {
        setDeliveryLocation(storedLocation);
    }
    const [seeMore, setSeeMore] = useState(false);
    const navigate = useNavigate();
    const breadcrumbsItems = [
        { name: "Tools and Consumables", link: "/" },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { SparefilterConfigurations, filterConfigurations } = filterAttribute(IndustryCheckBoxValue, CategoryCheckBoxValue);

    const handleFilters = (name, value) => {
        setActiveFilters((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleChangeSort = (sort) => {
        setSorting(sort);
        const selectedFilters = Object.assign({}, filters);
        selectedFilters.orderBy = sort;
        selectedFilters.page = 1;
        setFilters(selectedFilters);
        setShowShortList(false);
    };

    const handleSeeMore = (status) => {
        if (status) {
            setSeeMore(status);
            document.body.classList.add('no-overflow');
        } else {
            setSeeMore(false);
            document.body.classList.remove('no-overflow');
        }
    };

    const onGetPinCodeHandler = (pin) => {
        setDeliveryLocation(pin);
    };

    const toggleDropdown = () => {
        const dropdownElement = document.querySelector('.p-fixed');
        const dropdownElement1 = document.querySelector('.fixed-category-bottom');
        if (dropdownElement) {
            dropdownElement.classList.remove('dropdownHidden');
            dropdownElement1.classList.remove('dropdownHidden');
        }
    };

    

    const RangeSlider = ({ min, max, currentRefinement, refine }) => {
        const handleChange = (event, newValue) => {
            const currentYear = new Date().getFullYear();
            const minAllowedYear = currentYear - 25;
            if (minAllowedYear === newValue[0]) {
                refine({ min: 1900, max: newValue[1] });
            } else {
                refine({ min: newValue[0], max: newValue[1] });
            }
        };
        const [minYear, setMinYear] = useState(1940);

        useEffect(() => {
            const currentYear = new Date().getFullYear();
            const minAllowedYear = currentYear - 25;
            setMinYear(minAllowedYear);
        }, []);
        const defaultValue = [currentRefinement.min || min, currentRefinement.max || max];
        return (
            <div>
                <Slider
                    min={minYear}
                    max={max}
                    value={defaultValue}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    color="secondary"
                    aria-labelledby="range-slider"
                    getAriaValueText={(value) => `$${value}`}
                />
            </div>
        );
    };

    const CustomRangeInput = connectRange(RangeSlider);
    // const NoResultsFound = ({ searchResults }) => {
    //     const [showCustomerInfoForm, setShowCustomerInfoForm] = useState(false);
    
    //     useEffect(() => {
    //         if (searchResults && searchResults.nbHits === 0) {
    //             // If there are no hits, show the spareIndex instead of CustomerInfoForm
    //             setShowCustomerInfoForm(false);
    //         } else {
    //             // If the search input is not found in any indexes, show the CustomerInfoForm
    //             const allResultsEmpty = searchResults.every(result => result.nbHits === 0);
    //             setShowCustomerInfoForm(allResultsEmpty);
    //         }
    //     }, [searchResults]);
    
    //     if (searchResults && searchResults.nbHits === 0) {
    //         return (
    //             <div className='col col-lg-9 col-md-9 col-12'>
    //                 {/* Show the spareIndex here */}
    //                 <SpareHits />
    //                 <p>No product found.</p>
    //             </div>
    //         );
    //     }
    
    //     if (showCustomerInfoForm) {
    //         return (
    //             <div className='col col-lg-9 col-md-9 col-12'>
    //                 <CustomerInfo setCustomerInfoForm={setShowCustomerInfoForm} />
    //                 <p>No product found.</p>
    //             </div>
    //         );
    //     }
    
    //     return null;
    // };
  // Check if there are no results for both sellerResults and spareResults
  const noResults = combinedResults.sellerResults.length === 0 && combinedResults.spareResults.length === 0;

  return (
      <>
          {/* {loading && <Loader />} */}
          {/* {seeMore && (
              <DeliveryLocation modalAction={handleSeeMore} pinvalue={onGetPinCodeHandler} />
          )}
          <div className="container-fluid col-cust">
              <div className="max-container my-5">
                  <Breadcrumbs backnavi={() => navigate('/store')} boldtitle={"Tools Listing"} items={breadcrumbsItems} />
                  <div className="head-wrap">
                      <div className="heading-wrap">
                          <div className="sub-heading heading-400-16">Search Result for</div>
                          <div className="heading heading-600-20">{searchInput}</div>
                      </div>
                      <div className="btn-wrap">
                          <button onClick={() => { handleSeeMore(true) }} className="btn location">
                              {deliveryLocation ? `Delivery Location : ${deliveryLocation}` : 'Set Delivery Location'}
                          </button>
                      </div>
                  </div>

                  <div className='row layout-wrap'>
                      <div className='col col-lg-3 col-md-3 col-12'>
                          <div className="filter-wrap">
                              <div className="head">
                                  <div className="heading heading-600-20">
                                      {filterIcon({ width: 18, height: 12 })}
                                      Filters
                                  </div>
                              </div>
                              {SparefilterConfigurations?.map((config) => (
                                  <FilterSection
                                      key={config.filterType}
                                      title={config.title}
                                      attribute={config.attribute}
                                      filterType={config.filterType}
                                      activeFilters={activeFilters}
                                      setIndustryCheckBoxValue={setIndustryCheckBoxValue}
                                      CategoryCheckBoxValue={CategoryCheckBoxValue}
                                      setCategoryCheckBoxValue={setCategoryCheckBoxValue}
                                      handleFilters={handleFilters}
                                  />
                              ))}
                          </div>
                      </div>

                      <div className="col col-lg-9 col-md-9 col-12"> */}
                          {combinedResults.sellerResults.length > 0 && (
                              <div className="product-wrap">
                                  <div className="products">
                                      {(showAllSeller ? combinedResults.sellerResults : combinedResults.sellerResults.slice(0, 3)).map((result, index) => (
                                          <div className="product" key={index}>
                                              <div className="product-inner">
                                                  <img className="product-img" src={result.thumbnail} alt={result.name} />
                                                  <div className="content">
                                                      <div className="name">
                                                          {result?.productName?.length > 18
                                                              ? `${result?.productName?.substring(0, 18)}...`
                                                              : result?.productName}
                                                      </div>
                                                      {result.attributes && (result.attributes["Brands"] || result.attributes["Machine Location"]) && (
                                            <div className="location">
                                                {result.attributes["Brands"]} | {result.attributes["Machine Location"]}
                                            </div>
                                        )}
                                                   
                                                      <div className="price-wrap">
                                            <div className="time">{result.mfgYear === 1900 ? "N/A" : `${findYearDifference(result.mfgYear)} Year Old`}</div>
                                            <div className="price">₹{priceConvert(result.grossPrice)}</div>
                                            {/* <div className="categorie"> {product.categories['lvl0']}</div> */}
                                        </div>
                                        <button
                                            className={`heart ${WishListData.includes(result.productId) || result.favorite ? "favorite" : ""}`}
                                            type="button"
                                            onClick={() => onSubmitHandler(result, WishListData.includes(result.productId), index)}
                                        >
                                            {heartIcon({ width: 25, onClick: () => handleFavorite(index, !result.favorite) })}
                                        </button>
                                        {/* <button className={`heart ${product.favorite ? "favorite" : ""}`} type="button" onClick={() => onSubmitHandler(product,WishListData.includes(product.productId))}>{heartIcon({ width: 25, onClick: () => handleFavorite(index, !product.favorite) })}</button> */}
                                        <button className="purchase" key={index} onClick={() => handleAvailServiceseller(result)} type="button">Buy Machine</button>
                                                  </div>
                                              </div>
                                          </div>
                                      ))}
                                  </div>
                                  {!showAllSeller && combinedResults.sellerResults.length > 3 && (
                                      <button className="buttonviewall" onClick={viewAllforSeller}>View All</button>
                                  )}
                              </div>
                          )}

                          {combinedResults.spareResults.length > 0 && (
                              <div className="product-wrap">
                                  <div className="products">
                                      {(showAllSpare ? combinedResults.spareResults : combinedResults.spareResults.slice(0, 3)).map((result, index) => (
                                          <div className="product" key={index}>
                                              <div className="product-inner">
                                                  <img className="product-img" src={result.thumbnail} alt={result.name} />
                                                  <div className="content">
                                                      <div className="name">
                                                          {result?.productName?.length > 18
                                                              ? `${result?.productName?.substring(0, 18)}...`
                                                              : result?.productName}
                                                      </div>
                                                      <div className="location">
                                                          {(() => {
                                                              const lvl1Category = result?.categories?.lvl1 || "";
                                                              const parts = lvl1Category.split(" > ");
                                                              const lastPart = parts[parts.length - 1];
                                                              return lastPart;
                                                          })()}
                                                      </div>
                                                      <div className="price-wrap">
                                                          <div className="time">{result?.Phase}</div>
                                                          <div className="price">₹{result["grossPrice"]}</div>
                                                      </div>
                                                      <button className={`heart ${result?.favorite ? "favorite" : ""}`} type="button" onClick={() => onSubmitHandler(result)}>{heartIcon({ width: 25, onClick: () => handleFavorite(index, !result.favorite) })}</button>
                                         <div className="buttonsinoneline">
                                        <button className="purchasef pbtnsb" key={index} onClick={() => handleAvailService(result)} type="button">Add to Cart</button>
                                        <button className="purchasef pbtnsb1" key={index} onClick={() => handleBuyNow(result.objectID, quantity, result.productName, result.grossPrice)} type="button">Buy Now</button>

                                        </div>
                                                  </div>
                                              </div>
                                          </div>
                                      ))}
                                  </div>
                                  {!showAllSpare && combinedResults.spareResults.length > 3 && (
                                      <button className="buttonviewall" onClick={viewAllforSpare}>View All</button>
                                  )}
                              </div>
                          )}

{/* {noResults && (
    <NoResultsFound searchResults={[{ nbHits: 0 }, { nbHits: 0 },]} />
)} */}
                      {/* </div> */}
                  {/* </div>
              </div>
          </div> */}
      </>
  );
};

export default CombinedHits;
