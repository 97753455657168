import React, { useEffect, useState } from 'react';
import { filterArrowIcon } from "../../../../helpers/Icons";
import ConnectedCustomRefinementList from "../FilterRefinement";

const FilterSection = ({
  title,
  attribute,
  filterType,
  activeFilters,
  setIndustryCheckBoxValue,
  CategoryCheckBoxValue,
  setCategoryCheckBoxValue,
  handleFilters,
  MainFilters,
  setMainFilters,
}) => {
  // Check if filterType is Price
  const isPriceFilter = filterType === "Price";
  const [selectedValues, setSelectedValues] = useState([]);
  const [visibleItemsCount, setVisibleItemsCount] = useState(3);
  const priceOptions = [
    { name: "Below ₹50,000", value: "0 TO 50000" },
    { name: "₹50,001 to ₹100,000", value: "50001 TO 100000" },
    { name: "₹100,001 to ₹250,000", value: "100001 TO 250000" },
    { name: "₹250,001 to ₹500,000", value: "250001 TO 500000" },
    { name: "₹500,001 to ₹750,000", value: "500001 TO 750000" },
    { name: "₹750,001 to ₹1,000,000", value: "750001 TO 1000000" },
    { name: "Above ₹1,000,001", value: "1000001 TO 10000000" },
  ];

  const handlePrice = (event) => {
    const value = event.currentTarget.value;
    const newSelectedValues = [...selectedValues];
    if (event.currentTarget.checked) {
      newSelectedValues.push(value);
    } else {
      const index = newSelectedValues.indexOf(value);
      newSelectedValues.splice(index, 1);
    }

    setSelectedValues(newSelectedValues);

    let mainFiltersString = "";
    if (newSelectedValues.length > 0) {
      mainFiltersString = newSelectedValues
        .map((val) => `grossPrice:${val}`)
        .join(" OR ");
      mainFiltersString = `(${mainFiltersString})`;
    }

    setMainFilters(mainFiltersString);
  };

  const handleToggleFilter = () => {
    handleFilters(filterType, !activeFilters[filterType]);
  };

  useEffect(() => {
    if (isPriceFilter) {
      console.log("Price Filters : -----------> ",priceOptions.map((option) => option.name));
    }
  }, [title]);

  const [isExpanded,setIsExpanded] = useState(false);

  const handleShowMore = () => {
    setVisibleItemsCount(priceOptions.length); 
    setIsExpanded(true);
  };

  const handleShowLess = () => {
    setVisibleItemsCount(3)
    setIsExpanded(false); 
  }

  return (
    <div className="filter">
      <div className="head">
        <span className="heading-600-16">{title}</span>
        {filterArrowIcon({
          width: 22,
          height: 10,
          className: activeFilters[filterType] ? "open" : "",
          onClick: handleToggleFilter, 
        })}
      </div>


      {/* <div className="head" style={{justifyContent:"normal"}}>
      {filterArrowIcon({
          width: 22,
          height: 10,
          className: activeFilters[filterType] ? "open" : "",
          onClick: handleToggleFilter, 
        })}
        &nbsp;
        <span className="heading-600-16">{title}</span>
       
      </div> */}

      {activeFilters[filterType] && (
        <div className="filter-body">
          {isPriceFilter ? (
            <div className="checkbox-wrap">
            {priceOptions.slice(0, visibleItemsCount).map((option, index) => (
              // <div className="checkbox-item" key={index}>
              //   <label className="heading-400-16">{option.name}</label>
              //   <input
              //     type="checkbox"
              //     value={option.value}
              //     checked={selectedValues.includes(option.value)}
              //     onChange={handlePrice}
              //   />
              // </div>

              <div className="checkbox-item" key={index} style={{justifyContent:"normal"}}>

              <input
                                type="checkbox"
                                value={option.value}
                                checked={selectedValues.includes(option.value)}
                                onChange={handlePrice}
                              /> &nbsp;
                              <label className="heading-400-16">{option.name}</label>
              
                            </div>
            ))}
            
            {priceOptions.length > visibleItemsCount && (
              <button
                onClick={handleShowMore}
                className="load-more-button"
              >
                + {priceOptions.length - visibleItemsCount} more
              </button>
            )}

            {isExpanded && (
                <button
                onClick={handleShowLess}
                className="load-more-button"
              >
                Show less
              </button>
            )}


          </div>
          
          ) : (
            <div className="checkbox-wrap">
              <ConnectedCustomRefinementList
                attribute={attribute}
                limit={100}
                searchable={true}
                setIndustryCheckBoxValue={setIndustryCheckBoxValue}
                CategoryCheckBoxValue={CategoryCheckBoxValue}
                setCategoryCheckBoxValue={setCategoryCheckBoxValue}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FilterSection;
