// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-btn{background:none;padding:0;margin:0;border:none;}
.breadcrumbs-list{display:flex;column-gap:8px;margin:0;padding:0;list-style:none;align-items:center;}
.breadcrumbs-list li{position:relative;padding-right:10px;}
.breadcrumbs-list li::after{content:"/";position:absolute;right:0;top:4px;bottom:3px;font-size:14px;color:#7a7c7f;}
.breadcrumbs-list li:last-child{padding:0;}
.breadcrumbs-list li:last-child::after{content:none;}
.breadcrumbs-list li a{color:#7a7c7f;text-decoration:none;}
.breadcrumbs-list li .active{color:#211E24;}
@media(max-width:767px){
    .breadcrumbs-wrap{max-width:420px;margin:0 auto;width:100%;}
}`, "",{"version":3,"sources":["webpack://./src/component/Buying/MyMachine/Breadcrumbs/Breadcrumbs.css"],"names":[],"mappings":"AAAA,UAAU,eAAe,CAAC,SAAS,CAAC,QAAQ,CAAC,WAAW,CAAC;AACzD,kBAAkB,YAAY,CAAC,cAAc,CAAC,QAAQ,CAAC,SAAS,CAAC,eAAe,CAAC,kBAAkB,CAAC;AACpG,qBAAqB,iBAAiB,CAAC,kBAAkB,CAAC;AAC1D,4BAA4B,WAAW,CAAC,iBAAiB,CAAC,OAAO,CAAC,OAAO,CAAC,UAAU,CAAC,cAAc,CAAC,aAAa,CAAC;AAClH,gCAAgC,SAAS,CAAC;AAC1C,uCAAuC,YAAY,CAAC;AACpD,uBAAuB,aAAa,CAAC,oBAAoB,CAAC;AAC1D,6BAA6B,aAAa,CAAC;AAC3C;IACI,kBAAkB,eAAe,CAAC,aAAa,CAAC,UAAU,CAAC;AAC/D","sourcesContent":[".back-btn{background:none;padding:0;margin:0;border:none;}\n.breadcrumbs-list{display:flex;column-gap:8px;margin:0;padding:0;list-style:none;align-items:center;}\n.breadcrumbs-list li{position:relative;padding-right:10px;}\n.breadcrumbs-list li::after{content:\"/\";position:absolute;right:0;top:4px;bottom:3px;font-size:14px;color:#7a7c7f;}\n.breadcrumbs-list li:last-child{padding:0;}\n.breadcrumbs-list li:last-child::after{content:none;}\n.breadcrumbs-list li a{color:#7a7c7f;text-decoration:none;}\n.breadcrumbs-list li .active{color:#211E24;}\n@media(max-width:767px){\n    .breadcrumbs-wrap{max-width:420px;margin:0 auto;width:100%;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
