import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ColumnOne from "./ColumnOne";
import ColumnTwo from "./ColumnTwo";
import ColumnThree from "./ColumnThree";
import "./AllServiceOrder.css";

const AllServiceOrder = () => {
  const [selectedMachine, setSelectedMachine] = useState("CNC Turning");
  const [selectedServiceType, setSelectedServiceType] = useState("PM");
  const [cartItems, setCartItems] = useState([]);
  const [amcData, setAmcData] = useState([]);
  const [buttonData, setButtonData] = useState([]); // New state for button data
  const [loading, setLoading] = useState(true);
  const [columnGap, setColumnGap] = useState("10px"); // State for column gap
  const typeRefs = useRef({});

  useEffect(() => {
    fetchAMCData(selectedServiceType);
  }, [selectedServiceType]);

  const fetchAMCData = async (planType) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://devextension.origa.market/api/getamcmasterbytype",
        {
          type: "",
          plan_type: planType,
        }
      );
      if (response.data.status === "Success") {
        setAmcData(response.data.jsondata);
        const uniqueTypes = [
          ...new Set(response.data.jsondata.map((item) => item.type)),
        ];
        const buttonData = uniqueTypes.map((type) => {
          const item = response.data.jsondata.find(
            (item) => item.type === type
          );
          return { label: type, value: type, img: item.plan_image };
        });
        setButtonData(buttonData);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (err) {
      console.error("Error fetching data", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectMachine = (machine) => {
    console.log("First a machine being selected : ");
    setSelectedMachine(machine);
    const columnTwoElement = document.querySelector(".service-column-two");
    const targetElement = typeRefs.current[machine];
    if (targetElement && columnTwoElement) {
      columnTwoElement.scrollTo({
        top: targetElement.offsetTop - columnTwoElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleAddToCart = (plan) => {
    //check if there is already id is present in cartItems
    const isItemInCart = cartItems.some((item) => item.id === plan.id);

    //if the id is present is the cart
    if (isItemInCart) {
      setCartItems(
        cartItems.map((item) =>
          //spread operator is used to create new object rather than modifying the the orginal
          //but it inherit the properties of the object and then we can override the orginal when we want it
          item.id === plan.id ? { ...item, quantity: item.quantity + 1 } : item
        )
      );
    } else {
      setCartItems([...cartItems, { ...plan, quantity: 1 }]);
    }
  };

  const handleRemoveFromCart = (plan) => {
    const index = cartItems.findIndex(
      (item) => item.amc_plan_id === plan.amc_plan_id
    );
    if (index !== -1) {
      const newCartItems = [...cartItems];
      newCartItems.splice(index, 1);
      setCartItems(newCartItems);
    }
  };

  const groupedData = amcData.reduce((acc, item) => {
    if (!acc[item.type]) {
      acc[item.type] = [];
    }
    acc[item.type].push(item);
    return acc;
  }, {});

  const handleServiceTypeChange = (type) => {
    setSelectedServiceType(type);
    if (typeRefs.current[type]) {
      typeRefs.current[type].scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="service-all-service-order">
      <div className="service-type-container">
        {/* <label className="service-type-label">Service Type</label> */}
        <div className="service-type-buttons">
          <button
            className={`service-type-button ${
              selectedServiceType === "PM" ? "selected" : ""
            }`}
            onClick={() => handleServiceTypeChange("PM")}
          >
            Preventive
          </button>
          <button
            className={`service-type-button ${
              selectedServiceType === "BD" ? "selected" : ""
            }`}
            onClick={() => handleServiceTypeChange("BD")}
          >
            Breakdown
          </button>
          <button
            className={`service-type-button ${
              selectedServiceType === "AMC" ? "selected" : ""
            }`}
            onClick={() => handleServiceTypeChange("AMC")}
          >
            AMC
          </button>
        </div>
      </div>
      {loading ? (
        <div className="loader">Loading...</div>
      ) : (
        <div className="service-columns-container" style={{ gap: columnGap }}>
          <ColumnOne onSelect={handleSelectMachine} buttons={buttonData} />{" "}
          {/* Pass button data */}
          <div className="service-column service-column-two">
            {Object.keys(groupedData).map((type, index) => (
              <div key={index} ref={(el) => (typeRefs.current[type] = el)}>
                <h2 >{type}</h2>
                {groupedData[type].map((plan, idx) => (
                  <ColumnTwo
                    key={idx}
                    plan={plan}
                    onAddToCart={handleAddToCart}
                    onRemoveFromCart={handleRemoveFromCart}
                  />
                ))}
              </div>
            ))}
          </div>
          <ColumnThree
            cartItems={cartItems}
            onAddToCart={handleAddToCart}
            onRemoveFromCart={handleRemoveFromCart}
          />
        </div>
      )}
      {cartItems.length > 0 && (
        <div className="service-request-buttons">
          <button className="service-request-button">
            Request for call back
          </button>
          <button className="service-request-button">Request for quote</button>
        </div>
      )}
    </div>
  );
};

export default AllServiceOrder;
