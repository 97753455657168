import React from 'react'
import { Container,Accordion  } from 'react-bootstrap'
const ReturnPolicy = () => {
    const message1=`This Origa.Market Shipping Policy is applicable for all order placed on www.origa.market
(“website”) by the customer(“you”) and form an integral part of the Term of Use Policy as provided
on www.origa.market. This Origa.Market Shipping Policy should be read in conjunction and
together with the Terms of Use of the Website.`
    const message2=`The Personal Information which You may provide to us and/or which we may collect is or could be the following:` 
    const list1=`The shipping/logistics/delivery of the product shall be undertaken by third party service providers
appointed by the OEM/Dealers of the product purchased by you.`
   const list2=`The estimated cost of shipping is mentioned on the check out page at the time of order placement.
Estimated delivery timelines are mentioned on the product page. The estimated cost of shipping and
delivery timelines are determined basis delivery locations. The shipment costs and delivery
timelines are only estimates and the actual shipping cost and delivery timeline may vary depending
upon the delivery location and other factors. You will be responsible to pay all the duties, taxes,
charges, tolls etc as maybe payable to ship the products to the delivery location. Transit insurance,
if any, will be procured at additional costs, payable by you.`
   const list3=`Origa.Market will endeavor to provide you periodic updates on the shipping costs and delivery
timelines. Origa.Market is not responsible for any delay in delivery of the product and/or increased
shipping costs.`
   const list4=`Origa.Market will not be liable to cancel any order and/or provide any refund, damages and/or
compensation in the event of (i) increased shipping costs vis a vis the estimated shipping costs;
and/or (ii) delay in delivery of the product.`
    const list5=`
    You will be liable to promptly bear additional costs as maybe determined by Origa.Market in
consultation with the OEM/Dealers of the product purchased by you, in the event of (i) provision of
incorrect delivery address at the time of checkout, and such error is determined once the shipment
costs is paid by the you; (ii) change in delivery address once the shipment costs is paid by the you.
Origa.Market is not liable for any products including any of its part, spares etc damaged or lost
during shipping. If you receive any damaged product, please contact us at info.origaleasing.com
along with the images of the damaged product. Once the complaint is received, Origa.Market shall
align the OEM/Dealers of the product purchased by you, to verify and assess the damage, and take
corrective actions as maybe necessary, if the damage is accepted by the OEM/Dealer. The onus to
prove that the product is not damaged shall solely lie on the OEM/Dealer. In the event the
packaging material and/or damaged product is discarded by you, then you will not have the right to
raise grievance for damaged product and/or seek any relief. The damaged product will be dealt
with as per the guarantee/warrantee terms and/or the shipment policy, if any, provided by the
OEM/Dealers for the product.`
const list6=`You understand and agree that Origa.Market disclaims all liabilities that may accrue due to (i)
increased shipping costs; (ii) delay in delivery and (iii) damage to product in shipping/transit.`
   const list=[{heading:'Shipping and Return Policy'}]
    return (
        <Container className='privacypolicy-maindiv'>
        {list.map((products,index)=>(
            <Accordion  flush className='accordionborder'>
      <Accordion.Item eventKey={index} >
        <Accordion.Header className="accordion-header">{products.heading}</Accordion.Header>
        <Accordion.Body>
            
            <p className='privacypolicy-para'>{message1}</p>
            <p className='privacypolicy-para'>&nbsp;&nbsp;<p className='listp'>{list1}</p></p>
            <p className='privacypolicy-para'>&nbsp;&nbsp;<p className='listp'>{list2}</p></p>
            <p className='privacypolicy-para'>&nbsp;&nbsp;<p className='listp'>{list3}</p></p>
            <p className='privacypolicy-para'>&nbsp;&nbsp;<p className='listp'>{list4}</p></p>
            <p className='privacypolicy-para'>&nbsp;&nbsp;<p className='listp'>{list5}</p></p>
            <p className='privacypolicy-para'>&nbsp;&nbsp;<p className='listp'>{list6}</p></p>
            {/*<h1 className='privacypolicy-heading'>Information Collection and Use</h1>
         <Container>
            <p className='privacypolicy-para'>{message2}</p>
            <p className='privacypolicy-para'>&nbsp;&nbsp;1.<p className='listp'>{list1}</p></p>
            <p className='privacypolicy-para'>&nbsp;&nbsp;2.<p className='listp'>{list2}</p></p>
            <p className='privacypolicy-para'>&nbsp;&nbsp;3.<p className='listp'>{list3}</p></p>
            <p className='privacypolicy-para'>&nbsp;&nbsp;4.<p className='listp'>{list4}</p></p>
            <p className='privacypolicy-para'>{message3}</p>
        </Container> */}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
        ))}
    </Container>
  )
}

export default ReturnPolicy