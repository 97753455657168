// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listofservicewithoutdes{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

/* .listofservicewithoutdes img{
    width: 5.625rem;
    height: 5.625rem;
} */

.listofservicewithoutdes-title{
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0em;
    text-align: center;
}


`, "",{"version":3,"sources":["webpack://./src/component/HomePage/Eighth/ListofserviceWithoutdes/Listofservicewithoutdes.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;;;GAGG;;AAEH;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".listofservicewithoutdes{\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: column;\n    align-items: center;\n}\n\n/* .listofservicewithoutdes img{\n    width: 5.625rem;\n    height: 5.625rem;\n} */\n\n.listofservicewithoutdes-title{\n    font-family: Poppins;\n    font-size: 1rem;\n    font-weight: 400;\n    line-height: 1.5rem;\n    letter-spacing: 0em;\n    text-align: center;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
