
import React,{useEffect,useRef,useState} from 'react'
import HomePageFifthSection from '../NewHomePage/HomePageFifthSection'
import FinancefirstSection from '../Finance/FinancefirstSection'
import { newHomePage1,newHomePage2,newHomePage3,newHomePage4,newHomePage5,qCheck,qCall,qFinance,qValue,qHub,closeIcon} from '../../helpers/Icons'
import HomePageBanner from './HomePageBanner'
// import NewFooter from '../Footer/NewFooter'
import Footer from '../Footer/Footer'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import popupImage from '/asset/popup-image.png'; 
import './NewHomePage.css';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import HotDealsPromotions from './HotDealsPromotions'
import HotDealsPromotionNew from './HotDealsPromotionNew'

const NewHomePage = () => {
    const [showPopup, setShowPopup] = useState(false);
    const items = [
        { imagesrc: newHomePage1({ width: 60, height: 60 }), title: "Buy Machine", para: "50 + Brands Available", navi: "/buy", btnname: "Buy Now" },
        { imagesrc: newHomePage3({ width: 60, height: 60 }), title: "Capex Finance <br /> Lease & Loan", para: "400 + Equipment Financed", navi: "/finance", btnname: "Apply Now" },
        { imagesrc: newHomePage2({ width: 60, height: 60 }), title: "Machine Service", para: "500 + Satisfied Customers", navi: "/service", btnname: "Book Now" },
        {imagesrc: newHomePage5({width:60, height:60}),title:"Industrial Lubricants",para:"Cutting & Hydraulic oil",navi: "/store", btnname: "Buy Now"},
        { imagesrc: newHomePage4({ width: 60, height: 60 }), title: "Sell Machine", para: "1500  Machines  + Daily Visitors", navi: "/sell", btnname: "Sell Now" }
    ]

    const bannerTopSection={title:"India’s 1st One Stop Platform for All Equipment Needs",para:"We strive for your satisfaction, making us the preferred choice for reliable and affordable equipment solutions"}
    
    const product={  imgurl:"/origaReach.svg",heading: "We cater to over 35 Industries",
    para: "From Industrial Engineering, Medical, Pharma to Automotive and many more"}
    const topSliderData = [
        { message: "Machine Tool",imgsrc:"/asset/Machine Tool.png" },{ message: "Medical",imgsrc:"/asset/Medical.png" },{ message: "Construction",imgsrc:"/asset/Construction.png" },
        { message: "IT & Technology",imgsrc:"/asset/IT and Technology.png" },{ message: "Automative",imgsrc:"/asset/Automative.png" },{ message: "Food & Beverages",imgsrc:"/asset/Food and Beverages.png" },
        { message: "Machine Tool",imgsrc:"/asset/Machine Tool.png" },{ message: "Medical",imgsrc:"/asset/Medical.png" },{ message: "Construction",imgsrc:"/asset/Construction.png" },
        { message: "IT & Technology",imgsrc:"/asset/IT and Technology.png" },{ message: "Automative",imgsrc:"/asset/Automative.png" },{ message: "Food & Beverages",imgsrc:"/asset/Food and Beverages.png" },
        { message: "Machine Tool",imgsrc:"/asset/Machine Tool.png" },{ message: "Medical",imgsrc:"/asset/Medical.png" },{ message: "Construction",imgsrc:"/asset/Construction.png" },
        { message: "IT & Technology",imgsrc:"/asset/IT and Technology.png" },{ message: "Automative",imgsrc:"/asset/Automative.png" },{ message: "Food & Beverages",imgsrc:"/asset/Food and Beverages.png" },
        { message: "Machine Tool",imgsrc:"/asset/Machine Tool.png" },{ message: "Medical",imgsrc:"/asset/Medical.png" },{ message: "Construction",imgsrc:"/asset/Construction.png" },
        { message: "IT & Technology",imgsrc:"/asset/IT and Technology.png" },{ message: "Automative",imgsrc:"/asset/Automative.png" },{ message: "Food & Beverages",imgsrc:"/asset/Food and Beverages.png" },
        { message: "Machine Tool",imgsrc:"/asset/Machine Tool.png" },{ message: "Medical",imgsrc:"/asset/Medical.png" },{ message: "Construction",imgsrc:"/asset/Construction.png" },
        { message: "IT & Technology",imgsrc:"/asset/IT and Technology.png" },{ message: "Automative",imgsrc:"/asset/Automative.png" },{ message: "Food & Beverages",imgsrc:"/asset/Food and Beverages.png" },
        ];
        const bottomSliderData = [
        { message: "Industrial Engineering",imgsrc:"/asset/Industrial Engineering.png" },{ message: "Defense",imgsrc:"/asset/Defense.png" },{ message: "Power & Energy",imgsrc:"/asset/Power and Energy.png" },
        { message: "Pharma",imgsrc:"/asset/Pharma.png"  },{ message: "Logistics & Transportation",imgsrc:"/asset/Logistics and Transportation.png" },{ message: "Office",imgsrc:"/asset/Office.png" },
        { message: "Industrial Engineering",imgsrc:"/asset/Industrial Engineering.png" },{ message: "Defense",imgsrc:"/asset/Defense.png" },{ message: "Power & Energy",imgsrc:"/asset/Power and Energy.png" },
        { message: "Pharma",imgsrc:"/asset/Pharma.png"  },{ message: "Logistics & Transportation",imgsrc:"/asset/Logistics and Transportation.png" },{ message: "Office",imgsrc:"/asset/Office.png" },
        { message: "Industrial Engineering",imgsrc:"/asset/Industrial Engineering.png" },{ message: "Defense",imgsrc:"/asset/Defense.png" },{ message: "Power & Energy",imgsrc:"/asset/Power and Energy.png" },
        { message: "Pharma",imgsrc:"/asset/Pharma.png"  },{ message: "Logistics & Transportation",imgsrc:"/asset/Logistics and Transportation.png" },{ message: "Office",imgsrc:"/asset/Office.png" },
        { message: "Industrial Engineering",imgsrc:"/asset/Industrial Engineering.png" },{ message: "Defense",imgsrc:"/asset/Defense.png" },{ message: "Power & Energy",imgsrc:"/asset/Power and Energy.png" },
        { message: "Pharma",imgsrc:"/asset/Pharma.png"  },{ message: "Logistics & Transportation",imgsrc:"/asset/Logistics and Transportation.png" },{ message: "Office",imgsrc:"/asset/Office.png" },
        { message: "Industrial Engineering",imgsrc:"/asset/Industrial Engineering.png" },{ message: "Defense",imgsrc:"/asset/Defense.png" },{ message: "Power & Energy",imgsrc:"/asset/Power and Energy.png" },
        { message: "Pharma",imgsrc:"/asset/Pharma.png"  },{ message: "Logistics & Transportation",imgsrc:"/asset/Logistics and Transportation.png" },{ message: "Office",imgsrc:"/asset/Office.png" },
        ];
    const advantageData = [
        {title: "",description: "50+ quality checks on machines",icon: qCheck({width:60,height:60})},
        {title: "",description: "Quick 24-hour Response To Machine Breakdowns",icon: qCall({width:60,height:60})},
        {title: "",description: "Customised Financial Solutions",icon:  qFinance({width:60,height:60})},
        {title: "",description: "Best value for your machine",icon: qValue({width:60,height:60})},
        {title: "",description: "Unified Hub for Tools, Spares & Consumables",icon:  qHub({width:60,height:60})}
      ];
      const advantageTopSection={imgurl:"/OrigaWhy.svg",title:"Origa Advantage",para:"Step into a World of Superior Equipment Management Experience"}
  /*   useEffect(() => {
    window.scrollTo(0,0)
    }, []) */


    /* useEffect(() => {
        window.scrollTo(0, 0);
        const timer = setTimeout(() => {
            setShowPopup(true);
        }, 5000);
        return () => clearTimeout(timer);
    }, []); */
    const hotDealsData = [
        { imgsrc: "HotDeals/first.jpg", alt: "CNC VMC Lubricants Offer" },
        { imgsrc: "HotDeals/second.jpg", alt: "CNC VMC Services Offer" },
        { imgsrc: "HotDeals/third.jpg", alt: "Capex Finance Offer" },
        /* { imgsrc: "HotDeals/fourth.webp", alt: "Deal 4" }, */
        // ... other deals
    ];

    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false
                }
            }
        ]
    };
    
    useEffect(() => {
        window.scrollTo(0, 0);
        setShowPopup(true);
        const timer = setTimeout(() => {
            setShowPopup(false);
        }, 500000);
        return () => clearTimeout(timer);
    }, []);

    const images = [
        { original: "asset/first.jpg" },
        { original: "asset/second.jpg" },
        { original: "asset/third.jpg" },
        { original: "asset/Fourth.jpg" },

        // { imgsrc: "HotDeals/first.webp", alt: "Deal 1" },
        // { imgsrc: "HotDeals/second.webp", alt: "Deal 2" },
        // { imgsrc: "HotDeals/third.webp", alt: "Deal 3" },
        // { imgsrc: "HotDeals/fourth.webp", alt: "Deal 4" },
      ];
    
      const responsive = {
        0: { items: 1 },
        568: { items: 2 },  
        1024: { items: 3 },
      };
      const itemssj = images.map((image, index) => (
        <div className="custom-image-containerdf" key={index}> {/* Margin for gap */}
            <div className="custom-image-wrapperljsfbh">
                <img src={image.original} alt="Product" className="fbcustom-image" />
            </div>
        </div>
      ));
      

      const deals = [
        // { original: "asset/first.jpg" },
        { original: "asset/second.jpg" },
        { original: "asset/third.jpg" },
        { original: "asset/Fourth.jpg" },

      ];
    
      const itemssfbdbfdx = deals.map((deal, index) => (
        <div className="deal-item" key={index}>
           <img src={deal.original} alt="Product" className="deal-image" />
        </div>
      ));

         /* <div className="deal-content">
            <h3>{deal.title}</h3>
            {deal.offer && <p className="offer">{deal.offer}</p>}
            {deal.date && <p className="date">{deal.date}</p>}
            {deal.price && <p className="price">{deal.price}</p>}
            {deal.subtitle && <p className="subtitle">{deal.subtitle}</p>}
          </div> */
    
    
      
      const carouselRef = useRef(null);
      
      

      return (
        <>
        <HomePageBanner items={items} bannerTopSection={bannerTopSection}/>
        {/* <div className="hot-deals-section">
                <h2 className="text-right">Hot Deals & Promotions</h2>
                <Slider {...sliderSettings}>
                    {hotDealsData.map((deal, index) => (
                        <div key={index}>
                            <img src={deal.imgsrc} alt={deal.alt} style={{ width: '420px', height: '250px' }}/>
                        </div>
                    ))}
                </Slider>
            </div> */}
            
            {/* <div className="hot-deals-section d-flex justify-content-center">
                <h5 className="text-center">Hot Deals & Promotions</h5>

                <Slider {...sliderSettings}>
                    {hotDealsData.map((deal, index) => (
                        <div key={index} className="hot-deal-item">
                            <img src={deal.imgsrc} alt={deal.alt} className="hot-deal-image"/>
                        </div>
                    ))}
                </Slider>
            </div> */}

{/* 
<h5 className="text-center">Hot Deals & Promotions</h5>
            <div className="carousel-wrapper" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>

<AliceCarousel
  ref={carouselRef}
  mouseTracking
  items={itemssj}
  responsive={responsive}
  controlsStrategy="alternate"
  infinite
  disableDotsControls
  disableButtonsControls
/>

</div>
             */}
{/* 
<div className="hot-deals-section">
      <h5 className="section-title">Hot Deals & Promotions</h5>
      <div className="carousel-wrapper">
        <AliceCarousel
          ref={carouselRef}
          mouseTracking
          items={itemssfbdbfdx}
          responsive={responsive}
          controlsStrategy="alternate"
          infinite
          disableDotsControls
          disableButtonsControls
          
        />
      </div>
    </div> */}
{/* 
    <HotDealsPromotions /> */}


<HotDealsPromotionNew />

{/* <HotDealsPromotionNew /> */}
    <FinancefirstSection  topSliderData={topSliderData} product={product} bottomSliderData={bottomSliderData}/>

    <div className="container-fluid sectionfour">
    <div className="max-container text-center">
    <img src={advantageTopSection.imgurl} alt='whyOriga'/>
    <div className='text-center heading-600-24-20 c-green'>{advantageTopSection.title}</div>
    <div className='heading-400-16-14 op-80 text-center pt-12'>{advantageTopSection.para}</div>
        <div className="row mt-32-992">
            {advantageData.map((advantage,index) => (
                <div key={index} className="col-lg-2 col-6 mx-auto step-p-16">
                    <div className="cust-box-card">
                        <div className="svg-icon mb-2" >{advantage.icon}</div>
                        <div className="title heading-600-16-14">{advantage.title}</div>
                        <div className="desc heading-400-14-12 op-80">{advantage.description}</div>
                    </div>
                </div>
            ))}
        </div>
    </div>
</div>
    
    <HomePageFifthSection title={"Looking for a specific Brand?"} para={"From Machines to tools to finance everything you need in one place"} searchHide={true}/>
    <Footer/>   
    {/* {showPopup && (
                <div className="popup-banner">
                    <div className="popup-content">
                        <img src='/asset/popup-image.png' alt="Special Offer" />
                     
                        <div className="close-popup" onClick={() => setShowPopup(false)}>
                            {closeIcon({ width: 14, height: 14 })}
                        </div>
                    </div>
                </div>
            )} */}
        </>
      )
    }
export default NewHomePage