// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hot-deals-section {
    display: flex;

    align-items: center;
    flex-direction: column;
}

.hot-deals-section .slick-slider {
    width: 100%; /* Ensure the slider takes the full width */
}

.hot-deals-section .slick-list {
    display: flex;
    justify-content: center; /* Center the slides within the slider */
}

.hot-deals-section .slick-slide {
    margin: 0 5px !important; /* Use !important to override default styles */
    width: auto !important; /* Use !important to override default styles */
}

.hot-deal-item {
    padding: 0;
    width: 420px; /* Ensure the container has the same width as the image */
    height: 250px; /* Ensure the container has the same height as the image */
}

.hot-deal-image {
    border-radius: 15px; /* Adjust the value for more or less curvature */
    display: block;
    margin: 0 auto;
    width: 420px; /* Ensure the container has the same width as the image */
    height: 250px; /* Ensure the container has the same height as the image */
}`, "",{"version":3,"sources":["webpack://./src/component/NewHomePage/NewHomePage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;IAEb,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,WAAW,EAAE,2CAA2C;AAC5D;;AAEA;IACI,aAAa;IACb,uBAAuB,EAAE,wCAAwC;AACrE;;AAEA;IACI,wBAAwB,EAAE,8CAA8C;IACxE,sBAAsB,EAAE,8CAA8C;AAC1E;;AAEA;IACI,UAAU;IACV,YAAY,EAAE,yDAAyD;IACvE,aAAa,EAAE,0DAA0D;AAC7E;;AAEA;IACI,mBAAmB,EAAE,gDAAgD;IACrE,cAAc;IACd,cAAc;IACd,YAAY,EAAE,yDAAyD;IACvE,aAAa,EAAE,0DAA0D;AAC7E","sourcesContent":[".hot-deals-section {\r\n    display: flex;\r\n\r\n    align-items: center;\r\n    flex-direction: column;\r\n}\r\n\r\n.hot-deals-section .slick-slider {\r\n    width: 100%; /* Ensure the slider takes the full width */\r\n}\r\n\r\n.hot-deals-section .slick-list {\r\n    display: flex;\r\n    justify-content: center; /* Center the slides within the slider */\r\n}\r\n\r\n.hot-deals-section .slick-slide {\r\n    margin: 0 5px !important; /* Use !important to override default styles */\r\n    width: auto !important; /* Use !important to override default styles */\r\n}\r\n\r\n.hot-deal-item {\r\n    padding: 0;\r\n    width: 420px; /* Ensure the container has the same width as the image */\r\n    height: 250px; /* Ensure the container has the same height as the image */\r\n}\r\n\r\n.hot-deal-image {\r\n    border-radius: 15px; /* Adjust the value for more or less curvature */\r\n    display: block;\r\n    margin: 0 auto;\r\n    width: 420px; /* Ensure the container has the same width as the image */\r\n    height: 250px; /* Ensure the container has the same height as the image */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
