import React, { useEffect, useRef } from "react";
import "./ScrollableColumnLayout.css"; 

const ScrollableColumnLayout = () => {
  //ref is used to create reference to a dom element
  //it won’t be reset every time the component re-renders.
  //We can also get specific control over scrolling sidebar.

  const sidebarRef = useRef(null);//to access specific dom elements
  const contentRef = useRef(null);//normally set to null but once the elements are rendered  , React attaches the actual DOM node to these references


  
  useEffect(() => {
    const handleScroll = () => {//runs every time the user scrolls
      const sidebar = sidebarRef.current;//access the actua DOM elements using the current property of useRef
      const sidebarContent = contentRef.current;//and this is how we react with sidebar and content

      if (!sidebar || !sidebarContent) return;

      const scrollTop = window.scrollY;//how much the user has scrolled in Y axis
      const viewportHeight = window.innerHeight;//This is the height of the visible part of the browser window
      const sidebarTop = sidebar.getBoundingClientRect().top + window.pageYOffset;
      const contentHeight = sidebarContent.getBoundingClientRect().height;

      //Checking Scroll Position
      //check if the user has scrolled enough down the page that the bottom of the sidebar content is visible
      if (scrollTop >= contentHeight - viewportHeight + sidebarTop) {//checks the difference between the height of the content and the visible part of the screen.
        sidebarContent.style.transform = `translateY(-${contentHeight - viewportHeight + sidebarTop}px)`;
        sidebarContent.style.position = "fixed";
      } else {
        sidebarContent.style.transform = "";
        sidebarContent.style.position = "";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="bofsfu">
      <div className="maidvsn">
        {[...Array(15)].map((_, index) => (
          <div key={index}></div>
        ))}
      </div>

      <div className="sidebarsfdvvdd" ref={sidebarRef}>
        <div className="content-wrapper" ref={contentRef}>
          {[...Array(3)].map((_, index) => (
            <div key={index}></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScrollableColumnLayout;
