import React, { useEffect, useRef, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import "./HotDeals.css";
import { IoChevronBackSharp } from "react-icons/io5";
import { IoChevronForward } from "react-icons/io5";


const HotDealsPromotions = () => {
  const promotions = [
    {
      imageUrl: 'asset/first.jpg',
    },
    {
      imageUrl: 'asset/second.jpg',
    },
    {
      imageUrl: 'asset/third.jpg',
    },
    {
      imageUrl: 'asset/fourth.jpg',
    },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 829);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const displayedPromotions = isMobile ? promotions : promotions.slice(0, 3);

  const advantageTopSection={imgurl:"/OrigaWhy.svg",title:"Origa Advantage",para:"Step into a World of Superior Equipment Management Experience"}


  return (

<>

<div className="hot-deals-container">
<img src={advantageTopSection.imgurl} alt=''/>
      <h2 className=" text-left heading-600-24-20 c-green">Hot Deals & Promotions</h2>
      <div className="promotions-wrapper">
  <button className="nav-button prev">
    <IoChevronBackSharp  />
  </button>
  {displayedPromotions.map((promotion, index) => (
    <div className='promotion-card-upper' key={index}>
      <div className="promotion-card">
        <img src={promotion.imageUrl} alt={promotion.title} className="promotion-image" />
      </div>
    </div>
  ))}
  <button className="nav-button next">
    <IoChevronForward />
  </button>
</div>
    </div>

  </>
  );
};

export default HotDealsPromotions;
