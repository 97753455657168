import "./IconStyles/ArrowIcon.css";

import "./IconStyles/RatingStars.css";


import "./IconStyles/DetailsIcon.css";


import "./IconStyles/DropdownArrowIcon.css";


const getLocation = async () => {
  try {
    if (navigator.geolocation) {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const latitude = "12.937449";
      const longitude = "80.235013";

      const response = await fetch(
        `http://3.109.71.129:8002/api/getlatlngpincode`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ latitude, longitude }),
        }
      );

      const responseData = await response.json();
      //setDeliveryLocation(responseData?.postal_code)
      console.log("API response:", responseData?.postal_code);
      localStorage.setItem("CurrentLocation", responseData?.postal_code);

      // Handle response data here
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  } catch (error) {
    console.error("Error getting location:", error.message);
  }
};



export const ArrowIcon = ({ className = "" }) => {
    return (
      <div className={`${className}`}>
        <svg width="100%" height="100%" style={{ overflow: "visible" }} preserveAspectRatio="none" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 3.5L11 8.5L6 13.5" stroke="black" strokeWidth="1.5" />
        </svg>
      </div>
    );
  };


  export const RatingStarsv = ({ className = "" }) =>  {
    return (
      <div className={`${className}`}>
        <svg width="100%" height="100%" style={{"overflow":"visible"}} preserveAspectRatio="none" viewBox="0 0 96 15.2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_15_415)">
        <path d="M7.25672 1.4343C7.53172 0.781127 8.46832 0.781128 8.74332 1.4343L10.3663 5.28942C10.4823 5.56478 10.7445 5.75292 11.0454 5.77675L15.2582 6.11041C15.9719 6.16695 16.2614 7.04694 15.7175 7.50716L12.5079 10.2234C12.2786 10.4174 12.1784 10.7219 12.2485 11.0119L13.2291 15.0733C13.3952 15.7613 12.6375 16.3053 12.0264 15.9365L8.41965 13.7601C8.16202 13.6046 7.83802 13.6046 7.5804 13.7601L3.9736 15.9365C3.3625 16.3053 2.60477 15.7613 2.77091 15.0733L3.75155 11.0119C3.82159 10.7219 3.72147 10.4174 3.49221 10.2234L0.28245 7.50716C-0.261375 7.04694 0.0280544 6.16695 0.741835 6.11041L4.9547 5.77675C5.25561 5.75292 5.51774 5.56478 5.63367 5.28942L7.25672 1.4343Z" fill="black"/>
        <path d="M27.2567 1.4343C27.5317 0.781127 28.4683 0.781128 28.7433 1.4343L30.3663 5.28942C30.4823 5.56478 30.7445 5.75292 31.0454 5.77675L35.2582 6.11041C35.9719 6.16695 36.2614 7.04694 35.7175 7.50716L32.5079 10.2234C32.2786 10.4174 32.1784 10.7219 32.2485 11.0119L33.2291 15.0733C33.3952 15.7613 32.6375 16.3053 32.0264 15.9365L28.4196 13.7601C28.162 13.6046 27.838 13.6046 27.5804 13.7601L23.9736 15.9365C23.3625 16.3053 22.6048 15.7613 22.7709 15.0733L23.7515 11.0119C23.8216 10.7219 23.7215 10.4174 23.4922 10.2234L20.2825 7.50716C19.7386 7.04694 20.0281 6.16695 20.7418 6.11041L24.9547 5.77675C25.2556 5.75292 25.5177 5.56478 25.6337 5.28942L27.2567 1.4343Z" fill="black"/>
        <path d="M47.2567 1.4343C47.5317 0.781127 48.4683 0.781128 48.7433 1.4343L50.3663 5.28942C50.4823 5.56478 50.7445 5.75292 51.0454 5.77675L55.2582 6.11041C55.9719 6.16695 56.2614 7.04694 55.7175 7.50716L52.5079 10.2234C52.2786 10.4174 52.1784 10.7219 52.2485 11.0119L53.2291 15.0733C53.3952 15.7613 52.6375 16.3053 52.0264 15.9365L48.4196 13.7601C48.162 13.6046 47.838 13.6046 47.5804 13.7601L43.9736 15.9365C43.3625 16.3053 42.6048 15.7613 42.7709 15.0733L43.7515 11.0119C43.8216 10.7219 43.7215 10.4174 43.4922 10.2234L40.2825 7.50716C39.7386 7.04694 40.0281 6.16695 40.7418 6.11041L44.9547 5.77675C45.2556 5.75292 45.5177 5.56478 45.6337 5.28942L47.2567 1.4343Z" fill="black"/>
        <mask id="path-4-inside-1_15_415" fill="white">
        <path d="M67.2567 1.4343C67.5317 0.781127 68.4683 0.781128 68.7433 1.4343L70.3663 5.28942C70.4823 5.56478 70.7445 5.75292 71.0454 5.77675L75.2582 6.11041C75.9719 6.16695 76.2614 7.04694 75.7175 7.50716L72.5079 10.2234C72.2786 10.4174 72.1784 10.7219 72.2485 11.0119L73.2291 15.0733C73.3952 15.7613 72.6375 16.3053 72.0264 15.9365L68.4196 13.7601C68.162 13.6046 67.838 13.6046 67.5804 13.7601L63.9736 15.9365C63.3625 16.3053 62.6048 15.7613 62.7709 15.0733L63.7515 11.0119C63.8216 10.7219 63.7215 10.4174 63.4922 10.2234L60.2825 7.50716C59.7386 7.04694 60.0281 6.16695 60.7418 6.11041L64.9547 5.77675C65.2556 5.75292 65.5177 5.56478 65.6337 5.28942L67.2567 1.4343Z"/>
        </mask>
        <path d="M67.2567 1.4343C67.5317 0.781127 68.4683 0.781128 68.7433 1.4343L70.3663 5.28942C70.4823 5.56478 70.7445 5.75292 71.0454 5.77675L75.2582 6.11041C75.9719 6.16695 76.2614 7.04694 75.7175 7.50716L72.5079 10.2234C72.2786 10.4174 72.1784 10.7219 72.2485 11.0119L73.2291 15.0733C73.3952 15.7613 72.6375 16.3053 72.0264 15.9365L68.4196 13.7601C68.162 13.6046 67.838 13.6046 67.5804 13.7601L63.9736 15.9365C63.3625 16.3053 62.6048 15.7613 62.7709 15.0733L63.7515 11.0119C63.8216 10.7219 63.7215 10.4174 63.4922 10.2234L60.2825 7.50716C59.7386 7.04694 60.0281 6.16695 60.7418 6.11041L64.9547 5.77675C65.2556 5.75292 65.5177 5.56478 65.6337 5.28942L67.2567 1.4343Z" fill="black" stroke="black"strokeWidth="2" mask="url(#path-4-inside-1_15_415)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M95.9144 6.556L95.9782 6.756C96.0307 6.90695 95.9865 7.07472 95.8665 7.18L91.9892 10.5L93.138 15.476C93.1769 15.635 93.1172 15.802 92.9864 15.9L92.8109 16.02C92.7427 16.0736 92.6582 16.1018 92.5716 16.1C92.4985 16.1015 92.4266 16.0821 92.3642 16.044L88.0002 13.38L83.6601 16.044C83.5977 16.0821 83.5257 16.1015 83.4527 16.1C83.3661 16.1018 83.2815 16.0736 83.2133 16.02L83.0139 15.9C82.8831 15.802 82.8234 15.635 82.8623 15.476L84.0111 10.5L80.1418 7.188C80.0156 7.08334 79.9678 6.91092 80.0221 6.756L80.1099 6.556C80.1565 6.39794 80.2968 6.28604 80.4609 6.276L85.5589 5.868L87.5135 1.156C87.5761 0.999515 87.7283 0.897785 87.8965 0.899999H88.1039C88.2705 0.896453 88.4211 0.999237 88.4788 1.156L90.4654 5.868L95.5634 6.276C95.7275 6.28604 95.8677 6.39794 95.9144 6.556ZM91.2871 13.748L90.3936 10.004L93.3215 7.5L89.4841 7.196L88.0002 3.628V11.74L91.2871 13.748Z" fill="black"/>
        </g>
        <defs>
        <clipPath id="clip0_15_415">
        <rect width="96" height="15.2002" fill="white" transform="translate(0 0.89991)"/>
        </clipPath>
        </defs>
        </svg>
        
      </div>
    );
  }



  export const DropdownArrowIcon = ({ className = "" }) => {
    return (
      <div className={`${className}`}>
        <svg width="100%" height="100%" style={{"overflow":"visible"}} preserveAspectRatio="none" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.293 8.29297L12 12.586L7.70697 8.29297L6.29297 9.70697L12 15.414L17.707 9.70697L16.293 8.29297Z" fill="black"/>
        </svg>
        
      </div>
    );
  }
  
  


  export const  DetailsIcon = ({ className = "" })  => {
    return (
      <div className={`${className}`}>
        <svg width="100%" height="100%" style={{"overflow":"visible"}} preserveAspectRatio="none" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.3977 16.1629C12.178 16.3826 11.8219 16.3826 11.6022 16.1629L5.86739 10.428C5.64772 10.2084 5.64772 9.85227 5.86739 9.63259L6.13256 9.36739C6.35222 9.14772 6.70838 9.14772 6.92805 9.36739L12 14.4393L17.0719 9.36739C17.2916 9.14772 17.6477 9.14772 17.8674 9.36739L18.1326 9.63259C18.3522 9.85227 18.3522 10.2084 18.1326 10.428L12.3977 16.1629Z" fill="black"/>
        </svg>
        
      </div>
    );
  }
  