// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bi-popup-wrap .inner .heading-600-20 {color:#211E24;margin:0 0 20px;text-align:center;}
.bi-popup-wrap .inner .heading-400-14-12{color:#211E24;opacity: 0.8;text-align:center;}
.bi-popup-wrap .inner .mobile-icon{display:flex;margin: 0 0 30px;}
.bi-popup-wrap .inner .mobile-icon svg{margin:0 auto;flex:190px 0;}
.bi-popup-wrap .inner .continue-btn{padding: 14px 40px;background: #73509E;color: #fff;border-radius: 12px;border: none;width: max-content;margin: 30px auto 0;}
@media(max-width:767px){
    .bi-popup-wrap .inner .continue-btn{padding:12px 30px;}
}
@media(max-width:576px){
    .bi-popup-wrap .inner .heading-600-20 {text-align: center;margin:0 0 20px;}
    .bi-popup-wrap .inner .mobile-icon{margin: 0 0 20px;}
    .bi-popup-wrap .inner .continue-btn{padding:12px 20px;margin:20px auto 0;}
}
`, "",{"version":3,"sources":["webpack://./src/component/Account/Scheduled.css"],"names":[],"mappings":"AAAA,uCAAuC,aAAa,CAAC,eAAe,CAAC,iBAAiB,CAAC;AACvF,yCAAyC,aAAa,CAAC,YAAY,CAAC,iBAAiB,CAAC;AACtF,mCAAmC,YAAY,CAAC,gBAAgB,CAAC;AACjE,uCAAuC,aAAa,CAAC,YAAc,CAAC;AACpE,oCAAoC,kBAAkB,CAAC,mBAAmB,CAAC,WAAW,CAAC,mBAAmB,CAAC,YAAY,CAAC,kBAAkB,CAAC,mBAAmB,CAAC;AAC/J;IACI,oCAAoC,iBAAiB,CAAC;AAC1D;AACA;IACI,uCAAuC,kBAAkB,CAAC,eAAe,CAAC;IAC1E,mCAAmC,gBAAgB,CAAC;IACpD,oCAAoC,iBAAiB,CAAC,kBAAkB,CAAC;AAC7E","sourcesContent":[".bi-popup-wrap .inner .heading-600-20 {color:#211E24;margin:0 0 20px;text-align:center;}\n.bi-popup-wrap .inner .heading-400-14-12{color:#211E24;opacity: 0.8;text-align:center;}\n.bi-popup-wrap .inner .mobile-icon{display:flex;margin: 0 0 30px;}\n.bi-popup-wrap .inner .mobile-icon svg{margin:0 auto;flex:190px 0 0;}\n.bi-popup-wrap .inner .continue-btn{padding: 14px 40px;background: #73509E;color: #fff;border-radius: 12px;border: none;width: max-content;margin: 30px auto 0;}\n@media(max-width:767px){\n    .bi-popup-wrap .inner .continue-btn{padding:12px 30px;}\n}\n@media(max-width:576px){\n    .bi-popup-wrap .inner .heading-600-20 {text-align: center;margin:0 0 20px;}\n    .bi-popup-wrap .inner .mobile-icon{margin: 0 0 20px;}\n    .bi-popup-wrap .inner .continue-btn{padding:12px 20px;margin:20px auto 0;}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
