import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  closeIcon,
  ellipsePurpleIcon,
  ellipseWhiteIcon,
  vectorLineIcon,
} from "../../../helpers/Icons";
import Footer from "../../Footer/Footer";
import Confirmation from "../../Buying/Product/Confirmation";
import axios from "axios";
import Loader from "../../SubComponent/Loader";
import QuotationPopupForBuynow from "./QuotationPopupForBuynow";
import aa from "search-insights";
import { v4 as uuidv4 } from "uuid";

const SummaryCartPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let finalOrderId;

  const [showQuotationPopup, setShowQuotationPopup] = useState(false); // Control popup visibility

  const [showPopup2, setShowPopup2] = useState(false);
  const closePopup2 = () => {
    setShowQuotationPopup(false);
  };

  const location = useLocation();

  // Destructure the state object to retrieve the values
  const {
    newItemId,
    newBucketId,
    newQuantity,
    newOrderId,
    mpAddressId,
    quantityTotal,
    subtotalAmount,
    gstAmount,
    taxesAmount,
    shippingFee,
    totalAmount,
    itemDetails,
  } = location.state || {};

  // // Display or use the data in your component
  console.log("New Item ID:", newItemId);
  console.log("New Bucket ID:", newBucketId);
  console.log("New Quantity:", newQuantity);
  console.log("New Order ID:", newOrderId);
  console.log("Selected mp_address_id:", mpAddressId);
  console.log("Quantity Total:", quantityTotal);
  console.log("Subtotal Amount:", subtotalAmount);
  console.log("GST Amount:", gstAmount);
  console.log("Taxes Amount:", taxesAmount);
  console.log("Shipping Fee:", shippingFee);
  console.log("Total Amount:", totalAmount);// This is the mp_address_id you passed

  const [bucketId, setBucketId] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [quantityjd, setQuantityjd] = useState(0);
  const [itemName, setItemName] = useState("");

  const [price, setPrice] = useState(0);
  const [thumbnailUrl, setThumbnailUrl] = useState("");

  const [loadinged, setLoadinged] = useState(false);
  const navigate = useNavigate();
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const [orderId, setOrderId] = useState("");
  const address = location.state?.address || {}; // Ensure address is an object
  const [loginPortal, setLoginPortal] = useState(false);
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems")) || []
  );
  const id = queryParams.get("id");
  const message = queryParams.get("message");
  const [orderDetails, setOrderDetails] = useState(null);
  const [subtotal, setSubtotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [totalprice, setTotalPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [draftItems, setDraftItems] = useState([]);
  const [quantity, setQuantity] = useState();
console.log("quantity : ",quantity)

const adjustQuantityDisplay = (quantity) => {
  // If quantity is 11 or greater, display the last digit (quantity % 10)
  if (quantity >= 11) {
    return quantity % 10;
  }
  // Otherwise, display the quantity as is
  return quantity;
};


const adjustedQuantitytodispalycorrect = adjustQuantityDisplay(quantity);
console.log("adjustedQuantitytodispalycorrect : ",adjustedQuantitytodispalycorrect)

 
  const [deliveryAvailable, setDeliveryAvailable] = useState(false); // For delivery availability
  const [deliveryLocation, setDeliveryLocation] = useState(""); // Store postal code for checking delivery
  const selectedAddressIndex =
    parseInt(localStorage.getItem("selectedAddressIndex"), 10) || 0;
  const selectedAddress = data ? data[selectedAddressIndex]?.address : null; //

  // console.log("Selected address  in SummaryCartPage : ",selectedAddress);
  // console.log("Selected address index in SummaryCartPage : ",selectedAddressIndex);

  useEffect(() => {
    if (selectedAddress) {
      const postalCode = selectedAddress.input.postalCode;
      if (postalCode) {
        checkDeliveryAvailability(postalCode);
      }
    }
  }, [selectedAddress]);

  // Function to check delivery availability based on postal code
  const checkDeliveryAvailability = async (pincode) => {
    try {
      const response = await fetch(
        "https://devextension.origa.market/api/getdeliverypincode",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ pincode }),
        }
      );
      const data = await response.json();
      setDeliveryAvailable(data.available_status === "available");
      setDeliveryLocation(pincode);
    } catch (error) {
      console.error("Error checking delivery availability:", error);
      setDeliveryAvailable(false);
    }
  };

  // const handleProceedPaymentbtn = () => {
  //   const loggedin = !!localStorage.getItem("userToken");
  //   if (loggedin) {
  //     handleCreateOrder();
  //     navigate("/manageaddresspart");
  //   } else {
  //     setLoginPortal(true);
  //   }
  // };

  const handleProceedQuotationbtn = () => {
    // Handle the quotation logic here
    console.log("Quotation requested for unavailable delivery area.");

    setShowQuotationPopup(true); // Show the Quotation popup
  };
  // const location = useLocation();
  // const [loadinged, setLoadinged] = useState(false);
  // const navigate = useNavigate();
  // const [invoiceUrl, setInvoiceUrl] = useState('');
  // const queryParams = new URLSearchParams(location.search);
  // const [orderId, setOrderId] = useState('');
  // const address = location.state?.address || {}; // Ensure address is an object
  // const [loginPortal, setLoginPortal] = useState(false);
  // const [cartItems, setCartItems] = useState(
  //   JSON.parse(localStorage.getItem("cartItems")) || []
  // );
  // const id = queryParams.get('id');
  // const message = queryParams.get('message');
  //   const [orderDetails, setOrderDetails] = useState(null);
  // const [subtotal, setSubtotal] = useState(0);
  // const [taxTotal, setTaxTotal] = useState(0);
  // const [discountTotal, setDiscountTotal] = useState(0);
  // const [discountPercent, setDiscountPercent] = useState(0);
  // const [shippingCharge, setShippingCharge] = useState(0);
  // const [totalprice, setTotalPrice] = useState(0);
  // const [total, setTotal] = useState(0);
  // const [data, setData] = useState(null);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  // const [totalQuantity, setTotalQuantity] = useState(0);
  // const [draftItems, setDraftItems] = useState([]);
  // const [quantity, setQuantity] = useState("");

  // const selectedAddressIndex =
  //   parseInt(localStorage.getItem("selectedAddressIndex"), 10) || 0;

  // const selectedAddress = data ? data[selectedAddressIndex] : null;

  useEffect(() => {
    const fetchOrderDetails = async (customerId, referenceNumber) => {
      try {
        console.log("Fetching order details for:", {
          customerId,
          referenceNumber,
        });

        const response = await fetch(
          `https://devextension.origa.market/sparesapi/get_orders`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              customer_id: customerId,
              reference_number: referenceNumber,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch order details");
        }

        const data = await response.json();
        console.log("API response:", data);

        if (data.order) {
          setOrderDetails(data.order);

          // Extract the s3_url from the invoice_info
          if (data.order.invoice_info && data.order.invoice_info.length > 0) {
            setInvoiceUrl(data.order.invoice_info[0].s3_url);
          }
        } else {
          setOrderDetails(null);
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      } finally {
        setLoading(false);
      }
    };

    const id = localStorage.getItem("id");
    if (id && message === "Paid") {
      fetchOrderDetails(id);
    }
  }, [id, message]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://devextension.origa.market/api/fetchcustomeraddress",
          {
            id: localStorage.getItem("id"),
            mobileno: localStorage.getItem("number"),
          }
        );
        setData(response?.data?.response || []);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const [responseUrl, setResponseUrl] = useState("");

  // console.log(
  //   "selectedAddress-------------------------------->",
  //   selectedAddress
  // );

  useEffect(() => {
    const addCartItems = async () => {
      const loggedin = !!localStorage.getItem("userToken");
      const localCartItems =
        JSON.parse(localStorage.getItem("cartItems")) || [];
      const ompuserId = localStorage.getItem("id");
      const customer_first_name = localStorage.getItem("firstName");
      const customer_last_name = localStorage.getItem("lastName");
      const customer_contact_no = localStorage.getItem("number");
      const customer_email = localStorage.getItem("emailId");

      if (loggedin) {
        const requestBody = {
          items: draftItems.map((item) => ({
            item_id: item.item_id,
            quantity: item.quantity,
          })),
          ompuserId,
          customer_first_name,
          customer_last_name,
          customer_contact_no,
          order_status: "Draft",
          order_ref_number: "",
          customer_email,
          input: {
            city: "",
            cityArea: "",
            companyName: "",
            country: "",
            countryArea: "",
            firstName: "",
            lastName: "  ",
            phone: "",
            postalCode: "",
            streetAddress1: " ",
            streetAddress2: "",
          },
          businessaddress: {
            city: "",
            cityArea: "",
            companyName: " ",
            country: "",
            countryArea: "",
            firstName: "",
            lastName: "",
            phone: "",
            postalCode: "",
            streetAddress1: "",
            streetAddress2: "",
          },
          gst_no: "",
        };
        try {
          // Fetch draft items
          const draftResponse = await fetch(
            "https://devextension.origa.market/sparesapi/create_order",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBody),
            }
          );

          if (!draftResponse.ok) {
            throw new Error("Failed to fetch draft items");
          }

          const draftData = await draftResponse.json();
          const lineItem = draftData.line_items[0];
          setOrderId(draftData.order_id || 0);
          setCartItems(draftData.line_items || []);

          setBucketId(lineItem.bucket_id);
          setItemId(lineItem.item_id);
          setQuantityjd(lineItem.quantity);
          setItemName(lineItem.name);
          setPrice(lineItem.rate);
          setThumbnailUrl(lineItem.thumbnail[0]?.url || ""); // First image thumbnail

          setSubtotal(draftData.subtotal || 0);
          setTaxTotal(draftData.tax_total || 0);
          setDiscountTotal(draftData.discount_total || 0);
          setDiscountPercent(draftData.discount_percent || 0);
          setShippingCharge(draftData.shipping_charge || 0);
          setTotal(draftData.total || 0);
          setTotalPrice(draftData.total_quantity || 0);
          setQuantity(draftData.total_quantity || 0);

          // Calculate the total quantity from the response
          // const totalQuantity = draftData.line_items.reduce((acc, item) => acc + item.quantity, 0);
          // setTotalQuantity(totalQuantity);
        } catch (error) {
          console.error("Error fetching or merging cart items:", error);
        }
      } else {
        setCartItems(localCartItems); // Use local cart items if not logged in
      }
    };

    addCartItems(); // Call the function to add cart items on component mount
  }, []);

  const handleProceedPaymentbtn = () => {
    const loggedin = !!localStorage.getItem("userToken");
    if (loggedin) {
      handleCreateOrder();
      navigate("/manageaddresspart");
    } else {
      setLoginPortal(true);
    }
  };

  const queryID = localStorage.getItem("queryID");

  const uuidUserTokessn = localStorage.getItem("uuidUserToken");
  console.log("UUID User Token stored in localStorage:", uuidUserTokessn);

  const ALGOLIA_APP_IDs = "WMFH4GQ1HA";
  const ALGOLIA_API_KEYs = "26d776e16554d71413cb70ee9bc30fb6";
  const spareIndex = "zohoinventory_index";
  const timestampe = Date.now();

  console.log("objectId in SummaryCartPage  : ", itemId);
  console.log(" itemName in SummaryCartPage  : ", itemName);
  console.log("Query Id in SummaryCartPage  : ", queryID);
  console.log("timestamp in SummaryCartPage  :", timestampe);
  console.log("UserToken in SummaryCartPage  : ", uuidUserTokessn);

  const sendConversionEvent = () => {
    console.log("object Id : ", itemId);
    console.log("itemName : ", itemName);
    console.log("Query Id : ", queryID);
    console.log("timestamp :", timestampe);
    console.log("UserToken : ", uuidUserTokessn);

    localStorage.setItem("queryID", queryID);

    aa(
      "convertedObjectIDsAfterSearch",
      {
        eventType: "conversion",
        eventName: "Product Purchased",
        index: spareIndex,
        objectIDs: [itemId],
        userToken: uuidUserTokessn,
        queryID: queryID,
        timestamp: timestampe,
      },
      {
        headers: {
          "X-Algolia-Application-Id": ALGOLIA_APP_IDs,
          "X-Algolia-API-Key": ALGOLIA_API_KEYs,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        console.log("Conversion event sent successfully:", response);
        // alert("Conversion event sent successfully to Algolia!");
      })
      .catch((error) => {
        console.error("Error sending conversion event:", error);
        // alert("Error sending conversion event to Algolia.");
      });
  };

  const handleButtonClick = async () => {
    // await handleCreateOrder();

    await handleOrderAddressUpdate();
    await handleProceedConfirmation();
  };

  const handleOrderAddressUpdate = async () => {
    try {
      let payload;
      if (newOrderId && mpAddressId) {
        payload = {
          order_type: "Oil",
          order_id: newOrderId,
          mp_address_id: mpAddressId,
        };
        console.log("Proceeding with Buy: newOrderId is set.");
      } else if (orderId && mpAddressId) {
        payload = {
          order_type: "Oil",
          order_id: orderId,
          mp_address_id: mpAddressId,
        };
        console.log("Proceeding with Add to Cart: orderId is set.");
      } else {
        console.error(
          "Missing required information: newOrderId or orderId or mpAddressId is null"
        );
        return;
      }

      console.log("Order Id:", payload.order_id);
      console.log("Mp Address Id:", mpAddressId);

      const response = await axios.post(
        "https://devextension.origa.market/api/order_address_update",
        payload
      );

      // Handle the response
      if (response.data.status === "Success") {
        console.log(
          "Address updated successfully so that handleOrderAddressUpdate function is working : "
        );
      } else {
        console.error("Failed to update address:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating order address:", error);
    }
  };
  const handleProceedConfirmation = async () => {
    const customer_first_name = localStorage.getItem("firstName");
    const customer_last_name = localStorage.getItem("lastName");
    const customer_contact_no = localStorage.getItem("number");

    const query = `
      mutation CreatePayment($inputpayment: PaymentInput!) {
        createPayment(inputpayment: $inputpayment) {
          payment {
            merchantTransactionId
            PaymentID
          }
          message
          success
          responseurl
        }
      }
    `;

    if (newOrderId) {
      finalOrderId = newOrderId;
    } else if (orderId) {
      finalOrderId = orderId;
    }

    const variables = {
      inputpayment: {
        amount: "10",
        createdby: "Karthik",
        mobileno: "9361298454",
        paymentmethod: "PAGE",
        paymentstatus: "PEN",
        redirecturl: `https://devextension.origa.market/toolspaymentredirect?id=${finalOrderId}`,
      },
    };

    try {
      const response = await fetch(
        "https://devextension.origa.market/graphql/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query,
            variables,
          }),
        }
      );

      const result = await response.json();

      if (
        result.data &&
        result.data.createPayment &&
        result.data.createPayment.success &&
        result.data.createPayment.responseurl
      ) {
        const productID = itemId;
        const queryID = localStorage.getItem("queryID");
        sendConversionEvent(productID, queryID);

        setResponseUrl(result.data.createPayment.responseurl);
        window.location.href = result.data.createPayment.responseurl;
      } else {
        console.error(
          "Failed to get response URL or payment unsuccessful:",
          result
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const handleCreateOrder = async () => {
  //   const userToken = localStorage.getItem("userToken");
  //   if (!userToken) {

  //     setLoginPortal(true);
  //     return;
  //   }
  //   const localCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  //   console.log("localCartItems :: ", localCartItems);

  //   var itemList = [];
  //   for (var item in localCartItems) {
  //     var obj = {};
  //     obj["item_id"] = localCartItems[item]["productId"];
  //     obj["quantity"] = localCartItems[item]["quantity"];
  //     itemList.push(obj);
  //   }
  //   console.log("itemList -------------------->  :: ", itemList);
  //   const ompuserId = localStorage.getItem("id") || "defaultId";
  //   const customer_first_name = localStorage.getItem("firstName");
  //   const customer_last_name = localStorage.getItem("lastName");
  //   const customer_contact_no = localStorage.getItem("number");
  //   const customer_email = localStorage.getItem("emailId");

  //   const requestBody = {
  //     items: itemList,
  //     ompuserId,
  //     customer_first_name,
  //     customer_last_name,
  //     customer_contact_no,
  //     order_status: "Draft",
  //     order_ref_number: "",
  //     customer_email,
  //     input: {
  //       city: "",
  //       cityArea: "",
  //       companyName: "",
  //       country: "",
  //       countryArea: "",
  //       firstName: "",
  //       lastName: "  ",
  //       phone: "",
  //       postalCode: "",
  //       streetAddress1: " ",
  //       streetAddress2: "",
  //     },
  //     businessaddress: {
  //       city: "",
  //       cityArea: "",
  //       companyName: " ",
  //       country: "",
  //       countryArea: "",
  //       firstName: "",
  //       lastName: "",
  //       phone: "",
  //       postalCode: "",
  //       streetAddress1: "",
  //       streetAddress2: "",
  //     },
  //     gst_no: "",
  //   };

  //   try {
  //     console.log(
  //       "requestBody  ------------------------------------------------------------- > :: ",
  //       requestBody
  //     );

  //     const createOrderResponse = await fetch(
  //       "https://devextension.origa.market/sparesapi/create_order",
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${userToken}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(requestBody),
  //       }
  //     );

  //     if (!createOrderResponse.ok) {
  //       throw new Error("Failed to create order");
  //     }

  //     const createOrderData = await createOrderResponse.json();
  //     const draftItems = createOrderData.line_items || [];
  //     console.log("Created order with items:", draftItems);

  //     setCartItems([]);
  //   } catch (error) {
  //     console.error("Error creating order:", error);
  //   }
  // };

  const handleCreateOrder = async () => {
    const userToken = localStorage.getItem("userToken");
    if (!userToken) {
      setLoginPortal(true); // Show login portal if user is not logged in
      return;
    }

    const localCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const itemList = localCartItems.map((item) => ({
      item_id: item.productId,
      quantity: item.quantity,
      bucket_id: item.bucket_id,
    }));

    const ompuserId = localStorage.getItem("id") || "defaultId";
    const requestBody = {
      items: itemList,
      ompuserId,
      customer_first_name:
        selectedAddress?.input?.firstName || "DefaultFirstName",
      customer_last_name: selectedAddress?.input?.lastName || "DefaultLastName",
      customer_contact_no: selectedAddress?.input?.phone || "DefaultPhone",
      customer_email: localStorage.getItem("emailId"),
      order_status: "Draft",
      input: {
        ...selectedAddress.input,
      },
      businessaddress: {
        ...selectedAddress.businessaddress,
      },
      gst_no: selectedAddress.gst_no || "",
    };

    try {
      const createOrderResponse = await fetch(
        "https://devextension.origa.market/sparesapi/create_order",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!createOrderResponse.ok) {
        throw new Error("Failed to create order");
      }

      const createOrderData = await createOrderResponse.json();
      const draftItems = createOrderData.line_items || [];
      console.log("Created order with items:", draftItems);
      setCartItems([]);
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  //To fetch the customer addresses

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://devextension.origa.market/api/fetchcustomeraddress",
          {
            id: localStorage.getItem("id"),
            mobileno: localStorage.getItem("number"), // Ensure mobileno is retrieved correctly
          }
        );
        console.log("API Response:", response.data);

        // Set the response data (address list) to state
        setData(response?.data?.response || []);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const [isMobile, setIsMobile] = useState("");

  useEffect(() => {
    const resize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    resize();

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);




  const displayQuantity = (quantityTotal) => {
    if (quantityTotal >= 11 && quantityTotal % 11 === 0 ) {
      // If the quantity corresponds to barrels
      return quantityTotal / 11;
    }
    // For buckets, display the quantity as is
    return quantityTotal;
  };

  
  const displayTotalPrice = (totalprice, quantityTotal) => {
    if (quantityTotal >= 11 && quantityTotal % 11 === 0) {
      // Adjust total price for barrels
      return totalprice / quantityTotal * 11; // Price for 1 barrel
    }
    // Return the total price as is for buckets
    return totalprice;
  };
  // Example usage
  const quantityToDisplay = displayQuantity(quantityTotal);
  const priceToDisplay = displayTotalPrice(totalprice, quantityTotal);
  console.log("Quantity to Display:", quantityToDisplay);
  console.log("Quantity to Display:", priceToDisplay)

  return (
    <>
      {loadinged && <Loader />}

      <div className="totalcartpagebuttopformanageaddresspart">
        <div className="centertotaldivcart">
          <div className="icon-containere">
            <span className="dilevery-address-1">
              {ellipseWhiteIcon({ width: 32, height: 32 })}
            </span>
            <span className="icon-number">1</span>
          </div>
          <span className="add-to-cart-text">Add to Cart</span>
          <div className="linebetweenicons">
            {vectorLineIcon({ width: 30, height: 1 })}&nbsp;
          </div>
          <div className="icon-containere rsbbref mr-3">
            <span className="order-sumary-2">
              {ellipseWhiteIcon({ width: 32, height: 32 })}
            </span>
            <span className="icon-number">2</span>
          </div>
          <span className="add-to-cart-text">Delivery Address</span>
          <div className="linebetweenicons">
            {vectorLineIcon({ width: 30, height: 1 })}&nbsp;
          </div>
          <div className="icon-containere ">
            <span className="order-sumary-2">
              {ellipsePurpleIcon({ width: 32, height: 32 })}
            </span>
            <span className="icon-number">3</span>
          </div>
          <span className="add-to-cart-text">Payment</span>
        </div>
      </div>

      {/* <div class="row justify-content-center twodivborderseperate">
        <div className="DeliveryAddressesHeading">
        
        </div>
        <div class="col-6 leftsideborderdeliverypart">
          <h4 className="addressheading"> Address : </h4>

          <div className="horizontallineofcartpage">
            <hr className="lineofcartbottomiin" />
          </div>

          <p>
            <div></div>

            {selectedAddress && (
              <>
                <span className="headingindelivery">Name: </span>
                {selectedAddress.input.firstName}{" "}
                {selectedAddress.input.lastName}
                <br />
                <span className="headingindelivery">Mobile Number: </span>
                {selectedAddress.input.phone}
                <br />
                <span className="headingindelivery">Company Name: </span>
                {selectedAddress.input.companyName}
                <br />
                <span className="headingindelivery">Factory Name: </span>
                {selectedAddress.factoryName}
                <br />
                <span className="headingindelivery">Gst No: </span>
                {selectedAddress.gst_no}
                <br />
                <span className="headingindelivery">Billing Address: </span>
                <br />
                {selectedAddress.input.streetAddress1},{" "}
                {selectedAddress.input.city}
                <br />
                {selectedAddress.input.countryArea},{" "}
                {selectedAddress.input.postalCode}
                <br />
                <span className="headingindelivery">Shipping Address: </span>
                <br />
                {selectedAddress.businessaddress?.streetAddress1},{" "}
                {selectedAddress.businessaddress?.streetAddress2}
                <br />
                {selectedAddress.businessaddress.countryArea},
                {selectedAddress.businessaddress?.city},{" "}
                {selectedAddress.businessaddress?.postalCode}
                <br />
              </>
            )}
          </p>
        </div>

        <div class="col-4 rightsidebordersummarypart">
          <div className="buttondivsummaryaddress">

            <div className="button-in-summarycartpage">
            {deliveryAvailable ? (
              <button
                className="usethisaddressinbutton"
                onClick={handleButtonClick}
              >
                Proceed to Payment
              </button>
            ) : (
              <button
                className="usethisaddressinbutton"
                onClick={handleProceedQuotationbtn}
              >
                Get Quotation
              </button>
            )}
            </div>
            
            <p className="chooseashippingaddress">
              Choose a shipping address and payment method to calculate
              shipping, handling and tax.
            </p>
            <h6 className="ordersummaryheading">Order Summary</h6>
            <div className="pricing-details">
              <div className="pricing-item total">
                <span className="item-label">Total quantity:</span>
                <span className="item-value">
                  {totalprice > 0 ? totalprice : quantityTotal}
                </span>
              </div>

              <div className="pricing-item">
                <span className="item-label">Sub Total:</span>
                <span className="item-value">
                  {subtotal > 0 ? `₹${subtotal}` : `₹${subtotalAmount}`}
                </span>
              </div>

              <div className="pricing-item">
                <span className="item-label">Taxes :</span>
                <span className="item-value">
                  {taxTotal > 0 ? `₹${taxTotal}` : `₹${gstAmount}`}
                </span>
              </div>

              <div className="pricing-item">
                <span className="item-label">Shipping Charge:</span>
                <span className="item-value">
                  {shippingCharge >= 0
                    ? `₹${shippingCharge}`
                    : `₹${shippingFee}`}
                </span>
              </div>

              <div className="pricing-item total">
                <span className="item-label">Total:</span>
                <span className="item-value">
                  {total > 0 ? `₹${total}` : `₹${totalAmount}`}
                </span>
              </div>

              {discountTotal > 0 && (
                <div className="pricing-item promotion">
                  <span className="item-label">Discount percent:</span>
                  <span className="item-value">₹{discountTotal}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div> */}

      {isMobile ? (
        <>
          <div class="row justify-content-center twodivborderseperate pb-2">
            <div className="DeliveryAddressesHeading">
              {/* <h1 className='DeliverAddresContentText'>Your Delivery Address : </h1> */}
            </div>
            <div class="col-11 leftsideborderdeliverypart">
              <h4 className="addressheading"> Address : </h4>

              <div className="horizontallineofcartpage">
                <hr className="lineofcartbottomiin" />
              </div>

              {isMobile ? (
                <>
                  {selectedAddress && (
                    <>
                      <span className="headingindelivery">Name: </span>
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.input.firstName} &nbsp;
                        {selectedAddress.input.lastName}
                      </span>
                      <br />
                      <span className="headingindelivery">Mobile Number: </span>
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.input.phone}
                      </span>
                      <br />
                      <span className="headingindelivery">Company Name: </span>
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.input.companyName}
                      </span>
                      <br />
                      <span className="headingindelivery">Factory Name: </span>
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.factoryName}
                      </span>
                      <br />
                      <span className="headingindelivery">Gst No: </span>
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.gst_no}
                      </span>
                      <br />
                      <span className="headingindelivery">
                        Billing Address:{" "}
                      </span>
                      <br />
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.input.streetAddress1},{" "}
                        {selectedAddress.input.city}
                      </span>
                      <br />
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.input.countryArea},{" "}
                        {selectedAddress.input.postalCode}
                      </span>
                      <br />
                      <span className="headingindelivery">
                        Shipping Address:{" "}
                      </span>
                      <br />
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.businessaddress?.streetAddress1},{" "}
                        {selectedAddress.businessaddress?.streetAddress2}
                      </span>
                      <br />
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.businessaddress.countryArea},
                        {selectedAddress.businessaddress?.city},{" "}
                        {selectedAddress.businessaddress?.postalCode}
                      </span>
                      <br />
                    </>
                  )}
                </>
              ) : (
                <>
                  <p>
                    {selectedAddress && (
                      <>
                        <span className="headingindelivery">Name: </span>
                        <span className="answerdelivery">
                          {selectedAddress.input.firstName}{" "}
                          {selectedAddress.input.lastName}
                        </span>
                        <br />
                        <span className="headingindelivery">
                          Mobile Number:{" "}
                        </span>
                        <span className="answerdelivery">
                          {selectedAddress.input.phone}
                        </span>
                        <br />
                        <span className="headingindelivery">
                          Company Name:{" "}
                        </span>
                        <span className="answerdelivery">
                          {selectedAddress.input.companyName}
                        </span>
                        <br />
                        <span className="headingindelivery">
                          Factory Name:{" "}
                        </span>
                        <span className="answerdelivery">
                          {selectedAddress.factoryName}
                        </span>
                        <br />
                        <span className="headingindelivery">Gst No: </span>
                        <span className="answerdelivery">
                          {selectedAddress.gst_no}
                        </span>
                        <br />
                        <span className="headingindelivery">
                          Billing Address:{" "}
                        </span>
                        <br />
                        <span className="answerdelivery">
                          {selectedAddress.input.streetAddress1},{" "}
                          {selectedAddress.input.city}
                        </span>
                        <br />
                        <span className="answerdelivery">
                          {selectedAddress.input.countryArea},{" "}
                          {selectedAddress.input.postalCode}
                        </span>
                        <br />
                        <span className="headingindelivery">
                          Shipping Address:{" "}
                        </span>
                        <br />
                        <span className="answerdelivery">
                          {selectedAddress.businessaddress?.streetAddress1},{" "}
                          {selectedAddress.businessaddress?.streetAddress2}
                        </span>
                        <br />
                        <span className="answerdelivery">
                          {selectedAddress.businessaddress.countryArea},
                          {selectedAddress.businessaddress?.city},{" "}
                          {selectedAddress.businessaddress?.postalCode}
                        </span>
                        <br />
                      </>
                    )}
                  </p>
                </>
              )}
            </div>

            <div class="col-11 rightsidebordersummarypart">
              <div className="buttondivsummaryaddress">
                <div className="button-in-summarycartpage">
                  {deliveryAvailable ? (
                    <button
                      className="usethisaddressinbutton"
                      onClick={handleButtonClick}
                    >
                      Proceed to Payment
                    </button>
                  ) : (
                    <button
                      className="usethisaddressinbutton"
                      onClick={handleProceedQuotationbtn}
                    >
                      Get Quotation
                    </button>
                  )}
                </div>

                <p className="chooseashippingaddress">
                  Choose a shipping address and payment method to calculate
                  shipping, handling and tax.
                </p>
                <h6 className="ordersummaryheading">Order Summary</h6>
                <div className="pricing-details">
                  <div className="pricing-item total">
                    <span className="item-label">Total quantity:</span>
                    <span className="item-value">
                    {/* {localStorage.getItem("order_Status") === "Buy"
  ? displayQuantity(quantityTotal)
  : totalprice > 0
  ? totalprice
  : displayQuantity(quantityTotal)} */}
  {localStorage.getItem("order_Status") === "Buy"
  ? displayQuantity(quantityTotal)
: adjustedQuantitytodispalycorrect
}
                    </span>
                  </div>

                  <div className="pricing-item">
                    <span className="item-label">Sub Total:</span>
                    <span className="item-value">
                      {localStorage.getItem("order_Status") === "Buy"
                        ? `₹${subtotalAmount}`
                        : subtotal > 0
                        ? `₹${subtotal}`
                        : `₹${subtotalAmount}`}
                    </span>
                  </div>

                  <div className="pricing-item">
                    <span className="item-label">Taxes:</span>
                    <span className="item-value">
                      {localStorage.getItem("order_Status") === "Buy"
                        ? `₹${gstAmount}`
                        : taxTotal > 0
                        ? `₹${taxTotal}`
                        : `₹${gstAmount}`}
                    </span>
                  </div>

                  <div className="pricing-item">
                    <span className="item-label">Shipping Charge:</span>
                    <span className="item-value">
                      {localStorage.getItem("order_Status") === "Buy"
                        ? `₹${shippingFee}`
                        : shippingCharge >= 0
                        ? `₹${shippingCharge}`
                        : `₹${shippingFee}`}
                    </span>
                  </div>

                  <div className="pricing-item total">
                    <span className="item-label">Total:</span>
                    <span className="item-value">
                      {localStorage.getItem("order_Status") === "Buy"
                        ? `₹${totalAmount}`
                        : total > 0
                        ? `₹${total}`
                        : `₹${totalAmount}`}
                    </span>
                  </div>

                  {discountTotal > 0 && (
                    <div className="pricing-item promotion">
                      <span className="item-label">Discount percent:</span>
                      <span className="item-value">
                        {localStorage.getItem("order_Status") === "Buy"
                          ? `₹${discountTotal}`
                          : discountTotal}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div class="row justify-content-center twodivborderseperate pb-2">
            <div className="DeliveryAddressesHeading">
              {/* <h1 className='DeliverAddresContentText'>Your Delivery Address : </h1> */}
            </div>
            <div class="col-6 leftsideborderdeliverypart">
              <h4 className="addressheading"> Address : </h4>

              <div className="horizontallineofcartpage">
                <hr className="lineofcartbottomiin" />
              </div>

              {/* <p>
           
            {selectedAddress && (
              <>
                <span className="headingindelivery">Name: </span>
                <span className="answerdelivery">
                {selectedAddress.input.firstName}{" "}
                {selectedAddress.input.lastName}</span> 
                <br />
                <span className="headingindelivery">Mobile Number: </span>
                <span className="answerdelivery">{selectedAddress.input.phone}</span>
                <br />
                <span className="headingindelivery">Company Name: </span>
                <span className="answerdelivery">{selectedAddress.input.companyName}</span>                
                <br />
                <span className="headingindelivery">Factory Name: </span>
                <span className="answerdelivery">{selectedAddress.factoryName}</span>
                <br />
                <span className="headingindelivery">Gst No: </span>
                <span className="answerdelivery">{selectedAddress.gst_no}</span>
                <br />
                <span className="headingindelivery">Billing Address: </span>
                <br />
                <span className="answerdelivery">{selectedAddress.input.streetAddress1},{" "}
              {selectedAddress.input.city}</span>
                <br />
                <span className="answerdelivery">{selectedAddress.input.countryArea},{" "}
                {selectedAddress.input.postalCode}</span>
                <br />
                <span className="headingindelivery">Shipping Address: </span>
                <br />
                <span className="answerdelivery">{selectedAddress.businessaddress?.streetAddress1},{" "}
                {selectedAddress.businessaddress?.streetAddress2}</span>
                <br />
                <span className="answerdelivery">{selectedAddress.businessaddress.countryArea},
                {selectedAddress.businessaddress?.city},{" "}
                {selectedAddress.businessaddress?.postalCode}</span>
                <br />
              </>
            )}
          </p> */}

              {isMobile ? (
                <>
                  {selectedAddress && (
                    <>
                      <span className="headingindelivery">Name: </span>
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.input.firstName}{" "}
                        {selectedAddress.input.lastName}
                      </span>
                      <br />
                      <span className="headingindelivery">Mobile Number: </span>
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.input.phone}
                      </span>
                      <br />
                      <span className="headingindelivery">Company Name: </span>
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.input.companyName}
                      </span>
                      <br />
                      <span className="headingindelivery">Factory Name: </span>
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.factoryName}
                      </span>
                      <br />
                      <span className="headingindelivery">Gst No: </span>
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.gst_no}
                      </span>
                      <br />
                      <span className="headingindelivery">
                        Billing Address:{" "}
                      </span>
                      <br />
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.input.streetAddress1},{" "}
                        {selectedAddress.input.city}
                      </span>
                      <br />
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.input.countryArea},{" "}
                        {selectedAddress.input.postalCode}
                      </span>
                      <br />
                      <span className="headingindelivery">
                        Shipping Address:{" "}
                      </span>
                      <br />
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.businessaddress?.streetAddress1},{" "}
                        {selectedAddress.businessaddress?.streetAddress2}
                      </span>
                      <br />
                      <span
                        style={{ fontSize: "14px" }}
                        className="answerdelivery"
                      >
                        {selectedAddress.businessaddress.countryArea},
                        {selectedAddress.businessaddress?.city},{" "}
                        {selectedAddress.businessaddress?.postalCode}
                      </span>
                      <br />
                    </>
                  )}
                </>
              ) : (
                <>
                  <p>
                    {selectedAddress && (
                      <>
                        <span className="headingindelivery">Name: </span>
                        <span className="answerdelivery">
                          {selectedAddress.input.firstName}{" "}
                          {selectedAddress.input.lastName}
                        </span>
                        <br />
                        <span className="headingindelivery">
                          Mobile Number:{" "}
                        </span>
                        <span className="answerdelivery">
                          {selectedAddress.input.phone}
                        </span>
                        <br />
                        <span className="headingindelivery">
                          Company Name:{" "}
                        </span>
                        <span className="answerdelivery">
                          {selectedAddress.input.companyName}
                        </span>
                        <br />
                        <span className="headingindelivery">
                          Factory Name:{" "}
                        </span>
                        <span className="answerdelivery">
                          {selectedAddress.factoryName}
                        </span>
                        <br />
                        <span className="headingindelivery">Gst No: </span>
                        <span className="answerdelivery">
                          {selectedAddress.gst_no}
                        </span>
                        <br />
                        <span className="headingindelivery">
                          Billing Address:{" "}
                        </span>
                        <br />
                        <span className="answerdelivery">
                          {selectedAddress.input.streetAddress1},{" "}
                          {selectedAddress.input.city}
                        </span>
                        <br />
                        <span className="answerdelivery">
                          {selectedAddress.input.countryArea},{" "}
                          {selectedAddress.input.postalCode}
                        </span>
                        <br />
                        <span className="headingindelivery">
                          Shipping Address:{" "}
                        </span>
                        <br />
                        <span className="answerdelivery">
                          {selectedAddress.businessaddress?.streetAddress1},{" "}
                          {selectedAddress.businessaddress?.streetAddress2}
                        </span>
                        <br />
                        <span className="answerdelivery">
                          {selectedAddress.businessaddress.countryArea},
                          {selectedAddress.businessaddress?.city},{" "}
                          {selectedAddress.businessaddress?.postalCode}
                        </span>
                        <br />
                      </>
                    )}
                  </p>
                </>
              )}
            </div>

            <div class="col-4 rightsidebordersummarypart">
              <div className="buttondivsummaryaddress">
                <div className="button-in-summarycartpage">
                  {deliveryAvailable ? (
                    <button
                      className="usethisaddressinbutton"
                      onClick={handleButtonClick}
                    >
                      Proceed to Payment
                    </button>
                  ) : (
                    <button
                      className="usethisaddressinbutton"
                      onClick={handleProceedQuotationbtn}
                    >
                      Get Quotation
                    </button>
                  )}
                </div>

                <p className="chooseashippingaddress">
                  Choose a shipping address and payment method to calculate
                  shipping, handling and tax.
                </p>
                <h6 className="ordersummaryheading">Order Summary</h6>
                <div className="pricing-details">
                  <div className="pricing-item total">
                    <span className="item-label">Total quantity:</span>
                    <span className="item-value">
                      {/* {localStorage.getItem("order_Status") === "buy"
                        ? quantityTotal
                        : totalprice > 0
                        ? totalprice
                        : quantityTotal} */}
                          {localStorage.getItem("order_Status") === "Buy"
  ? displayQuantity(quantityTotal)
: adjustedQuantitytodispalycorrect
}
                    </span>
                  </div>

                  <div className="pricing-item">
                    <span className="item-label">Sub Total:</span>
                    <span className="item-value">
                      {localStorage.getItem("order_Status") === "buy"
                        ? `₹${subtotalAmount}`
                        : subtotal > 0
                        ? `₹${subtotal}`
                        : `₹${subtotalAmount}`}
                    </span>
                  </div>

                  <div className="pricing-item">
                    <span className="item-label">Taxes:</span>
                    <span className="item-value">
                      {localStorage.getItem("order_Status") === "buy"
                        ? `₹${gstAmount}`
                        : taxTotal > 0
                        ? `₹${taxTotal}`
                        : `₹${gstAmount}`}
                    </span>
                  </div>

                  <div className="pricing-item">
                    <span className="item-label">Shipping Charge:</span>
                    <span className="item-value">
                      {localStorage.getItem("order_Status") === "buy"
                        ? `₹${shippingFee}`
                        : shippingCharge >= 0
                        ? `₹${shippingCharge}`
                        : `₹${shippingFee}`}
                    </span>
                  </div>

                  <div className="pricing-item total">
                    <span className="item-label">Total:</span>
                    <span className="item-value">
                      {localStorage.getItem("order_Status") === "buy"
                        ? `₹${totalAmount}`
                        : total > 0
                        ? `₹${total}`
                        : `₹${totalAmount}`}
                    </span>
                  </div>

                  {discountTotal > 0 && (
                    <div className="pricing-item promotion">
                      <span className="item-label">Discount percent:</span>
                      <span className="item-value">
                        {localStorage.getItem("order_Status") === "buy"
                          ? `₹${discountTotal}`
                          : discountTotal}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showQuotationPopup && (
        <div className="popup-overlay">
          <div className="popup-banner">
            <div className="popup-content">
              <QuotationPopupForBuynow
                bucketId={bucketId}
                itemId={itemId}
                quantity={quantity}
                itemName={itemName}
                price={price}
                thumbnailUrl={thumbnailUrl}
                subtotal={subtotal}
                subtotalAmount={subtotalAmount}
                total={total}
                totalAmount={totalAmount}
                totalQuantity={totalQuantity}
                quantityTotal={quantityTotal}
                itemDetails={itemDetails}
              />
              <div className="close-popup" onClick={closePopup2}>
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default SummaryCartPage;