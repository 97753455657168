import React from "react";
import "./SimilarProductsToolsStyles.css";
import Slider from "react-slick";

import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
const SimilarProductsforTools = ({ similarProducts }) => {
  // const sliderdata = [
  //   {
  //     id: 1,
  //     image: "https://origamarketuat.s3.amazonaws.com/thumbnails/products/799848_files-1-1708c1_d14160a8_thumbnail_256.jpeg", // Replace with actual image URL
  //     title: "Product 1",
  //     description: "Description for Product 1",
  //     price: "₹5,799",
  //     originalPrice: "₹8,180",
  //     discount: "29% OFF",
  //     rating: "4.6",
  //     reviews: "5 Reviews"
  //   },
  //   {
  //     id: 2,
  //     image: "https://origamarketuat.s3.amazonaws.com/thumbnails/products/799848_files-1-1708c1_d14160a8_thumbnail_256.jpeg", // Replace with actual image URL
  //     title: "Product 2",
  //     description: "Description for Product 2",
  //     price: "₹5,799",
  //     originalPrice: "₹8,180",
  //     discount: "29% OFF",
  //     rating: "4.6",
  //     reviews: "5 Reviews"
  //   },
  //   {
  //     id: 3,
  //     image: "https://origamarketuat.s3.amazonaws.com/thumbnails/products/799848_files-1-1708c1_d14160a8_thumbnail_256.jpeg", // Replace with actual image URL
  //     title: "Product 3",
  //     description: "Description for Product 3",
  //     price: "₹5,799",
  //     originalPrice: "₹8,180",
  //     discount: "29% OFF",
  //     rating: "4.6",
  //     reviews: "5 Reviews"
  //   },
  //   {
  //       id: 4,
  //       image: "https://origamarketuat.s3.amazonaws.com/thumbnails/products/799848_files-1-1708c1_d14160a8_thumbnail_256.jpeg", // Replace with actual image URL
  //       title: "Product 4",
  //       description: "Description for Product 4",
  //       price: "₹5,799",
  //       originalPrice: "₹8,180",
  //       discount: "29% OFF",
  //       rating: "4.6",
  //       reviews: "5 Reviews"
  //     },
  //     {
  //       id: 5,
  //       image: "https://origamarketuat.s3.amazonaws.com/thumbnails/products/799848_files-1-1708c1_d14160a8_thumbnail_256.jpeg", // Replace with actual image URL
  //       title: "Product 5",
  //       description: "Description for Product 5",
  //       price: "₹5,799",
  //       originalPrice: "₹8,180",
  //       discount: "29% OFF",
  //       rating: "4.6",
  //       reviews: "5 Reviews"
  //     },
  //     {
  //       id: 6,
  //       image: "https://origamarketuat.s3.amazonaws.com/thumbnails/products/799848_files-1-1708c1_d14160a8_thumbnail_256.jpeg", // Replace with actual image URL
  //       title: "Product 6",
  //       description: "Description for Product 6",
  //       price: "₹5,799",
  //       originalPrice: "₹8,180",
  //       discount: "29% OFF",
  //       rating: "4.6",
  //       reviews: "5 Reviews"
  //     },
  //     {
  //       id: 7,
  //       image: "https://origamarketuat.s3.amazonaws.com/thumbnails/products/799848_files-1-1708c1_d14160a8_thumbnail_256.jpeg", // Replace with actual image URL
  //       title: "Product 7",
  //       description: "Description for Product 7",
  //       price: "₹5,799",
  //       originalPrice: "₹8,180",
  //       discount: "29% OFF",
  //       rating: "4.6",
  //       reviews: "5 Reviews"
  //   },
  //   {
  //       id: 8,
  //       image: "https://origamarketuat.s3.amazonaws.com/thumbnails/products/799848_files-1-1708c1_d14160a8_thumbnail_256.jpeg", // Replace with actual image URL
  //       title: "Product 8",
  //       description: "Description for Product 8",
  //       price: "₹5,799",
  //       originalPrice: "₹8,180",
  //       discount: "29% OFF",
  //       rating: "4.6",
  //       reviews: "5 Reviews"
  //   },
  //   {
  //       id: 9,
  //       image: "https://origamarketuat.s3.amazonaws.com/thumbnails/products/799848_files-1-1708c1_d14160a8_thumbnail_256.jpeg", // Replace with actual image URL
  //       title: "Product 9",
  //       description: "Description for Product 9",
  //       price: "₹5,799",
  //       originalPrice: "₹8,180",
  //       discount: "29% OFF",
  //       rating: "4.6",
  //       reviews: "5 Reviews"
  //   },
  //   {
  //       id: 10,
  //       image: "https://origamarketuat.s3.amazonaws.com/thumbnails/products/799848_files-1-1708c1_d14160a8_thumbnail_256.jpeg", // Replace with actual image URL
  //       title: "Product 10",
  //       description: "Description for Product 10",
  //       price: "₹5,799",
  //       originalPrice: "₹8,180",
  //       discount: "29% OFF",
  //       rating: "4.6",
  //       reviews: "5 Reviews"
  //   },
  //   {
  //       id: 11,
  //       image: "https://origamarketuat.s3.amazonaws.com/thumbnails/products/799848_files-1-1708c1_d14160a8_thumbnail_256.jpeg", // Replace with actual image URL
  //       title: "Product 11",
  //       description: "Description for Product 11",
  //       price: "₹5,799",
  //       originalPrice: "₹8,180",
  //       discount: "29% OFF",
  //       rating: "4.6",
  //       reviews: "5 Reviews"
  //   }
    
   
  // ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: (
        <IoIosArrowForward
          fontSize={20}
          color="black"
          className="forwardiconintoolssimilar"
          
        />
      ),
      prevArrow: (
        <IoIosArrowBack
          fontSize={20}
          color="black"
          className="backwardiconintoolssimilar"
         
        />
      ),
      
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // console.log("similar Products : ",similarProducts);
  return (
  <>
<div className="similar-products-container">
  <h6 className="similar-products-heading">Similar Products</h6>
  {similarProducts.length > 0 ? (
    <Slider {...settings}>
      {similarProducts.map((product) => {
       
        const grossPrice = product.grossPrice;
    
      const formattedGrossPrice = new Intl.NumberFormat('en-IN').format(grossPrice);

      // console.log("Gross price: ", formattedGrossPrice); 

      

        return (
          <div key={product.objectID} className="each-individual-card-for-similarmachines">
            <img src={product.thumbnail || product.image} alt={product.title || product.productName} />
            <div className="product-infofor">
              <h4 className="beforeofproductname">{product.title || product.productName}</h4>
              <div className="price">
                <span>{product.price}</span>
              </div>
              <div className="rating-container">
                <span style={{ fontSize: '14px' }}>
                  ₹{formattedGrossPrice} 
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
  ) : (
    <p>No similar products found.</p>
  )}
</div>



  </>

  );
};

export default SimilarProductsforTools;
