/* import React from 'react';

const ColumnOne = ({ onSelect }) => {
  const buttons = [
    { label: 'CNC Turning', value: 'Turning Centre', img: 'asset/Service_Logos/CNCVMC.webp' },
    { label: 'CNC VMC', value: 'CNC Vertical machine Center (VMC)', img: 'asset/Service_Logos/Twins Spindle.webp' },
    { label: 'CNC Turnmill Machine', value: 'Turning Centre', img: 'asset/Service_Logos/VMC.webp' },
    { label: 'CNC HMC', value: 'HMC', img: 'asset/Service_Logos/Twins Spindle.webp' },
    { label: 'CNC Lathe', value: 'CNC LATHE',  img: 'asset/Service_Logos/VMC.webp' },
    { label: 'CMH', value: 'CMH', img: 'asset/Service_Logos/Twins Spindle.webp' },
    { label: 'Grinding Machine', value: 'Grinding Machine',  img: 'asset/Service_Logos/VMC.webp' },
    { label: 'SPM (Special Purpose machine)', value: 'SPM (Special Purpose machine)', img: 'asset/Service_Logos/Twins Spindle.webp' },
    { label: 'VTL', value: 'VTL',  img: 'asset/Service_Logos/VMC.webp' },
    { label: 'Twins Spindle', value: 'Twines Spindle', img: 'asset/Service_Logos/Twins Spindle.webp' },
    { label: 'Others', value: 'Others',  img: 'asset/Service_Logos/VMC.webp' },
  ];

  return (
    <div className="column column-one">
      {buttons.map((button, index) => (
        <button key={index} onClick={() => onSelect(button.value)} className="machine-button">
          <div className="button-content">
            <img src={button.img} alt={button.label} className="button-image" />
            <span>{button.label}</span>
          </div>
        </button>
      ))}
    </div>
  );
};

export default ColumnOne; */
import React, { useState } from 'react';

const fallbackImage = 'https://origa.market/asset/image%206.png'; // Fallback image URL

const ColumnOne = ({ onSelect, buttons }) => { // Accept buttons as a prop
  const [selectedItem, setSelectedItem] = useState(null);

  const handleClick = (item) => {
    setSelectedItem(item);
    
    onSelect(item);
  };
  return (
    <div className="service-column service-column-one">
      {buttons.map((button, index) => (
        <button key={index} onClick={() => onSelect(button.value)} className="service-machine-button">
          <div className="service-button-content">
            <img 
              src={button.img || fallbackImage} 
              alt={button.label} 
              className="service-button-image" 
              width="64" 
              height="64" 
            />
            <span>{button.label}</span>
          </div>
        </button>
      ))}
    </div>
  );
};

export default ColumnOne;