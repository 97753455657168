// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-login {
    display: inline-flex;
    padding: 72px 36px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    border-radius: 4px;
    background: var(--white, #ccc);
    margin-top: 50px;
  }
  .App {
    display: flex;
    justify-content: center;
    /* height: 60vh; */
  }
  .contents{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contents img {
    width: 4.3125rem;
    height: 4.3125rem;
    /* margin-left: 8rem; */
    margin-bottom: 2rem;
  }
  .contents > p {
    margin-left: 0px;
  }
  
  .lyt-section-for-alert {
    padding:0px !important;
    
  }`, "",{"version":3,"sources":["webpack://./src/component/Buying/Product/Confirmation.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,kBAAkB;IAClB,8BAA8B;IAC9B,gBAAgB;EAClB;EACA;IACE,aAAa;IACb,uBAAuB;IACvB,kBAAkB;EACpB;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;;EAExB","sourcesContent":[".success-login {\n    display: inline-flex;\n    padding: 72px 36px;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 40px;\n    border-radius: 4px;\n    background: var(--white, #ccc);\n    margin-top: 50px;\n  }\n  .App {\n    display: flex;\n    justify-content: center;\n    /* height: 60vh; */\n  }\n  .contents{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }\n  .contents img {\n    width: 4.3125rem;\n    height: 4.3125rem;\n    /* margin-left: 8rem; */\n    margin-bottom: 2rem;\n  }\n  .contents > p {\n    margin-left: 0px;\n  }\n  \n  .lyt-section-for-alert {\n    padding:0px !important;\n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
