import React from "react";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

const SummaryCartLoggedIn = ({ summaryData, onProceedPayment }) => {
  const {
    subtotal,
    taxTotal,
    discountTotal,
    discountPercent,
    shippingCharge,
    total,
  } = summaryData;
  console.log("summaryData", summaryData);
  console.log("subtotal", subtotal);

  const hasSummaryData = summaryData && Object.keys(summaryData).length > 0;

  return (
    <>
      <div>
        <div className="rifhtsidebortertotaldivcontent">
          <p>
            {/* <div className="meter-container">
            <div className="meter">
              <div className="meter-fill" style={{ width: "80%" }}></div>
            </div>
          </div> */}
            <IoCheckmarkDoneSharp className="ioiconscc" size={28} /> &nbsp;
            <span className="orderdetailscart">
              Your order is ready for processing.{" "}
            </span>
            <br />
          </p>
          <p className="summaryContent">
            <span className="summaryItem">
              <span className="label labledddd">Summary</span>
            </span>
            {/*           
          <span className="summaryItem">
            <span className="label">Quanity:</span>
            <span className="value">₹{quantity}</span>
          </span> */}
            <span className="summaryItem">
              <span className="label">Subtotal:</span>
              <span className="value">₹{subtotal.toFixed(2)}</span>
            </span>
            <span className="summaryItem">
              <span className="label">Tax:</span>
              <span className="value">₹{taxTotal.toFixed(2)}</span>
            </span>
            {discountTotal > 0 && (
              <span className="summaryItem">
                <span className="label">Discount ({discountPercent}%):</span>
                <span className="value">-₹{discountTotal.toFixed(2)}</span>
              </span>
            )}
            {shippingCharge > 0 && (
              <span className="summaryItem">
                <span className="label">Shipping Charge:</span>
                <span className="value">₹{shippingCharge.toFixed(2)}</span>
              </span>
            )}
            <span className="summaryItem">
              <span className="label">Total:</span>
              <span className="value">₹{total.toFixed(2)}</span>
            </span>
          </p>
        </div>
        <div className="proceddcartbuttondiv">
          <button className="proceedcartbtn" onClick={onProceedPayment}>
            Proceed to Buy
          </button>
        </div>
      </div>
    </>
  );
};

export default SummaryCartLoggedIn;
