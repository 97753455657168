// import React from 'react'

// const CombinedFilterSection = () => {

//   const isPriceFilter = filterType === "Price";
//   const [selectedValues, setSelectedValues] = useState([]);

//   const priceOptions = [
//     { name: "Below ₹50,000", value: "0 TO 50000" },
//     { name: "₹50,001 to ₹100,000", value: "50001 TO 100000" },
//     { name: "₹100,001 to ₹250,000", value: "100001 TO 250000" },
//     { name: "₹250,001 to ₹500,000", value: "250001 TO 500000" },
//     { name: "₹500,001 to ₹750,000", value: "500001 TO 750000" },
//     { name: "₹750,001 to ₹1,000,000", value: "750001 TO 1000000" },
//     { name: "Above ₹1,000,001", value: "1000001 TO 10000000" }
//   ];


//   const handlePrice = (event) => {
//     const value = event.currentTarget.value;
//     const newSelectedValues = [...selectedValues];
//     if (event.currentTarget.checked) {
//       newSelectedValues.push(value);
//     } else {
//       const index = newSelectedValues.indexOf(value);
//       newSelectedValues.splice(index, 1);
//     }

//     setSelectedValues(newSelectedValues);

//     let mainFiltersString = "";
//     if (newSelectedValues.length > 0) {
//       mainFiltersString = newSelectedValues.map((val) => `grossPrice:${val}`).join(" OR ");
//       mainFiltersString = `(${mainFiltersString})`;
//     }

//     setMainFilters(mainFiltersString);
//   };

//   useEffect(() => {
//     console.log(`Values under title "${title}":`);
//     if (isPriceFilter) {
//       console.log(priceOptions.map(option => option.name));
//     } else {
//       // Values are handled by ConnectedSpareRefinementList
//     }
//   }, [title]);

//   return (
//     <div></div>
//   )
// }

// export default CombinedFilterSection