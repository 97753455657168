import React, { useState } from "react";
import { closeIcon } from "../../../helpers/Icons";
import LocationPopup from "./LocationPopup";

const fallbackImage = "https://origa.market/asset/image%206.png";

const AllServiceColumnOne = ({ onSelect, buttons = [] }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [showLocationPopup, setShowLocationPopup] = useState();
  const [selectedLocation, setSelectedLocation] = useState(null);

  const closeLocationPopup = () => {
    setShowLocationPopup(false);
  };

  const [clickedImage, setClickedImage] = useState(null);
  const handleClick = (item) => {
    setSelectedItem(item);
    onSelect(item);
  };

  const handleImageClick = (value) => {
    setSelectedItem(value);
    setSelectedItem(value);
    onSelect(value);
    setClickedImage(value);
  };



  

  const multiplelocations = [
    {
      title: "Chennai",
      img: "https://thumbs.dreamstime.com/b/puratchi-thalaivar-dr-mgr-central-railway-station-chennai-india-tamilnadu-beautiful-view-day-light-blue-say-183608328.jpg",
    },
    {
      title: "Mumbai",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuOwjKtpRcPyzXGhHWx4UHDw1Z84k-hhYL8Q&s",
    },
    {
      title: "Bangalore",
      img: "https://www.fabhotels.com/blog/wp-content/uploads/2018/06/Shopping_1000x650_SubHeadImages_210618.jpg",
    },
    {
      title: "Pune",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQt_bJoDMPdiupRKe42R2q1xFVyRpzuESDshQ&s",
    },
  ];

  return (
    <>
      <div className="ColumnONEkARTHK">
        <div className="insidecolummnnone">
          <div className="service-selection">
            <div className="service-heading-bttt">
              <div>
                <h2 style={{ textAlign: "left" }}>Select Machines</h2>
              </div>
            </div>

            <div className="service-grid">
              {buttons.map((button, index) => (
                <button
                  key={index}
                  onClick={() => handleClick(button.value)}
                  className={`service-button ${
                    selectedItem === button.value ? "selected" : ""
                  }`}
                >
                  <img
            src={button.img || fallbackImage}
            alt={button.label}
            className={`service-icon ${
              clickedImage === button.value ? "clicked" : ""
            }`} 
            onClick={(e) => {
              e.stopPropagation(); 
              handleImageClick(button.value);
            }}
          />
                  <span className="service-label"
                   onClick={(e) => {
                    e.stopPropagation(); 
                    handleImageClick(button.value);
                  }}
                  >{button.label}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {showLocationPopup && (
        <div className="popup-overlay">
          <div className="popup-banner">
            <div className="popup-content">

<LocationPopup
multiplelocations={multiplelocations}
closeLocationPopup={closeLocationPopup}
 />
            <div className="close-popup" onClick={closeLocationPopup}>
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AllServiceColumnOne;
