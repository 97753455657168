// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bi-popup-wrap .inner-video .video-wrap{max-width:757px;padding: 30px 0 20px;position: relative;}
.bi-popup-wrap .inner-video{
    background: #fff;
    max-width: 600px;
    padding: 30px 30px;
    border-radius:16px;
}
.bi-popup-wrap .inner-video .play-icon{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);z-index: 2;}
.bi-popup-wrap .inner-video .video-wrap .machine-video{width:100%;max-height:408px;}
.bi-popup-wrap .inner-video .timer span{width: 200px;height: 4px;border:#AAAAAA;}`, "",{"version":3,"sources":["webpack://./src/component/Buying/Modals/VideoModal.css"],"names":[],"mappings":"AAAA,wCAAwC,eAAe,CAAC,oBAAoB,CAAC,kBAAkB,CAAC;AAChG;IACI,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;AACtB;AACA,uCAAuC,kBAAkB,CAAC,QAAQ,CAAC,SAAS,CAAC,gCAAgC,CAAC,UAAU,CAAC;AACzH,uDAAuD,UAAU,CAAC,gBAAgB,CAAC;AACnF,wCAAwC,YAAY,CAAC,WAAW,CAAC,cAAc,CAAC","sourcesContent":[".bi-popup-wrap .inner-video .video-wrap{max-width:757px;padding: 30px 0 20px;position: relative;}\n.bi-popup-wrap .inner-video{\n    background: #fff;\n    max-width: 600px;\n    padding: 30px 30px;\n    border-radius:16px;\n}\n.bi-popup-wrap .inner-video .play-icon{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);z-index: 2;}\n.bi-popup-wrap .inner-video .video-wrap .machine-video{width:100%;max-height:408px;}\n.bi-popup-wrap .inner-video .timer span{width: 200px;height: 4px;border:#AAAAAA;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
