// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Vector_vector__rVMLL{
    width: 7.75rem;
    height: 2.875rem;
    display: flex;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/component/Vector/Vector.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,aAAa;IACb,8BAA8B;AAClC","sourcesContent":[".vector{\n    width: 7.75rem;\n    height: 2.875rem;\n    display: flex;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vector": `Vector_vector__rVMLL`
};
export default ___CSS_LOADER_EXPORT___;
