import { connectHits } from "react-instantsearch-dom";
import { heartIcon, botIcon, closeIcon } from "../../../../helpers/Icons";
import { useNavigate, useLocation } from "react-router-dom";
// import CustomPagination from "../CustomPagination";
import React, { useState, useEffect } from "react";
import useWishListAddOrUpdate from "../../../SubComponent/useWishListAddOrUpdate";
import CustomPagination from "../CustomPagination";
import {
  secondClient,
  GET_SPARE_DETAILS,
} from "../../../OrigaExtentionAPI/mutations";

import LoginModel from "../../../Authentication/LoginModel/LoginModel";
import Loader from "../../../SubComponent/Loader";
import QuotationPopupForInSpare from "./QuotationPopupForInSpare";
import QuotationPopupForSpareAddtoCart from "./QuotationPopupForSpareAddtoCart";
import "../../Product/SparePage/ViewProduct.css";
import aa from "search-insights";
import {
  ALGOLIA_ID,
  ALGOLIA_KEY,
  ALGOLIA_SELLER_INDEX,
  ALGOLIA_SPARE_INDEX,
} from "../../../../constants";
import { sendAlgoliaEvent } from "search-insights";
import { v4 as uuidv4 } from "uuid";
//----------------------------------------Showing Information Of the Product Based On Result Hits-------------------------------------------------------

const client = secondClient;
const NewSpareHits = ({
  loading,
  setLoading,
  hits,
  isGridView,
  toggleGridforthree,
  toggleGridforfour,
  Sorting,
  deliveryAvailable,
  sendEvent,
}) => {
  const [productDETAILS, setProductDETAILS] = useState({});

  const [successMessage, setSuccessMessage] = useState(null);

  const closePopup4 = () => {
    setQuotationPopupforAddtoCartinSpare(false);
    navigate("/store");
  };

  const closePopup5 = () => {
    setSuccessMessage(false);
    navigate("/store");
  };
  const [
    QuotationPopupforAddtoCartinSpare,
    setQuotationPopupforAddtoCartinSpare,
  ] = useState();

  const [quantity, setQuantity] = useState(1);
  const loggedin = localStorage.getItem("userToken");
  const queryParams = new URLSearchParams();
  const [loginPortal, setLoginPortal] = useState(false);
  const productId = queryParams.get("id"); //to get the id from url
  const groupId = queryParams.get("group_id"); //to get the id from url
  const ompuserId = localStorage.getItem("id");
  const customer_first_name = localStorage.getItem("firstName");
  const customer_last_name = localStorage.getItem("lastName");
  const customer_contact_no = localStorage.getItem("number");
  const customer_email = localStorage.getItem("emailId");
  // console.log('productId---->', productId);
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [buymachineId, setBuymachineId] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [infokey, setinfokey] = useState(0);
  let productIdtoCart = productDETAILS?.[infokey]?.id;

  const [showPopup2, setShowPopup2] = useState(false);

  const closePopup2 = () => {
    setQuotationPopupforBuyNowinSpare(false);
    navigate("/store");
  };
  const [QuotationPopupforBuyNowinSpare, setQuotationPopupforBuyNowinSpare] =
    useState();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the window to the top on component mount or update
    const fetchData = async () => {
      let id = localStorage.getItem("id");
      if (id === null) {
        id = "";
      }
      try {
        // Performs a GraphQL query using the Apollo client 'client'
        const { data } = await client.query({
          query: GET_SPARE_DETAILS,
          variables: { groupId: groupId || "", itemId: productId || "" },
        });
        // Sets the fetched data to state variable 'productDETAILS'
        setProductDETAILS(data?.inventoryItem?.response);
        // Sets 'loading' state to false after data is fetched
        setLoading(false);
        // Updates formatted price
        updateFormattedPrice();
      } catch (error) {}
    };
    fetchData();
  }, [groupId, productId]);

  const productname = productDETAILS?.[infokey]?.name;
  const productbrand = productDETAILS?.[infokey]?.brand || "N/A";
  const productimage =
    productDETAILS[infokey]?.images?.productImages[infokey]?.url || "N/A";
  const saleprice = productDETAILS?.[infokey]?.saleprice;
  //   const productbrand = productDETAILS?.[infokey]?.brand || "N/A";
  //   const productimage =
  //     productDETAILS[infokey]?.images?.productImages[infokey]?.url || "N/A";
  const handleCart = async (
    productIdtoCart,
    productName,
    salePrice,
    product
  ) => {
    // Get product details from the response

    const productDetails = Object.values(productDETAILS).find(
      (product) => product.id === productIdtoCart
    );

    // If product is not found, handle accordingly
    if (!productDetails) {
      console.log("Product not found in product details.");
      return;
    }

    const priceList = productDetails.price_list || [];
    console.log(
      "Price list Calculated total cart price in handleSubmitForCart in SpareHits.jsx: ",
      priceList
    );

    if (priceList.length === 0) {
      console.log("Price list is empty for the given product ID");
      return;
    }

    // Retrieve the first item from the price list
    if (priceList.length > 0) {
      const firstPriceInfo = priceList[0];
      const bucketId = firstPriceInfo.bucket_id;
      const bucketSize = firstPriceInfo.bucket_size;
      const firstPrice = firstPriceInfo.price;

      console.log(
        "First bucket ID Calculated total cart price in handleSubmitForCart in SpareHits.jsx:",
        bucketId
      );
      console.log(
        "First bucket size Calculated total cart price in handleSubmitForCart in SpareHits.jsx:",
        bucketSize
      );
      console.log(
        "First price Calculated total cart price in handleSubmitForCart in SpareHits.jsx:",
        firstPrice
      );

      // For now, set quantity to 1 as per the requirement
      const quantity = 1;
      const quantitypricevc = quantity; // Same quantity

      // Calculate total cart price
      const totalCartPriceof = firstPrice * bucketSize * quantitypricevc;
      console.log(
        "Calculated total cart price in handleSubmitForCart in SpareHits.jsx:",
        totalCartPriceof
      );

      // Get user details from localStorage
      const loggedin = localStorage.getItem("userToken");
      let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      console.log("Before - Cart Items:", cartItems);

      const ompuserId = localStorage.getItem("id");
      const customer_first_name = localStorage.getItem("firstName");
      const customer_last_name = localStorage.getItem("lastName");
      const customer_contact_no = localStorage.getItem("number");
      const customer_email = localStorage.getItem("emailId");

      // Assign other product details
      const productbrand = productDETAILS?.[infokey]?.brand || "N/A";
      const productimage =
        productDETAILS?.[infokey]?.images?.productImages[0]?.url || "N/A";

      // Check if the product is already in the cart
      const isProductInCart = cartItems.some(
        (item) => item.productId === productIdtoCart
      );

      if (!isProductInCart) {
        // Add product to cart if not already present
        cartItems.push({
          productId: productIdtoCart,
          productName: productName,
          productBrand: productbrand,
          productImage: productimage,
          salePrice: firstPrice, // Use the price from price list
          quantity: quantitypricevc,
          bucketId: bucketId,
          bucketSize: bucketSize,
          bucketPrice: firstPrice,
          totalCartPriceocvf: totalCartPriceof,
          endQuantity: firstPriceInfo.end_quantity,
        });

        console.log("After - Cart Items:", cartItems);
      }

      if (loggedin) {
        // If logged in, send the item to the API
        try {
          let itemList = cartItems.map((item) => ({
            item_id: item.productId,
            quantity: item.quantity,
            bucket_id: item.bucketId,
          }));

          console.log("Sending itemList to API:", itemList);

          await fetch(
            "https://devextension.origa.market/sparesapi/create_order",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                items: itemList,
                bucket_id: bucketId,
                ompuserId,
                customer_first_name,
                customer_last_name,
                customer_contact_no,
                order_status: "Draft",
                order_ref_number: "",
                customer_email,
                input: {
                  city: "",
                  cityArea: "",
                  companyName: "",
                  country: "",
                  countryArea: "",
                  firstName: "",
                  lastName: "",
                  phone: "",
                  postalCode: "",
                  streetAddress1: "",
                  streetAddress2: "",
                },
                businessaddress: {
                  city: "",
                  cityArea: "",
                  companyName: "",
                  country: "",
                  countryArea: "",
                  firstName: "",
                  lastName: "",
                  phone: "",
                  postalCode: "",
                  streetAddress1: "",
                  streetAddress2: "",
                },
                gst_no: "",
              }),
            }
          );
          console.log(`Item added to API cart. Product ID: ${productIdtoCart}`);
        } catch (error) {
          console.error("Error adding item to API cart:", error);
        }
      } else {
        // If not logged in, save to localStorage
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
      }

      // setLoading(true);

      // setTimeout(() => {
      //     navigate("/cartpage");
      // }, 500);

      setLoading(true);
      setTimeout(() => {
        window.location.reload();
        // navigate("/cartpage");
      }, 200);

      //   navigate(`/buy/sparetools`, { state });
    } else {
      console.error("Price list is empty or not available");
    }
  };

  const handleCartNow = async (
    product,
    position,
    queryID,
    productIdtoCart,
    productName,
    salePrice,
    productThumbnail
  ) => {
    console.log("Entire Product : ", product);
    console.log("position : ", position);
    const productImage = product?.thumbnail || "N/A"; // Use product thumbnail directly
    const productBrand = product.brands || "N/A"; // Extract the brand
    const productBucketSize = product.bucketsize || "N/A"; // Extract the bucket size
    const productPackPrice = product.packprice || 0; // Extract the pack price

    console.log("Inside handleCartNow - productIdtoCart:", productIdtoCart);
    console.log("Inside handleCartNow - productName:", salePrice);
    console.log("Inside handleCartNow - productBrand:", productBrand);
    console.log("Inside handleCartNow - productBucketSize:", productBucketSize);
    console.log("Inside handleCartNow - productPackPrice:", productPackPrice);
    //    const productbrand = productDETAILS?.[infokey]?.brand || "N/A";
    //    console.log("Inside HandleCartNow - productBrand  :  ",braffss);

    //    console.log("Inside handleCartNow - productImage:", productImage);

    setQuotationPopupforAddtoCartinSpare(true);

    console.log("Inside handleCartNow - setting product details");
    setProductDETAILS({
      id: productIdtoCart,
      name: salePrice,
      image: productImage,
      brands: productBrand,
      packPrice: productPackPrice,
      bcukettSize: productBucketSize,
      price_list: productDETAILS.price_list,
      queryID: queryID,
      position: position,
    });
  };

  const handleBuyNow = async (
    product,
    position,
    queryID,
    productIdtoCart,
    productName,
    salePrice,
    productThumbnail
  ) => {
    console.log("position : ", position);
    console.log("Inside handleBuyNow - id ", product?.objectID);
    console.log("Inside handleBuyNow - productIdtoCart", productIdtoCart);
    console.log("Inside handleBuyNow ", product);
    console.log("Inside handleBuyNow - productName:", product?.productName);

    const productImage = product?.thumbnail || "N/A"; // Use product thumbnail directly
    console.log("Inside handleBuyNow - productImage:", productImage);
    const productBrand = product?.brands || "N/A"; // Extract the brand
    const productBucketSize = product?.bucketsize || "N/A"; // Extract the bucket size
    const productPackPrice = product?.packprice || 0; // Extract the pack price

    console.log("Inside handleBuyNow - productBrand:", product?.brands);
    console.log(
      "Inside handleBuyNow - productBucketSize:",
      product?.bucketsize
    );
    console.log("Inside handleBuyNow - productPackPrice:", product?.packprice);
    setQuotationPopupforBuyNowinSpare(true);

    setProductDETAILS({
      id: product?.objectID,
      name: product?.productName,
      image: productImage,
      brands: productBrand,
      packPrice: productPackPrice,
      bcukettSize: productBucketSize,
      price_list: productDETAILS.price_list,
      queryID: queryID,
      position: position,
    });
  };

  const handleSubmit = async ({
    productId,
    quantity,
    bucketId,
    productName,
    salePrice,
    position,
  }) => {
    console.log("Inside handleSubmit in SpareHits.jsx");
    console.log(" Inside handleSubmit in SpareHits.jsx Product ID:", productId);
    console.log(" Inside handleSubmit in SpareHits.jsx:", quantity);
    console.log(" Inside handleSubmit in SpareHits.jsx:", bucketId);
    console.log(" Inside handleSubmit in SpareHits.jsx:", salePrice);

    const loggedin = !!localStorage.getItem("userToken");
    console.log("User logged in:", loggedin);

    const queryID = localStorage.getItem("queryID"); // Retrieve queryID stored earlier

    console.log("queryID : ", queryID);
    console.log("productId : ", productId);
    console.log("position : ", position);
    const timestamp = Date.now();
    if (queryID) {
      sendEvent("clickedObjectIDs", {
        eventType: "click",
        eventName: "Product Clicked",
        index: "zohoinventory_index",
        objectIDs: [productId],
        userToken: localStorage.getItem("uuidUserToken"),
        queryID: queryID,
        positions: [position],
        timestamp: timestamp,
      });
    } else {
      console.error("Query ID not found. Click event not sent.");
    }

    setQuotationPopupforBuyNowinSpare(false);
    if (!loggedin) {
      console.log("User not logged in, saving pending action to localStorage");
      localStorage.setItem(
        "pendingBuyNow",
        JSON.stringify({
          productId,
          quantity,
          bucketId,
        })
      );
      setLoginPortal(true);
      return;
    }

    console.log("Proceeding with Buy Now");
    await proceedWithBuyNow(productId, quantity, bucketId);
  };

  const proceedWithBuyNow = async (productId, quantity, bucketId) => {
    console.log("Inside proceedWithBuyNow in SpareHits.jsx");
    console.log(
      "Inside proceedWithBuyNow in SpareHits.jsx Product ID:",
      productId
    );
    console.log(
      "Inside proceedWithBuyNow in SpareHits.jsx Quantity:",
      quantity
    );
    console.log(
      "Inside proceedWithBuyNow in SpareHits.jsx Bucket ID:",
      bucketId
    );

    const userToken = localStorage.getItem("userToken");
    const ompuserId = localStorage.getItem("id");
    const customer_first_name = localStorage.getItem("firstName");
    const customer_last_name = localStorage.getItem("lastName");
    const customer_contact_no = localStorage.getItem("number");
    const customer_email = localStorage.getItem("emailId");

    const itemList = [
      {
        item_id: productId,
        quantity: quantity,
        bucket_id: bucketId,
      },
    ];

    console.log("Item list for API:", itemList);

    try {
      const response = await fetch(
        "https://devextension.origa.market/sparesapi/create_order",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            items: itemList,
            ompuserId,
            customer_first_name,
            customer_last_name,
            customer_contact_no,
            order_status: "Buy",
            order_ref_number: "",
            customer_email,
            input: {
              city: "",
              cityArea: "",
              companyName: "",
              country: "IN",
              countryArea: "",
              firstName: "",
              lastName: "",
              phone: "",
              postalCode: "",
              streetAddress1: "",
              streetAddress2: "",
            },
            businessaddress: {
              city: "",
              cityArea: "",
              companyName: "",
              country: "IN",
              countryArea: "",
              firstName: "",
              lastName: "",
              phone: "",
              postalCode: "",
              streetAddress1: "",
              streetAddress2: "",
            },
            gst_no: "",
          }),
        }
      );

      console.log("Response status:", response.status);
      const responseData = await response.json();
      console.log("Response data:", responseData);

      const {
        order_id: orderId,
        total_quantity: totalQuantity,
        subtotal: subtotttal,
        tax_total: gst,
        discount_total: taxes,
        shipping_charge: shippingCharges,
        total,
      } = responseData;

      console.log("Order ID:", orderId);
      console.log("Total Quantity:", totalQuantity);
      console.log("Subtotal:", subtotttal);
      console.log("GST Amount:", gst);
      console.log("Discount Total:", taxes);
      console.log("Shipping Charges:", shippingCharges);
      console.log("Total Amount:", total);

      setLoading(true);
      setTimeout(() => {
        console.log("Navigating to manage address page");
        navigate("/manageaddresspart", {
          state: {
            itemId: productId,
            bucketId,
            quantity,
            orderId,
            quantityTotal: totalQuantity,
            subtotalAmount: subtotttal,
            gstAmount: gst,
            taxesAmount: taxes,
            shippingFee: shippingCharges,
            totalAmount: total,
          },
        });
        window.location.reload();
      }, 400);
    } catch (error) {
      console.error("Error adding item to API cart:", error);
    }
  };

  useEffect(() => {
    const pendingBuyNow = localStorage.getItem("pendingBuyNow");
    console.log("useEffect - pendingBuyNow:", pendingBuyNow);

    if (pendingBuyNow) {
      const { productId, quantity, bucketId } = JSON.parse(pendingBuyNow);
      console.log("useEffect - Proceeding with pending Buy Now:", {
        productId,
        quantity,
        bucketId,
      });

      proceedWithBuyNow(productId, quantity, bucketId);

      localStorage.removeItem("pendingBuyNow");
      console.log("useEffect - Cleared pendingBuyNow from localStorage");
    }
  }, [loggedin]);

  const navigate = useNavigate();
  const [filteredHitsToRender, setFilteredHitsToRender] = useState(hits);
  const { onWishlistHandler, heartColor } = useWishListAddOrUpdate();

  const updateFormattedPrice = () => {
    //is used to update how the price of a product is displayed on a webpage
    setProductDETAILS((prevDetails) => ({
      //it takes the current details of the product(prevdetails)and creates a new set of details with an updatted formatted price
      ...prevDetails,
      price_details: {
        ...prevDetails.price_details,
        //amc_cost: formatCurrency(prevDetails.price_details.amc_cost),
        //delivery_charges: formatCurrency(prevDetails.price_details.delivery_charges),
        machine_price: formatCurrency(prevDetails.sales_rate),
      },
    }));
  };

  const formatCurrency = (value) => {
    //like to show in indian rupees it adds commas where needed and shows the right number of decimal place
    const fractionDigits = value % 1 !== 0 ? 2 : 0;
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(value || 0);
  };

  const findYearDifference = (targetYear) => {
    const currentYear = new Date().getFullYear();
    const yearDifference = currentYear - targetYear;
    return yearDifference;
  };
  const priceConvert = (price) => {
    price = typeof price === "string" ? price : String(price);

    let count = 1;
    let comma = 3;
    let formatedPrice = "";
    for (let i = price.length - 1; i >= 0; i--) {
      formatedPrice = price[i] + formatedPrice;
      if (count === comma) {
        formatedPrice = "," + formatedPrice;
        comma = 2;
        count = 0;
      }
      count++;
    }
    //console.log("==>>", formatedPrice)
    if (formatedPrice[0] === ",") {
      formatedPrice = formatedPrice.slice(1, formatedPrice.length);
    }
    return formatedPrice;
  };
  // console.log("filteredHitsToRender---->", filteredHitsToRender);
  useEffect(() => {
    let sortedHits = [...hits];

    if (Sorting !== null) {
      sortedHits = [...hits].sort((a, b) => {
        if (Sorting === "Pricelow_High") {
          return a["grossPrice"] - b["grossPrice"];
        } else if (Sorting === "PriceHigh_low") {
          return b["grossPrice"] - a["grossPrice"];
        } else if (Sorting === "AlphabeticallyAtoZ") {
          return a["productName"].localeCompare(b["productName"]);
        }
        return 0; // Default case
      });
    }

    setFilteredHitsToRender(sortedHits);
  }, [Sorting, hits]);

  const handleFavorite = (index, value) => {
    const updatedHits = [...filteredHitsToRender];
    updatedHits[index]["favorite"] = value;
    setFilteredHitsToRender(updatedHits);
  };

  const onSubmitHandler = (props) => {
    console.log(
      "whish list working",
      props?.categories.lvl2?.split(">")[2].trim()
    );
    const loggedin = !!localStorage.getItem("userToken");
    if (loggedin) {
      onWishlistHandler(
        props?.productId,
        props?.thumbnail,
        props?.grossPrice,
        props?.productName,
        props?.attributes?.Brands,
        props?.categories?.lvl1?.split(">")[1].trim(),
        props?.categories.lvl2?.split(">")[2].trim()
      );
    }
  };

  const handleSubmitForCart = async ({
    productIded,
    quantityed,
    bucketIded,
    productName,
    salePrice,
    priceList,
    position,
  }) => {
    console.log("Inside handleSubmitForCart in SpareHits.jsx");
    console.log(
      "Product ID Inside handleSubmitForCart in SpareHits.jsx:",
      productIded
    );
    console.log(
      "Quantity Inside handleSubmitForCart in SpareHits.jsx:",
      quantityed
    );
    console.log(
      "Bucket ID Inside handleSubmitForCart in SpareHits.jsx:",
      bucketIded
    );
    console.log(
      "priceList Inside handleSubmitForCart in SpareHits.jsx:",
      priceList
    );

    // Ensure productIded is a valid value
    if (!productIded) {
      console.error("Invalid productIded:", productIded);
      return;
    }

    // Retrieve the product details from priceList
    const priceItem = priceList.find((item) => item.bucket_id === bucketIded);

    // Check if the priceItem is found
    if (!priceItem) {
      console.error("Price item not found for bucket ID:", bucketIded);
      return;
    }

    const {
      bucket_size: bucketSize,
      price: firstPrice,
      end_quantity: endQuantity,
    } = priceItem;

    console.log(
      "Bucket Size Inside handleSubmitForCart in SpareHits.jsx:",
      bucketSize
    );
    console.log(
      "First Price Inside handleSubmitForCart in SpareHits.jsx:",
      firstPrice
    );

    // Calculate total cart price
    const totalCartPriceof = firstPrice * bucketSize * quantityed;
    console.log(
      "Calculated total cart price Inside handleSubmitForCart in SpareHits.jsx:",
      totalCartPriceof
    );

    // Get user details from localStorage
    const loggedin = localStorage.getItem("userToken");
    let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    console.log("Before - Cart Items:", cartItems);

    const ompuserId = localStorage.getItem("id");
    const customer_first_name = localStorage.getItem("firstName");
    const customer_last_name = localStorage.getItem("lastName");
    const customer_contact_no = localStorage.getItem("number");
    const customer_email = localStorage.getItem("emailId");

    // Retrieve product details from productDETAILS
    const productbrand = productDETAILS?.[productIded]?.brand || "N/A";
    const productimage =
      productDETAILS?.[productIded]?.images?.productImages[0]?.url || "N/A";

    // Check if the product is already in the cart
    const isProductInCart = cartItems.some(
      (item) => item.productId === productIded
    );

    if (!isProductInCart) {
      // Add product to cart if not already present
      cartItems.push({
        productId: productIded,
        productName: productName,
        productBrand: productbrand,
        productImage: productimage,
        salePrice: firstPrice,
        quantity: quantityed,
        bucketId: bucketIded,
        bucketSize: bucketSize,
        bucketPrice: firstPrice,
        totalCartPriceocvf: totalCartPriceof,
        endQuantity: endQuantity,
      });

      console.log(
        "After - Cart Items Inside in SpareHits in handleSubmitForCart:",
        cartItems
      );
    } else {
      // Update quantity and price if product is already in the cart
      cartItems = cartItems.map((item) =>
        item.productId === productIded
          ? {
              ...item,
              quantity: item.quantity + quantityed,
              totalCartPriceocvf: item.totalCartPriceocvf + totalCartPriceof,
            }
          : item
      );
    }

    // Trigger the Algolia event here
    const timestamp = Date.now();
    const uuidUserToken = localStorage.getItem("uuidUserToken") || uuidv4(); // Get or generate the UUID token
    const srghf = localStorage.getItem("queryID"); // Get the query ID from localStorage

    console.log("object Id:", productIded);
    console.log("Query Id:", srghf);
    console.log("Positions:", position);
    console.log("timestamp:", timestamp);
    console.log("spareIndex:", "zohoinventory_index");
    console.log("UserToken:", uuidUserToken);

    // Send event to Algolia or analytics platform
    const ALGOLIA_APP_IDs = "WMFH4GQ1HA";
    const ALGOLIA_API_KEYs = "26d776e16554d71413cb70ee9bc30fb6";
    const spareIndex = "zohoinventory_index";

    sendEvent(
      "clickedObjectIDs",
      {
        eventType: "click",
        eventName: "Product Clicked",
        index: spareIndex,
        objectIDs: [productIded],
        positions: [position],
        userToken: uuidUserToken,
        queryID: srghf,
        timestamp: timestamp,
      },
      {
        headers: {
          "X-Algolia-Application-Id": ALGOLIA_APP_IDs,
          "X-Algolia-API-Key": ALGOLIA_API_KEYs,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        console.log("Event sent successfully:", response);
      })
      .catch((error) => {
        console.error("Error sending event:", error);
      });

    // If logged in, send the item to the API
    if (loggedin) {
      try {
        let itemList = cartItems.map((item) => ({
          item_id: item.productId,
          quantity: item.quantity,
          bucket_id: item.bucketId,
        }));

        console.log("Sending itemList to API:", itemList);

        await fetch(
          "https://devextension.origa.market/sparesapi/create_order",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              items: itemList,
              bucket_id: bucketIded, 
              ompuserId,
              customer_first_name,
              customer_last_name,
              customer_contact_no,
              order_status: "Draft",
              order_ref_number: "",
              customer_email,
              input: {
                city: "",
                cityArea: "",
                companyName: "",
                country: "",
                countryArea: "",
                firstName: "",
                lastName: "",
                phone: "",
                postalCode: "",
                streetAddress1: "",
                streetAddress2: "",
              },
              businessaddress: {
                city: "",
                cityArea: "",
                companyName: "",
                country: "",
                countryArea: "",
                firstName: "",
                lastName: "",
                phone: "",
                postalCode: "",
                streetAddress1: "",
                streetAddress2: "",
              },
              gst_no: "",
            }),
          }
        );

        console.log(`Item added to API cart. Product ID: ${productIded}`);
      } catch (error) {
        console.error("Error adding item to API cart:", error);
      }
    } else {
      // If not logged in, save to localStorage
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      console.log("If not logged in, save to localStorage", cartItems);
    }

    // Close quotation popup and show success message
    setQuotationPopupforAddtoCartinSpare(false);
    setSuccessMessage(true);

    // Reload page after a short delay
    setTimeout(() => {
      window.location.reload();
    }, 900);
  };

  const [selectedPosition, setSelectedPosition] = useState(null);

  const handleAvailService = (product, position, queryID) => {
    const state = {
      group_id: product.group_id,
      objectID: product.objectID || product.id,
      details: product,
    };
    setSelectedPosition(position); // Store the position in state
    console.log("Selected Position for state variable:", position);

    const objectIdforsimilartools = product.objectID;
    localStorage.setItem("ObjectIdToolforSimilar", objectIdforsimilartools);
    const timestamp = Date.now();
    const uuidUserToken = uuidv4();

    const postionfor = position;
    localStorage.setItem("uuidUserToken", uuidUserToken);
    localStorage.setItem("Positionforalgolia", postionfor);

    console.log("UUID User Token stored in localStorage:", uuidUserToken);

    console.log("object Id : ", product.objectID);
    console.log("Product Name : ", product.productName);
    console.log("Query Id : ", queryID);
    console.log("Positions :", position);
    console.log("timestamp :", timestamp);
    console.log("UserToken : ", uuidUserToken);

    localStorage.setItem("queryID", queryID);
    console.log(
      "QueryId in LocalStorage :   ",
      localStorage.getItem("queryID")
    );

    const ALGOLIA_APP_IDs = "WMFH4GQ1HA";
    const ALGOLIA_API_KEYs = "26d776e16554d71413cb70ee9bc30fb6";
    const spareIndex = "zohoinventory_index";

    sendEvent(
      "viewedObjectIDs",
      {
        eventType: "view",
        eventName: "Product Viewed",
        index: spareIndex,
        objectIDs: [state.objectID],
        userToken: uuidUserToken,
        queryID: queryID,
        timestamp: timestamp,
      },
      {
        headers: {
          "X-Algolia-Application-Id": ALGOLIA_APP_IDs,
          "X-Algolia-API-Key": ALGOLIA_API_KEYs,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        console.log("Event sent successfully:", response);
      })
      .catch((error) => {
        console.error("Error sending event:", error);
      });

    setLoading(true);
    setTimeout(() => {
      if (product?.group_id) {
        navigate(`/buy/spares-revised?group_id=${product.group_id}`, { state });
      } else {
        navigate(`/buy/spares-revised?id=${product.objectID}`, { state });
      }

      console.log(
        `Redirecting to ViewSpareProduct page: Group Id: ${product.group_id}, Item Id: ${state.objectID}`
      );
    }, 1000);
  };

  const handleModal = async (status, obj) => {
    // Check if the user is logged in
    const loggedin = !!localStorage.getItem("userToken");
    if (!loggedin) {
      // If not logged in, set login portal to true and stop further execution
      setLoginPortal(true);
      return;
    }

    // If status is 'phone-modal', handle specific logic
    if (status === "phone-modal") {
      // Check logged in status again (though redundant as already checked above)
      const loggedin = !!localStorage.getItem("userToken");
      if (!loggedin) {
        // If not logged in, set login portal to true and stop further execution
        setLoginPortal(true);
        return;
      } else {
        // Navigate to a specific route with query parameters
        navigate(`/manageaddresspart`);
      }
    }

    // If status is truthy (not 'phone-modal'), show modal
    if (status) {
      setShowModal(status);
    } else {
      // If status is falsy, hide modal and perform additional actions
      setShowModal(false);
      const id = localStorage.getItem("id");
      console.log("obj------------>", obj);
      setBuymachineId(obj?.buymachine_id); // Set buy machine ID from obj parameter
      try {
        // Fetch product details using GraphQL query based on productId
        const { data } = await client.query({
          query: GET_SPARE_DETAILS,
          variables: { itemId: productId },
        });
        setProductDETAILS(data?.inventoryItem?.response); // Update product details state
        updateFormattedPrice(); // Update formatted price based on new product details
      } catch (error) {
        // Handle errors if GraphQL query fails
        console.error("Error fetching product details:", error);
      }
    }
  };

  const onHidePortal = () => {
    setLoginPortal(false);
  };

  // console.log("ProductDetails: ", productDETAILS);
  /* console.log("Product Min Price: ",product.minPrice);
    console.log("Product Max Price: ",product.maxPrice); */

  return (
    <>
      {loading && <Loader />}
      {filteredHitsToRender?.length > 0 ? (
       <div className="product-wrapdiv">
       <div className="products-div">
         {filteredHitsToRender?.map((product, index) => (
           <div className="products-div" key={index}>
             <div
               className="product-innerdiv"
               onClick={() =>
                 handleAvailService(product, index + 1, product.__queryID)
               }
               style={{ cursor: "pointer" }}
             >
               <img
                 className="product-imgdiv"
                 src={product.thumbnail}
                 alt={product.name}
               />
               <div
                 style={{
                   position: "absolute",
                   top: "18px",
                   left: "10px",
                   backgroundColor: "#CC0C39",
                   color: "white",
                   padding: "5px",
                   borderRadius: "5px",
                   fontSize: "12px",
                   fontWeight: "bold",
                 }}
               >
                 Exclusive Online
               </div>
     
               <div className="content-div">
                 <div className="name-div">
                   <span
                     style={{ cursor: "pointer", color: "inherit" }}
                     onMouseOver={(e) => (e.target.style.color = "")}
                     onMouseOut={(e) => (e.target.style.color = "inherit")}
                   >
                     {product?.brands} <br />
                     {product?.productName}
                   </span>
                 </div>
                 <div className="location-div" style={{ cursor: "pointer" }}>
                   <div className="price-wrapdiv" style={{ cursor: "pointer" }}>
                     <div className="time-div">{product?.Phase}</div>
     
                     <div className="price-div" style={{ cursor: "pointer" }}>
                       {product?.bucketsize} (Pack of {product?.noofpacks}) <br />₹
                       {product?.packprice} (₹{product?.grossPrice} /Ltr)
                       <br />
                       <span style={{ color: "#878787", fontSize: "18px" }}>
                         <s>₹{product?.mrp}/Ltr</s>
                         <span
                           style={{
                             color: "#58cb58",
                             marginLeft: "5px",
                             fontSize: "18px",
                             fontWeight: "500",
                           }}
                         >
                           {Math.ceil(
                             ((product?.mrp - product?.grossPrice) / product?.mrp) *
                               100
                           )}
                           % off
                         </span>
                         <br />
                       </span>
                     </div>
                   </div>
                 </div>
     
                 <div className="buttonsinonelinediv">
                   {deliveryAvailable ? (
                     <>
                       <button
                         className="purchase-div pbtnsbdiv"
                         onClick={(event) => {
                           event.stopPropagation(); 
                           handleCartNow(
                             product,
                             index + 1,
                             product.__queryID,
                             product.objectID,
                             quantity,
                             product.productName,
                             product,
                             product.thumbnail,
                             product.brands
                           );
                         }}
                         type="button"
                       >
                         Add to Cart
                       </button>
                       <button
                         className="purchasef-div pbtnsb1div"
                         onClick={(event) => {
                           event.stopPropagation(); 
                           handleBuyNow(
                             product,
                             index + 1,
                             product.__queryID,
                             product.objectID,
                             product.productName,
                             product,
                             product.thumbnail,
                             product.brands
                           );
                         }}
                         type="button"
                       >
                         Buy Now
                       </button>
                     </>
                   ) : (
                     <button
                       className="purchasefdiv pbtnsbdiv"
                       onClick={(event) => {
                         event.stopPropagation(); 
                         handleAvailService(
                           product,
                           index + 1,
                           product.__queryID
                         );
                       }}
                       type="button"
                     >
                       Request for Quotation
                     </button>
                   )}
                 </div>
               </div>
             </div>
           </div>
         ))}
       </div>
       <div className="mt-4">
         <CustomPagination />
       </div>
     </div>
     
      ) : (
        <p>No product found.</p>
      )}

      {loginPortal && <LoginModel onHide={onHidePortal} />}

      {QuotationPopupforBuyNowinSpare && (
        <div className="popup-overlay">
          <div className="popup-banner">
            <div className="popup-content">
              <QuotationPopupForInSpare
                productDetails={productDETAILS}
                onSubmit={handleSubmit}
              />
              <div className="close-popup" onClick={closePopup2}>
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}

      {QuotationPopupforAddtoCartinSpare && (
        <div className="popup-overlay">
          <div className="popup-banner">
            <div className="popup-content">
              <QuotationPopupForSpareAddtoCart
                productDetails={productDETAILS}
                onSubmit={handleSubmitForCart}
                position={selectedPosition}
              />
              <div className="close-popup" onClick={closePopup4}>
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}

      {successMessage && (
        <div className="success-popup">
          <div className="success-popup-content">
            <div className="success-icon">✓</div>
            <div className="success-message">
              <h3>Item added to Cart Successfully</h3>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default connectHits(NewSpareHits);
