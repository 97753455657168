import React, { useState, useRef, useEffect } from "react";
import './ViewSpareProductGivenTemplate.css'

import { ArrowIcon, RatingStarsv , DropdownArrowIcon } from '../../.././../helpers/IconsforNewPage';
import TTHeadingIconTextTDivider from "./HeadingIconTextDivider/TTHeadingIconTextTDivider";


const ViewSpareProductGivenTemplate  = ({className = "" }) => {
    
  return (
    <section className={`${className} product-details-section-product-header`}>
      <div className="product-details-section-tt-link-icon-link-icon-link">
        <div className="product-details-section-t-link-icon-link-icon-link">
          <div>Browse All</div>
          <ArrowIcon className="product-details-section-icon-7" />
          <div>Category</div>
          <ArrowIcon className="product-details-section-icon-8" />
          <div className="product-details-section-link-2">
            Product Name
          </div>
        </div>
      </div>
      <div className="product-details-section-ttt-placeholder-image-placeholder-image-placeholder-image-placeholder-image-placeholder-image-ttheading-tprice-tttstars-text-ttext-tvariant-icon-false-tvariant-tselected-true-selected-false-selected-false-tquantity-type-default-tstyle-primary-small-false-dark-mode-false-icon-position-no-icon" >
        <div className="product-details-section-tt-placeholder-image-placeholder-image-placeholder-image-placeholder-image-placeholder-image" >
          <div className="product-details-section-t-placeholder-image-placeholder-image-placeholder-image-placeholder-image" >
            <img
              className="product-details-section-placeholder-image-1"
              src="/assets/placeholder-image-4.png"
              loading="lazy"
             />
            <img
              className="product-details-section-placeholder-image-2"
              src="/assets/placeholder-image-4.png"
              loading="lazy"
             />
            <img
              className="product-details-section-placeholder-image-3"
              src="/assets/placeholder-image-4.png"
              loading="lazy"
             />
            <img
              className="product-details-section-placeholder-image-4"
              src="/assets/placeholder-image-4.png"
              loading="lazy"
             />
          </div>
          <img
            className="product-details-section-placeholder-image-5"
            src="/assets/placeholder-image-4.png"
            loading="lazy"
           />
        </div>
        <div className="product-details-section-tt-heading-tprice-tttstars-text-ttext-tvariant-icon-false-tvariant-tselected-true-selected-false-selected-false-tquantity-type-default-tstyle-primary-small-false-dark-mode-false-icon-position-no-icon" >
          <div className="product-details-section-t-heading">
            <div className="product-details-section-heading-2">
              <p>Product Name</p>
            </div>
          </div>
          <div className="product-details-section-t-price">
            <div className="product-details-section-price-1">$55</div>
          </div>
          <div className="product-details-section-ttt-stars-text">
            <div className="product-details-section-tt-stars-text">
              <div className="product-details-section-t-stars">
                <RatingStarsv className="product-details-section-stars-4" />
              </div>
              <div className="product-details-section-text-4">
                (3.5 stars) • 10 reviews
              </div>
            </div>
          </div>
          <div className="product-details-section-t-text">
            <div className="product-details-section-text-5">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ultricies purus ac ex tincidunt, ut gravida nunc fermentum.
              </p>
            </div>
          </div>
          <div className="product-details-section-t-variant">
            <div>Variant</div>
          </div>
          <div className="product-details-section-icon-false">
            <div className="product-details-section-t-select-one-icon-chevron-down" >
              <div className="product-details-section-select-one">
                Select
              </div>
              <DropdownArrowIcon className="product-details-section-icon-chevron-down-1" />
            </div>
          </div>
          <div className="product-details-section-t-variant-1">
            <div>Variant</div>
          </div>
          <div className="product-details-section-t-selected-true-selected-false-selected-false" >
            <div className="product-details-section-selected-true">
              <div className="product-details-section-button-3">
                Option One
              </div>
            </div>
            <div className="product-details-section-selected-false">
              <div className="product-details-section-button-4">
                Option Two
              </div>
            </div>
            <div className="product-details-section-selected-false-1">
              <div className="product-details-section-button-5">
                Option Three
              </div>
            </div>
          </div>
          <div className="product-details-section-t-quantity">
            <div>Quantity</div>
          </div>
          <div className="product-details-section-type-default">
            <div className="product-details-section-placeholder">1</div>
          </div>
          <div className="product-details-section-t-style-primary-small-false-dark-mode-false-icon-position-no-icon" >
            <div className="product-details-section-style-primary-small-false-dark-mode-false-icon-position-no-icon" >
              <div className="product-details-section-button-6">
                Add to Cart
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="product-details-section-style-secondary-small-false-dark-mode-false-icon-position-no-icon" >
        <div className="product-details-section-button-7">Buy Now</div>
      </div>
      <div className="product-details-section-text-6">
        Free Shipping Over $50
      </div>
      <div className="product-details-section-t-divider-1">
        <div className="product-details-section-divider-1" />
      </div>
      <div className="product-details-section-tttt-heading-icon-text-tdivider-ttheading-icon-text-tdivider-ttheading-icon-text-tdivider" >
        <div className="product-details-section-ttt-heading-icon-text-tdivider-ttheading-icon-text-tdivider-ttheading-icon-text-tdivider" >
          <TTHeadingIconTextTDivider text="Details" />
          <TTHeadingIconTextTDivider text="Shipping" />
          <TTHeadingIconTextTDivider text="Returns" />
        </div>
      </div>
    </section>
  );
  };
export default ViewSpareProductGivenTemplate