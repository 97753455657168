// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div{
    width: 2.875rem;
height: 2.875rem;
border-radius: 2.125rem;
border: 1px solid #211E24;
display: flex;
align-items: center;
justify-content: center;
}
/* .img{
    width: 1rem;
    height: 0.875rem;
} */
`, "",{"version":3,"sources":["webpack://./src/component/Vector/Vector.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB,gBAAgB;AAChB,uBAAuB;AACvB,yBAAyB;AACzB,aAAa;AACb,mBAAmB;AACnB,uBAAuB;AACvB;AACA;;;GAGG","sourcesContent":[".div{\n    width: 2.875rem;\nheight: 2.875rem;\nborder-radius: 2.125rem;\nborder: 1px solid #211E24;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\n}\n/* .img{\n    width: 1rem;\n    height: 0.875rem;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
