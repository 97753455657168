import React, { useState, useEffect, useRef } from "react";
// import "./ProductListing.css";
import "./StorePart.css"
import aa from "search-insights";
import Breadcrumbs from "../../SubComponent/Breadcrumbs";
import DeliveryLocation from "../Modals/DeliveryLocation";
import { closeIcon, filterIcon, shortIcon } from "../../../helpers/Icons";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ALGOLIA_ID,
  ALGOLIA_KEY,
  ALGOLIA_SELLER_INDEX,
  ALGOLIA_SPARE_INDEX,
} from "../../../constants";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Configure,
  connectRange,
} from "react-instantsearch-dom";
import "react-image-gallery/styles/css/image-gallery.css";
import Loader from "../../SubComponent/Loader";
import SpareHits from "./SpareHits";

import { filterAttribute } from "./FilterAttribute";
import Slider from "@mui/material/Slider";
import CombinedHits from "./CombinedHits";
import SpareFilterSection from "./FilterSection/SpareFilterSection";
import CategoryPopup from "../../Account/Popup/CategoryPopup";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Footer from "../../Footer/Footer";
import { FaGripLinesVertical } from "react-icons/fa";
import { IoGrid } from "react-icons/io5";
import { HiBars4 } from "react-icons/hi2";
import { HiBars3 } from "react-icons/hi2";
import { TfiLayoutColumn2Alt } from "react-icons/tfi";
import { TfiLayoutColumn3Alt } from "react-icons/tfi";
import { TfiLayoutColumn4Alt } from "react-icons/tfi";
import SpareHitsNewDesign from "./SpareHits/SpareHitsNewDesign";
// import ImageGallery from "react-image-gallery";
// import { IoIosArrowForward } from "react-icons/io";
// import { IoIosArrowBack } from "react-icons/io";
// import { MdOpacity } from "react-icons/md";

aa("init", {
  appId: "ALGOLIA_ID",
  apiKey: "ALGOLIA_KEY",
});

const StoreMyDesign = () => {
  const [loading, setLoading] = useState(false);
  const client = algoliasearch(ALGOLIA_ID, ALGOLIA_KEY);
  const location = useLocation();
  const [isMobCategoryShow, setIsMobCategoryShow] = useState(
    window.innerWidth <= 767
  );

  /************************************************To set the filter Section as fixed and the product data as scrollable********************************************** */
  const [isSticky, setIsSticky] = useState(true);

  const scrollPositionLimit = 12308.2000122070312;

  const handleScroll = () => {
    if (window.scrollY >= scrollPositionLimit) {
      setIsSticky(false);
    } else {
      setIsSticky(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [showDeliveryPopup, setShowDeliveryPopup] = useState(false);

  const [filteredHits, setFilteredHits] = useState([]);
  // const [activeTab, setActiveTab] = useState("machines");
  const [MainFilters, setMainFilters] = useState("");
  const [filters, setFilters] = useState({
    industry: [],
    category: [],
    subcategory: [],
    brand: [],
    Model: [],
    Price: [],
    Year: [],
    Controller: [],
    XAXIS: [],
    YAXIS: [],
    ZAXIS: [],
    spindlespeed: [],
    spindletaper: [],
    tailstock: [],
    barcapacity: [],
    chucksize: [],
    maxturninglength: [],
    swingoverbed: [],
    swingovercrossslide: [],
    subSpindle: [],
    liveTooling: [],
    orderBy: "",
    page: 1,
  });
  const [IndustryCheckBoxValue, setIndustryCheckBoxValue] = useState([]);
  const [CategoryCheckBoxValue, setCategoryCheckBoxValue] = useState([]);

  const { SparefilterConfigurations } = filterAttribute(
    IndustryCheckBoxValue,
    CategoryCheckBoxValue
  );

  const initialActiveFilters = SparefilterConfigurations.reduce(
    (acc, config) => {
      acc[config.filterType] = true;
      return acc;
    },
    {}
  );
  const [activeFilters, setActiveFilters] = useState(initialActiveFilters);

  // const [activeFilters, setActiveFilters] = useState({
  //   industry: true,
  //   category: false,
  //   brand: false,
  //   model: false,
  //   Price: false,
  //   year: false,
  //   Controller: false,
  //   XAXIS: false,
  //   YAXIS: false,
  //   ZAXIS: false,
  //   spindlespeed: false,
  //   spindletaper: false,
  //   tailstock: false,
  //   barcapacity: false,
  //   chucksize: false,
  //   maxturninglength: false,
  //   swingoverbed: false,
  //   swingovercrossslide: false,
  //   subSpindle: false,
  //   liveTooling: false,
  // });
  // console.log("Spare Filter Configurations : ", SparefilterConfigurations);

  //Object.entries(activeFilters) - converts the object to array of key value pairs (["Category",true]).
  // useEffect(() => {
  //   console.log("Active Filters on load:", activeFilters);

  //   Object.entries(activeFilters).forEach(([filterType, isActive]) => {
  //     if (isActive) {
  //       console.log(`Filter "${filterType}" is active and visible in the UI.`);
  //     } else {
  //       console.log(`Filter "${filterType}" is not active and not visible in the UI.`);
  //     }
  //   });
  // }, [activeFilters]);

  // Use useEffect to track active filters and update scrollbar visibility
  //if there are two or more active filters  , show the scrollbar

  const [showScrollbar, setShowScrollbar] = useState(false);
  useEffect(() => {
    const activeCount = Object.values(activeFilters).filter(Boolean).length;
    setShowScrollbar(activeCount >= 2);
  }, [activeFilters]);

  //When i click on the filter this function is called
  //name - is the Category , value - is like true or false
  const handleFilters = (name, value) => {
    setActiveFilters((prevState) => {
      const newState = { ...prevState, [name]: value }; //makes a copy of the current activeFilters state and updates the specified filters in the new value
      const activeCount = Object.values(newState).filter(Boolean).length; //counts active filters
      setShowScrollbar(activeCount >= 2); // Show scrollbar if 2 or more filters are active
      return newState;
    });
  };

  const searchParams = new URLSearchParams(location.search);
  const searchInput = searchParams.get("searchInput");

  const [Sorting, setSorting] = useState(null);
  const [showShortList, setShowShortList] = useState(false);

  const handleChangeSort = (sort) => {
    setSorting(sort);
    const selectedFilters = { ...filters, orderBy: sort, page: 1 };
    setFilters(selectedFilters);
    setShowShortList(false);
  };

  const isBrandSelected = filters.brand.length > 0;

  const navigate = useNavigate();
  const [combinedResults, setCombinedResults] = useState({
    sellerResults: [],
    spareResults: [],
  });
  const [isSpareIndex, setIsSpareIndex] = useState(true);

  const breadcrumbsItems = [{ name: "Industrial Lubricants", link: "/" }];
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [deliveryAvailable, setDeliveryAvailable] = useState(true);
  const [seeMore, setSeeMore] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    const storedLocation = localStorage.getItem("deliveryLocation");
    if (storedLocation && !deliveryLocation) {
      setDeliveryLocation(storedLocation);
      checkDeliveryAvailability(storedLocation);
      console.log("Delivery Location is SparesRevised.jsx : ", storedLocation);
    }
  }, [deliveryLocation]);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        if (!searchInput) {
          setIsSpareIndex(true);
          setCombinedResults({ sellerResults: [], spareResults: [] });
          return;
        }

        const searchTerms = searchInput.split(" ");
        const queries = searchTerms.flatMap((term) => [
          { indexName: ALGOLIA_SELLER_INDEX, query: term },
          { indexName: ALGOLIA_SPARE_INDEX, query: term },
        ]);

        const { results } = await client.multipleQueries(queries);

        const sellerResults =
          results.find((result) => result.index === ALGOLIA_SELLER_INDEX)
            ?.hits || [];
        const spareResults =
          results.find((result) => result.index === ALGOLIA_SPARE_INDEX)
            ?.hits || [];

        setCombinedResults({
          sellerResults,
          spareResults,
        });

        setIsSpareIndex(spareResults.length > sellerResults.length);
      } catch (error) {
        console.error("Error fetching combined results:", error);
      }
    };

    fetchResults();
  }, [searchInput]);

  const checkDeliveryAvailability = async (pincode) => {
    try {
      const response = await fetch(
        "https://devextension.origa.market/api/getdeliverypincode",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ pincode }),
        }
      );
      const data = await response.json();
      setDeliveryAvailable(data.available_status === "available");
      if (data.available_status === "available") {
        setDeliveryLocation(pincode);
        localStorage.setItem("deliveryLocation", pincode);
      } else {
        setShowDeliveryPopup(false);
      }
    } catch (error) {
      console.error("Error checking delivery availability:", error);
      setDeliveryAvailable(false);
    }
  };
  const onGetPinCodeHandler = (pin) => {
    if (pin && pin.length === 6) {
      setDeliveryLocation(pin);
      localStorage.setItem("deliveryLocation", pin);
      checkDeliveryAvailability(pin);
    } else {
      console.error("Invalid pincode");
    }
  };

  const handleSeeMore = (status) => {
    console.log("First the delivery Location button is clicked : ");
    setSeeMore(status);
    document.body.classList.toggle("no-overflow", status);
  };

  const [isGridView, setIsGridView] = useState(false); 

  const toggleGridView = () => {
    console.log("The grid button being clicked ");
    setIsGridView((prevState) => !prevState);
  };
  
  const toggleDropdown = () => {
    console.log("First entering into this function");
    const dropdownElement = document.querySelector(".p-fixed");
    const dropdownElement1 = document.querySelector(".fixed-category-bottom");
    if (dropdownElement) {
      dropdownElement.classList.remove("dropdownHidden");
      dropdownElement1.classList.remove("dropdownHidden");
    }
  };

  const RangeSlider = ({ min, max, currentRefinement, refine }) => {
    const handleChange = (event, newValue) => {
      const currentYear = new Date().getFullYear();
      const minAllowedYear = currentYear - 25;
      if (minAllowedYear === newValue[0]) {
        refine({ min: 1900, max: newValue[1] });
      } else {
        refine({ min: newValue[0], max: newValue[1] });
      }
    };
    const [minYear, setMinYear] = useState(1940);

    useEffect(() => {
      const currentYear = new Date().getFullYear();
      const minAllowedYear = currentYear - 25;
      setMinYear(minAllowedYear);
    }, []);

    const defaultValue = [
      currentRefinement.min || min,
      currentRefinement.max || max,
    ];
    return (
      <Slider
        min={minYear}
        max={max}
        value={defaultValue}
        onChange={handleChange}
        valueLabelDisplay="auto"
        color="secondary"
        aria-labelledby="range-slider"
        getAriaValueText={(value) => `$${value}`}
      />
    );
  };

  const CustomRangeInput = connectRange(RangeSlider);

  const images = [
    { original: "asset/Listing-Banner_optimized.jpg" },
    { original: "asset/Listing-Banner-2.jpg" },
    { original: "asset/Listing-Banner-3.jpg" },
    { original: "asset/Listing-Banner-4.jpg" },
  ];

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
    1610: { items: 4 },
  };

  const carouselRef = useRef(null);
  const items = images.map((image, index) => (
    <div className="custom-image-container" key={index}>
      <div className="custom-image-wrapper">
        <img
          src={image.original}
          alt="Product"
          className="custom-image"
          draggable="false"
        />
      </div>
    </div>
  ));
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 576);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {loading && <Loader />}
      {seeMore && (
        <DeliveryLocation
          modalAction={handleSeeMore}
          pinvalue={onGetPinCodeHandler}
        />
      )}
      <div className="container-fluid col-cust">
        {/* <div className="max-container my-3"> */}
        <div className={isMobile ? "max-container my-3" : "mx-5 my-3"}>
          <InstantSearch
            searchClient={client}
            indexName={ALGOLIA_SPARE_INDEX}
            insights={true}
          >
            <Configure
              maxValuesPerFacet={50}
              query={searchInput || ""}
              filters={MainFilters}
              hitsPerPage={12}
            />
            <div className="d-md-none">
              <CategoryPopup
                filterConfigurations={SparefilterConfigurations}
                activeFilters={activeFilters}
                setIndustryCheckBoxValue={setIndustryCheckBoxValue}
                CategoryCheckBoxValue={CategoryCheckBoxValue}
                setCategoryCheckBoxValue={setCategoryCheckBoxValue}
                MainFilters={MainFilters}
                setMainFilters={setMainFilters}
              />
            </div>

            {/* BreadCrumbs */}
            <div className="Breadcrumbsanddeliverlocation">
              <div>
                <Breadcrumbs
                  backnavi={() => navigate("/store")}
                  boldtitle={"Lubricants"}
                  items={breadcrumbsItems}
                />
              </div>
            </div>

            {/*----------------------------------- Delivery Location button adn Filters button---------------------------- */}
            <div className="filterAndLocationWrapper d-flex ">
              <div className="head-wrap d-flex justify-content-end pb-2 ">
                <div className="btn-wrap">
                  <div>
                    {" "}
                    <button
                      onClick={() => handleSeeMore(true)}
                      className="filterAndLocationWrapper delveryloactionn"
                    >
                      {deliveryLocation
                        ? `Delivery Location : ${deliveryLocation}`
                        : "Set Delivery Location"}
                    </button>
                  </div>
                  <div className="short-wrap">
                    <button
                      className=" filterAndLocationWrapper btn short-btn "
                      onClick={toggleDropdown}
                    >
                      Filters
                      {filterIcon({ width: 22, height: 22, fill: "#73509E" })}
                    </button>
                  </div>
                </div>
              </div>
             
            </div>

            <div className="filterAndLocationWrapper d-flex justify-content-center p-2">
            <div className="gridButtonformobile">
            <span className="gridtext">Grid - &nbsp;</span>
                      <span className="gridlines">
                        
                       
                        <TfiLayoutColumn2Alt
                          onClick={toggleGridView}
                          size={20}
                        />
                      </span>
              </div>
            </div>

            {/*---------------Main code starts here leftside  - filtersection  , rightside - products and banner------------*/}
            <div
              className={`row-div layout-wrapdiv ${isGridView ? "grid-view" : ""}`}
              style={{ position: "relative" }}
            >
              <div
                className={`filtersectioncse col col-lg-3 col-md-3 col-12`}
                id="filterSection"
              >
                <div
                  className={`filter-wrap ${showScrollbar ? "scrollable" : ""}`}
                  style={{
                    position: isSticky ? "sticky" : "relative",
                    top: isSticky ? "205px" : "auto",
                  }}
                >
                  <div className="head">
                    <div className="heading heading-600-20">
                      {filterIcon({ width: 18, height: 12 })}
                      Filters
                    </div>
                    <div
                      id="ResetBtn"
                      className="heading-600-14 curser-pointer"
                    >
                      Reset
                    </div>
                  </div>
                  {SparefilterConfigurations?.map((config) => (
                    <SpareFilterSection
                      key={config.filterType}
                      title={config.title}
                      attribute={config.attribute}
                      filterType={config.filterType}
                      activeFilters={activeFilters}
                      setIndustryCheckBoxValue={setIndustryCheckBoxValue}
                      CategoryCheckBoxValue={CategoryCheckBoxValue}
                      setCategoryCheckBoxValue={setCategoryCheckBoxValue}
                      handleFilters={handleFilters}
                      MainFilters={MainFilters}
                      setMainFilters={setMainFilters}
                    />
                  ))}
                  {isBrandSelected && (
                    <SpareFilterSection
                      title="Grade"
                      attribute="grade"
                      filterType="grade"
                      activeFilters={activeFilters}
                      setIndustryCheckBoxValue={setIndustryCheckBoxValue}
                      CategoryCheckBoxValue={CategoryCheckBoxValue}
                      setCategoryCheckBoxValue={setCategoryCheckBoxValue}
                      handleFilters={handleFilters}
                      MainFilters={MainFilters}
                      setMainFilters={setMainFilters}
                    />
                  )}
                </div>
              </div>
              <div
                className={`productcardsectioncse col col-lg-9 col-md-9 col-12`}
              >
                {/*----------------------------------------------------Banner---------------------------------------------------*/}

                <AliceCarousel
                  ref={carouselRef}
                  mouseTracking
                  items={items}
                  responsive={responsive}
                  controlsStrategy="alternate"
                  infinite
                  autoPlay
                  autoPlayInterval={3000}
                  disableDotsControls
                  disableButtonsControls
                  disableDragging={true}
                />

                {/*--------This div is for showing three structures in desktop such as - Delivery Location , grid and sort------*/}

                <div className="filters-delivery-grid d-flex justify-content-end align-items-center mt-1">
                  {/* Div 1 - Align to the left */}
                  <div className="individual-div-filters-delivery-grid ">
                    <div className="d-flex">
                      <span className="gridlines">
                        <TfiLayoutColumn3Alt
                        onClick={toggleGridView}
                          size={20}
                        />
                      </span>
                      {/* <span className="gridlines">
                        <TfiLayoutColumn4Alt
                          onClick={toggleGridforfour}
                          size={22}
                        />
                      </span> */}
                      
                    </div>

                  </div>

                  {/* Div 2 and Div 3 - Align to the right */}
                 
                    {/* Div 2 */}


                    <div className="individual-div-filters-delivery-grid">
                      <button
                        onClick={() => handleSeeMore(true)}
                        className="delveryloactionned"
                      >
                        {deliveryLocation
                          ? `Delivery Location : ${deliveryLocation}`
                          : "Set Delivery Location"}
                      </button>
                    </div>

                    {/* Div 3 */}
                    <div className="individual-div-filters-delivery-grid sortbutton">
                      <div style={{ position: "relative" }}>
                        <button
                          className="sortforstorejsxed"
                          onClick={() => setShowShortList(!showShortList)}
                        >
                          {shortIcon({ width: 22, height: 22 })}
                          <span>
                            {filters.orderBy ? filters.orderBy : "Sort Result"}
                          </span>
                        </button>
                        {showShortList && (
                          <div className="shorts">
                            <span
                              className="item"
                              onClick={() =>
                                handleChangeSort("Sort Alphabetically (A - Z)")
                              }
                            >
                              Sort Alphabetically (A - Z)
                            </span>
                            <span
                              className="item"
                              onClick={() =>
                                handleChangeSort(
                                  "Sort by Price (Highest to Lowest)"
                                )
                              }
                            >
                              Sort by Price (Highest to Lowest)
                            </span>
                            <span
                              className="item"
                              onClick={() =>
                                handleChangeSort(
                                  "Sort by Price (Lowest to Highest)"
                                )
                              }
                            >
                              Sort by Price (Lowest to Highest)
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                </div>

                {/*-----------------------------------------------Showing Products----------------------------------------------*/}

                {isSpareIndex ? (
                  <SpareHitsNewDesign
                    isGridView={isGridView}
                    setLoading={setLoading}
                    Sorting={Sorting}
                    hitComponent={filteredHits}
                    query={searchInput}
                    deliveryAvailable={deliveryAvailable}
                    sendEvent={aa}
                    
                  />
                ) : (
                  <CombinedHits
                    sellerResults={combinedResults.sellerResults}
                    spareResults={combinedResults.spareResults}
                    deliveryAvailable={deliveryAvailable}
                  />
                )}
              </div>
            </div>
          </InstantSearch>
        </div>
      </div>
      <Footer />
      {showDeliveryPopup && (
        <div className="  popup-overlay ">
          <div className="popup-bannavjer ">
            <div className="popup-content ">
              <div className="">
                <h3 className="">Thank you for your quotation request!</h3>
                <p className="wewillcontactu">We'll get back to you soon</p>
                <br></br>
                <div
                  className="close-popup"
                  onClick={() => setShowDeliveryPopup(false)}
                >
                  {closeIcon({ width: 14, height: 14 })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StoreMyDesign;
