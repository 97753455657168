// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bi-popup-wrap .inner .heading-400-16-14{color:#211E24;opacity:0.8;margin:0 0 20px;}
.bi-popup-wrap .inner .form-group{position:relative;width:100%;}
.bi-popup-wrap .inner .form-group input{border:1px solid #AAA;padding:0 40px 0 15px;height:48px;}
.bi-popup-wrap .inner .form-group svg{position:absolute;right:10px;top:10px;}
.bi-popup-wrap .inner .popup-svg{display:flex;}
.bi-popup-wrap .inner .popup-svg svg{margin:0 auto;flex:190px 0;}
.bi-popup-wrap .inner .text{text-align:right;width:100%;}
.bi-popup-wrap .inner .location-btn{padding:14px 40px;background:#73509E;color:#fff;border-radius:12px;border:none;width:max-content;margin:30px auto 0;}`, "",{"version":3,"sources":["webpack://./src/component/Buying/Modals/DeliveryLocation.css"],"names":[],"mappings":"AAAA,yCAAyC,aAAa,CAAC,WAAW,CAAC,eAAe,CAAC;AACnF,kCAAkC,iBAAiB,CAAC,UAAU,CAAC;AAC/D,wCAAwC,qBAAqB,CAAC,qBAAqB,CAAC,WAAW,CAAC;AAChG,sCAAsC,iBAAiB,CAAC,UAAU,CAAC,QAAQ,CAAC;AAC5E,iCAAiC,YAAY,CAAC;AAC9C,qCAAqC,aAAa,CAAC,YAAc,CAAC;AAClE,4BAA4B,gBAAgB,CAAC,UAAU,CAAC;AACxD,oCAAoC,iBAAiB,CAAC,kBAAkB,CAAC,UAAU,CAAC,kBAAkB,CAAC,WAAW,CAAC,iBAAiB,CAAC,kBAAkB,CAAC","sourcesContent":[".bi-popup-wrap .inner .heading-400-16-14{color:#211E24;opacity:0.8;margin:0 0 20px;}\n.bi-popup-wrap .inner .form-group{position:relative;width:100%;}\n.bi-popup-wrap .inner .form-group input{border:1px solid #AAA;padding:0 40px 0 15px;height:48px;}\n.bi-popup-wrap .inner .form-group svg{position:absolute;right:10px;top:10px;}\n.bi-popup-wrap .inner .popup-svg{display:flex;}\n.bi-popup-wrap .inner .popup-svg svg{margin:0 auto;flex:190px 0 0;}\n.bi-popup-wrap .inner .text{text-align:right;width:100%;}\n.bi-popup-wrap .inner .location-btn{padding:14px 40px;background:#73509E;color:#fff;border-radius:12px;border:none;width:max-content;margin:30px auto 0;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
