import React, { useEffect, useState } from "react";
import algoliarecommend from "@algolia/recommend";
import { LookingSimilar } from "@algolia/recommend-react";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "./SimilarProductsToolsStyles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import Loader from "../../../SubComponent/Loader";
import { rightArrowIcon } from "../../../../helpers/Icons";
import useMediaQuery from "@mui/material/useMediaQuery";
const ALGOLIA_APP_ID = "WMFH4GQ1HA";
const ALGOLIA_API_KEY = "26d776e16554d71413cb70ee9bc30fb6";
const ALGOLIA_INDEX_NAME = "zohoinventory_index";

const recommendClient = algoliarecommend(ALGOLIA_APP_ID, ALGOLIA_API_KEY);
/*

*/
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: (
    <IoIosArrowForward
      fontSize={20}
      color="black"
      className="forwardiconintoolssimilar"
    />
  ),
  prevArrow: (
    <IoIosArrowBack
      fontSize={20}
      color="black"
      className="backwardiconintoolssimilar"
    />
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function ProductItem({ item }) {
  const navigate = useNavigate();
  const grossPrice = item.grossPrice;
  const formattedGrossPrice = new Intl.NumberFormat("en-IN").format(grossPrice);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    localStorage.setItem("ObjectIdToolforSimilar", item.objectID);
    const state = {
      group_id: item.group_id,
      objectID: item.objectID || item.id,
      details: item,
    };

    setLoading(true);
    setTimeout(() => {
      if (item?.group_id) {
        navigate(`/buy/spares-revised?group_id=${item.group_id}`, { state });
      } else {
        navigate(`/buy/spares-revised?id=${item.objectID}`, { state });
      }

      console.log(
        `Redirecting to ViewSpareProduct page: Group Id: ${item.group_id}, Item Id: ${state.objectID}`
      );
    }, 1000);
  };

  return (
    <>
      {loading && <Loader />}
      <div
        key={item.objectID}
        className="each-individual-card-for-similarmachines mt-3"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        <img
          src={item.thumbnail || item.image}
          alt={item.title || item.productName}
        />
        <div className="product-infofor">
          <h4 className="beforeofproductname">
            {item.title || item.productName}
          </h4>
          <div className="heading-underline"></div>

          <div className="d-flex justify-content-between align-items-center mt-2">
            <div style={{ fontSize: "14px" }}>{item.group_name}</div>
            <div style={{ fontSize: "14px" }}>₹{formattedGrossPrice}</div>
          </div>
        </div>
      </div>
    </>
  );
}

function LookingSimilarAlgolia() {
  const [objectIDs, setObjectIDs] = useState([]);

  useEffect(() => {
    const storedObjectId = localStorage.getItem("ObjectIdToolforSimilar");

    if (storedObjectId) {
      setObjectIDs([storedObjectId]);
    }
  }, []);
  const navigate = useNavigate();

  const handleIconClick = () => {
    navigate("/store");
  };


  return (
    <>
      <div className="similar-products-container ">
        <div className="d-flex justify-content-between align-items-center similar-oils-container">
          <div>
            <h3 className="similar-products-heading">
              Similar Oils you may like
            </h3>
          </div>
          <div className="arrowviewoils mb-3 d-flex align-items-center">
            <a
              href="/store"
              className="arrowicontext box-btn heading-600-14 heading-600-14-12"
            >
              View Oils&nbsp;&nbsp;
            </a>
            <span className="arrow-iconfor" style={{cursor:"pointer"}}
              onClick={handleIconClick}>
              {rightArrowIcon({ width: 24, height: 24 })}
            </span>
          </div>
        </div>

        
        <LookingSimilar
          recommendClient={recommendClient}
          indexName={ALGOLIA_INDEX_NAME}
          objectIDs={objectIDs}
          itemComponent={ProductItem}
          render={(items) => (
            <Slider {...settings} className="custom-slider">
              {items.map((item) => (
                <ProductItem key={item.objectID} item={item} />
              ))}
            </Slider>
          )}
        />
      </div>
    </>
  );
}

export default LookingSimilarAlgolia;
