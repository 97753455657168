import React, { useState, useEffect } from "react";

const LocationPopup = ({ multiplelocations, handleLocationSelect, currentLocation,planToAdd  }) => {
  const [selectedLocation, setSelectedLocation] = useState(currentLocation);

  // Update selectedLocation when currentLocation changes
  useEffect(() => {
    setSelectedLocation(currentLocation);
  }, [currentLocation]);
  
  const handleLocationClick = (location) => {
    setSelectedLocation(location); 
    handleLocationSelect(location, planToAdd); // Pass the selected location and plan to parent
    console.log("Location selected: ", location);
};

  return (
    <div className="">
      <h6 style={{ textAlign: "left", fontSize: "1.1rem", fontWeight: "600" }}>Select a Location</h6>
      <div className="service-grid">
        {multiplelocations.map((location, index) => (
          <button
            key={index}
            onClick={() => handleLocationClick(location.title)} 
            className={`service-button ${selectedLocation === location.title ? "selected" : ""}`}
          >
            <img src={location.img || "fallback-image-url"} alt={location.title} className="service-icon" />
            <span className="service-label">{location.title}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default LocationPopup;
