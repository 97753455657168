// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttontwoinaddresspage {
    display: flex;           /* Enable flexbox layout */
    justify-content: flex-end; /* Align items to the right end */
    align-items: center;
    padding-top: 1rem;       /* Add padding to the top (equivalent to pt-4) */
  }
  
  .buttontwoinaddresspage  {
  /* Adjust margin-right to add space to the right of the Cancel button (equivalent to mr-5) */
  }
  `, "",{"version":3,"sources":["webpack://./src/component/Account/ManageAddress/MyAddressButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa,YAAY,0BAA0B;IACnD,yBAAyB,EAAE,iCAAiC;IAC5D,mBAAmB;IACnB,iBAAiB,QAAQ,gDAAgD;EAC3E;;EAEA;EACA,4FAA4F;EAC5F","sourcesContent":[".buttontwoinaddresspage {\r\n    display: flex;           /* Enable flexbox layout */\r\n    justify-content: flex-end; /* Align items to the right end */\r\n    align-items: center;\r\n    padding-top: 1rem;       /* Add padding to the top (equivalent to pt-4) */\r\n  }\r\n  \r\n  .buttontwoinaddresspage  {\r\n  /* Adjust margin-right to add space to the right of the Cancel button (equivalent to mr-5) */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
