import React, { useState, useEffect } from 'react';
import ManageEditAddressForPopup from '../../Account/ManageAddress/ManageEditAddressForPopup';
import MyAccAddressforAddressPopupForEdit from '../../Account/ManageAddress/MyAccAddressforAddressPopupForEdit';

const AddressPopupForEdit = ({ selectedLocationfromapi, closePopupForAddress, handleSaveAfterAddressSelectionForEdit, planToAdd }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);

  // Fetch selected address from localStorage when the component mounts
  useEffect(() => {
    const storedAddress = localStorage.getItem("selectedAddress");
    if (storedAddress) {
      setSelectedAddress(JSON.parse(storedAddress)); // Parse and set the selected address
    }
  }, []);

  console.log("SelectedAddress in Address Popup", selectedAddress);

  const handleEditClick = (data) => {
    setEditData({
      ...data,
      selectedLocationfromapi, // Ensure selectedLocation is passed to the editData
    });
    setIsEditing(true);
  };

  const handleBackClick = ({ closePopup }) => {
    if (closePopup) {
      closePopupForAddress(); // Close the entire AddressPopup if "Add Address" was clicked
    } else {
      setIsEditing(false); // Go back to ManageAddressForPopup if "Edit Address" was clicked
    }
  };

  const handleDeliverHereForEdit = () => {
    if (!selectedAddress) {
      console.error("No address selected");
      return;
    }

    // Close the popup and call the save function with the selected address and the plan
    closePopupForAddress();
    handleSaveAfterAddressSelectionForEdit(selectedAddress, planToAdd); // Call the save function
  };

  return (
    <>
      {!isEditing ? (
        <ManageEditAddressForPopup
          selectedLocationfromapi={selectedLocationfromapi}
          onDeliverHere={handleDeliverHereForEdit}
          onEdit={handleEditClick}
          setSelectedAddress={setSelectedAddress}
        />
      ) : (
        <MyAccAddressforAddressPopupForEdit
          selectedLocationfromapi={selectedLocationfromapi}
          editData={editData}
          onBack={handleBackClick}
          setSelectedAddress={setSelectedAddress}
        />
      )}
    </>
  );
};

export default AddressPopupForEdit;
