import React, { useEffect, useState } from 'react';
import { secondClient, GET_SPARE_DETAILS } from "../OrigaExtentionAPI/mutations";
import { useLocation, useNavigate } from "react-router-dom";
import "./CartPageSampleStyles.css";
import LoginModel from "../Authentication/LoginModel/LoginModel";
import { TiTick } from "react-icons/ti";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { MdOutlineDoneOutline } from "react-icons/md";
const client = secondClient;

const CartAndSummaryNotLoggedIn  = ({ productId, productName, productBrand, productImage, salePrice }) => {
    const location = useLocation(); // to access the current location
      const queryParams = new URLSearchParams(location.search); // to pass the query parameters to the url
      const groupId = queryParams.get('group_id'); // to get the id from url
      const [cartItems, setCartItems] = useState(
          JSON.parse(localStorage.getItem("cartItems")) || []
        );
        
 const [totalQuantity, setTotalQuantity] = useState(0);
        

 const getCartItems = () => {
    return JSON.parse(localStorage.getItem("cartItems")) || [];
  };
  
   useEffect(() => {
      const totalItems = getTotalQuantity();
      setTotalQuantity(totalItems);
    }, []);
  
  
    const getTotalQuantity = () => {
      const cartItems = getCartItems();
      return cartItems.reduce((total, item) => total + item.quantity, 0);
    };
  
    useEffect(() => {
      const total = getTotalPrice();
      setTotalPrice(total);
    }, []);
  
  
    const getTotalPrice = () => {
      const cartItems = getCartItems();
      return cartItems.reduce((total, item) => total + (item.salePrice * item.quantity), 0);
    };
  
  


    useEffect(() => {
      const checkCartItems = () => {
        // Retrieve cartItems from localStorage
        const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        const cartItemsLength = cartItems.length;
  
        // Check if user is logged in
        const loggedIn = !!localStorage.getItem("userToken");
       
        // Show login portal if cartItems length is 0 and user is not logged in
        if (cartItemsLength === 0 && !loggedIn) {
          setLoginPortal(true);
        }
      };
  
      checkCartItems();
    }, []);
  
 // Safe to use map now
      const [productDETAILS, setProductDETAILS] = useState({});
      const [loading, setLoading] = useState(true);
  
      // Retrieve the quantity from localStorage specific to the product
      // const [quantity, setQuantity] = useState(() => {
      //     const savedQuantity = localStorage.getItem(`quantity_${productId}`);
      //     return savedQuantity ? parseInt(savedQuantity, 10) : 1;
      // });
  
      const [quantity, setQuantity] = useState(() => {
        const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        const item = cartItems.find(item => item.productId === productId);
        return item ? item.quantity : 1;
      });
    
      useEffect(() => {
        // Save the quantity to localStorage whenever it changes
        const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        const itemIndex = cartItems.findIndex(item => item.productId === productId);
        if (itemIndex !== -1) {
          cartItems[itemIndex].quantity = quantity;
        } else {
          cartItems.push({ productId, quantity }); // Add new item if not found
        }
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
      }, [quantity, productId]);
      
      
    const cartItemsString = localStorage.getItem("cartItems");
    const cartItemsnow = JSON.parse(cartItemsString);  
    const quantities = cartItemsnow.map(item => item.quantity);
    // console.log("Quantities from cart:", quantities);
  
  
  
   // Function to get the total price from cart items
  const getBucketPrice = () => {
    // Use reduce to sum up totalCartPriceocvf from each item
    return cartItems.reduce((total, item) => total + (item.bucketPrice || 0), 0);
  };
  
  // Calculate total cart price
  const buckettPriceinCartItem = getBucketPrice();
  // console.log("Bucket Price in CartItemSample : ---------> ", buckettPriceinCartItem);
  
  const bucketpriceintoquantity = (buckettPriceinCartItem * quantity).toString(); // Convert to string
  
  
  const getTotalCartPrice = () => {
    // Use reduce to sum up totalCartPriceocvf from each item
    return cartItems.reduce((total, item) => total + (item.totalCartPriceocvf || 0), 0);
  };
  
  // Calculate total cart price
  const totalCartPrice = getTotalCartPrice();
  
    const onHidePortal = () => {
      setLoginPortal(false);
    };
  
      const uniqueProductIds = [
          ...new Set(cartItems.map((item) => item.productId)),
        ];
      const totalUniqueItems = uniqueProductIds.length;
      const calculateTotalPrice = () => {
        return cartItems.reduce((total, item) => {
          return total + item.salePrice;
        }, 0);
      };
      const navigate = useNavigate();
  
      const product = cartItems.find(item => item.productId === productId);
  
      const [totalPrice, setTotalPrice] = useState(0);
  // Extract the totalCartPriceocvf for the found item
  const totalCartPriceForProduct = product ? product.totalCartPriceocvf : 0;
  
  
      const totalPricdmve = calculateTotalPrice();
    
      const handleCreateOrder = async () => {
          const userToken = localStorage.getItem("userToken");
          if (!userToken) {
            // Handle case where user is not logged in
            setLoginPortal(true); // Assuming this sets state to show login portal
            return;
          }
      
          const localCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
          console.log("localCartItems :: ", localCartItems);
      
          var itemList = [];
          let bucketId = null;
          for (var item of localCartItems) { // Use 'of' to iterate over array elements
            var obj = {};
            
            obj["item_id"] = item["productId"];
            console.log("Cart Items While Loggin in handleCreateOrder Function in CartitemSampleLoggedin.jsx")
            console.log("Quantity in localCartitems :  --------------> ", item["quantity"]);
            obj["quantity"] = item["quantity"];
            obj["bucket_id"] = item["bucketId"]; // Use bucket_id
      
            console.log("Bucketid in localCartitems :  --------------> ", item["bucketId"]);
      
            if (bucketId === null) {
              bucketId = item["bucketId"]; // Set bucketId if it's null
            }
      
            itemList.push(obj);
          }
          console.log("itemList :: ", itemList);
          console.log("itemList ---------------------------------------------------------------------->:: ", itemList);
          console.log("itemList ---------------------------------------------------------------------->:: ", itemList);
          console.log("itemList ---------------------------------------------------------------------->:: ", itemList);
          console.log("itemList ---------------------------------------------------------------------->:: ", itemList);
          const ompuserId = localStorage.getItem("id");
          const customer_first_name =
            localStorage.getItem("firstName") ;
          const customer_last_name =
            localStorage.getItem("lastName");
          const customer_contact_no =
            localStorage.getItem("number") ;
          const customer_email =
            localStorage.getItem("emailId") ;
      
          const requestBody = {
            items: itemList,
            bucket_id: bucketId, 
            ompuserId, //get the id from the localStorage
            customer_first_name,
            customer_last_name,
            customer_contact_no,
            order_status: "Draft",
            order_ref_number: "",
            customer_email,
            input: {
              city: "",
              cityArea: "",
              companyName: "",
              country: "",
              countryArea: "",
              firstName: "",
              lastName: "  ",
              phone: "",
              postalCode: "",
              streetAddress1: " ",
              streetAddress2: "",
            },
            businessaddress: {
              city: "",
              cityArea: "",
              companyName: " ",
              country: "",
              countryArea: "",
              firstName: "",
              lastName: "",
              phone: "",
              postalCode: "",
              streetAddress1: "",
              streetAddress2: "",
            },
            gst_no: "",
          };
      
          try {
            console.log(
              "requestBody  ------------------------------------------------------------- > :: ",
              requestBody
            );
            // Create order request
            const createOrderResponse = await fetch(
              "https://devextension.origa.market/sparesapi/create_order",
              {
                method: "POST",
                headers: {
                  
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
              }
            );
      
            if (!createOrderResponse.ok) {
              throw new Error("Failed to create order");
            }
      
            const createOrderData = await createOrderResponse.json();
            const draftItems = createOrderData.line_items || []; // Assuming draft items are in line_items array
            console.log("Created order with items:", draftItems);
      
            // Clear local storage cartItems after successful order creation
            // localStorage.removeItem('cartItems');
            setCartItems([]); // Clear cart items state
          } catch (error) {
            console.error("Error creating order:", error);
          }
        };
      
      const handleProceedPaymentbtn = () => {
          const loggedin = !!localStorage.getItem("userToken");
          if (loggedin) {
            handleCreateOrder();
            navigate("/manageaddresspart");
          } else {
            setLoginPortal(true);
          }
        };
      
        const [loginPortal, setLoginPortal] = useState(false);
    
      
      useEffect(() => {
          // Save the quantity to localStorage specific to the product whenever it changes
          localStorage.setItem(`quantity_${productId}`, quantity);
      }, [quantity, productId]);
  
      const AvailableStock = productDETAILS[0]?.available_stock || "N/A;";
      // let cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
  
      const handleIncreaseQuantity = () => {
        setLoading(true); 
          setQuantity((prevQuantity) => prevQuantity + 1); // for increasing
          window.location.reload();
      };
  
      const handleDecreaseQuantity = () => {
          setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1)); // for decreasing
          window.location.reload();
      };
  
      const handleDelete = (productId) => {
          let cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
          cartItems = cartItems.filter(cartItem => cartItem.productId !== productId);
          localStorage.setItem('cartItems', JSON.stringify(cartItems));
          console.log(`Item with productId ${productId} deleted. Total items in cart: ${cartItems.length}`);
          window.location.reload();
      };
  
      const updateFormattedPrice = () => { // is used to update how the price of a product is displayed on a webpage
          setProductDETAILS((prevDetails) => ({ // it takes the current details of the product(prevdetails)and creates a new set of details with an updated formatted price
              ...prevDetails,
              price_details: {
                  ...prevDetails.price_details,
                  // amc_cost: formatCurrency(prevDetails.price_details.amc_cost),
                  // delivery_charges: formatCurrency(prevDetails.price_details.delivery_charges),
                  machine_price: formatCurrency(prevDetails.sales_rate),
              },
          }));
      };
  
      const formatCurrency = (value) => { // like to show in Indian rupees it adds commas where needed and shows the right number of decimal place
          const fractionDigits = value % 1 !== 0 ? 2 : 0;
          return new Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
              minimumFractionDigits: fractionDigits,
              maximumFractionDigits: fractionDigits,
          }).format(value || 0);
      };
  
      useEffect(() => {
          window.scrollTo(0, 0); // Scrolls the window to the top on component mount or update
          const fetchData = async () => {
              let id = localStorage.getItem('id');
              if (id === null) {
                  id = "";
              }
              try {
                  // Performs a GraphQL query using the Apollo client 'client'
                  const { data } = await client.query({ query: GET_SPARE_DETAILS, variables: { groupId: groupId || '', itemId: productId || '' } });
                  // Sets the fetched data to state variable 'productDETAILS'
                  setProductDETAILS(data?.inventoryItem?.response);
                  // Sets 'loading' state to false after data is fetched
                  setLoading(false);
                  // Updates formatted price
                  updateFormattedPrice();
              } catch (error) {
                  // Handle error
              }
          };
          fetchData();
      }, [groupId, productId]);
  
      console.log("Cart Items  :  -------------------------------------->", cartItems);
  
      return (
          <>
          
          <div className="threesectionsofcart">
              <div className="firstsectionofcart">
                  <img className="imageonleftofcart" src={productDETAILS[0]?.images?.productImages[0]?.url || "N/A"} alt="Product" />
              </div>
              <div className="secondsectionofcart">
                  <h1 className="headingpartofcarttt">
                      {productDETAILS[0]?.name || "N/A"}
                      {productDETAILS[0]?.brand || "N/A" && (
                          <span className="rbfbef heading-400-20-14 op-60">
                              &nbsp;| {productDETAILS[0]?.brand || "N/A"}
                          </span>
                      )}
                  </h1>
                  <div className="contentofcartpara">
                      <p className="inStocktext">
                          <span className="inStocktextcontent">In Stock</span>
                          <br></br>
                          <span className="stockbold"> Bucket : </span>20Ltrs
  
                      </p>
                  </div>
                  <div className="buttonsgapbetweentwocart">
                      <span className="quantitytextright">Quantity{" "}</span>
                      <div className="quantity-selector-wrapper">
                          <div className="quantity-selector">
                              <button onClick={handleDecreaseQuantity} className="quantity-button">-</button>
                              <span className="quantity-value">{quantity}</span>
                              <button onClick={handleIncreaseQuantity} className="quantity-button">+</button>
                          </div>
                      </div>
                      <button className="deletecartbtn" onClick={() => handleDelete(productId)}>Delete</button>
                  </div> 
              </div>
              <div className="thirdsectionofcart">
                  <p className="badgerightside">
                      <span className="salespricecart">₹{totalCartPriceForProduct}</span>
                  </p>
              </div>
              <div className='basespace'></div>
              
          </div>
          

          <div>
      <div className="rifhtsidebortertotaldivcontent">
        <p>
          <div class="meter-container">
            <div class="meter">
              <div class="meter-fill" style={{ width: "80%" }}></div>
            </div>
          </div>
          <IoCheckmarkDoneSharp className="ioiconscc" size={28} /> &nbsp;
          <span className="orderdetailscart">
            Your order is ready for processing.{" "}
          </span>
          <br></br>
        </p>
        <p className="summaryContent">
          <span className="summaryItem">
            <span className="label labledddd">Summary</span>
          </span>
          <span className="summaryItem">
            <span className="label">Total quantity:</span>
            <span className="value">{totalQuantity}</span>
          </span>
    
          <span className="summaryItem">
                <span className="label">MRP Value:</span>
                <span className="value valuesdbf">₹{totalCartPrice}</span>
              </span>

          <span className="summaryItem">
            <span className="label">Our Price:</span>
            <span className="value">
              ₹{totalCartPrice}
            </span>
          </span>
        </p>
      </div>

      <div className="proceddcartbuttondiv">
        <button className="proceedcartbtn" onClick={handleProceedPaymentbtn}>
          Proceed to Payment
        </button>
      </div>
    </div>
  
  
        {loginPortal && <LoginModel onHide={onHidePortal} />}
  
           </>
      );
  };

export default CartAndSummaryNotLoggedIn