import React from "react";
import "../Mogilx.css";
import { IoLocationSharp } from "react-icons/io5";
import { FcPackage } from "react-icons/fc";
import { FcMoneyTransfer } from "react-icons/fc";
import { FaTruckMoving } from "react-icons/fa";

const ThirdSummaryDivofMogilix = () => {
  return (
    <div className="thirdinsidedivofmogilix">
      <div className="pricin2ndcontentdiv mt-3">
        <p className="pricelightofsecond">
          ₹1,549 (Incl. of all taxes)
          <br></br>
          <span className="priceboldofsecond">₹1,143</span>
          <span className="pricelightofsecond">+ ₹206 GST</span>
          <br></br>
          <span className="pricelightofsecond">
            MRP <span className="textcannceeled">₹3,281</span> &nbsp;
            <span className="offercolor">58% OFF</span>{" "}
          </span>
        </p>
      </div>

      <div className="summarydivofnewtool">
        <div className="updatewttyy">Qty</div>

        <div className="buttonsinquantity ">
          <button className="plusminusbutton">
            <span className="gosbfdnlj">+</span>
          </button>
          <button className="quatitybuttonsinn">1</button>
          <button className="plusminusbutton">
            <span className="gosbfdnlj">-</span>
          </button>
        </div>

        {/*  */}
      </div>

      <div className="addtocartbuttonandbuynow mt-4">
        <button
          className="addtocartinsummary"
          style={{ fontWeight: "700", fontSize: "1rem" }}
        >
          Add to Cart
        </button>

        <button
          className="buynowinsummary"
          style={{ fontWeight: "700", fontSize: "1rem" }}
        >
          Buy Now
        </button>
      </div>

      <div className="deliverydetailstooat mt-4">
        <p className="deigvbn">Delivery Details</p>

        <div className="ksfbbsf">
          <span className="left-item">
            <IoLocationSharp fontSize={20} color="red" />
            <span className="middle-item ml-1">614625</span>
          </span>

          <button
            className="right-item checkbutttn "
            style={{ fontSize: "0.85rem" }}
          >
            Check
          </button>
        </div>

        <div className="sjvdbf">
          <div className="first-item">
          {/* <FaTruck /> */}
          <FaTruckMoving  fontSize={25} color="olive" />
          <span className="KJGWBKF"> 
          Shipping applicable - ₹19</span>
          </div>
          <div className="second-item">
          <FcPackage fontSize={40} />
          <span  className="KJGWBKF" >Delivery available at 614625 in 8 day(s)</span>
          </div>
          <div className="third-item">
          <FcMoneyTransfer  fontSize={25} />
          <span className="KJGWBKF">COD Applicable 
      
          </span>

          </div>
        </div>
      </div>

      <div className="mb-4">
           
           </div>
    </div>
  );
};

export default ThirdSummaryDivofMogilix;
