import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const OrderSummary = () => {
  const location = useLocation();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrderDetails = async (customerId, referenceNumber) => {
      try {
        const response = await fetch(`https://devextension.origa.market/sparesapi/get_orders`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            customer_id: "VXNlcjoxNjc=",
            reference_number: "REF-9715-20240717"
          })
        });

        if (!response.ok) {
          throw new Error('Failed to fetch order details');
        }

        const data = await response.json();
        if (data.order) {
          setOrderDetails(data.order);
        } else {
          setOrderDetails(null);
        }
      } catch (error) {
        console.error('Error fetching order details:', error);
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    const searchParams = new URLSearchParams(location.search);
    const orderId = searchParams.get('id');
    const message = searchParams.get('message');

    const customer_id = localStorage.getItem("id"); // Replace with actual logic for customer ID retrieval

    if (orderId && message === 'Paid') {
      fetchOrderDetails(customer_id, orderId);
    }
  }, [location.search]);

  return (
    <div className="order-summary">
      <h2>Order Summary</h2>
      {loading ? (
        <p>Loading...</p>
      ) : orderDetails ? (
        <div className="order-details">
          <p>Customer Name: {orderDetails.customer_name}</p>
          <p>Reference ID: {orderDetails.reference_id}</p>
          <p>Order Status: {orderDetails.order_status}</p>
          <h3>Products Ordered:</h3>
          <ul>
            {orderDetails.line_items.map((item, index) => (
              <li key={index}>
                <div className="img-wrap-1">
                  <img
                    className="img-1"
                    src={item.thumbnail[0]?.url || "N/A"}
                    alt="Product"
                  />
                </div>
                <div className="item-details">
                  <p>Item Name: {item.name}</p>
                  <p>Quantity: {item.quantity}</p>
                  <p>Price: ₹{item.rate}</p>
                </div>
              </li>
            ))}
          </ul>
          <p>Total Quantity: {orderDetails.total_quantity}</p>
          <p>Subtotal: ₹{orderDetails.subtotal}</p>
          <p>Shipping Charge: ₹{orderDetails.shipping_charge}</p>
          <p>Total: ₹{orderDetails.total}</p>
          <p>Payment Status: {orderDetails.payment_status.length > 0 ? orderDetails.payment_status[0].status : "Not available"}</p>
          <p>Billing Address: {orderDetails.billing_address.address}, {orderDetails.billing_address.city}, {orderDetails.billing_address.state}, {orderDetails.billing_address.zip}, {orderDetails.billing_address.country}</p>
          <p>Shipping Address: {orderDetails.shipping_address.address}, {orderDetails.shipping_address.city}, {orderDetails.shipping_address.state}, {orderDetails.shipping_address.zip}, {orderDetails.shipping_address.country}</p>
        </div>
      ) : (
        <p>No order details found.</p>
      )}
    </div>
  );
};

export default OrderSummary;
