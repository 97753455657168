import React, { useEffect, useState } from "react";
import "./ProductBlock.css";
import { Container } from "react-bootstrap";
import Button from "../../Button/Button";
import Correct from "../../SubComponent/AllSvgs/Correct";
import CorrectGreen from "../../SubComponent/AllSvgs/CorrectGreen";
import Wrong from "../../SubComponent/AllSvgs/Wrong";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LoginModel from "../../Authentication/LoginModel/LoginModel";
import axios from "axios";
import Loader from "../../SubComponent/Loader";
import { closeIcon } from "../../../helpers/Icons";

import DelayedForm from "../../Authentication/CustomerModel/InfoPage";
import { gql } from "graphql-tag";
import client from "../../Services/ServicesPopup/apolloclient";
import MALeftsection from "../../Account/ManageAddress/MALeftsection";

const CREATE_CONTACT_US = gql`
  mutation CreateConatctUs($contactusinput: ContactUsInput!) {
    createContactUs(contactusinput: $contactusinput) {
      contactus {
        id
        firstname
        lastname
        emailid
        reasonId {
          id
          reasonTitle
          reasonDescription
        }
        queryDescription
      }
      message
      success
    }
  }
`;

const ProductBlockCard = (props) => {
  const [loadinged, setLoadinged] = useState(false);
  const navigate = useNavigate();
  const [loginPortal, setLoginPortal] = useState(false);
  const [hoveredButton, setHoveredButton] = useState(null);


  // console.log("SelectedLocation :  ",selectedLocation);

  const handleMouseEnter = (button) => {
    setHoveredButton(button);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const [productDetails, setProductDetails] = useState(null);
  const [formOrder, setFormOrder] = useState("default");
  const handleInspectionReport = (product) => {
    setProductDetails(product);
    const {
      id,
      price,
      gst_amount,
      total_amount,
      type,
      name,
      plan,
      selectedCategory,
      selectedLocation,
    } = product;
    localStorage.setItem("formOrder", "jumbled");

    localStorage.setItem("amcid", id);
    // console.log("Name in localStorage",localStorage.getItem("amcid"));

    localStorage.setItem("plan", plan);
    console.log("Plan in Local Storage : ",localStorage.getItem("plan"));

    console.log(
      "Selected location --------------------->  : ",
      props.selectedLocation
    );

    console.log(
      "Selected category -------------------> : ",
      props.selectedCategory
    );

    const selectedcategoryin =  props.selectedCategory;
    const selectedLocationin =  props.selectedLocation;

    //  console.log("appAmcPlanId in Inspection Report  :  ",id);
    //  console.log("appAmcPlanName in Inspection Report  :  ",name);

    //  console.log("appAmcPlanName in Inspection Report  :  ",plan);
    // console.log("Form Order in ProductBlockCard :",formOrder);
    if (!loggedIn) {
      setLoginPortal(true);
    } else {
      setLoadinged(true);
      setTimeout(() => {
        navigate("/ServiceRequest", {
          state: {
            selectedCategory,
            selectedLocationin,
            selectedcategoryin,
            selectedLocation,
            name,
            id,
            plan,
            price,
            gst_amount,
            total_amount,
          },
        });
      }, 1000);
    }
  };

  const handleRequestCallback = (product) => {
    setProductDetails(product);
    const {
      id,
      price,
      gst_amount,
      total_amount,
      type,
      name,
      plan,
      selectedCategory,
      selectedLocation,
    } = product;
    localStorage.setItem("formOrder", "jumbled");

    localStorage.setItem("amcid", id);
    // console.log("Name in localStorage",localStorage.getItem("amcid"));

    localStorage.setItem("plan", plan);
    // console.log("Plan in Local Storage : ",localStorage.getItem("plan"));

    console.log(
      "Selected location --------------------->  : ",
      props.selectedLocation
    );

    console.log(
      "Selected category -------------------> : ",
      props.selectedCategory
    );

    const selectedcategoryin =  props.selectedCategory;
    const selectedLocationin =  props.selectedLocation;

    // console.log("appAmcPlanId in Inspection Report  :  ",id);
    // console.log("appAmcPlanName in Inspection Report  :  ",name);
    // console.log("appAmcPlanName in Inspection Report  :  ",plan);

    // console.log("Form Order in ProductBlockCard :",formOrder);

    if (!loggedIn) {
      setLoginPortal(true);
    } else {
      setLoadinged(true);
      setTimeout(() => {
        navigate("/ServiceRequest", {
          state: {
            selectedCategory,
            selectedcategoryin,
            selectedLocationin,
            selectedLocation,
            name,
            id,
            plan,
            price,
            gst_amount,
            total_amount,
          },
        });
      }, 1000);
    }
  };
  const [ShowCallbackpopup, setShowCallbackpopup] = useState(false);
  const [ShowInpectionpopup, setShowInpectionpopup] = useState(false);
  const closePopup1 = () => {
    setShowInpectionpopup(false);
  };

  const closePopup2 = () => {
    setShowCallbackpopup(false);
  };
  const [products, setProducts] = useState([]);
  const location = useLocation();
  const AMC_BASED_ON_TYPE = location.state?.selectedCategory?.toUpperCase();

  const selectedLocation = location.state?.selectedCategoryType;
  const { selectedCategory, modellNo, firstname, serialNo } =
    location.state || {};
  const {
    title,
    imageUrl,
    onProductBlockNavigate,
    product,
    index,
    totalProducts,
  } = props;

  console.log("Title from props : ",props.title);

  const productData = location.state && location.state.product;
  const [isHovered, setIsHovered] = useState(false);

  // const { title, price, imageUrl, id, onProductBlockNavigate, product } = props;
  const loggedIn = !!localStorage.getItem("userToken");
  const onMouseEnterhandler = () => {
    setIsHovered(true);
  };

  let description;
  try {
    const parsedDescription = JSON.parse(product.description);
    // Extract the first element from the array if it exists
    description = parsedDescription.length > 0 ? parsedDescription[0] : "";
  } catch (error) {
    console.error("Error parsing JSON:", error);
    description = ""; // Default value in case of error
  }

  // console.log("Description  ----------------------------->  ", description);

  // Function to split the description into chunks of 5 words
  const splitDescription = (desc) => {
    const words = desc.split(" ");
    let result = [];
    let currentLine = [];
    let currentLength = 0;
    const maxLength = 25; // Adjust this value based on your box width

    words.forEach((word) => {
      if (currentLength + word.length > maxLength) {
        result.push(currentLine.join(" "));
        currentLine = [word];
        currentLength = word.length;
      } else {
        currentLine.push(word);
        currentLength += word.length + 1; // +1 for the space
      }
    });

    if (currentLine.length > 0) {
      result.push(currentLine.join(" "));
    }

    return result;
  };

  const descriptionLines = splitDescription(description);
  const onMouseLeavehandler = () => {
    setIsHovered(false);
  };

  const onHidePortal = () => {
    setLoginPortal(false);
  };
  // const onClickhandle = () => {
  //     window.scrollTo(0, 0);
  //     // navigate('/product');
  //     // navigate(`/productidsecond/${id}`);

  //     console.log(id,"Show Product")
  //     onProductBlockNavigate(product)
  // }
  const onClickhandle = (product) => {
    // Destructure the product to get the required details
    const { id, price, gst_amount, total_amount, type, name, plan } = product;

    // Log the required details
    console.log("When Get Started button is clicked ------------------- >");

    console.log("GST amount   :", gst_amount);
    console.log("Product Name:", name);
    console.log("Product Type:", type);
    console.log("Product Type:", price);
    console.log("Product Total Amount:", total_amount);

    // Handle the navigation if logged in
    if (!loggedIn) {
      setLoginPortal(true);
      return;
    } else {
      setLoadinged(true);
      setTimeout(() => {
        navigate("/cartpageofamc", {
          state: {
            product,
            selectedCategory,
            firstname,
            selectedLocation,
            serialNo,
            modellNo,
          },
        });
      }, 300);

      // navigate('/cartpageofamcrough', { state: { product } });
    }
  };

  const getProducts = () => {
    const planType = "PM";
    axios
      .post("http://3.111.236.154:9003/fetchAMCbasedontype", {
        type: selectedCategory,
        plan_type: planType,
      })
      .then((response) => {
        setProducts(response.data.jsondata);
        // navigate('/annual', { state: { products } });
      })
      .catch((error) => {
        // console.error('Error fetching data:', error);
      });
  };
  // console.log('Annual getProductss ==>>',products)

  useEffect(() => {
    getProducts();
  }, [AMC_BASED_ON_TYPE]);
  const learnMoreFunction = () => {
    // Check if the user is logged in

    // Scroll to the top of the page
    window.scrollTo(0, 0);

    // Show the loader
    setLoadinged(true);

    // Add a small delay to ensure the loader is visible before navigating
    setTimeout(() => {
      // Perform the navigation
      onProductBlockNavigate(product);

      // Optionally hide the loader after navigation if needed
      // setLoadinged(false);
    }, 300); // Adjust the delay as needed
  };

  // console.log("See what is inside product ", products);
  // products.forEach((product) => {
  //   console.log("Product ID:", product.id);
  //   console.log("Product Name:", product.name);
  //   console.log("Product Price:", product.price);
  // });

  // console.log("productData---->", productData?.productData);

  const isLimitedOffer = index === 0;
  const isMostPopular =
    (totalProducts === 3 && index === 1) || (totalProducts > 3 && index === 2);
  // console.log(`Product ${index + 1}: isLimitedOffer = ${isLimitedOffer}, isMostPopular = ${isMostPopular}, totalProducts = ${totalProducts}`);

  return (
    <>
      {loadinged && <Loader />}
      <Container
        className={`productblockmaindiv  w-390  mt-4${
          isHovered ? "hovered" : ""
        }`}
        onMouseEnter={onMouseEnterhandler}
        onMouseLeave={onMouseLeavehandler}
      >
        <div className="position-relative">
          {/* {isLimitedOffer && (
            <div className="offer-tag-wrapper limited-offer-wrapper">
              <div className="offer-tag limited-offer-tag">Limited Offer</div>
            </div>
          )}
          {isMostPopular && (
            <div className="offer-tag-wrapper most-popular-wrapper">
              <div className="offer-tag most-popular-tag">Most Popular</div>
            </div>
          )} */}
          <div className="d-flex pb-2">
            <div className="totalbadgediv">
              <div className="firstbagediv">
                <span className="firstbagedicproductplan">{product.plan}</span>
              </div>
              {/* <div className="thirdbagediv">
                <span className="productpriceofamc">₹{product.price}</span>
              </div> */}
            </div>
            <div className="imgdiv">
              <img src={imageUrl} alt={title} />
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col col-12 pr-4">
            <p className="break-line"></p>
          </div>
          {/* <div className="buttongetstarted col col-10">
          <Button
            message={`Buy ${product.plan}`}
            callFunction={() => onClickhandle(product)}
          />
          <p className="break-line"></p>
        </div> */}

          <div className="reportwobuttons d-flex col col-10">
            <button
              className={`reportbutton ${
                hoveredButton === "button2" ? "hover-effect" : ""
              }`}
              onMouseEnter={() => handleMouseEnter("button1")}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleInspectionReport(product)}
            >
              <span>Request for</span>
              <span>Inspection</span>
            </button>

            <button
              className={`reportbutton2 ${
                hoveredButton === "button1" ? "hover-effect" : ""
              }`}
              onMouseEnter={() => handleMouseEnter("button2")}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleRequestCallback(product)} 
            >
              <span>Request for</span>
              <span>Callback</span>
            </button>
          </div>

          <p className="break-line"></p>
        </div>

        <div className="col-12 d-flex  align-items-start">
          <div className="mr-2">
            <CorrectGreen />
          </div>
          <div className="flex-grow-1 description-container pl-1">
            {descriptionLines.map((line, index) => (
              <p key={index} className="mb-1 description-text">
                {line}
              </p>
            ))}
          </div>
        </div>
        {/* <div className="col-12 d-flex align-items-center mt-2">S
            <div className="mr-2">
              <CorrectGreen />
            </div>
            <p className="mb-0 maintenance-text pl-1">
              1 Preventive Maintenance
            </p>Addonservice
          </div> */}

        <div>
          <a className="learnmoreabout " onClick={learnMoreFunction}>
            Know More about Preventive Maintenance
          </a>
        </div>

        {loginPortal && <LoginModel onHide={onHidePortal} />}
      </Container>
      {/* {ShowInpectionpopup && (
        <div className="popup-overlayy">
          <div className="popup-banner">
            <div className="popup-content22">
            <div className="insideinputformfields">
            <div className='container pt-5 pb-5'>
              <div className='row'>
                <div className='col heading-600-24 p-0'>Edit Address : </div>
              </div>
              <div className='row pb-5 d-flex justify-content-between border-bottom'>
                <div className='col col-lg-4 col-12 pl-0 pt-30px '>
                  <MALeftsection heading="" para="kavdbbdvkjsdbkjs" />
                </div>
                <div className='col col-lg-7 col-12 p-0'>
                  <div className='row '>
                    <div className='col col-md-6 col-12'>
                      <div className={`bi-form-group ${validation.fvalidation ? "error-red" : ""}`}>
                        <input type="text" name="fname" id="fname" className={`bi-form-field bg-white ${validation.fvalidation ? "error-red" : ""}`} placeholder="First Name" value={firstname}
                        />
                        <label htmlFor="fname" className="heading-400-14-12 bi-form-label">First Name{<span style={{ color: '#CB1923' }}>*</span>}</label>
                      </div>
                    </div>
                    <div className='col col-md-6 col-12 '>
                      <div className={`bi-form-group ${validation.fvalidation ? "error-red" : ""}`}>
                        <input type="text" name="lname" id="lname" className={`bi-form-field bg-white ${validation.fvalidation ? "error-red" : ""}`} placeholder="Last Name" value={firstname}
                           />
                        <label htmlFor="lname" className="heading-400-14-12 bi-form-label">Last Name{<span style={{ color: '#CB1923' }}>*</span>}</label>

                      </div>
                    </div>
                  </div>


                  <div className='row '>
                    <div className='col col-md-6 col-12'>

                      <div className={`bi-form-group ${validation.pvalidation ? "error-red" : ""}`}>
                        <input type="text" name="phone" id="phone" className={`bi-form-field bg-white ${validation.pvalidation ? "error-red" : ""}`} placeholder="Phone Number"
                         />
                        <label htmlFor="phone" className="heading-400-14-12 bi-form-label">Phone Number{<span style={{ color: '#CB1923' }}>*</span>}</label>
                      </div>
                    </div>
                    <div className='col col-md-6 col-12'>

                      <div className={`bi-form-group ${validation.alterphovalidation ? "error-red" : ""}`}>
                        <input type="text" name="altphone" id="altphone" className={`bi-form-field  bg-white ${validation.alterphovalidation ? "error-red" : ""}`} placeholder="Alt Phone Number"
                        />
                        <label htmlFor="altphone" className="heading-400-14-12 bi-form-label">Alternative Phone Number</label>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              </div>


</div>
              <div className="close-popup" onClick={closePopup1}>
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}

      {ShowCallbackpopup && (
        <div className="popup-overlayy">
          <div className="popup-banner">
            <div className="popup-content">
                         <>
                <div className="CallbackReport">
                  <span className="">CallBack Report </span>

                  <div className="mt-3">
                    <span>Enter Your Details : </span>
                  </div>
                  <div className="insideformofcallback">
                    <div className="insideinputformfields">

                      <div className="col-lg-6 col-md-6 col-12">
                        <div
                          className={`bi-form-group ${
                            validation.fvalidation ? "error-red" : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className={`bi-form-field bg-white ${
                              validation.fvalidation ? "error-red" : ""
                            }`}
                            placeholder="Name"
                            value={Customername}
                            onChange={onFirstnameChange}
                            onClick={() =>
                              setvalidaion((prev) => ({
                                ...prev,
                                fvalidation: false,
                              }))
                            }
                          />
                          <label
                            htmlFor="name"
                            className="heading-400-14-12 bi-form-label-white"
                          >
                            Name
                            <span style={{ color: "#CB1923" }}>*</span>
                          </label>
                        </div>
                      </div>

<div className="col-lg-6 col-md-6 col-12">
<div
                        className={`bi-form-group ${
                          validation.pvalidation ? "error-red" : ""
                        }`}
                      >
                        <input
                          type="phonenumber"
                          name="phonenumber"
                          id="phonenumber"
                          className={`bi-form-field bg-white ${
                            validation.pvalidation ? "error-red" : ""
                          }`}
                          placeholder="PhoneNumber"
                          value={phonenumber}
                          onChange={onPhonenumber}
                          onClick={() =>
                            setvalidaion((prev) => ({
                              ...prev,
                              pvalidation: false,
                            }))
                          }
                          maxLength={10}
                        />
                        <label
                          htmlFor="phone"
                          className="heading-400-14-12 bi-form-label-white"
                        >
                          Phone Number{" "}
                          <span style={{ color: "#CB1923" }}>*</span>
                        </label>
                      </div>
</div>
                  
                    </div>

           <div className="insideinputformfields">
             <p>Phone Number  :</p>
             <input type="text" name="number" id="number" />
           </div> 
                  </div>
                  <div className="Submitbuttonthereport">
                    <button
                      className="Submitreportbuttonv"
                      onClick={() => SubmitRequestCallback(product)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </>
              <div className="close-popup" onClick={closePopup2}>
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}

      {showThanksPopup && (
        <div className="popup-overlay ">
          <div className="popup-banner ">
            <div
              className="popup-content "
              style={{ backgroundColor: "#d2eff1" }}
            >
              <div
                className="differentthankspopupcontnet"
                style={{ backgroundColor: "white" }}
              >
                <h3 className="thanksforyourresponse">
                  Thank you for your quotation request!
                </h3>
                <p className="wewillcontactu">We'll get back to you soon</p>
                <br></br>
                <div
                  className="close-popup"
                  onClick={() => setShowThanksPopup(false)}
                >
                  {closeIcon({ width: 14, height: 14 })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default ProductBlockCard;
