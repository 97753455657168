import { connectHits } from "react-instantsearch-dom";
import { heartIcon, botIcon } from "../../../../helpers/Icons";
import { useNavigate } from "react-router-dom";
// import CustomPagination from "../CustomPagination";
import React, { useState, useEffect } from "react";
import useWishListAddOrUpdate from "../../../SubComponent/useWishListAddOrUpdate";
import useWishListRemover from "../../../SubComponent/useWishListRemover";
import CustomPagination from "../CustomPagination";
import LoginModel from "../../../Authentication/LoginModel/LoginModel";
import { gql } from "@apollo/client";
import client from "../../../Services/ServicesPopup/apolloclient";
import CustomerInfo from "../CustomerInformation/CustomerInfoModel";

//----------------------------------------Showing Information Of the Product Based On Result Hits-------------------------------------------------------

const GET_WISHLIST_DATA = gql`
  query userWishlists($userid: String!) {
    userWishlists(userId: $userid) {
      message
      code
      response
    }
  }
`;

const HitsForMachines = ({
  setLoading,
  loading,
  hits,
  Sorting,
  isGridView,
}) => {
  const navigate = useNavigate();
  const [filteredHitsToRender, setFilteredHitsToRender] = useState(hits);
  const [loginPortal, setLoginPortal] = useState(false);
  const [WishListData, setWishListData] = useState([]);
  const { onWishlistHandler, heartColor } = useWishListAddOrUpdate();
  const { removewishlist, heartColor1 } = useWishListRemover();
  const [CustomerInfoForm, setCustomerInfoForm] = useState(false);
  const userId = localStorage.getItem("id");
  //console.log('WishListData---->',WishListData);
  useEffect(() => {
    const fetchWishListData = async () => {
      try {
        const { data } = await client.mutate({
          mutation: GET_WISHLIST_DATA,
          variables: {
            userid: userId,
          },
        });
        if (data) {
          const wishlistItems = data.userWishlists.response[0]?.items;
          //console.log('wishlistdata---->', wishlistItems);
          if (wishlistItems) {
            const productIds = wishlistItems.map((item) => item.product_id);
            setWishListData(productIds);
          }
        }
      } catch (error) {
        console.error("Error fetching wishlist data:", error);
      }
    };

    fetchWishListData();
  }, [userId]);

  const findYearDifference = (targetYear) => {
    const currentYear = new Date().getFullYear();
    const yearDifference = currentYear - targetYear;
    return yearDifference;
  };
  const priceConvert = (price) => {
    price = typeof price === "string" ? price : String(price);

    let count = 1;
    let comma = 3;
    let formatedPrice = "";
    for (let i = price.length - 1; i >= 0; i--) {
      formatedPrice = price[i] + formatedPrice;
      if (count === comma) {
        formatedPrice = "," + formatedPrice;
        comma = 2;
        count = 0;
      }
      count++;
    }
    //console.log("==>>", formatedPrice)
    if (formatedPrice[0] === ",") {
      formatedPrice = formatedPrice.slice(1, formatedPrice.length);
    }
    return formatedPrice;
  };
  //console.log('filteredHitsToRender---->', filteredHitsToRender);
  useEffect(() => {
    if (hits.length > 0) {
      let sortedHits = [...hits];

      if (Sorting !== null) {
        sortedHits = [...hits].sort((a, b) => {
          if (Sorting === "Sort by Price (Lowest to Highest)") {
            setLoading(false);
            return a["grossPrice"] - b["grossPrice"];
          } else if (Sorting === "Sort by Price (Highest to Lowest)") {
            setLoading(false);
            return b["grossPrice"] - a["grossPrice"];
          } else if (Sorting === "Sort Alphabetically (A - Z)") {
            setLoading(false);
            return a["productName"].localeCompare(b["productName"]);
          }
          setLoading(false);
          return 0; // Default case
        });
      }
      setFilteredHitsToRender(sortedHits);
      setLoading(false);
      if (filteredHitsToRender.length === 0) {
        setCustomerInfoForm(true);
      }
    } else {
      setLoading(false);
      // if (filteredHitsToRender.length === 0) {
      //     setCustomerInfoForm(true)
      // }
    }
  }, [Sorting, hits, loading]);

  // const handleFavorite = (index, value) => {
  //     console.log("Coming inside handle Favorite function  : ");
  //     const updatedHits = [...filteredHitsToRender];
  //     updatedHits[index]["favorite"] = value;

  //     setFilteredHitsToRender(updatedHits);
  // };

  const handleFavorite = (index, value) => {
    console.log("Entering handleFavorite function");
    console.log("Index:", index);
    console.log("Value:", value);

    console.log("Initial filteredHitsToRender:", filteredHitsToRender);

    const updatedHits = [...filteredHitsToRender];
    console.log(
      "UpdatedHits after spreading filteredHitsToRender:",
      updatedHits
    );

    updatedHits[index]["favorite"] = value;
    console.log(
      `UpdatedHits after setting favorite at index ${index}:`,
      updatedHits
    );

    setFilteredHitsToRender(updatedHits);
    console.log("State updated with new filteredHitsToRender:", updatedHits);
  };

  // const onSubmitHandler = (props, wishlisted, index) => {
  //     if (wishlisted === true || props?.favorite === false) {
  //         const heartButtons = document.querySelectorAll('.heart');
  //         if (heartButtons && heartButtons.length > index) {
  //             const button = heartButtons[index];
  //             if (button && button.classList.contains('favorite')) {
  //                 button.classList.remove('favorite');
  //             }
  //         }
  //         removewishlist(props?.productId, props?.thumbnail, props?.grossPrice, props?.productName, props?.attributes?.Brands, props?.categories?.lvl1?.split('>')[1].trim(), props?.categories.lvl2?.split('>')[2].trim())
  //     }
  //     else {
  //         const loggedin = !!localStorage.getItem('userToken');
  //         if (loggedin) {
  //             onWishlistHandler(props?.productId, props?.thumbnail, props?.grossPrice, props?.productName, props?.attributes?.Brands, props?.categories?.lvl1?.split('>')[1].trim(), props?.categories.lvl2?.split('>')[2].trim())
  //         }
  //         else {
  //             localStorage.setItem('wishlistPayload', JSON.stringify({
  //                 productId: props?.productId,
  //                 thumbnail: props?.thumbnail,
  //                 productName: props?.productName,
  //                 pricing: props?.grossPrice,
  //                 Brands: props?.Brands,
  //                 category: props?.categories?.lvl1?.split('>')[1].trim(),
  //                 subcategory: props?.categories.lvl2?.split('>')[2].trim()
  //             }));

  //             setLoginPortal(true);
  //         }

  //     }

  // }

  const onSubmitHandler = (props, wishlisted, index) => {
    console.log("Entering onSubmitHandler function");
    console.log("Props:", props);
    console.log("Wishlisted:", wishlisted);
    console.log("Index:", index);

    if (wishlisted === true || props?.favorite === false) {
      console.log("Condition met for removing from wishlist");

      // Select all heart buttons
      const heartButtons = document.querySelectorAll(".heart");
      console.log("Heart Buttons:", heartButtons);

      // Check if the heart button exists at the given index
      if (heartButtons && heartButtons.length > index) {
        const button = heartButtons[index];
        console.log("Button at index", index, ":", button);

        // Check if the button has the 'favorite' class
        if (button && button.classList.contains("favorite")) {
          console.log("Button has 'favorite' class. Removing it.");
          button.classList.remove("favorite");
        } else {
          console.log("Button does not have 'favorite' class.");
        }
      } else {
        console.log("No button found at index", index);
      }

      // Call removewishlist function
      console.log(
        "Calling removewishlist with parameters:",
        props?.productId,
        props?.thumbnail,
        props?.grossPrice,
        props?.productName,
        props?.attributes?.Brands,
        props?.categories?.lvl1?.split(">")[1].trim(),
        props?.categories.lvl2?.split(">")[2].trim()
      );
      removewishlist(
        props?.productId,
        props?.thumbnail,
        props?.grossPrice,
        props?.productName,
        props?.attributes?.Brands,
        props?.categories?.lvl1?.split(">")[1].trim(),
        props?.categories.lvl2?.split(">")[2].trim()
      );
    } else {
      console.log("Condition met for adding to wishlist");

      // Check if user is logged in
      const loggedin = !!localStorage.getItem("userToken");
      console.log("User logged in:", loggedin);

      if (loggedin) {
        console.log(
          "User is logged in. Calling onWishlistHandler with parameters:",
          props?.productId,
          props?.thumbnail,
          props?.grossPrice,
          props?.productName,
          props?.attributes?.Brands,
          props?.categories?.lvl1?.split(">")[1].trim(),
          props?.categories.lvl2?.split(">")[2].trim()
        );
        onWishlistHandler(
          props?.productId,
          props?.thumbnail,
          props?.grossPrice,
          props?.productName,
          props?.attributes?.Brands,
          props?.categories?.lvl1?.split(">")[1].trim(),
          props?.categories.lvl2?.split(">")[2].trim()
        );
      } else {
        console.log(
          "User is not logged in. Storing wishlist payload and setting login portal."
        );

        // Store wishlist payload in localStorage
        const wishlistPayload = {
          productId: props?.productId,
          thumbnail: props?.thumbnail,
          productName: props?.productName,
          pricing: props?.grossPrice,
          Brands: props?.Brands,
          category: props?.categories?.lvl1?.split(">")[1].trim(),
          subcategory: props?.categories.lvl2?.split(">")[2].trim(),
        };
        console.log("Wishlist Payload:", wishlistPayload);

        localStorage.setItem(
          "wishlistPayload",
          JSON.stringify(wishlistPayload)
        );
        console.log("Wishlist payload saved to localStorage.");

        // Set login portal state
        setLoginPortal(true);
        console.log("Login portal state set to true.");
      }
    }
  };

  const handleAvailService = (product) => {
    const UserId = localStorage.getItem("id");
    //console.log('UserId---->', UserId);
    if (UserId === null) {
      const existingProducts =
        JSON.parse(localStorage.getItem("SelectedProducts")) || [];
      const isProductInList = existingProducts.some(
        (existingProduct) => existingProduct.productId === product.productId
      );

      if (!isProductInList) {
        existingProducts.push(product);
        localStorage.setItem(
          "SelectedProducts",
          JSON.stringify(existingProducts)
        );
      }
    } else if (UserId !== null) {
      const existingProducts =
        JSON.parse(localStorage.getItem("SelectedProductsWithUser")) || [];
      const isProductInList = existingProducts.some(
        (existingProduct) => existingProduct.productId === product.productId
      );

      const productWithUserId = { ...product, userId: UserId };

      if (!isProductInList) {
        existingProducts.push(productWithUserId);
        localStorage.setItem(
          "SelectedProductsWithUser",
          JSON.stringify(existingProducts)
        );
      }
    }

    localStorage.setItem("objectID", product.objectID);
    // Navigate to the service page
    navigate(`/buy/cnc-machine?slug=${product.slug}&id=${product.productId}`);
  };

  const onHidePortal = () => {
    //setPricebtn(!!localStorage.getItem('id'))
    setLoginPortal(false);
    window.location.reload();
  };

  const [is576px, setIs576px] = useState(false);

  useEffect(() => {
    const checkScreenWidth = () => {
      setIs576px(window.innerWidth < 576);
    };

    checkScreenWidth();

    window.addEventListener("resize", checkScreenWidth);

    return () => window.removeEventListener("resize", checkScreenWidth);
  }, []);
  return (
    <>
      {loginPortal && <LoginModel onHide={onHidePortal} />}
      <div className="whole-container-products-section">
        <div
          className={`whole-product-sectionformachines ${
            isGridView ? "grid-view" : ""
          }`}
        >
          {filteredHitsToRender?.map((product, index) => (
            <div className="individual-product" key={index}>
              <img
                style={{ cursor: "pointer" }}
                className="individual-product-imagehere"
                src={product.thumbnail}
                alt={product.name}
              />

              <div>
                <span className="brandsandproductname-wrapper">
                  <span className="brandsandproductname">
                    {product.productName.length > 18
                      ? `${product.productName.substring(0, 18)}...`
                      : product.productName}
                  </span>
                </span>

                <div>
                  {product.attributes &&
                    (product.attributes["Brands"] ||
                      product.attributes["Machine Location"]) && (
                      <div className="location">
                        {product.attributes["Brands"]} |{" "}
                        {product.attributes["Machine Location"]}
                      </div>
                    )}

                  {is576px ? (
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="time">
                        {product.mfgYear === 1900
                          ? "N/A"
                          : `${findYearDifference(product.mfgYear)} Year Old`}
                      </div>
                      <div className="price">
                        ₹{priceConvert(product.grossPrice)}
                      </div>
                      {/* <div className="categorie"> {product.categories['lvl0']}</div> */}
                    </div>
                  ) : (
                    <div className="price-wrap d-flex align-items-center justify-content-between">
                      <div className="time">
                        {product.mfgYear === 1900
                          ? "N/A"
                          : `${findYearDifference(product.mfgYear)} Year Old`}
                      </div>
                      <div className="price">
                        ₹{priceConvert(product.grossPrice)}
                      </div>
                      {/* <div className="categorie"> {product.categories['lvl0']}</div> */}
                    </div>
                  )}

                  <button
                    className={`heartcolor heart ${
                      WishListData.includes(product.productId) ||
                      product.favorite
                        ? "favorite"
                        : ""
                    }`}
                    type="button"
                    onClick={() =>
                      onSubmitHandler(
                        product,
                        WishListData.includes(product.productId),
                        index
                      )
                    }
                  >
                    {heartIcon({
                      width: 25,
                      onClick: () => handleFavorite(index, !product.favorite),
                    })}
                  </button>
                  <button
                    className="purchase"
                    key={index}
                    onClick={() => handleAvailService(product)}
                    type="button"
                  >
                    Buy Machine
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-4">
          <CustomPagination />
        </div>
      </div>
    </>
  );
};

export default connectHits(HitsForMachines);
