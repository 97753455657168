// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LookingForBuySellRight_maindiv__atVQM{
    display: flex;
    justify-content: center;
padding-top: 15px;
}
@media (max-width:768px){
    .LookingForBuySellRight_maindiv__atVQM{
        min-height: 340px;
    }
}
@media (max-width:576px){
    .LookingForBuySellRight_maindiv__atVQM{
        min-height: 300px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/component/HomePage/SecondPage/LokkingBuySell/LookingForBuySellRight/LookingForBuySellRight.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B,iBAAiB;AACjB;AACA;IACI;QACI,iBAAiB;IACrB;AACJ;AACA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".maindiv{\n    display: flex;\n    justify-content: center;\npadding-top: 15px;\n}\n@media (max-width:768px){\n    .maindiv{\n        min-height: 340px;\n    }\n}\n@media (max-width:576px){\n    .maindiv{\n        min-height: 300px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"maindiv": `LookingForBuySellRight_maindiv__atVQM`
};
export default ___CSS_LOADER_EXPORT___;
