import React, { useState, useEffect } from "react";
import Cplus from "../../SubComponent/Cplus";
import EditIcon from "../../SubComponent/EditIcon";
import EmailIcon from "../../SubComponent/EmailIcon";
import PhoneIcon from "../../SubComponent/PhoneIcon";
import Button from "../../Button/Button";
import Breadcrumbs from "../../SubComponent/Breadcrumbs";
import RadioButton from "../../SubComponent/AllSvgs/RadioButton";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../SubComponent/Loader";
import AccountFooter from "../AccountFooter";
import "./SummaryPageSampleStyles.css";
import {
  closeIcon,
  ellipsePurpleIcon,
  ellipseWhiteIcon,
  skipIcon,
  tickIcon,
  vectorLineIcon,
} from "../../../helpers/Icons";

const ManageAddressForPopup = ({   selectedLocation,onEdit, onDeliverHere }) => {
  const [loadinged, setLoadinged] = useState(false);
  console.log("Selected Location from AddressPopup",selectedLocation );
  const [activeAddress, setActiveAddress] = useState(0);
  const [isSmallScreen] = useState(window.innerWidth <= 992);
  const navigate = useNavigate();
  const [selectedMpAddressId, setSelectedMpAddressId] = useState(null);
  const [showPopupforCart, setShowPopupforCart] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const emailIdta = localStorage.getItem("emailId");

 


 

  const [selectedAddressIndex, setSelectedAddressIndex] = useState(
    parseInt(localStorage.getItem("selectedAddressIndex"), 10) || 0
  );

  const [addressSelectionError, setAddressSelectionError] = useState(false);

  useEffect(() => {
    if (data.length > 0) {
      const defaultIndex = selectedAddressIndex || 0;
      const defaultMpAddressId = data[defaultIndex]?.mp_address_id;

      setSelectedAddressIndex(defaultIndex);
      setSelectedMpAddressId(defaultMpAddressId);

      // If mp_address_id is not already set in localStorage, set it
      if (!localStorage.getItem("selectedMpAddressId")) {
        localStorage.setItem("selectedAddressIndex", defaultIndex.toString());
        localStorage.setItem("selectedMpAddressId", defaultMpAddressId);
      }

      handleCheckboxChange(defaultIndex); // Trigger address display on first render
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://devextension.origa.market/api/fetchcustomeraddress",
          {
            id: localStorage.getItem("id"),
            mobileno: localStorage.getItem("number"),
          }
        );
        console.log("API Response:", response.data);

        const fetchedData = response?.data?.response || [];
        setData(fetchedData);

        // Automatically trigger "Add New Address" if no address is found
        if (fetchedData.length === 0) {
          onEdit({
            status: "Add Address",
          });
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [onEdit]);

  if (loading) return <Loader />;
  if (error) return <p>Error: {error.message}</p>;

  const handleCheckboxChange = (index) => {
    console.log(`Selected index: ${index}`);
  
    // Check if data exists and if the index is valid
    if (!data || !data[index]) {
      console.error(`Invalid index: ${index}, no data found at this index.`);
      return;
    }
  
    // Fetch the selected address and mp_address_id from `data`
    const selectedAddress = data[index]?.address;
    const mpAddressId = data[index]?.mp_address_id;
  
    console.log(`Selected Address: `, selectedAddress);
    console.log(`Selected mp_address_id: ${mpAddressId}`);
  
    // Update the selected address index, mpAddressId, and address in the state and localStorage
    setSelectedAddressIndex(index);
    setSelectedMpAddressId(mpAddressId);
    
    // Store the selected address along with index and mp_address_id
    localStorage.setItem("selectedAddressIndex", index.toString());
    localStorage.setItem("selectedMpAddressId", mpAddressId);
    localStorage.setItem("selectedAddress", JSON.stringify(selectedAddress)); // Storing the address as a stringified object
  };
  

  return (
    <>
      {loadinged && <Loader />}
      <div className={`${isSmallScreen ? "container-fluid" : "container"} address-container`}>
        {data.length > 0 ? (
          <div className={`${isSmallScreen ? "pt-4" : "p-3"} address-wrapper`}>
            <div className="address-header">
              <h4 style={{ fontWeight: "bold" }}>Select a Delivery Address</h4>
              <div
                className={`add-new-address ${isSmallScreen ? "pt-3" : ""}`}
                onClick={() =>{
                    setLoadinged(true);
                    setTimeout(()=> {
                      onEdit({ status: "Add Address",selectedLocation })
                      
                    },1500)
                  }}
              >
                <Cplus />
                <p className="ml-1 add-new-address-text">&nbsp;Add New Address</p>
              </div>
            </div>
            {data.map((add, index) => (
              <div key={index} className="address-card">
                <div
                  className="address-selection"
                  onClick={() => handleCheckboxChange(index)}
                >
                  <RadioButton
                    w={24}
                    h={24}
                    fInner={selectedAddressIndex === index ? "#73509E" : "none"}
                    fOuter={selectedAddressIndex === index ? "#73509E" : "#AAAAAA"}
                    checked={selectedAddressIndex === index}
                    onChange={() => handleCheckboxChange(index)}
                  />
                  <p className="ml-3 address-name">
                    {`${add.address?.input?.firstName || "N/A"} ${add.address?.input?.lastName || "N/A"}`}
                  </p>
                </div>
                <div className="address-details">
                  {add.address?.factoryName && (
                    <span className="factory-name">
                      <strong>Factory Name: </strong>{add.address.factoryName}
                    </span>
                  )}
                  {/* {add.address?.input && (
                    <>
                      <span>
                        <strong>Company Name: </strong>{add.address.input.companyName}
                      </span>
                      <span className="mt-1">
                        <strong>Billing Address: </strong>{add.address.input.streetAddress1}, {add.address.input.streetAddress2}, {add.address.input.city}, {add.address.input.countryArea}, {add.address.input.postalCode}
                      </span>
                    </>
                  )} */}
                  {add.address?.businessaddress && (
                    <span className="mt-1">
                      <strong>Shipping Address: </strong>{add.address.businessaddress.streetAddress1}, {add.address.businessaddress.streetAddress2}, {add.address.businessaddress.city}, {add.address.businessaddress.countryArea}, {add.address.businessaddress.postalCode}
                    </span>
                  )}
                </div>
                <div className="address-contact">
                  <div className="d-flex">
                    <PhoneIcon />
                    <p>{add.address?.input?.phone || "N/A"}</p>
                  </div>
                  {emailIdta && !emailIdta.includes("@origa.market") && (
                    <div className="d-flex">
                      <EmailIcon />
                      <p style={{ fontSize: "14px" }}>{emailIdta}</p>
                    </div>
                  )}
                </div>
                <div
                  className="edit-addreskfsjds"
                  onClick={() =>{
                    setLoadinged(true); 
                    setTimeout(() => {
                      onEdit({
                        AddressId: add.mp_address_id,
                        firstName: add.address?.input?.firstName,
                        status: "Edit Address",
                        lastName: add.address?.input?.lastName,
                        phoneNumberr:add.address?.input?.phone,
                        companyName: add.address?.input?.companyName,
                        factoryName: add.address?.factoryName,
                        alterPhoneNumber:add.address.alternativePhone,
                        // BillingstreetAddress1: add.address?.input?.streetAddress1,
                        // BillingstreetAddress2: add.address?.input?.streetAddress2,
                        // Billingcity: add.address?.input?.city,
                        // BillingpostalCode: add.address?.input?.postalCode,
                        ShippingstreetAddress1: add.address?.businessaddress?.streetAddress1,
                        ShippingstreetAddress2: add.address?.businessaddress?.streetAddress2,
                        Shippingcity: add.address.businessaddress.city,
                        ShippingpostalCode: add.address.businessaddress.postalCode,
                        selectedLocation
                      });
                    },1500) 
                  }}
                >
                  <EditIcon />
                  <span style={{ fontSize: "14px" }}>
                    &nbsp;&nbsp;&nbsp;Edit Address
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : null}
        <div className="deliver-here-container">
        <button
            className="deliver-here-btn"
            disabled={selectedAddressIndex === null || !data[selectedAddressIndex]}
            onClick={() => {
              setLoadinged(true);
              setTimeout(() => {
                onDeliverHere();
              }, 2000);
            }}
          >
            Proceed
          </button>

        </div>
      </div>

      {addressSelectionError && (
            <p style={{ color: "red", marginTop: "10px" }}>
              Please select an address to proceed.
            </p>
          )}
    </>
  );
};

export default ManageAddressForPopup;
