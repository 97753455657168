// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-btn {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
}
.breadcrumbs-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
}
.breadcrumbs-list li {
  position: relative;
  padding-right: 10px;
}
.breadcrumbs-list li::after {
  content: "/";
  position: absolute;
  right: 0;
  top: 4px;
  bottom: 3px;
  font-size: 12px;
  color: #7a7c7f;
}
.breadcrumbs-list li:last-child {
  padding: 0;
}
.breadcrumbs-list li:last-child::after {
  content: none;
}
.breadcrumbs-list li a {
  color: #7a7c7f;
  text-decoration: none;
}
.breadcrumbs-list li .active {
  color: #211e24;
}
@media (max-width: 767px) {
  .breadcrumbs-wrap {
    max-width: 420px;
    margin: 0 auto;
    width: 100%;
    margin: 0 !important;
    align-items: flex-start;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/Buying/Breadcrumbs/Breadcrumbs.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;EACT,YAAY;AACd;AACA;EACE,aAAa;EACb,eAAe;EACf,eAAe;EACf,SAAS;EACT,UAAU;EACV,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,QAAQ;EACR,QAAQ;EACR,WAAW;EACX,eAAe;EACf,cAAc;AAChB;AACA;EACE,UAAU;AACZ;AACA;EACE,aAAa;AACf;AACA;EACE,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,cAAc;AAChB;AACA;EACE;IACE,gBAAgB;IAChB,cAAc;IACd,WAAW;IACX,oBAAoB;IACpB,uBAAuB;EACzB;AACF","sourcesContent":[".back-btn {\r\n  background: none;\r\n  padding: 0;\r\n  margin: 0;\r\n  border: none;\r\n}\r\n.breadcrumbs-list {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  column-gap: 8px;\r\n  margin: 0;\r\n  padding: 0;\r\n  list-style: none;\r\n  align-items: center;\r\n}\r\n.breadcrumbs-list li {\r\n  position: relative;\r\n  padding-right: 10px;\r\n}\r\n.breadcrumbs-list li::after {\r\n  content: \"/\";\r\n  position: absolute;\r\n  right: 0;\r\n  top: 4px;\r\n  bottom: 3px;\r\n  font-size: 12px;\r\n  color: #7a7c7f;\r\n}\r\n.breadcrumbs-list li:last-child {\r\n  padding: 0;\r\n}\r\n.breadcrumbs-list li:last-child::after {\r\n  content: none;\r\n}\r\n.breadcrumbs-list li a {\r\n  color: #7a7c7f;\r\n  text-decoration: none;\r\n}\r\n.breadcrumbs-list li .active {\r\n  color: #211e24;\r\n}\r\n@media (max-width: 767px) {\r\n  .breadcrumbs-wrap {\r\n    max-width: 420px;\r\n    margin: 0 auto;\r\n    width: 100%;\r\n    margin: 0 !important;\r\n    align-items: flex-start;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
