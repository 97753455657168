// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pninthbar::after{
    content: "";
    background: #735197;
    width: 80px;
    height: 1px;
    display: block;
    position: absolute;
    left: calc(50% - 40px);
    margin: 7px 0;
}
.pninthbar{
    position: relative;
}
 
  .marquee_image{
height:200px;
width: 408px;
padding:0px 10px;
  }
 
  @media (max-width:576px){
    .marquee_image{
      height:153px ;
      width: 241px;
      padding:0px 10px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/component/HomePage/ninth/ninth.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,WAAW;IACX,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,sBAAsB;IACtB,aAAa;AACjB;AACA;IACI,kBAAkB;AACtB;;EAEE;AACF,YAAY;AACZ,YAAY;AACZ,gBAAgB;EACd;;EAEA;IACE;MACE,aAAa;MACb,YAAY;MACZ,gBAAgB;IAClB;EACF","sourcesContent":[".pninthbar::after{\n    content: \"\";\n    background: #735197;\n    width: 80px;\n    height: 1px;\n    display: block;\n    position: absolute;\n    left: calc(50% - 40px);\n    margin: 7px 0;\n}\n.pninthbar{\n    position: relative;\n}\n \n  .marquee_image{\nheight:200px;\nwidth: 408px;\npadding:0px 10px;\n  }\n \n  @media (max-width:576px){\n    .marquee_image{\n      height:153px ;\n      width: 241px;\n      padding:0px 10px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
