import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { MdOutlineDone } from "react-icons/md";
import { FcPackage, FcShipped } from "react-icons/fc";
import { FaCartShopping } from "react-icons/fa6";
import { TbFileInvoice } from "react-icons/tb";
import { MdCircle } from "react-icons/md";
import { PiNewspaperClippingLight } from "react-icons/pi";
import { LiaNewspaperSolid } from "react-icons/lia";
import { IoCartOutline } from "react-icons/io5";
import { BiPackage } from "react-icons/bi";
import { CiDeliveryTruck } from "react-icons/ci";
import { useEffect } from "react";



export default function VerticalLinearStepper({activeStep, selectedStepper }) {

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  // Check if steps is an array before proceeding with the rendering
  if (!Array.isArray(selectedStepper)) {
    return <Typography>No steps available</Typography>;
  }

  return (
    <Box className="vertical-lineStepper-all" sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {selectedStepper.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={index === selectedStepper.length - 1 ? null : null}
              icon={
                <div
                  style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    padding: "2px",
                    border: "1px solid transparent",
                    backgroundColor: index <= activeStep ? "green" : "lightgray",
                    color: index <= activeStep ? "white" : "gray",
                  }}
                >
                  {step.icon}
                </div>
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>
              <MdCircle color={index <= activeStep ? "green" : "gray"} size={10} />
              &nbsp;{step.description}
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {/* {activeStep === selectedStepper.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you're finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )} */}
    </Box>
  );
}

