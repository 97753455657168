// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marginforNewCartPage {
  margin-left: 50px;
  margin-right: 50px;
}
.Leftsidepfcartpage {
  padding: 10px;
  /* border: 1px solid #ddd; */
  /* border-radius: 8px; */
}
.cartHeading-RemoveDiv {
  padding: 8px;
  /* border-bottom: 1px solid #ddd; */
}
.CartHeadinginNewDesign {
  font-weight: 600;
}

.squareand-productheading {
  gap: 30px;
}
.delete-btn-new-cartpage-design {
  border-radius: 8px;
  border: 1px solid #ddd;
  padding: 5px;
  background-color: #e8e8e8;
  font-size: 14px;
}

.headingparticartpagenedd {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}
.qty-anf-price {
  gap: 150px;
}

.innerheadingof-headingparticartpagenedd {
  color: gray;
  font-weight: 500;
}

.individual-card-item-for-cart-page{
    
}

.Rightsidepfcartpage {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
}
`, "",{"version":3,"sources":["webpack://./src/component/CartPageNewDesign/NewDesignCartPageStyles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,4BAA4B;EAC5B,wBAAwB;AAC1B;AACA;EACE,YAAY;EACZ,mCAAmC;AACrC;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,SAAS;AACX;AACA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;EACZ,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,6BAA6B;AAC/B;AACA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;;AAEA;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;AACxB","sourcesContent":[".marginforNewCartPage {\r\n  margin-left: 50px;\r\n  margin-right: 50px;\r\n}\r\n.Leftsidepfcartpage {\r\n  padding: 10px;\r\n  /* border: 1px solid #ddd; */\r\n  /* border-radius: 8px; */\r\n}\r\n.cartHeading-RemoveDiv {\r\n  padding: 8px;\r\n  /* border-bottom: 1px solid #ddd; */\r\n}\r\n.CartHeadinginNewDesign {\r\n  font-weight: 600;\r\n}\r\n\r\n.squareand-productheading {\r\n  gap: 30px;\r\n}\r\n.delete-btn-new-cartpage-design {\r\n  border-radius: 8px;\r\n  border: 1px solid #ddd;\r\n  padding: 5px;\r\n  background-color: #e8e8e8;\r\n  font-size: 14px;\r\n}\r\n\r\n.headingparticartpagenedd {\r\n  padding: 8px;\r\n  border-bottom: 1px solid #ddd;\r\n}\r\n.qty-anf-price {\r\n  gap: 150px;\r\n}\r\n\r\n.innerheadingof-headingparticartpagenedd {\r\n  color: gray;\r\n  font-weight: 500;\r\n}\r\n\r\n.individual-card-item-for-cart-page{\r\n    \r\n}\r\n\r\n.Rightsidepfcartpage {\r\n  padding: 10px;\r\n  border-radius: 8px;\r\n  border: 1px solid #ddd;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
