import React, { useState, useEffect } from 'react'
import './FinanceCalculator.css'
import StepperTick from '../SubComponent/AllSvgs/StepperTick'
import Button from '../Button/Button'
import FinanceModel from './FinanceModel'
import InterestRateDiv from './InterestRateDiv'
import Loader from "../SubComponent/Loader";

const priceConvert = (price) => {
   price = typeof price === 'string' ? price : String(price);


   let count = 1;
   let comma = 3;
   let formatedPrice = ""
   for (let i = price.length - 1; i >= 0; i--) {
      formatedPrice = price[i] + formatedPrice
      if (count === comma) {
         formatedPrice = "," + formatedPrice
         comma = 2;
         count = 0;
      } count++;

   }
   console.log("==>>", formatedPrice)
   if (formatedPrice[0] === ",") {
      formatedPrice = formatedPrice.slice(1, formatedPrice.length)
   }
   return formatedPrice;



};

const FinanceSecondSection = () => {
   const [securityDeposit, setSecurityDeposit] = useState(10);
   const [tenureOfLease, setTenureOfLease] = useState(36);
   const [corporateTax, setCorporateTax] = useState('');
   const [legalType, setLegalType] = useState('');
   /* const [tenureOfLease, setTenureOfLease] = useState(''); */
   const [equipmentCost, setEquipmentCost] = useState('');
   const [gst, setGst] = useState('');
   const [totalLeaseRental, setTotalLeaseRental] = useState('');
   const [leaseRentalAmount, setLeaseRentalAmount] = useState('');
   const [interestRate, setInterestRate] = useState('');
   const [loading, setloading] = useState(false);
   const [apiCalled, setApiCalled] = useState(false);
   const [showModel, setShowModel] = useState(false);
   const [showIntrestRate, SetshowIntrestRate] = useState(false);
   const [EMICalculation, setEMICalculation] = useState('');
   const [IntrestRangeFrom, setIntrestRangeFrom] = useState('');
   const [IntrestRangeTo, setIntrestRangeTo] = useState('');
   const [LowerSlabAmount, setLowerSlabAmount] = useState('');
   const [HigherSlabAmount, setHigherSlabAmount] = useState('');
   const [machineprice, setmachineprice] = useState('');
   const [RatingInputMode, setRatingInputMode] = useState('select');
   const [RatingText, setRatingText] = useState('');
   const [finaceRate, setFinanceRate] = useState({
      machineprice: "",
      revenue: "",
      Creditagency: '',
      selectedOption: "",
      profit: "We are Profitable"
   })
   const [CreditAgency, setCreditAgency] = useState('');
   const [validation, setValidation] = useState({ MPinValidation: "", Creditagency: '', Mrevenue: "", selectedOption: "" })

   const onOptionChange = (event) => {
      setFinanceRate((prev) => ({ ...prev, selectedOption: event.target.value }));
   };
   const onMachineHandler = (e) => {
      const newInputString = e.target.value;
      const sanitizedInput = newInputString.replace(/[^0-9]/g, '');
      setmachineprice(priceConvert(sanitizedInput))
      console.log('sanitizedInput----->', sanitizedInput);

      // Update the state only if the input is empty or contains valid characters
      if (sanitizedInput) {
         setFinanceRate((prev) => ({ ...prev, machineprice: (sanitizedInput) }))
      }

   }
   const onRevanueHandler = (e) => {
      const newInputString = e.target.value;
      const sanitizedInput = newInputString.replace(/[^0-9]/g, '');


      // Update the state only if the input is empty or contains valid characters
      if (newInputString) {
         setFinanceRate((prev) => ({ ...prev, revenue: newInputString }))
      }

   }

   useEffect(() => {

      const EMIData = EMICalculation?.data?.body?.data;
      if (EMIData) {
         console.log('EMIData---', EMIData);
         console.log('finaceRate---', finaceRate.revenue);
         console.log('finaceRate---', finaceRate.selectedOption);
         let filteredData = EMIData;
         if (finaceRate.revenue) {
            filteredData = filteredData.filter(item => item.revenueValue === finaceRate.revenue);
         }

         // if (finaceRate.revenue && finaceRate.selectedOption) {
         //    console.log('workinnin', filteredData);
         //    filteredData = filteredData.filter(item =>
         //       item.revenueValue === finaceRate.revenue &&
         //       ((parseInt(finaceRate.selectedOption) <= 6 && item.creditRatingLesserOrEquals === 6.0) ||
         //          (parseInt(finaceRate.selectedOption) > 6 && parseInt(finaceRate.selectedOption) <= 15 && item.creditRatingLesserOrEquals === 8.0) ||
         //          (parseInt(finaceRate.selectedOption) > 15 && item.creditRatingGreaterOrEquals === 25.0))
         //    );
         // }
         console.log('filteredData:', filteredData);
         if (filteredData.length > 0) {
            // Find the min and max values for interest range and EMI
            const minIntrestRangeFrom = Math.min(...filteredData.map(item => item.rateRangeFrom));
            const maxIntrestRangeTo = Math.max(...filteredData.map(item => item.rateRangeTo));
            const minLowerSlabAmount = Math.min(...filteredData.map(item => item.lowerSlabAmount));
            const maxHigherSlabAmount = Math.max(...filteredData.map(item => item.higherSlabAmount));

            // Set the state with the min and max values
            setIntrestRangeFrom(minIntrestRangeFrom);
            setIntrestRangeTo(maxIntrestRangeTo);
            setLowerSlabAmount(minLowerSlabAmount);
            setHigherSlabAmount(maxHigherSlabAmount);
         }
         else {
            // Find the min and max values for interest range and EMI
            const minIntrestRangeFrom = Math.min(...EMIData.map(item => item.rateRangeFrom));
            const maxIntrestRangeTo = Math.max(...EMIData.map(item => item.rateRangeTo));
            const minLowerSlabAmount = Math.min(...EMIData.map(item => item.lowerSlabAmount));
            const maxHigherSlabAmount = Math.max(...EMIData.map(item => item.higherSlabAmount));

            if (finaceRate.revenue === 'Below 10Cr') {
               setIntrestRangeFrom('11');
               setIntrestRangeTo('15');
               setLowerSlabAmount(minLowerSlabAmount);
               setHigherSlabAmount(maxHigherSlabAmount);
            }
            else {
               // Set the state with the min and max values
               setIntrestRangeFrom(minIntrestRangeFrom);
               setIntrestRangeTo(maxIntrestRangeTo);
               setLowerSlabAmount(minLowerSlabAmount);
               setHigherSlabAmount(maxHigherSlabAmount);

            }

         }



      }

   }, [EMICalculation, finaceRate.revenue, finaceRate.selectedOption]);

   const onFinanceHandler = async () => {

      try {
         setloading(true)
         const response = await fetch(`https://devextension.origa.market/api/getleaserentallist`, {
            method: "POST",
            body: JSON.stringify({ "principalAmount": finaceRate.machineprice })
         });
         const responseData = await response.json();
         setEMICalculation(responseData)
         setloading(false)
         //console.log('Model----->', responseData);
      } catch (error) {
         setloading(false)
         console.error('Error fetching data:', error);
      }

      console.log("--check>>>", finaceRate.revenue, "--->>>", finaceRate.selectedOption, "--->>>", finaceRate.profit);
      if (finaceRate.MPinValidation === "" || finaceRate.revenue === "" || finaceRate.selectedOption === "" || finaceRate.profit === "" || finaceRate.Creditagency === "") {
         if (finaceRate.machineprice === "") { setValidation((prev) => ({ ...prev, MPinValidation: true })) }
         if (finaceRate.revenue === "") { setValidation((prev) => ({ ...prev, Mrevenue: true })) }
         if (finaceRate.Creditagency === "") { setValidation((prev) => ({ ...prev, Creditagency: true })) }
         if (finaceRate.selectedOption === "") { setValidation((prev) => ({ ...prev, selectedOption: true })) }
         return
      }
      setShowModel(true)
   }
   const handleCreditagencyChange = (event) => {
      setFinanceRate((prev) => ({ ...prev, Creditagency: event.target.value }));
      setCreditAgency(event.target.value)
      if ((event.target.value >= '4' && event.target.value <= '8')) {
         setRatingInputMode('text')
         setFinanceRate((prev) => ({ ...prev, selectedOption: "0" }));
      }
      else {
         setRatingInputMode('select');
      }
   }
   const onRatingTextChange = (e) => {
      setRatingText(e.target.value)
   };
  /*  const handleSliderChange = (event) => {
      setSecurityDeposit(event.target.value);
    }; */
    /* const handleLegalTypeChange = (event) => {
      const selectedLegalType = event.target.value;
      setLegalType(selectedLegalType);
  
      if (selectedLegalType === 'Private Ltd' || selectedLegalType === 'Public Ltd') {
        setCorporateTax('25');
      } else {
        setCorporateTax('30');
      }
    }; */
   /*  const handleTenureChange = (event) => {
      setTenureOfLease(event.target.value);
   }; */

   useEffect(() => {
    if (securityDeposit && corporateTax && tenureOfLease && equipmentCost && gst) {
      handleCalculateLeaseRental(); // Call the API when all data is entered
    }
  }, [securityDeposit, corporateTax, tenureOfLease, equipmentCost, gst]);

  const handleSliderChange = (event) => {
    setSecurityDeposit(event.target.value);
  };

  const handleLegalTypeChange = (event) => {
    const selectedLegalType = event.target.value;
    setLegalType(selectedLegalType);

    if (selectedLegalType === 'Private Ltd') {
      setCorporateTax('25');
    } else {
      setCorporateTax('30');
    }
  };

  const handleTenureChange = (event) => {
    setTenureOfLease(event.target.value);
  };

  const handleCalculateLeaseRental = async () => {
    setloading(true);
    const requestData = {
      PricingData: {
        corporateTax,
        securityDeposit,
        tenureOfLease,
        equipmentCost,
        gst,
      }
    };

    try {
      const response = await fetch('https://devextension.origa.market/api/getfinancecalculator', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();

      if (responseData.status === 'success') {
        const leaseRental = responseData.data.leaseRentalAmount;
        setLeaseRentalAmount(leaseRental);  // Set lease rental amount
        const totalLease = ((equipmentCost / 100000) * leaseRental).toFixed(2);  // Calculate total lease rental by dividing by 100
        setTotalLeaseRental(totalLease);  // Set the calculated total lease rental
        setInterestRate(responseData.data.interestRate);  // Set the interest rate if needed
        setApiCalled(true);
      }

    } catch (error) {
      console.error('Error fetching lease rental data:', error);
    } finally {
      setloading(false);
    }
  };
  const handleApply = () => {
    window.location.href = '/finance_stepper?Type=Lease';
  };

  const handleReset = () => {
    setSecurityDeposit(10);
    setTenureOfLease(36);
    setCorporateTax('');
    setLegalType('');
    setEquipmentCost('');
    setGst('');
    setTotalLeaseRental('');
    setLeaseRentalAmount('');
    setApiCalled(false);
  };

  return (
    <>
      {loading && <Loader />}
      {showModel && <FinanceModel modalAction={() => setShowModel(false)} interestRate={() => SetshowIntrestRate(true)} />}
      <div className='container-fluid f-secons-section-div'>
        <div className='max-container'>
          <div className='row'>
            <div className='col col-md-12'>
              <div className='heading-container'>
                <img src="/OrigaFinance.svg" alt="OrigaFinance" />
                <div className='heading-600-24-20 c-green'>
                Lease Rental Calculator
                </div>
                <p className='heading-400-16-14 op-80'>Enter the details given below to calculate your lease interest rate</p>
              </div>
            </div>
            <div className='col col-md-6 col-12'>
              <div className='f-check-select'>
                <div className={`bi-form-group`}>
                  <input 
                    type="number"  
                    name="machine-add1" 
                    id="machine-add1" 
                    className={`bi-form-field bg-white`} 
                    value={equipmentCost} 
                    onChange={(e) => setEquipmentCost(e.target.value)} 
                    placeholder="Enter Equipment Cost" 
                    min="0" 
                    step="1" 
                  />
                  <label htmlFor="machine-add1" className="heading-400-14-12 bi-form-label">Enter Machine Value (Excl GST)<span style={{ color: '#CB1923' }}>*</span></label>
                </div>
              </div>
            </div>
            <div className='col col-md-6 col-12'>
              <div className='slider-container'>
                <label htmlFor="security-deposit-slider" className="heading-400-14-12 bi-form-label1">Security Deposit</label>
                <input 
                  type="range" 
                  id="security-deposit-slider" 
                  className="slider" 
                  min="10" 
                  max="20" 
                  step="5" 
                  value={securityDeposit} 
                  onChange={handleSliderChange}
                  style={{ background: `linear-gradient(90deg, rgba(76, 67, 118, 1) ${(securityDeposit - 10) / 10 * 100}%, rgba(214, 214, 214, 1) ${(securityDeposit - 10) / 10 * 100}%)` }}
                  
                />
                <div className="slider-labels">
                  <span>10%</span>
                  <span>15%</span>
                  <span>20%</span>
                </div>
              </div>
            </div>
          </div>

          {/* Legal Type and Corporate Tax */}
          <div className='row'>
            <div className='col col-md-6 col-12'>
              <div className='f-check-select'>
                <div className={`bi-form-group`}>
                  <select className={`bi-form-field`} value={legalType} onChange={handleLegalTypeChange}>
                    <option value="">Select Legal Type</option>
                    <option value="Private Ltd">Private Limited Company</option>
                    <option value="Proprietorship">Proprietorship</option>
                    <option value="Partnership Firm">Partnership Firm</option>
                    <option value="LLP">LLP</option>
                    <option value="Public Ltd">Public Ltd</option>
                    <option value="Business Entities Created by Statutes">Business Entities Created by Statutes</option>
                    <option value="Trust">Trust</option>
                    <option value="Hindu Undivided Family">Hindu Undivided Family</option>
                    <option value="Co-operative Society">Co-operative Society</option>
                    <option value="Association of Persons">Association of Persons</option>
                    <option value="Government">Government</option>
                    <option value="Not Classified">Not Classified</option>
                    <option value="Individual">Individual</option>
                    <option value="Others">Others</option>
                  </select>
                  <label htmlFor="legal-type" className="heading-400-14-12 bi-form-label">Legal Type</label>
                </div>
              </div>
            </div>
            <div className='col col-md-6 col-12'>
              <div className='f-check-select'>
                <div className={`bi-form-group`}>
                  <input 
                    type="text" 
                    name="corporate-tax" 
                    id="corporate-tax" 
                    className={`bi-form-field bg-white`} 
                    value={corporateTax} 
                    readOnly 
                  />
                  <label htmlFor="corporate-tax" className="heading-400-14-12 bi-form-label">Corporate Tax (%)</label>
                </div>
              </div>
            </div>
          </div>

          {/* Tenure and GST Tax */}
          <div className='row'>
            <div className='col col-md-6 col-12'>
              <div className='slider-container'>
                <label htmlFor="tenure-slider" className="heading-400-14-12 bi-form-label1">Tenure in Months</label>
                <input 
                  type="range" 
                  id="tenure-slider" 
                  className="slider" 
                  min="36" 
                  max="60" 
                  step="12" 
                  value={tenureOfLease} 
                  onChange={handleTenureChange}
                  style={{ background: `linear-gradient(90deg, rgba(76, 67, 118, 1) ${((tenureOfLease - 36) / 24) * 100}%, rgba(214, 214, 214, 1) ${((tenureOfLease - 36) / 24) * 100}%)` }}
                />
                <div className="slider-labels">
                  <span>36 Months</span>
                  <span>48 Months</span>
                  <span>60 Months</span>
                </div>
              </div>
            </div>
            <div className='col col-md-6 col-12'>
              <div className='f-check-select'>
                <div className={`bi-form-group`}>
                  <select className={`bi-form-field`} value={gst} onChange={(e) => setGst(e.target.value)}>
                    <option value="">Select GST Tax</option>
                    <option value="18">18%</option>
                  </select>
                  <label htmlFor="gst-tax" className="heading-400-14-12 bi-form-label">GST Tax</label>
                </div>
              </div>
            </div>
          </div>

          {/* Total Lease Rental and Lease Rental per Lakh */}
          <div className='row'>
            <div className='col col-md-6 col-12'>
              <div className='f-check-select'>
                <div className={`bi-form-group`}>
                  <input 
                    type="text" 
                    name="total-lease-rental" 
                    id="total-lease-rental" 
                    className={`bi-form-field bg-white`} 
                    value={`₹ ${totalLeaseRental}`} 
                    readOnly 
                  />
                  <label htmlFor="total-lease-rental" className="heading-400-14-12 bi-form-label">Total Lease Rental (Excl GST)</label>
                </div>
              </div>
            </div>
            <div className='col col-md-6 col-12'>
              <div className='f-check-select'>
                <div className={`bi-form-group`}>
                  <input 
                    type="text" 
                    name="lease-rental-per-lakh" 
                    id="lease-rental-per-lakh" 
                    className={`bi-form-field bg-white`} 
                    value={`₹ ${leaseRentalAmount}`} 
                    readOnly 
                  />
                  <label htmlFor="lease-rental-per-lakh" className="heading-400-14-12 bi-form-label">Lease Rental (per lakh per month) (Excl GST)</label>
                </div>
              </div>
            </div>
          </div>

         {/* Conditionally render buttons only if API has been called */}
         {apiCalled && (
            <div className='row'>
              <div className='col col-md-12 text-center'> {/* Centering the content */}
                <p className="heading-400-16-14 op-80">The effective rate of interest is {interestRate}%</p>
                <div className='pt-3 d-flex justify-content-center'> {/* Centering the buttons */}
                  <Button message={"Apply Now"} callFunction={handleApply} />
                  
                  <div className='reset-button'>
                  <Button
  message={"Reset"}
  callFunction={handleReset}
   // Add this class
/>
</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FinanceSecondSection;