import React from "react";

const FirstImageDivofMogilix = () => {
  return (
    <>
      <div className="Totalimagesdivofmogilix">
        <div className="Firstmajorimagediv">
          <img
            className="imagefirstdivv"
            src="https://cdn.moglix.com/p/qaBuRcdrwHrL4-xxlarge.jpg"
          />
        </div>

        <div className="fiveimgsmalldivs">
          <div className="firstimgsmalldiv">
          <img className="imagefirstljdbdivv" src="https://cdn.moglix.com/p/NIW5WgMIyyxHQ-xxlarge.jpg" />
          </div>

          <div  className="firstimgsmalldiv">
          <img className="imagefirstljdbdivv" src="https://cdn.moglix.com/p/NIW5WgMIyyxHQ-xxlarge.jpg" />
          </div>

          <div  className="firstimgsmalldiv">
          <img className="imagefirstljdbdivv" src="https://cdn.moglix.com/p/NIW5WgMIyyxHQ-xxlarge.jpg" />
          </div>

          <div  className="firstimgsmalldiv">
          <img className="imagefirstljdbdivv" src="https://cdn.moglix.com/p/NIW5WgMIyyxHQ-xxlarge.jpg" />
          </div>
         
          <div  className="firstimgsmalldiv">
          <img className="imagefirstljdbdivv" src="https://cdn.moglix.com/p/NIW5WgMIyyxHQ-xxlarge.jpg" />
          </div>

        </div>
        <div className="mb-4">
           
        </div>
      </div>
    </>
  );
};

export default FirstImageDivofMogilix;
