import React, { useState } from 'react';

const ColumnTwo = ({ plan, onAddToCart, onRemoveFromCart }) => {
  const [itemCounts, setItemCounts] = useState({});

  const handleIncrement = () => {
    const newCount = (itemCounts[plan.amc_plan_id] || 0) + 1;
    setItemCounts({ ...itemCounts, [plan.amc_plan_id]: newCount });
    onAddToCart(plan);
  };

  const handleDecrement = () => {
    const newCount = (itemCounts[plan.amc_plan_id] || 0) - 1;
    if (newCount <= 0) {
      const { [plan.amc_plan_id]: _, ...rest } = itemCounts;
      setItemCounts(rest);
      onRemoveFromCart(plan);
    } else {
      setItemCounts({ ...itemCounts, [plan.amc_plan_id]: newCount });
      onRemoveFromCart(plan);
    }
  };

  return (
    <div className="amc-plan">
      <div>
        <h3>{plan.name}</h3>
        <p>{plan.plan}</p>
        {plan.scope_of_work && (
          <ul>
            {JSON.parse(plan.scope_of_work).map((scope, idx) => (
              <li key={idx}>{scope}</li>
            ))}
          </ul>
        )}
      </div>
      <div className="service-quantity-controls">
        {itemCounts[plan.amc_plan_id] ? (
          <>
            <button className="service-square-button" onClick={handleDecrement}>-</button>
            <span>{itemCounts[plan.amc_plan_id]}</span>
            <button className="service-square-button" onClick={handleIncrement}>+</button>
          </>
        ) : (
          <button className="service-square-button add-button" onClick={handleIncrement}>Add</button>
        )}
      </div>
    </div>
  );
};

export default ColumnTwo;