import React from "react";
import "../Mogilx.css";
import { IoStar } from "react-icons/io5";
import { MdOutlineTrendingUp } from "react-icons/md";
import { FaPercentage } from "react-icons/fa";
const SecondContentDivofMogilix = () => {
  return (
    <>
      <div className="secondcontentinsidedivofmogilix">
        <div className="headingpartofmogilxsecondcontent">
          <h1 className="Headingosseconddiv">
            Candes Florence 400rpm Silver Blue Anti Dust Ceiling Fan, Sweep:
            1200 mm
          </h1>
        </div>

        <div className="ratingsandreviewdiv mt-3">
          <button className="stardiv">
            4.8 &nbsp; <IoStar />
          </button>{" "}
          <span className="reviewwwww">(44 reviews)</span>
        </div>

        <div className="pricin2ndcontentdiv mt-3">
          <p className="pricelightofsecond">
            ₹1,549 (Incl. of all taxes)
            <br></br>
            <span className="priceboldofsecond">₹1,143</span>
            <span className="pricelightofsecond">+ ₹206 GST</span>
            <br></br>
            <span className="pricelightofsecond">
              MRP <span className="textcannceeled">₹3,281</span> &nbsp;
              <span className="offercolor">58% OFF</span>{" "}
            </span>
          </p>

          <button className="peopleboughtfor">
            <MdOutlineTrendingUp /> &nbsp; 350 people bought this recently
          </button>
        </div>

        <div className="Quantity-packs mt-3">
          <p className="packof ">Pack of </p>
          <div className="buttonsinpackof">
            <button className="onebuttonofpack">1</button>
            <button className="secondbuttonofpack">2</button>
          </div>

          <p className="packof ">Colour </p>
          <div className="buttonsinpackof">
            <button className="onebuttonofpackss">Blue Silver</button>
            <button className="secondbuttonofpackss">Gold ivory</button>
            <button className="secondbuttonofpackss">Olive green</button>
          </div>
        </div>

        <div className="Offersinsecondtotaldiv mt-4">
          <p className="offersheading">Offers </p>

          <div>
            <p>
              <FaPercentage color="green" size={20} />{" "}
              <span className="sSSave">
                Save ₹100 instantly with online payment.
              </span>
              <br></br>
              <span className="uoiiii">
                via UPI, EMI, Credit/Debit Card, Net Banking, Wallets on min.
                cart value of ₹999 in Longway Ceiling Fans
              </span>
              <br></br>
              <span className="nocouoon">*No coupon needed</span>
            </p>
          </div>

          <div className="dividerinsecondmogilixcontent"></div>

          <div className="anotherlinedivdavd">
            <p>
              <FaPercentage color="green" size={20} />{" "}
              <span className="dvsbdssf">
                Get GST invoice and save up to 18% on business purchases
              </span>
              <br></br>
            </p>
          </div>
        </div>

        <div className="BuyMoreAndSaveMore mt-4">
          <p>Buy More & Save More</p>
          <div className="fourdivsofquantity">
            <div className="firstquanitydiv">
              <span className="quantituyb">Qty 2</span>
              <span className="rateperpc">₹ 1,342</span>
              <span className="lrnwsbfoffer">59%OFF</span>
            </div>
            <div className="secondquanitydiv">
              <span className="quantituyb">Qty 3</span>
              <span className="rateperpc">₹ 1,596</span>
              <span className="lrnwsbfoffer">62%OFF</span>
            </div>
            <div className="thirdquanitydiv">
              <span className="quantituyb">Qty 4-5</span>
              <span className="rateperpc">₹ 1,712</span>
              <span className="lrnwsbfoffer">64%OFF</span>
            </div>
            <div className="fourthquanitydiv">
              <span className="quantituyb">Qty 6</span>
              <span className="rateperpc">₹ 1,988</span>
              <span className="lrnwsbfoffer">70%OFF</span>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondContentDivofMogilix;
