import React from "react";
import "./NewDesignCartPageStyles.css"
import { CiCircleRemove } from "react-icons/ci";
import { IoSquareOutline } from "react-icons/io5";
const CartPageHome = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="marginforNewCartPage pt-3 mb-2">
          <div className="row gap-5">
            {/*Left side div */}
            <div className="Leftsidepfcartpage col-md-7 col-sm-12">
                <div className="cartHeading-RemoveDiv d-flex justify-content-between align-items-center">
                        <div><h4 className="CartHeadinginNewDesign">Cart</h4></div>
                        <div><button className="delete-btn-new-cartpage-design"><CiCircleRemove />&nbsp;Remove</button></div>
                </div>
                <div className="headingparticartpagenedd d-flex justify-content-between align-items-center">
                   <div className="squareand-productheading d-flex">
                   <div className="innerheadingof-headingparticartpagenedd"><IoSquareOutline size={20}/></div>
                    <div className="innerheadingof-headingparticartpagenedd">Product</div>
                   </div>

                   <div className="qty-anf-price d-flex ">
                   <div className="innerheadingof-headingparticartpagenedd">Qty</div>
                   <div className="innerheadingof-headingparticartpagenedd">Price</div>
                   </div>
               

                </div>
                <div className="individual-card-item-for-cart-page">
                    <div className="row">
                      <div className="col-6"><div className="innerheadingof-headingparticartpagenedd"><IoSquareOutline size={20}/></div></div>
                      <div className="col">Hi</div>
                      <div className="col">Hi</div>

                    </div>
                </div>

            </div>

            {/*Right side div */}
            <div className="Rightsidepfcartpage col-md-4 col-sm-12 d-flex justify-content-between p-3">

              <div>
              <span style={{display:"block"}} className="innerheadingof-headingparticartpagenedd">Subtotal</span>
              <span style={{display:"block"}} className="innerheadingof-headingparticartpagenedd">Tax</span>

              <span style={{display:"block"}} className="innerheadingof-headingparticartpagenedd mt-2">Total</span>
             
              </div>



              <div>
              <span style={{display:"block",color:"black"}} className="innerheadingof-headingparticartpagenedd">₹0</span>
              <span style={{display:"block",color:"black"}} className="innerheadingof-headingparticartpagenedd">₹0</span>
              <span style={{display:"block",color:"black"}} className="innerheadingof-headingparticartpagenedd mt-2">₹0</span>

              </div>
            
            
            
            
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartPageHome;
