// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popuppagecartdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-color: rgba(232, 227, 227, 0.5); /* Optional: to dim the background */
  }
  
  .phniintheumber{
    text-align: left;
    font-size: 12px;
  }
  .centeredcolumndivforpopup {
    background-color: white;
    border: 1px solid rgb(205, 200, 200);
    border-radius: 5px;
    padding: 20px; /* Optional: add padding if necessary */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add shadow for better visibility */
  }
  
  .edityouraddresstext {
    border-bottom: 1px solid rgb(205, 200, 200);
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .youreditaddresstext {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
  }
  
  .input-container {
    margin: 0;
  }
  
  .bi-form-group-white {
    background-color: white;
  }
  
  .bi-form-field-white {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .heading-400-14-12 {
    font-size: 14px;
    font-weight: 400;
  }
  
  .bi-form-label-white {
    margin-top: 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/component/Account/ManageAddress/PopupforCartPageStyles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,0CAA0C,EAAE,oCAAoC;EAClF;;EAEA;IACE,gBAAgB;IAChB,eAAe;EACjB;EACA;IACE,uBAAuB;IACvB,oCAAoC;IACpC,kBAAkB;IAClB,aAAa,EAAE,uCAAuC;IACtD,wCAAwC,EAAE,+CAA+C;EAC3F;;EAEA;IACE,2CAA2C;IAC3C,oBAAoB;IACpB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,SAAS;EACX;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".popuppagecartdiv {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 80vh;\r\n    background-color: rgba(232, 227, 227, 0.5); /* Optional: to dim the background */\r\n  }\r\n  \r\n  .phniintheumber{\r\n    text-align: left;\r\n    font-size: 12px;\r\n  }\r\n  .centeredcolumndivforpopup {\r\n    background-color: white;\r\n    border: 1px solid rgb(205, 200, 200);\r\n    border-radius: 5px;\r\n    padding: 20px; /* Optional: add padding if necessary */\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add shadow for better visibility */\r\n  }\r\n  \r\n  .edityouraddresstext {\r\n    border-bottom: 1px solid rgb(205, 200, 200);\r\n    padding-bottom: 10px;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .youreditaddresstext {\r\n    font-size: 18px;\r\n    font-weight: bold;\r\n    text-align: left;\r\n  }\r\n  \r\n  .input-container {\r\n    margin: 0;\r\n  }\r\n  \r\n  .bi-form-group-white {\r\n    background-color: white;\r\n  }\r\n  \r\n  .bi-form-field-white {\r\n    width: 100%;\r\n    padding: 10px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n  }\r\n  \r\n  .heading-400-14-12 {\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n  }\r\n  \r\n  .bi-form-label-white {\r\n    margin-top: 5px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
