import React, { useState } from "react";
import "./NewBanner.css";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../SubComponent/Loader";
import { FaGooglePlay } from "react-icons/fa";
import { FaApple } from "react-icons/fa6";

const NewBanner = ({ items, bannerTopSection }) => {
  const [loadinged, setLoadinged] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const downloadSectionWidths = {
    "/buy": "50%",
    "/sell": "60%",
    "/finance": "40%",
    "/": "60%",
    "/service": "60%",
  };

  const downloadSectionWidth = downloadSectionWidths[pathname] || "60%";

  
  const getDownloadSectionWidth = () => {
    if (pathname === "/buy") {
      return window.innerWidth <= 568 ? "60%" : downloadSectionWidth; 
    }
    if(pathname === "/finance"){
      return window.innerWidth <= 568 ? "60%" : downloadSectionWidth; 
    }
    return downloadSectionWidth;
  };

  return (
    <>
      {loadinged && <Loader />}

      <div className="newbanner">
        <div className="max-container newbanner-main">
          <div className={`newbanner-text ${bannerTopSection.mw}`}>
            <h2 className="heading-600-28 text-white">
              {bannerTopSection.title}
            </h2>
            <p className="heading-400-16-14 op-80" style={{ color: "#F5F5F5" }}>
              {bannerTopSection.para}
            </p>

            {pathname === "/service" && (
                <div className="download-section" style={{ width: getDownloadSectionWidth() }}>
                <a href="/origaapps" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <span>Download Origa App</span>
                </a>
              
                <a href="https://apps.apple.com/in/app/origa-market/id6476490074">
                  <FaApple
                    style={{
                      fontSize: "21px",
                      color: "white",
                      marginLeft: "6px",
                    }}
                  />
                </a>
              
                <a href="https://play.google.com/store/apps/details?id=com.origa.serviceXL">
                  <FaGooglePlay
                    style={{
                      color: "white",
                      marginLeft: "6px",
                    }}
                  />
                </a>
              </div>
              
            )}
          

          </div>

          <div className="banner-items">
            <div className="banner-items-wrap">
              {items.map((item) => (
               <div className={`banner-item ${pathname === "/service" ? "no-hover" : ""}`} key={item.id}>
                  <div className="banner-item-wrap">
                    <div>{item.imagesrc}</div>
                    <div className="heading-500-16-14 banner-item-title">
                      {item.title}
                    </div>
                    <div
                      className="heading-400-14-12 banner-item-para op-80"
                      style={{ paddingTop: "8px" }}
                    >
                      {item.para}
                    </div>

                    {pathname !== "/service" && (
                      <button
                        className="f-btn mt-12"
                        onClick={() => {
                          setLoadinged(true);
                          setTimeout(() => {

                            if (pathname === "/service") {
                              item.navi(); 
                            } else {
                              navigate(item.navi);
                            }
                          }, 500);
                        }}
                      >
                        {pathname === "/buy"
                          ? "Buy Now"
                          : pathname === "/sell"
                          ? "Sell Now"
                          : pathname === "/finance"
                          ? "Apply Now"
                          : "Buy Now"}{" "}
                        
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewBanner;