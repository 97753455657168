import React from 'react';
import { ALGOLIA_ID, ALGOLIA_KEY, ALGOLIA_SELLER_INDEX } from '../../../../constants';
import { RelatedProducts } from '@algolia/recommend-react';
import recommend from '@algolia/recommend';

const recommendClient = recommend(ALGOLIA_ID, ALGOLIA_KEY);
const indexName = ALGOLIA_SELLER_INDEX;

const SimilarProductsforAlgolia = ({ setSimilarProductsItem }) => {
  
  const storedObjectID = localStorage.getItem('objectID');
  const validObjectID = storedObjectID && typeof storedObjectID === 'string' 
    ? storedObjectID 
    : "UHJvZHVjdDoxOTE3_UHJvZHVjdFZhcmlhbnQ6MTc4OQ==";

  const handleRelatedProducts = (response) => {
    setSimilarProductsItem(response.items); 
  };

  return (
    <>
      <RelatedProducts
        recommendClient={recommendClient}
        indexName={indexName}
        objectIDs={[validObjectID]} 
        view={handleRelatedProducts}
      />
    </>
  );
};

export default SimilarProductsforAlgolia;
