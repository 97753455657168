

export const ALGOLIA_ID = process.env.REACT_APP_ALGOLIA_ID

export const ALGOLIA_KEY = process.env.REACT_APP_ALGOLIA_KEY

export const ALGOLIA_SELLER_INDEX = process.env.REACT_APP_ALGOLIA_SELLER_INDEX

export const ALGOLIA_BUYER_INDEX = process.env.REACT_APP_ALGOLIA_BUYER_INDEX


export const ALGOLIA_SPARE_INDEX = process.env.REACT_APP_ALGOLIA_SPARE_INDEX

