import React, { useEffect } from 'react'
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import FourthSection from './FourthSection';
import FifthSection from './FifthSection';

import Footer from '../Footer/Footer';
import AmcFirstSection from './AmcFirstSection';
import AmcFirstSectionPage from './AmcFirstSectionPage';
import ThirdSectionPage from './ThirdSectionPage';
import SecondSectionCard from './SecondSectionCard';

const AmcforFirstCard = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
       })
        return (
            <>
              <AmcFirstSectionPage/> 
                {/* <SecondSectionCard />
                <ThirdSectionPage />
                <FourthSection />*/}
                <FifthSection /> 
                 <Footer /> 
            </>
        )
    }
    

export default AmcforFirstCard