import React from 'react'

const LocationF = ({fill}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g opacity="0.8" clipPath="url(#clip0_1_1949)">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.625 7.50093C15.6276 8.70619 15.2404 9.88 14.5213 10.8472L10 16.8759L5.47875 10.8472C4.94938 10.132 4.59724 9.30139 4.45124 8.42365C4.30524 7.54592 4.36955 6.64606 4.63889 5.79801C4.90824 4.94995 5.37492 4.17789 6.00062 3.54525C6.62632 2.9126 7.39318 2.43741 8.23821 2.15872C9.08324 1.88003 9.98232 1.80578 10.8616 1.94208C11.7409 2.07838 12.5753 2.42133 13.2963 2.94277C14.0173 3.4642 14.6044 4.14925 15.0092 4.94163C15.414 5.73401 15.625 6.61113 15.625 7.50093ZM17.5 7.50093C17.5 9.17593 16.9513 10.7209 16.025 11.9684L11.1713 18.4384L10 20.0009L8.8275 18.4384L3.975 11.9684C3.26836 11.0152 2.79797 9.90793 2.60248 8.73756C2.407 7.5672 2.49199 6.36715 2.85049 5.23602C3.20898 4.10489 3.83074 3.07497 4.66468 2.23086C5.49863 1.38676 6.52095 0.752572 7.64765 0.380405C8.77436 0.00823922 9.97329 -0.0912834 11.1459 0.090015C12.3186 0.271313 13.4315 0.728258 14.3932 1.42331C15.3549 2.11835 16.138 3.03167 16.678 4.0882C17.2181 5.14474 17.4998 6.31435 17.5 7.50093ZM10 10.0009C10.663 10.0009 11.2989 9.73753 11.7678 9.26869C12.2366 8.79985 12.5 8.16397 12.5 7.50093C12.5 6.83788 12.2366 6.202 11.7678 5.73316C11.2989 5.26432 10.663 5.00093 10 5.00093C9.33696 5.00093 8.70107 5.26432 8.23223 5.73316C7.76339 6.202 7.5 6.83788 7.5 7.50093C7.5 8.16397 7.76339 8.79985 8.23223 9.26869C8.70107 9.73753 9.33696 10.0009 10 10.0009Z" fill={fill}/>
    </g>
    <defs>
      <clipPath id="clip0_1_1949">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
  )
}

export default LocationF