import React, { useEffect, useState } from "react";
import "./ProductBlock.css";
import { Container } from "react-bootstrap";
import Button from "../../Button/Button";
import Correct from "../../SubComponent/AllSvgs/Correct";
import Wrong from "../../SubComponent/AllSvgs/Wrong";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LoginModel from "../../Authentication/LoginModel/LoginModel";
import axios from "axios";

const ProductBlock = (props) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const location = useLocation();
  const AMC_BASED_ON_TYPE = location.state?.selectedCategory?.toUpperCase();
  const { selectedCategory, selectedLocation, firstname, serialNo } =
    location.state || {};
  const productData = location.state && location.state.product;
  const [isHovered, setIsHovered] = useState(false);
  const [loginPortal, setLoginPortal] = useState(false);
  const { title, price, imageUrl, id, onProductBlockNavigate, product } = props;
  const loggedIn = !!localStorage.getItem("userToken");
  const onMouseEnterhandler = () => {
    setIsHovered(true);
  };

  let description;
  try {
    const parsedDescription = JSON.parse(product.description);
    // Extract the first element from the array if it exists
    description = parsedDescription.length > 0 ? parsedDescription[0] : "";
  } catch (error) {
    console.error("Error parsing JSON:", error);
    description = ""; // Default value in case of error
  }

  console.log("Description  ----------------------------->  ",description);
  
  // Function to split the description into chunks of 5 words
  const splitDescription = (desc) => {
    const words = desc.split(' ');
    let result = [];
    for (let i = 0; i < words.length; i += 5) {
      result.push(words.slice(i, i + 5).join(' '));
    }
    return result;
  };
  
  const descriptionLines = splitDescription(description);
  const onMouseLeavehandler = () => {
    setIsHovered(false);
  };

  const onHidePortal = () => {
    setLoginPortal(false);
  };
  // const onClickhandle = () => {
  //     window.scrollTo(0, 0);
  //     // navigate('/product');
  //     // navigate(`/productidsecond/${id}`);

  //     console.log(id,"Show Product")
  //     onProductBlockNavigate(product)
  // }
  const onClickhandle = (product) => {
    // Destructure the product to get the required details
    const { id, price, gst_amount, total_amount, type, name } = product;

    // Log the required details
    console.log("When Get Started button is clicked ------------------- >");
    console.log("GST amount   :", gst_amount);
    console.log("Product Name:", name);
    console.log("Product Type:", type);
    console.log("Product Type:", price);
    console.log("Product Total Amount:", total_amount);

    // Handle the navigation if logged in
    if (!loggedIn) {
      setLoginPortal(true);
      return;
    } else {
      navigate("/cartpageofamc", {
        state: {
          product,
          selectedCategory,
          firstname,
          selectedLocation,
          serialNo,
        },
      });
      // navigate('/cartpageofamcrough', { state: { product } });
    }
  };

  const getProducts = () => {
    axios
      .post("http://3.111.236.154:9003/fetchAMCbasedontype", {
        type: AMC_BASED_ON_TYPE,
        plan_type:"PM"
      })
      .then((response) => {
        setProducts(response.data.jsondata);
        // navigate('/annual', { state: { products } });
      })
      .catch((error) => {
        // console.error('Error fetching data:', error);
      });
  };
  // console.log('Annual getProductss ==>>',products)

  useEffect(() => {
    getProducts();
  }, [AMC_BASED_ON_TYPE,]);

  const learnMoreFunction = () => {
    window.scrollTo(0, 0);
    onProductBlockNavigate(product);
  };

  // console.log("See what is inside product ", products);
  // products.forEach((product) => {
  //   console.log("Product ID:", product.id);
  //   console.log("Product Name:", product.name);
  //   console.log("Product Price:", product.price);
  // });

  // console.log("productData---->", productData?.productData);
  return (
    <Container
      className={`productblockmaindiv h-470 w-390  ${
        isHovered ? "hovered" : ""
      }`}
      onMouseEnter={onMouseEnterhandler}
      onMouseLeave={onMouseLeavehandler}
    >
      <div className=" d-flex pb-2">
        <div className="totalbadgediv">
          <div className="firstbagediv">
            <span className="firstbagedicproductplan">{product.plan}</span>
          </div>
          {/* <div className='secondtbagediv'>
         <span className=''>₹{product.price}</span>
         </div> */}
          <div className="thirdbagediv">
            <span className="productpriceofamc">₹{product.price}</span>
          </div>
        </div>
        <div className="imgdiv">
          <img src={imageUrl} alt={imageUrl} />
        </div>
      </div>
      <div className="row pt-2">
        <div className="col col-12 pr-4">
          <p className="break-line"></p>
        </div>
        <div className="buttongetstarted col col-10">
          <Button
            /* message={"Get Started"} */
            message={"Buy AMC"}
            callFunction={() => onClickhandle(product)}
          />
          <p className="break-line"></p>
        </div>
        <div className="col-lg-6 col-6 d-flex">
          {/* <Correct fill={"green"} /><p className='pl-1 heading-400-14-12'>Remote Assist</p>  */}
        </div>

        <div className="col-lg-6 col-6 d-flex">
          {/* <Correct  fill={"green"} /><p className='pl-1 heading-400-14-12'>Safety Checks</p>
           */}
        </div>
        <div className="col-lg-6 col-6 d-flex">
         <p className="pl-1 heading-400-14-12 kjbvsfb">
         
          
          <span className="description-text">
          <Correct />   &nbsp;  {description}
            </span></p>


          {/* <p className="pl-1 heading-400-14-12 kjbvsfb">
    <Correct />
    {descriptionLines.map((line, index) => (
      <span key={index}>
        {line}
        <br />&nbsp;
      </span>
    ))}
  </p> */}
          
        </div>

        <div className="col-lg-6 col-6 d-flex">
          {/* <Correct fill={"green"} /> <p className='pl-1 heading-400-14-12'></p>  */}
        </div>
        <div className="col-lg-6 col-6 d-flex">
         
          <p className="pl-1 heading-400-14-12 kjbvsfb">
          <Correct />
            {" "}
            Preventive Maintenance
          </p>
          {/* <Correct  fill={"green"}/> <p className='pl-1 heading-400-14-12'>Evolve program</p>  */}
        </div>
        {/* <div className='col-lg-6 col-6 d-flex op-60'>
     <Correct  /> <p className='pl-1 heading-400-14-12'>Preventive <br/> Maintenance</p> 
     </div>
     <div className='col-lg-6 col-6 d-flex op-60'>
     <Correct  /> <p className='pl-1 heading-400-14-12'>Preventive <br/> Maintenance</p> 
     </div> */}
      </div>

      {/* <div className='col-lg-6 col-6 d-flex'>
       <Correct fill={isHovered ? "#FFFFFF":null}/> <p className='pl-1 heading-400-14-12'>Evolve program</p> 
     </div>
     <div className='col-lg-6 col-6 d-flex'>
     <Correct fill={isHovered ? "#FFFFFF":null}/><p className='pl-1 heading-400-14-12'>Safety Checks</p>

     </div>
     <div className='col-lg-6 col-6 d-flex'>
     <Correct fill={isHovered ? "#FFFFFF":null}/><p className='pl-1 heading-400-14-12'>Remote Assist</p> 
     </div>
     <div className='col-lg-6 col-6 d-flex'>
     <Correct fill={isHovered ? "#FFFFFF":null}/> <p className='pl-1 heading-400-14-12'>Quality Checks</p> 
     </div>
     <div className='col-lg-6 col-6 d-flex op-60'>
    <Wrong fill={isHovered ? "#FFFFFF":null} inner={isHovered ? "#000000":null}/> <p className='pl-1 heading-400-14-12'>Preventive <br/> Maintenance</p> 
     </div>
    
     <div className='col-lg-6 col-6 d-flex op-60'>
     <Wrong fill={isHovered ? "#FFFFFF":null} inner={isHovered ? "#000000":null}/><p className='pl-1 heading-400-14-12'>Updates</p> 
     </div>
  
 </div>
 <div className='col col-12 d-flex justify-content-end'>{isHovered && <Button message={"View Plan"} callFunction={onClickhandle} />}</div>
*/}

      <div>
        <div>
          <a className="learnmoreabout" onClick={learnMoreFunction}>
            Know More about {title}{" "}
          </a>
        </div>

        {loginPortal && <LoginModel onHide={onHidePortal} />}
      </div>
    </Container>
  );
};

export default ProductBlock;
