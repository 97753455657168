import React from 'react'
import './Mogilx.css';
import FirstImageDivofMogilix from './ThreeDivsofMogilix/FirstImageDivofMogilix';
import SecondContentDivofMogilix from './ThreeDivsofMogilix/SecondContentDivofMogilix';
import ThirdSummaryDivofMogilix from './ThreeDivsofMogilix/ThirdSummaryDivofMogilix';
const MogilixWebsiteTemplate = () => {
  return (
    <>
   <div className='totalsjvnbrf'>
  <div className="totaldivofmogilixwebsite ">
    <div className="row rowtotalmogilixdiv">
      <div className="col-4 firstcolumnifmogilix">
        <FirstImageDivofMogilix />
      </div>
      <div className="col-5 secondcolumnifmogilix">
        <SecondContentDivofMogilix />
      </div>
      <div className="thirdcolumnifmogilix" style={{width: "22%"}}>
        <ThirdSummaryDivofMogilix />
      </div>
    </div>
  </div>
</div>

</>
  )
}

export default MogilixWebsiteTemplate