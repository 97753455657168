import React from 'react'

const ChatBot = () => {
  return (
    <div className='p-a right-0' style={{bottom:"-50px"}}>
    <img src='/asset/Frame1000004018.png' alt='Frame1000004018.png'/>
    </div>
  )
}

export default ChatBot