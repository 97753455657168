

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import AllServiceColumnOne from "./AllServiceColumnOne";
import AllServiceColumnTwo from "./AllServiceColumnTwo";
import AllServiceColumnThree from "./AllServiceColumnThree";
import "./AllServiceStyles.css";
import Loader from "../../SubComponent/Loader";
import LocationPopup from "./LocationPopup";
import { closeIcon } from "../../../helpers/Icons";
import EditLocationPopup from "./EditLocationPopup";
import { gql } from "@apollo/client";
import client from "../../Services/ServicesPopup/apolloclient";
import LoginModel from "../../Authentication/LoginModel/LoginModel";
import AddressPopup from "./AddressPopup";
import AddressPopupForEdit from "./AddressPopupForEdit";
import EditIcon from "../../SubComponent/EditIcon";

const createMultiService = gql`
  mutation createMultiServiceRequest($requestinput: ServiceRequestInput!) {
    createMultiServiceRequest(requestinput: $requestinput) {
      response
      message
      success
    }
  }
`;
const AllService = ({loadingforall,setloadingforall}) => {
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showThanksPopup, setShowThanksPopup] = useState(false);
  const [showThanksPopupForQuotation, setshowThanksPopupForQuotation] =useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
      console.log("scrollposition :  ",scrollPosition);
    };

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [scrollPosition]);

  const isDesktop = windowWidth > 1073;
  
  const getColumnPosition = (scrollPos) => {
    if (scrollPos <= 456) {
      return "absolute"; // Initial position
    } else {
      return "fixed"; // Fixed position after 456px scroll
    }
  };

  const getColumnTop = (scrollPos) => {
    if (scrollPos <= 456) {
      return "auto";
    } else if (scrollPos <= 1764.800048828125) {
      return "170px";
    } else {
      return "170px"; // Keep the final position instead of reverting
    }
  };

  const desktopStyles = {
    columnOne: {
      position: getColumnPosition(scrollPosition),
      top: getColumnTop(scrollPosition),
      left: 0,
      width: "30%",
      // Fix height after 1764.800048828125px scroll position
      height: scrollPosition > 1764.800048828125 ? "calc(100vh - 70px)" : "auto", // Change as per your design
      overflowY: scrollPosition > 1764.800048828125 ? "auto" : "visible", // Add scrollbar if needed
      transform: scrollPosition > 1764.800048828125 ? `translateY(${1764.800048828125 - scrollPosition}px)` : "none",
    },
    
    columnTwo: {
      marginLeft: "30%",
      width: "40%",
      minHeight: "100vh",
    },
  
    columnThree: {
      position: getColumnPosition(scrollPosition),
      top: getColumnTop(scrollPosition),
      right: 0,
      width: "30%",
      // Set a fixed height to 1764.800048828125px when exceeded
      height: scrollPosition > 1764.800048828125 ? "calc(100vh - 70px)" : "calc(100vh - 70px)", // Prevent height increase
      overflowY: scrollPosition > 1764.800048828125 ? "auto" : "visible", // Enable scrollbar for scrolling
      transform: scrollPosition > 1764.800048828125 ? `translateY(${1764.800048828125 - scrollPosition}px)` : "none",
    },
  };
  
  const mobileStyles = {
    columnOne: {},
    columnTwo: {},
    columnThree: {},
  };

  const styles = isDesktop ? desktopStyles : mobileStyles;

  const multiplelocations = [
    {
      title: "Chennai",
      img: "https://thumbs.dreamstime.com/b/puratchi-thalaivar-dr-mgr-central-railway-station-chennai-india-tamilnadu-beautiful-view-day-light-blue-say-183608328.jpg",
    },
    {
      title: "Mumbai",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuOwjKtpRcPyzXGhHWx4UHDw1Z84k-hhYL8Q&s",
    },
    {
      title: "Bangalore",
      img: "https://www.fabhotels.com/blog/wp-content/uploads/2018/06/Shopping_1000x650_SubHeadImages_210618.jpg",
    },
    {
      title: "Pune",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQt_bJoDMPdiupRKe42R2q1xFVyRpzuESDshQ&s",
    },
  ];

  const [showLocationPopup, setShowLocationPopup] = useState();
  const [showCartPopup, setShowCartPopup] = useState();
  const [hoveredButton, setHoveredButton] = useState(null);
  const selectedMpAddressId = localStorage.getItem("defaultMpAddressId");

  const [savedLocations, setSavedLocations] = useState({});
  const [selectedServiceType, setSelectedServiceType] = useState("PM");
  const [cartItems, setCartItems] = useState([]);
  const [amcData, setAmcData] = useState([]);
  const [buttonData, setButtonData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [planToAdd, setPlanToAdd] = useState(() => {
    // Retrieve the stored plan from localStorage on initial load
    const savedPlan = localStorage.getItem("planToAdd");
    return savedPlan ? JSON.parse(savedPlan) : null; // Parse it back to object
  });
  const currentLocation = savedLocations[selectedServiceType];
  const [showEditLocationPopup, setShowEditLocationPopup] = useState(false);
  const [currentEditingItem, setCurrentEditingItem] = useState(null);
  const [draftItems, setDraftItems] = useState([]);
  // Function to open the popup when triggered from ColumnThreeKarthik
  const handleEditLocationClick = (item) => {
    setCurrentEditingItem(item);
    setShowEditLocationPopup(true);
  };

  const [isAddressPopupOpen, setShowAddressPopup] = useState(false);
  const handleAddressPopup = () => {
    setShowAddressPopup(true);
  };

  const [isAddressPopupOpenForEdit, setisAddressPopupOpenForEdit] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const handleAddressPopupforedit = (item) => {
    setShowCartPopup(false);
    setSelectedItem(item); // Save the item for the popup
    console.log("Selected item details: ", item);
    setisAddressPopupOpenForEdit(true);
  };

  const closeAddressPopup = () => {
    setShowAddressPopup(false);
    setisAddressPopupOpenForEdit(false);
  };

  const closeEditLocationPopup = () => {
    setShowEditLocationPopup(false);
    setCurrentEditingItem(null);
  };

  const handleMouseEnter = (button) => {
    setHoveredButton(button);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const editLocationSelect = (newLocation) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.amc_plan_id === currentEditingItem.amc_plan_id
          ? { ...item, location: newLocation }
          : item
      )
    );
    closeEditLocationPopup(); // Close the popup after selecting a location
  };

  const handleRequestForQuotation = () => {};

  useEffect(() => {
    fetchAMCData(selectedServiceType);
  }, [selectedServiceType]);

  const fetchAMCData = async (planType) => {
    // console.log("After the handleServiceType is called then  the loading is about to happen in fetchAMCData  ");
    // setLoading(true);
    try {
      const response = await axios.post(
        "https://devextension.origa.market/api/getamcmasterbytype",
        {
          type: "",
          plan_type: planType,
        }
      );
      if (response.data.status === "Success") {
        setAmcData(response.data.jsondata);
        const uniqueTypes = [
          ...new Set(response.data.jsondata.map((item) => item.type)),
        ];
        const buttonData = uniqueTypes.map((type) => {
          const item = response.data.jsondata.find(
            (item) => item.type === type
          );
          return { label: type, value: type, img: item.plan_image };
        });
        setButtonData(buttonData);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (err) {
      console.error("Error fetching data", err);
    } finally {
      // setLoading(false);
      // console.log("Now loading is set to false as fetchAmcData is completed   ");
    }
  };

  const [selectedMachine, setSelectedMachine] = useState("CNC Turning");

  // Refs to store the references for each type in ColumnTwo
  const typeRefs = useRef({}); // Object to store refs for different types
  const handleSelectMachine = (machine) => {
    setSelectedMachine(machine);
    console.log("First of all the button is selected : ");

    // Scroll to the corresponding type in ColumnTwo if it exists
    if (typeRefs.current[machine]) {
      const offset = 160; // Adjust this value to set how much above the element you want to scroll
      const elementPosition =
        typeRefs.current[machine].getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset; // Subtract offset from the position

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const handleEditLocation = () => {
    setShowLocationPopup(true);
  };
  const [loginPortal, setLoginPortal] = useState(false);
  const onHidePortal = () => {
    setLoginPortal(false);
  };

  useEffect(() => {
    const loggedin = localStorage.getItem("userToken");
    if (!loggedin) {
      localStorage.removeItem("number");
    }
  }, []);

  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://devextension.origa.market/api/fetchcustomeraddress",
          {
            id: localStorage.getItem("id"),
          }
        );
        console.log("API Response:", response.data);
        setData(response?.data?.response?.data?.user?.addresses);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const getDraftItemsForService = async () => {
      const phoneNumberForPayload = localStorage.getItem("number");
      const payload = {
        requestinput: {
          servicerequestjson: [],
          phonenumber: phoneNumberForPayload,
        },
      };

      setLoading(true); // Start loading before the API call

      try {
        const { data } = await client.mutate({
          mutation: createMultiService,
          variables: payload,
        });
        console.log("API response:", data);
        const fetchedItems = data.createMultiServiceRequest.response;
        setDraftItems(fetchedItems);

        // Assuming the address comes along with each draft item
        const fetchedAddresses = fetchedItems.map((item) => item.address);
        setAddresses(fetchedAddresses);
      } catch (error) {
        console.error("Error fetching draft items:", error);
        setError(error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    getDraftItemsForService();
  }, []); // Only run once on mount

  // Effect to log updated draftItems whenever they change
  useEffect(() => {
    console.log("Updated draft items:", draftItems);
  }, [draftItems]); // This runs whenever draftItems changes
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState(null);

  const [selectedLocationfromapi, setSelectedLocationfromapi] = useState(null);

  useEffect(() => {
    console.log("Selected Location updated: ", selectedLocation);
  }, [selectedLocation]);

  const handleAddToCart = (plan) => {
    const loggedin = localStorage.getItem("userToken");

    if (!loggedin) {
      console.log("Not logged in, showing the Login portal");
      // Store the plan in localStorage
      localStorage.setItem("storedPlan", JSON.stringify(plan));
      setLoginPortal(true);
      console.log("Stored plan for later:", plan);
      return;
    }

    console.log("User is logged in, adding the plan directly:", plan);
    continueAddToCartProcess(plan);
  };

  const continueAddToCartProcess = (plan) => {
    setPlanToAdd(plan); // Set the plan to be added
    console.log("Adding the plan (user logged in):", plan.id);
    // setShowLocationPopup(true);
    setShowAddressPopup(true);
  };

  const handleLoginSuccess = () => {
    console.log("User logged in successfully");
    setLoginPortal(false);
    setIsUserLoggedIn(true);

    // Clear the stored plan from localStorage after successful login
    localStorage.removeItem("storedPlan");
  };
  useEffect(() => {
    const loggedin = localStorage.getItem("userToken");
    console.log("Checking login status inside useEffect:", loggedin);

    if (loggedin) {
      console.log("User is logged in, checking for stored plan:");
      const storedPlan = localStorage.getItem("storedPlan");
      if (storedPlan) {
        const plan = JSON.parse(storedPlan);
        setPlanToAdd(plan); // Store the plan to be used after login
        continueAddToCartProcess(plan);
        localStorage.removeItem("storedPlan");
      } else {
        console.log("No plan stored to add after login");
      }
    } else {
      console.log("User is not logged in");
    }
  }, [isUserLoggedIn]);

  // Handle location selection
  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
    setShowLocationPopup(false);
    setShowAddressPopup(true);
  };

  // Handle closing location popup
  const closeLocationPopup = (location) => {
    if (location) {
      setSelectedLocation(location); // Store the selected location for later use
    }
    setShowLocationPopup(false); // Close Location Popup
  };

  // Handle saving after address selection
  const handleSaveAfterAddressSelection = async (selectedAddresspart) => {
    window.scrollTo(0, 0);

    try {
      const isItemInCart = cartItems.some(
        (item) => item.amc_plan_id === planToAdd.amc_plan_id
      );

      // Call the API with the saved location and selected address
      setLoading(true);
      await savemultiservicerequest(selectedAddresspart);
      addItemToCart(planToAdd, isItemInCart, selectedAddresspart);
      setPlanToAdd(null); // Reset plan to add
    } catch (error) {
      console.error("Error during request or adding to cart:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveAfterAddressSelectionForEdit = async (
    selectedAddress,
    selectedItem
  ) => {
    setLoading(true);

    try {
      // Fetch selected address from localStorage
      const selectedAddress = JSON.parse(
        localStorage.getItem("selectedAddress")
      ); // Parsing the stringified object from localStorage
      const selectedMpAddressId = localStorage.getItem("selectedMpAddressId");

      console.log("Selected Address from LocalStorage:", selectedAddress);
      console.log("Selected item:", selectedItem);

      // Prepare the payload using the selectedAddress and selectedItem
      const payload = {
        requestinput: {
          servicerequestjson: [
            {
              id: selectedItem.id,
              customerid: selectedItem.customer_id,
              firstname: selectedItem.first_name,
              lastname: selectedItem.last_name,
              emailid: selectedItem.email_id,
              phonenumber: selectedItem.phone_number,
              machinename: selectedItem.machine_name || "",
              machineproblem: selectedItem.machine_problem || "",
              servicelocation: selectedItem.service_location || "",
              description: selectedItem.description || "",
              servicetype: selectedItem.service_type,
              factoryname: selectedAddress.factoryName || "", // Fetched from localStorage
              machinecategory: selectedItem.machine_category || "",
              modelname: selectedItem.model_name || "",
              mfgyear: selectedItem.mfg_year || null,
              machineworkingcondition:
                selectedItem.machine_working_condition || "",
              machineinwarranty: selectedItem.machine_in_warranty || "",
              needamc: selectedItem.need_amc || "",
              requesttype: selectedItem.request_type || "",
              pincode: selectedAddress.input?.postalCode || null,
              serialno: selectedItem.serial_no || "",
              companyname: selectedAddress.input?.companyName || "",
              address: {
                id: selectedAddress.id || "",
                input: {
                  city: selectedAddress.input?.city || "",
                  phone: selectedAddress.input?.phone || "",
                  country: selectedAddress.input?.country || "",
                  cityArea: selectedAddress.input?.cityArea || "",
                  lastName: selectedAddress.input?.lastName || "",
                  firstName: selectedAddress.input?.firstName || "",
                  postalCode: selectedAddress.input?.postalCode || "",
                  companyName: selectedAddress.input?.companyName || "",
                  countryArea: selectedAddress.input?.countryArea || "",
                  streetAddress1: selectedAddress.input?.streetAddress1 || "",
                  streetAddress2: selectedAddress.input?.streetAddress2 || "",
                },
                businessaddress: {
                  city: selectedAddress.businessaddress?.city || "",
                  phone: selectedAddress.businessaddress?.phone || "",
                  country: selectedAddress.businessaddress?.country || "IN",
                  cityArea: selectedAddress.businessaddress?.cityArea || "",
                  lastName: selectedAddress.businessaddress?.lastName || "",
                  firstName: selectedAddress.businessaddress?.firstName || "",
                  postalCode: selectedAddress.businessaddress?.postalCode || "",
                  companyName:
                    selectedAddress.businessaddress?.companyName || "",
                  countryArea:
                    selectedAddress.businessaddress?.countryArea || "",
                  streetAddress1:
                    selectedAddress.businessaddress?.streetAddress1 || "",
                  streetAddress2:
                    selectedAddress.businessaddress?.streetAddress2 || "",
                },
                gst_no: selectedAddress.gst_no || "",
                factoryName: selectedAddress.factoryName || "",
              },
              appamcplanid: selectedItem.app_amc_plan_id,
              appamcplanname: selectedItem.app_amc_plan_name,
              quantity: selectedItem.quantity,
              status: "Draft",
              mp_address_id: selectedMpAddressId, // Fetched from localStorage
            },
          ],
          phonenumber: selectedItem.phone_number,
        },
      };

      // Make the API request with the prepared payload
      const { data } = await client.mutate({
        mutation: createMultiService,
        variables: payload,
      });

      console.log("API Response:", data);

      // Handle the response and reload the page after processing
      setLoading(false);
      setShowAddressPopup(false);
      window.location.reload(); // Reload the page
    } catch (error) {
      console.error("Error in saving service request:", error);
      setLoading(false);
    }
  };

  const handleIncreaseItem = async (item) => {
    setLoading(true);
    window.scrollTo(0, 0);
    try {
      const newQuantity = item.quantity + 1;

      // Check if draftItems is defined
      if (!Array.isArray(draftItems)) {
        console.error("draftItems is not an array");
        return;
      }

      const draftItem = draftItems.find((i) => i.id === item.id);

      if (!draftItem) {
        console.error("Draft item not found");
        return;
      }

      // Ensure address exists before accessing
      const address = draftItem.address || {}; // Access the address directly
      console.log("Draft Item:", draftItem); // Log draft item
      console.log("Address:", address); // Log address

      const payload = {
        requestinput: {
          servicerequestjson: [
            {
              id: draftItem.id,
              customerid: draftItem.customer_id,
              firstname: draftItem.first_name,
              lastname: draftItem.last_name,
              emailid: draftItem.email_id,
              phonenumber: draftItem.phone_number,
              machinename: draftItem.machine_name || "",
              machineproblem: draftItem.machine_problem || "running issue",
              servicelocation: draftItem.service_location || "",
              description: draftItem.description || "",
              servicetype: draftItem.service_type || "Breakdown",
              factoryname: draftItem.factory_name || "",
              machinecategory: draftItem.machine_category || "",
              modelname: draftItem.model_name || "",
              mfgyear: draftItem.mfg_year || null,
              machineworkingcondition:
                draftItem.machine_working_condition || "Yes",
              machineinwarranty: draftItem.machine_in_warranty || "Yes",
              needamc: draftItem.need_amc || "Yes",
              requesttype: draftItem.request_type || "Request Callback",
              pincode: draftItem.pincode || null,
              serialno: draftItem.serial_no || "",
              companyname: draftItem.company_name || "",
              address: {
                id: draftItem.customer_id,
                input: {
                  city: draftItem.address?.input?.city || "",
                  cityArea: draftItem.address?.input?.cityArea || "",
                  companyName: draftItem.address?.input?.companyName || "",
                  country: draftItem.address?.input?.country || "",
                  countryArea: draftItem.address?.input?.countryArea || "",
                  firstName: draftItem.address?.input?.firstName || "",
                  lastName: draftItem.address?.input?.lastName || "",
                  gst: draftItem.address?.gst_no || "",
                  streetAddress1:
                    draftItem.address?.input?.streetAddress1 || "",
                  streetAddress2:
                    draftItem.address?.input?.streetAddress2 || "",
                },
                businessaddress: {
                  city: draftItem.address?.businessaddress?.city || "",
                  cityArea: draftItem.address?.businessaddress?.cityArea || "",
                  companyName:
                    draftItem.address?.businessaddress?.companyName || "",
                  country: draftItem.address?.businessaddress?.country || "",
                  countryArea:
                    draftItem.address?.businessaddress?.countryArea || "",
                  firstName:
                    draftItem.address?.businessaddress?.firstName || "",
                  lastName: draftItem.address?.businessaddress?.lastName || "",
                  gst: draftItem.address?.businessaddress?.gst || "",
                  streetAddress1:
                    draftItem.address?.businessaddress?.streetAddress1 || "",
                  streetAddress2:
                    draftItem.address?.businessaddress?.streetAddress2 || "",
                },
              },
              appamcplanid: draftItem.app_amc_plan_id,
              appamcplanname: draftItem.app_amc_plan_name,
              status: "Draft",
              quantity: newQuantity, // Set quantity to 0 for deletion
              mp_address_id: draftItem.mp_address_id || null,
            },
          ],
          phonenumber: draftItem.phone_number,
        },
      };

      // Log the payload before the mutation
      console.log("Payload for mutation:", JSON.stringify(payload, null, 2)); // Pretty print payload

      // Wait for 2 seconds before executing the mutation
      await new Promise((resolve) => setTimeout(resolve, 2000));

      window.scrollTo(0, 0);

      const { data } = await client.mutate({
        mutation: createMultiService,
        variables: payload,
      });

      // Log the response data
      console.log("Response Data:", data);

      const responseItems = data.createMultiServiceRequest.response;

      if (!responseItems || responseItems.length === 0) {
        console.error("No response items found");
        return;
      }

      const updatedItem = responseItems[0];

      setDraftItems((prevItems) =>
        prevItems.map((i) =>
          i.app_amc_plan_id === item.app_amc_plan_id
            ? { ...i, quantity: newQuantity }
            : i
        )
      );

      console.log(
        `Increased item: ${draftItem.id}, new quantity: ${newQuantity}`
      );
    } catch (error) {
      console.error("Error updating item:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDecreaseItem = async (item) => {
    setLoading(true);
    window.scrollTo(0, 0);
    try {
      // Calculate new quantity
      const newQuantity = item.quantity - 1;

      // Check if draftItems is defined
      if (!Array.isArray(draftItems)) {
        console.error("draftItems is not an array");
        return;
      }

      const draftItem = draftItems.find((i) => i.id === item.id);
      console.log("the draftItem id to be deleted : ", draftItem.id);
      console.log("the item id to be deleted : ", item.id);
      if (!draftItem) {
        console.error("Draft item not found");
        return;
      }

      // Ensure address exists before accessing
      const address = draftItem.address || {}; // Access the address directly
      console.log("Draft Item:", draftItem); // Log draft item
      console.log("Address:", address); // Log address

      const payload = {
        requestinput: {
          servicerequestjson: [
            {
              id: draftItem.id,
              customerid: draftItem.customer_id,
              firstname: draftItem.first_name,
              lastname: draftItem.last_name,
              emailid: draftItem.email_id,
              phonenumber: draftItem.phone_number,
              machinename: draftItem.machine_name || "",
              machineproblem: draftItem.machine_problem || "running issue",
              servicelocation: draftItem.service_location || "",
              description: draftItem.description || "",
              servicetype: draftItem.service_type || "Breakdown",
              factoryname: draftItem.factory_name || "",
              machinecategory: draftItem.machine_category || "",
              modelname: draftItem.model_name || "",
              mfgyear: draftItem.mfg_year || null,
              machineworkingcondition:
                draftItem.machine_working_condition || "Yes",
              machineinwarranty: draftItem.machine_in_warranty || "Yes",
              needamc: draftItem.need_amc || "Yes",
              requesttype: draftItem.request_type || "Request Callback",
              pincode: draftItem.pincode || null,
              serialno: draftItem.serial_no || "",
              companyname: draftItem.company_name || "",
              address: {
                id: draftItem.customer_id,
                input: {
                  city: draftItem.address?.input?.city || "",
                  cityArea: draftItem.address?.input?.cityArea || "",
                  companyName: draftItem.address?.input?.companyName || "",
                  country: draftItem.address?.input?.country || "",
                  countryArea: draftItem.address?.input?.countryArea || "",
                  firstName: draftItem.address?.input?.firstName || "",
                  lastName: draftItem.address?.input?.lastName || "",
                  gst: draftItem.address?.gst_no || "",
                  streetAddress1:
                    draftItem.address?.input?.streetAddress1 || "",
                  streetAddress2:
                    draftItem.address?.input?.streetAddress2 || "",
                },
                businessaddress: {
                  city: draftItem.address?.businessaddress?.city || "",
                  cityArea: draftItem.address?.businessaddress?.cityArea || "",
                  companyName:
                    draftItem.address?.businessaddress?.companyName || "",
                  country: draftItem.address?.businessaddress?.country || "",
                  countryArea:
                    draftItem.address?.businessaddress?.countryArea || "",
                  firstName:
                    draftItem.address?.businessaddress?.firstName || "",
                  lastName: draftItem.address?.businessaddress?.lastName || "",
                  gst: draftItem.address?.businessaddress?.gst || "",
                  streetAddress1:
                    draftItem.address?.businessaddress?.streetAddress1 || "",
                  streetAddress2:
                    draftItem.address?.businessaddress?.streetAddress2 || "",
                },
              },
              appamcplanid: draftItem.app_amc_plan_id,
              appamcplanname: draftItem.app_amc_plan_name,
              status: "Draft",
              quantity: newQuantity, // Set quantity to 0 for deletion
              mp_address_id: draftItem.mp_address_id || null,
            },
          ],
          phonenumber: draftItem.phone_number,
        },
      };

      // Log the payload before the mutation
      console.log("Decrease Item Payload:", payload);

      // Wait for 2 seconds before executing the mutation
      await new Promise((resolve) => setTimeout(resolve, 2000));

      window.scrollTo(0, 0);

      const { data } = await client.mutate({
        mutation: createMultiService,
        variables: payload,
      });

      // Log the API response
      console.log("API response:", data);

      // Update the draft items based on the new quantity
      if (newQuantity > 0) {
        setDraftItems((prevItems) =>
          prevItems.map((i) =>
            i.app_amc_plan_id === item.app_amc_plan_id
              ? { ...i, quantity: newQuantity }
              : i
          )
        );
        console.log(
          `Decreased item: ${draftItem.id}, new quantity: ${newQuantity}`
        );
      } else {
        setDraftItems((prevItems) =>
          prevItems.filter((i) => i.app_amc_plan_id !== item.app_amc_plan_id)
        );
        console.log(`Removed item: ${item.app_amc_plan_id}`);
      }
    } catch (error) {
      console.error("Error updating item:", error);
    } finally {
      setLoading(false);
    }
  };

  const openCartPopup = () => {
    setShowCartPopup(true);
    document.body.classList.add("no-scroll"); // Add this line
  };

  const closeCartPopupf = () => {
    setShowCartPopup(false);
    document.body.classList.remove("no-scroll"); // Add this line
  };
  const handleDeleteitem = async (item) => {
    window.scrollTo(0, 0);
    setLoading(true);
    try {
      if (!Array.isArray(draftItems)) {
        console.error("draftItems is not an array");
        return;
      }

      console.log("Draft Items: ", draftItems);

      const draftItem = draftItems.find((i) => i.id === item.id);
      console.log("DraftItem to be deleted: ", draftItem);

      console.log("the draftItem id to be deleted: ", draftItem.id);
      console.log("the item id to be deleted: ", item.id);

      if (!draftItem) {
        console.error("Draft item not found");
        return;
      }

      const payload = {
        requestinput: {
          servicerequestjson: [
            {
              id: draftItem.id,
              customerid: draftItem.customer_id,
              firstname: draftItem.first_name,
              lastname: draftItem.last_name,
              emailid: draftItem.email_id,
              phonenumber: draftItem.phone_number,
              machinename: draftItem.machine_name || "",
              machineproblem: draftItem.machine_problem || "running issue",
              servicelocation: draftItem.service_location || "",
              description: draftItem.description || "",
              servicetype: draftItem.service_type || "Breakdown",
              factoryname: draftItem.factory_name || "",
              machinecategory: draftItem.machine_category || "",
              modelname: draftItem.model_name || "",
              mfgyear: draftItem.mfg_year || null,
              machineworkingcondition:
                draftItem.machine_working_condition || "Yes",
              machineinwarranty: draftItem.machine_in_warranty || "Yes",
              needamc: draftItem.need_amc || "Yes",
              requesttype: draftItem.request_type || "Request Callback",
              pincode: draftItem.pincode || null,
              serialno: draftItem.serial_no || "",
              companyname: draftItem.company_name || "",
              address: {
                id: draftItem.customer_id,
                input: {
                  city: draftItem.address?.input?.city || "",
                  cityArea: draftItem.address?.input?.cityArea || "",
                  companyName: draftItem.address?.input?.companyName || "",
                  country: draftItem.address?.input?.country || "",
                  countryArea: draftItem.address?.input?.countryArea || "",
                  firstName: draftItem.address?.input?.firstName || "",
                  lastName: draftItem.address?.input?.lastName || "",
                  gst: draftItem.address?.gst_no || "",
                  streetAddress1:
                    draftItem.address?.input?.streetAddress1 || "",
                  streetAddress2:
                    draftItem.address?.input?.streetAddress2 || "",
                },
                businessaddress: {
                  city: draftItem.address?.businessaddress?.city || "",
                  cityArea: draftItem.address?.businessaddress?.cityArea || "",
                  companyName:
                    draftItem.address?.businessaddress?.companyName || "",
                  country: draftItem.address?.businessaddress?.country || "",
                  countryArea:
                    draftItem.address?.businessaddress?.countryArea || "",
                  firstName:
                    draftItem.address?.businessaddress?.firstName || "",
                  lastName: draftItem.address?.businessaddress?.lastName || "",
                  gst: draftItem.address?.businessaddress?.gst || "",
                  streetAddress1:
                    draftItem.address?.businessaddress?.streetAddress1 || "",
                  streetAddress2:
                    draftItem.address?.businessaddress?.streetAddress2 || "",
                },
              },
              appamcplanid: draftItem.app_amc_plan_id,
              appamcplanname: draftItem.app_amc_plan_name,
              status: "Draft",
              quantity: 0, // Set quantity to 0 for deletion
              mp_address_id: draftItem.mp_address_id || null,
            },
          ],
          phonenumber: draftItem.phone_number,
        },
      };

      console.log("Payload for deletion: ", payload);

      // Execute the mutation
      const { data } = await client.mutate({
        mutation: createMultiService,
        variables: payload,
      });

      console.log("API response after deletion: ", data);

      // Update the success check according to the API response structure
      if (
        data &&
        data.createMultiServiceRequest &&
        data.createMultiServiceRequest.success
      ) {
        setDraftItems((prevItems) =>
          prevItems.filter((i) => i.id !== draftItem.id)
        );
      } else {
        console.error(
          "Failed to delete item: ",
          data.createMultiServiceRequest.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      setLoading(false);
    }
  };

  const savemultiservicerequest = async (
    selectedLocation,
    selectedAddresspart
  ) => {
    window.scrollTo(0, 0);
    setLoading(true);
    console.log(
      "After location is called, this addmultiserviceRequest is called:"
    );

    console.log(
      "Location received in savemultiservicerequest: ",
      selectedLocation
    );
    // Fetch user and service details from localStorage
    const idforservice = localStorage.getItem("id");
    const firstnameforservice = localStorage.getItem("firstName");
    const lastnameforservice = localStorage.getItem("lastName");
    const emailidforservice = localStorage.getItem("emailId");
    const phonenumberforpayload = localStorage.getItem("number");
    const { id, name, plan, plan_type, type, description } = planToAdd || {};
    console.log("", selectedServiceTypeMachineName);
    console.log("id", id);
    console.log("name", name);
    console.log("plan", plan);
    console.log("plan_type", plan_type);
    console.log("type", type);
    // Clean the description from the API
    const cleanDescriptionfromapi = (description || "").replace(/[\[\]"]/g, "");

    // Get the selected address index from localStorage
    const selectedAddressIndex =
      parseInt(localStorage.getItem("selectedAddressIndex"), 10) || 0;

    // Retrieve and parse the selected address from localStorage
    const selectedAddressString = localStorage.getItem("selectedAddress");
    const selectedAddresswsss = selectedAddressString
      ? JSON.parse(selectedAddressString)
      : null;

    console.log("selectedAddressIndex : ", selectedAddressIndex);
    console.log("Selected Address  : ", selectedAddresswsss);

    // Access factory name and other fields safely
    const factorynamefromLocal = selectedAddresswsss?.factoryName || "";
    console.log("factorynamefromLocal : ---->", factorynamefromLocal);

    if (!selectedAddresswsss) {
      console.error("No selected address found");
      return;
    }

    console.log(
      "Selected Location in Hitting the api : ",
      selectedAddresswsss.businessaddress?.city
    );

    // Prepare the payload for the API request
    const payload = {
      requestinput: {
        servicerequestjson: [
          {
            customerid: idforservice,
            firstname: firstnameforservice,
            lastname: lastnameforservice,
            emailid: emailidforservice,
            phonenumber: phonenumberforpayload,
            machinename: type,
            machineproblem: "", // Not present
            servicelocation: selectedAddresswsss.businessaddress?.city,
            description: cleanDescriptionfromapi,
            servicetype:
              selectedServiceTypeMachineName ||
              "Preventive Maintenance" ||
              "Breakdown" ||
              "AMC",
            factoryname: factorynamefromLocal,
            machinecategory: type,
            modelname: "", // Not present
            mfgyear: null,
            machineworkingcondition: "", // Not present
            machineinwarranty: "", // Not present
            needamc: "", // Not present
            requesttype: "", // Not present
            pincode: selectedAddresswsss.input?.postalCode || null, // From selectedAddress
            serialno: "", // Not present
            companyname: selectedAddresswsss.input?.companyName || "", // From selectedAddress
            address: {
              id: selectedAddresswsss.id || "", // Use if `id` is dynamic
              input: {
                city: selectedAddresswsss.input?.city || "",
                phone: selectedAddresswsss.input?.phone || "",
                country: selectedAddresswsss.input?.country || "IN",
                cityArea: selectedAddresswsss.input?.cityArea || "",
                lastName: selectedAddresswsss.input?.lastName || "",
                firstName: selectedAddresswsss.input?.firstName || "",
                postalCode: selectedAddresswsss.input?.postalCode || "",
                companyName: selectedAddresswsss.input?.companyName || "",
                countryArea: selectedAddresswsss.input?.countryArea || "",
                streetAddress1: selectedAddresswsss.input?.streetAddress1 || "",
                streetAddress2: selectedAddresswsss.input?.streetAddress2 || "",
              },
              businessaddress: {
                city: selectedAddresswsss.businessaddress?.city || "",
                phone: selectedAddresswsss.businessaddress?.phone || "",
                country: selectedAddresswsss.businessaddress?.country || "IN",
                cityArea: selectedAddresswsss.businessaddress?.cityArea || "",
                lastName: selectedAddresswsss.businessaddress?.lastName || "",
                firstName: selectedAddresswsss.businessaddress?.firstName || "",
                postalCode:
                  selectedAddresswsss.businessaddress?.postalCode || "",
                companyName:
                  selectedAddresswsss.businessaddress?.companyName || "",
                countryArea:
                  selectedAddresswsss.businessaddress?.countryArea || "",
                streetAddress1:
                  selectedAddresswsss.businessaddress?.streetAddress1 || "",
                streetAddress2:
                  selectedAddresswsss.businessaddress?.streetAddress2 || "",
              },
              gst_no: selectedAddresswsss.gst_no || "",
              factoryName: selectedAddresswsss.factoryName || "",
            },
            appamcplanid: id,
            appamcplanname: plan,
            quantity: 1,
            status: "Draft",
            mp_address_id: localStorage.getItem("selectedMpAddressId"),
          },
        ],
        phonenumber: phonenumberforpayload,
      },
    };

    try {
      window.scrollTo(0, 0);
      // Make the API call using the client.mutate function
      const { data } = await client.mutate({
        mutation: createMultiService,
        variables: payload,
      });

      console.log("API response:", data);

      // Extract and set the selected address from the API response
      const addressFromApi = data.createMultiServiceRequest.selectedAddress;
      setSelectedAddress(addressFromApi);
    } catch (error) {
      console.error("Error creating service request:", error);
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  const handleRequestCallback = async () => {
    window.scrollTo(0, 0);
    console.log("First handle Request for callback function is called: ");

    // Set loading state to true
    setLoading(true);

    // Create the servicerequestjson from draftItems
    const serviceRequestJson = draftItems.map((item) => ({
      id: item.id,
      customerid: item.customer_id,
      firstname: item.first_name,
      lastname: item.last_name,
      emailid: item.email_id,
      phonenumber: item.phone_number,
      machinename: item.machine_name,
      machineproblem: item.machine_problem || "",
      servicelocation: item.service_location,
      description: item.description || "",
      servicetype: item.service_type,
      factoryname: item.factory_name,
      machinecategory: item.machine_category,
      modelname: item.model_name || "",
      mfgyear: item.mfg_year || null,
      machineworkingcondition: item.machine_working_condition || "",
      machineinwarranty: item.machine_in_warranty || "",
      needamc: item.need_amc || "",
      requesttype: item.request_type || "Request Callback",
      pincode: item.pincode || null,
      serialno: item.serial_no || "",
      companyname: item.company_name,
      quantity: item.quantity,
      address: {
        id: item.address.id || "",
        input: {
          city: item.address.input.city || "",
          phone: item.phone_number,
          country: item.address.input.country || "IN",
          cityArea: item.address.input.cityArea || "",
          lastName: item.address.input.lastName || "",
          firstName: item.address.input.firstName || "",
          postalCode: item.address.input.postalCode || "",
          companyName: item.address.input.companyName || "",
          countryArea: item.address.input.countryArea || "",
          streetAddress1: item.address.input.streetAddress1 || "",
          streetAddress2: item.address.input.streetAddress2 || "",
        },
        businessaddress: {
          city: item.address.businessaddress.city || "",
          phone: item.phone_number,
          country: item.address.businessaddress.country || "IN",
          cityArea: item.address.businessaddress.cityArea || "",
          lastName: item.address.businessaddress.lastName || "",
          firstName: item.address.businessaddress.firstName || "",
          postalCode: item.address.businessaddress.postalCode || "",
          companyName: item.address.businessaddress.companyName || "",
          countryArea: item.address.businessaddress.countryArea || "",
          streetAddress1: item.address.businessaddress.streetAddress1 || "",
          streetAddress2: item.address.businessaddress.streetAddress2 || "",
        },
        gst_no: item.address.gst_no || "",
        factoryName: item.address.factoryName || item.factory_name,
      },
      appamcplanid: item.app_amc_plan_id,
      appamcplanname: item.app_amc_plan_name,
      status: "Confirm",
      mp_address_id: item.mp_address_id,
    }));

    console.log("serviceRequestJson: ", serviceRequestJson);

    const payload = {
      requestinput: {
        servicerequestjson: serviceRequestJson,
        phonenumber: localStorage.getItem("number"),
      },
    };
    window.scrollTo(0, 0);

    try {
      const { data } = await client.mutate({
        mutation: createMultiService,
        variables: payload,
      });
      console.log("Callback request API response:", data);

      // Scroll to the top of the page
      window.scrollTo(0, 0);
      // Show the thanks popup
      setShowThanksPopup(true);

      // Close the popup after 3 seconds and reload the page
      setTimeout(() => {
        setShowThanksPopup(false);
        window.location.reload(); // Reload the page
      }, 3000);
    } catch (error) {
      console.error("Error during callback request:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleQuotationRequest = async () => {
    window.scrollTo(0, 0);
    console.log("First handle Request for CallBack function is called: ");

    // Set loading state to true
    setLoading(true);

    // Create the servicerequestjson from draftItems
    const serviceRequestJson = draftItems.map((item) => ({
      id: item.id,
      customerid: item.customer_id,
      firstname: item.first_name,
      lastname: item.last_name,
      emailid: item.email_id,
      phonenumber: item.phone_number,
      machinename: item.machine_name,
      machineproblem: item.machine_problem || "",
      servicelocation: item.service_location,
      description: item.description || "",
      servicetype: item.service_type,
      factoryname: item.factory_name,
      machinecategory: item.machine_category,
      modelname: item.model_name || "",
      mfgyear: item.mfg_year || null,
      machineworkingcondition: item.machine_working_condition || "",
      machineinwarranty: item.machine_in_warranty || "",
      needamc: item.need_amc || "",
      requesttype: "Request for Quote",
      pincode: item.pincode || null,
      serialno: item.serial_no || "",
      companyname: item.company_name,
      quantity: item.quantity,
      address: {
        id: item.address.id || "",
        input: {
          city: item.address.input.city || "",
          phone: item.phone_number,
          country: item.address.input.country || "IN",
          cityArea: item.address.input.cityArea || "",
          lastName: item.address.input.lastName || "",
          firstName: item.address.input.firstName || "",
          postalCode: item.address.input.postalCode || "",
          companyName: item.address.input.companyName || "",
          countryArea: item.address.input.countryArea || "",
          streetAddress1: item.address.input.streetAddress1 || "",
          streetAddress2: item.address.input.streetAddress2 || "",
        },
        businessaddress: {
          city: item.address.businessaddress.city || "",
          phone: item.phone_number,
          country: item.address.businessaddress.country || "IN",
          cityArea: item.address.businessaddress.cityArea || "",
          lastName: item.address.businessaddress.lastName || "",
          firstName: item.address.businessaddress.firstName || "",
          postalCode: item.address.businessaddress.postalCode || "",
          companyName: item.address.businessaddress.companyName || "",
          countryArea: item.address.businessaddress.countryArea || "",
          streetAddress1: item.address.businessaddress.streetAddress1 || "",
          streetAddress2: item.address.businessaddress.streetAddress2 || "",
        },
        gst_no: item.address.gst_no || "",
        factoryName: item.address.factoryName || item.factory_name,
      },
      appamcplanid: item.app_amc_plan_id,
      appamcplanname: item.app_amc_plan_name,
      status: "Confirm",
      mp_address_id: item.mp_address_id,
    }));

    console.log("serviceRequestJson: ", serviceRequestJson);

    const payload = {
      requestinput: {
        servicerequestjson: serviceRequestJson,
        phonenumber: localStorage.getItem("number"),
      },
    };
    window.scrollTo(0, 0);

    try {
      const { data } = await client.mutate({
        mutation: createMultiService,
        variables: payload,
      });
      console.log("Callback request API response:", data);

      // Scroll to the top of the page
      window.scrollTo(0, 0);
      // Show the thanks popup
      setshowThanksPopupForQuotation(true);

      // Close the popup after 3 seconds and reload the page
      setTimeout(() => {
        setshowThanksPopupForQuotation(false);
        window.location.reload(); // Reload the page
      }, 3000);
    } catch (error) {
      console.error("Error during callback request:", error);
    } finally {
      setLoading(false);
    }
  };

  const addItemToCart = (
    plan,
    isItemInCart,
    selectedLocation,
    selectedAddress
  ) => {
    const newItem = {
      ...plan,
      quantity: 1,
      location: selectedLocation,
      serviceTypeMachineName: selectedServiceTypeMachineName,
      selectedAddress: selectedAddress, // Include the selected address
    };

    if (isItemInCart) {
      setCartItems(
        cartItems.map((item) =>
          item.amc_plan_id === plan.amc_plan_id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        )
      );
    } else {
      setCartItems([newItem, ...cartItems]);
    }
  };

  const ViewCartinMobile = () => {
    setShowCartPopup(true);
  };

  const closeCartPopup = () => {
    setShowCartPopup(false);
  };

  const handleRemoveFromCart = (plan) => {
    const existingItem = cartItems.find(
      (item) => item.amc_plan_id === plan.amc_plan_id
    );

    if (existingItem && existingItem.quantity > 1) {
      setCartItems(
        cartItems.map((item) =>
          item.amc_plan_id === plan.amc_plan_id
            ? { ...item, quantity: item.quantity - 1 }
            : item
        )
      );
    } else {
      setCartItems(
        cartItems.filter((item) => item.amc_plan_id !== plan.amc_plan_id)
      );
    }
  };
  const groupedData = amcData.reduce((acc, item) => {
    // console.log("Current item:", item); // Log the current item being processed
    if (!acc[item.type]) {
      acc[item.type] = [];
      // console.log(`Creating new array for type: ${item.type}`); // Log if a new array is created
    }
    acc[item.type].push(item);
    // console.log("Current accumulator state:", acc); // Log the state of the accumulator after pushing the item
    return acc;
  }, {});

  const [selectedServiceTypeMachineName, setSelectedTypeMachineName] = useState(
    "Preventive Maintenance"
  );

  const handleServiceTypeChange = (type, machineName) => {

    console.log("First the loading is about to happen in handleServiceTypeChange ")
    // setLoading(true);

    setSelectedServiceType(type);
    setSelectedTypeMachineName(machineName);
    // console.log("Selected Service type: ", machineName);

    if (typeRefs.current[type]) {
      typeRefs.current[type].scrollIntoView({ behavior: "smooth" });
    }

    // setTimeout(() => {
    //   setLoading(false);
    //   console.log("After data is fetched , then loading is set to false as in handleServiceTypeChange")
    // }, 2000);
  };

  const handleThanksPopup = () => {
    setShowThanksPopup(true); // Correctly call setShowThanksPopup to show the popup
  };
  return (
    <>
      <div className="sdsdddvfbhkfs mt-3 ">
        {/* <button onClick={handleThanksPopup}>
            Thanks Popup
        </button> */}
        <div className="service-type-containeeer">
          <div
            className="service-type-buttons"
            //  style={styles.columnFour}
          >
            <span className="serviceeading">Select Service Type - </span>
            <button
              className={`service-type-button ${
                selectedServiceType === "PM" ? "selected" : ""
              }`}
              onClick={() =>
                handleServiceTypeChange("PM", "Preventive Maintenance")
              }
            >
              Preventive
            </button>
            <button
              className={`service-type-button ${
                selectedServiceType === "BD" ? "selected" : ""
              }`}
              onClick={() => handleServiceTypeChange("BD", "Breakdown")}
            >
              Breakdown
            </button>
            <button
              className={`service-type-button ${
                selectedServiceType === "AMC" ? "selected" : ""
              }`}
              onClick={() => handleServiceTypeChange("AMC", "AMC")}
            >
              AMC
            </button>
          </div>
        </div>
        {loading ? (
          <div className="loader">
            <Loader />
          </div>
        ) : (
          <div
          style={{ position: "relative" }}  
          className={`app-containeregbdg ${isDesktop ? "desktop" : "mobile"}` }
          >
            <div
              className="sidebarsdvfsa text-center columeen columeen-oneeeee"
              style={styles.columnOne}
            >
              <AllServiceColumnOne
                onSelect={handleSelectMachine}
                buttons={buttonData}
              />
            </div>
            <div className="columeen columeen-twvsfo" style={styles.columnTwo}>
              {Object.keys(groupedData).map((type, index) => {
                // Check if the current type is in the specialTypes array
                const specialTypes = [
                  "Custom Machines (CMH)",
                  "Twin Spindle Lathe",
                  "Vertical Turning Lathe (VTL)",
                  "Special Purpose Machine (SPM)",
                ];
                const isSpecialType = specialTypes.includes(type);

                return (
                  <div
                    className="service-type-card"
                    key={index}
                    ref={(el) => {
                      typeRefs.current[type] = el;
                    }}
                  >
                    {/* Only show the heading if the type is NOT a special type */}
                    {!isSpecialType && (
                      <h2 className="typeofsecondcolumn">{type}</h2>
                    )}

                    <div className="plans-wrapper">
                      {groupedData[type].map((plan, idx) => {
                        const matchingDraftItem = draftItems.find(
                          (item) => item.app_amc_plan_id === plan.id
                        );

                        return (
                          <AllServiceColumnTwo
                            key={idx}
                            plan={plan}
                            draftItems={draftItems}
                            cartItems={cartItems}
                            handleIncreaseItem={handleIncreaseItem}
                            handleDecreaseItem={handleDecreaseItem}
                            onAddToCart={(plan) => {
                              handleAddToCart(plan);
                            }}
                            onRemoveFromCart={handleRemoveFromCart}
                            amcData={amcData}
                            isInDraft={!!matchingDraftItem}
                            quantity={
                              matchingDraftItem ? matchingDraftItem.quantity : 0
                            }
                            type={type} // Pass the type to the component
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className="sidebarsdvfsa columeen columeen-threeeee"
              style={styles.columnThree}
            >
              <AllServiceColumnThree
                selectedServiceTypeMachineName={selectedServiceTypeMachineName}
                cartItems={cartItems}
                onAddToCart={handleAddToCart}
                onRemoveFromCart={handleRemoveFromCart}
                savedLocations={savedLocations}
                setSavedLocations={setSavedLocations}
                selectedServiceType={selectedServiceType}
                multiplelocations={multiplelocations}
                onEditLocation={handleEditLocationClick}
                onAddressPopup={handleAddressPopup}
                currentLocation={currentLocation}
                draftItems={draftItems}
                selectedAddress={selectedAddress}
                handleRequestCallback={handleRequestCallback}
                handleQuotationRequest={handleQuotationRequest}
                addresses={addresses}
                handleAddressPopupforedit={handleAddressPopupforedit}
                handleDeleteitem={handleDeleteitem}
              />
            </div>

            {/* <div>
              <button onClick={handleThanksPopup}>Thanks Popup</button>
              </div> */}
          </div>
        )}

        {draftItems.length > 0 && window.innerWidth < 1078 && (
          <div className="bnzdfjbfd ">
            <div className="service-request-buttons">
              <button
                className={`callbackfbdj ${
                  hoveredButton === "button2" ? "hover-effect" : ""
                }`}
                onMouseEnter={() => handleMouseEnter("button1")}
                onMouseLeave={handleMouseLeave}
                onClick={handleRequestCallback}
              >
                Request for call back
              </button>
              <button
                className={`callbackfbdj2 ${
                  hoveredButton === "button1" ? "hover-effect" : ""
                }`}
                onMouseEnter={() => handleMouseEnter("button2")}
                onMouseLeave={handleMouseLeave}
                onClick={handleQuotationRequest}
              >
                Request for quote
              </button>
              <button className="callbackfbdj" onClick={ViewCartinMobile}>
                View Cart
              </button>
            </div>
          </div>
        )}
      </div>
      {showLocationPopup && (
        <div className="popup-overlay">
          <div className="popup-banner">
            <div className="popup-content">
              <LocationPopup
                multiplelocations={multiplelocations}
                currentLocation={currentLocation}
                handleLocationSelect={handleLocationSelect}
                selectedServiceType={selectedServiceType}
                planToAdd={planToAdd} // Pass the plan to LocationPopup
              />
              <div
                className="close-popup"
                onClick={() => setShowLocationPopup(false)}
              >
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}

      {isAddressPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-banner">
            <div className="popup-contentedvbff">
              <AddressPopup
                selectedLocation={selectedLocation}
                closePopupForAddress={closeAddressPopup}
                handleSaveAfterAddressSelection={
                  handleSaveAfterAddressSelection
                }
                planToAdd={planToAdd} // Pass the plan to AddressPopup
              />
              <div className="close-popup" onClick={closeAddressPopup}>
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}

      {isAddressPopupOpenForEdit && (
        <div className="popup-overlay">
          <div className="popup-banner">
            <div className="popup-contentedvbff">
              <AddressPopupForEdit
                selectedLocationfromapi={selectedLocationfromapi}
                closePopupForAddress={closeAddressPopup}
                handleSaveAfterAddressSelectionForEdit={
                  handleSaveAfterAddressSelectionForEdit
                }
                planToAdd={selectedItem}
              />
              <div className="close-popup" onClick={closeAddressPopup}>
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}
      {showEditLocationPopup && (
        <div className="popup-overlay">
          <div className="popup-banner">
            <div className="popup-content">
              <EditLocationPopup
                multiplelocations={multiplelocations}
                currentLocation={currentEditingItem?.location || ""}
                editLocationSelect={editLocationSelect}
                selectedServiceType={selectedServiceType}
                item={currentEditingItem}
                closePopup={closeEditLocationPopup}
              />
              <div className="close-popup" onClick={closeEditLocationPopup}>
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}

      {showCartPopup && !isAddressPopupOpenForEdit && (
        <div className="popup-overlay">
          <div className="popup-banner">
            <div className="popup-content">
              <div className="insidecolummnnone" style={{ textAlign: "left" }}>
                {cartItems.length === 0 && draftItems.length === 0 ? (
                  <div className="cart-empty-full-container">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/11329/11329060.png"
                      className="imagesrbentzdg"
                      alt="empty-cart"
                    />
                    <div className="cart-empty-message">
                      <p className="cartttfsd">Your cart is empty.</p>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="cart-scrollable-container">
                      <div className="cart-details">
                        <span className="boldheadingfor">Cart</span>
                        <div className="mt-3"></div>
                        {draftItems.map((item, index) => {
                          const address = item.address || {}; // Use address from item directly

                          const renderPriceText = (serviceType) => {
                            switch (serviceType) {
                              case "Preventive Maintenance":
                                return (
                                  <div className="price-text">
                                    <div>Rs. 5000 onwards</div>
                                  </div>
                                );
                              case "Breakdown Service":
                              case "Annual Maintenance Contract":
                                return (
                                  <div className="price-text">
                                    <div>Price will be decided</div>
                                    <div>after inspection</div>
                                  </div>
                                );
                              default:
                                return null; // or any default message if needed
                            }
                          };

                          return (
                            <div key={index}>
                              <span className="boldheadingfor">
                                {item.service_type || "Service Type"}
                              </span>{" "}
                              &nbsp;-&nbsp;&nbsp;
                              <span className="boldheadingfor">
                                {item.machine_category || "Machine Category"}
                              </span>
                              <div className="cart-item-details">
                                <div className="description-price-container">
                                  <div className="clean-description">
                                    {item.description}
                                  </div>
                                  {renderPriceText(item?.service_type)}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <span>Quantity : {item.quantity}</span>
                                  </span>
                                </div>
                              </div>
                              {address && ( // Check if the address exists
                                <div>
                                  <div className="d-flex">
                                    {/* Address display */}
                                  </div>
                                  {address.input?.firstName && (
                                    <div>
                                      <span className="headingindelivery">
                                        Name:{" "}
                                      </span>
                                      {address.input.firstName}{" "}
                                      {address.input.lastName}
                                      <br />
                                    </div>
                                  )}
                                  {address.input?.phone && (
                                    <div>
                                      <span className="headingindelivery">
                                        Mobile Number:{" "}
                                      </span>
                                      {address.input.phone}
                                      <br />
                                    </div>
                                  )}
                                  {address.businessaddress?.streetAddress1 && (
                                    <div>
                                      <span className="headingindelivery">
                                        Address:
                                      </span>
                                      <span
                                        onClick={() =>
                                          handleAddressPopupforedit(item)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <EditIcon />
                                      </span>
                                      <br />
                                      {
                                        address.businessaddress.streetAddress1
                                      },{" "}
                                      {address.businessaddress
                                        .streetAddress2 && (
                                        <>
                                          {
                                            address.businessaddress
                                              .streetAddress2
                                          }
                                          ,
                                        </>
                                      )}
                                      {address.businessaddress.countryArea},{" "}
                                      {address.businessaddress.city},{" "}
                                      {address.businessaddress.postalCode}
                                      <br />
                                    </div>
                                  )}
                                </div>
                              )}
                              <br />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="button-container mt-2">
                      <button
                        className={`callbackfbdj ${
                          hoveredButton === "button1" ? "hover-effect" : ""
                        }`}
                        onMouseEnter={() => handleMouseEnter("button2")}
                        onMouseLeave={handleMouseLeave}
                        onClick={handleRequestCallback}
                      >
                        Request for call back
                      </button>
                      <button
                        className={`callbackfbdj2 ${
                          hoveredButton === "button2" ? "hover-effect" : ""
                        }`}
                        onMouseEnter={() => handleMouseEnter("button1")}
                        onMouseLeave={handleMouseLeave}
                        onClick={handleQuotationRequest}
                      >
                        Request for quote
                      </button>
                    </div>
                    <div className="Disclaimer mt-3">
                      <span>
                        For any assistance call&nbsp;
                        <a
                          className="gszrbfdjkbdg"
                          href="tel:8828099099"
                          style={{ textDecoration: "none", color: "blue" }}
                        >
                          8828099099
                        </a>
                        , Mon - Fri, 10 am - 5 pm
                      </span>
                    </div>

                    <div className="Disclaimer mt-2">
                      <span style={{ fontWeight: "bold" }}>
                        View Service Scope:
                      </span>
                      <br />
                      <span>
                        Disclaimer: Final service scope will be determined post
                        inspection
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="close-popup" onClick={closeCartPopup}>
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}

      {showThanksPopup && (
        <div className="  popup-overlay ">
          <div className="popup-banner ">
            <div
              className="popup-content "
              style={{ backgroundColor: "#d2eff1" }}
            >
              <div
                className="differentthankspopupcontnet"
                style={{ backgroundColor: "white" }}
              >
                <h3
                  className="thanksforyourresponse"
                  style={{ fontSize: "28px" }}
                >
                  Thank you for requesting a call back.
                </h3>
                <p className="wewillcontactu">
                  Our team will be in touch within 24 hours.
                </p>
                <br></br>
                <div
                  className="close-popup"
                  onClick={() => setShowThanksPopup(false)}
                >
                  {closeIcon({ width: 14, height: 14 })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showThanksPopupForQuotation && (
        <div className="  popup-overlay ">
          <div className="popup-banner ">
            <div
              className="popup-content "
              style={{ backgroundColor: "#d2eff1" }}
            >
              <div
                className="differentthankspopupcontnet"
                style={{ backgroundColor: "white" }}
              >
                <h3
                  className="thanksforyourresponse"
                  style={{ fontSize: "28px" }}
                >
                  Thank you for requesting a quote.
                </h3>
                <p className="wewillcontactu">We'll respond within 24 hours.</p>
                <br></br>
                <div
                  className="close-popup"
                  onClick={() => setshowThanksPopupForQuotation(false)}
                >
                  {closeIcon({ width: 14, height: 14 })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {loginPortal && (
        <LoginModel onHide={onHidePortal} onSuccess={handleLoginSuccess} />
      )}
    </>
  );
};

export default AllService;
