
import React from "react";

const AllServiceColumnTwo = ({
  plan,
  onAddToCart,
  handleIncreaseItem,
  handleDecreaseItem,
  draftItems,
  type, // Get the type prop
}) => {
  const descriptionText = plan?.description || "";
  const cleanDescription = descriptionText.replace(/[\[\]"]/g, "");

  const matchingDraftItem = draftItems.find(item => String(item.app_amc_plan_id) === String(plan.id));
  const currentQuantity = matchingDraftItem ? matchingDraftItem.quantity : 0;

  // Check if the current type matches the specified types
  const specialTypes = ["Custom Machines (CMH)", "Twin Spindle Lathe", "Vertical Turning Lathe (VTL)", "Special Purpose Machine (SPM)"];
  const isSpecialType = specialTypes.includes(type);

  return (
    <div className="ColumntWOkARTHIK">
      <div className="insideColumnTwo">
        {isSpecialType ? (
          <div className="plan-item-no-description" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <h2 className="typeofsecondcolumn">{type}</h2>
            <button className="service-square-button add-button" style={{ marginLeft: 'auto' }} onClick={() => onAddToCart(plan)}>
              Add
            </button>
          </div>
        ) : (
          <div className="plan-item" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ textAlign: "left", flex: 1 }}>
              <span>{cleanDescription}</span>
            </div>
            <div>
              {currentQuantity > 0 ? (
                <div className="buttonsforquantiy">
                  <button className="quantity-btnsfv" onClick={() => handleDecreaseItem(matchingDraftItem)}>-</button>
                  <span>{currentQuantity}</span>
                  <button className="quantity-btnsfv" onClick={() => handleIncreaseItem(matchingDraftItem)}>+</button>
                </div>
              ) : (
                <button className="service-square-button add-button" onClick={() => onAddToCart(plan)}>Add</button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllServiceColumnTwo;