import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import { secondClient } from "../OrigaExtentionAPI/mutations";
import LoginModel from "../Authentication/LoginModel/LoginModel";
import "./CartPageSampleStyles.css";
import {
  ellipsePurpleIcon,
  ellipseWhiteIcon,
  vectorLineIcon,
} from "../../helpers/Icons";
import CartItemSample from "./CartItemSample";
import CartItemSampleLoggedIn from "./CartItemSampleLoggedIn";
import SummaryCartLoggedIn from "./SummaryCartLoggedIn";
import SummaryCartLoggedNot from "./SummaryCartLoggedNot";
import Loader from "../SubComponent/Loader";

const client = secondClient;

const CartPageSample = () => {
  const navigate = useNavigate();
  const [productDETAILS, setProductDETAILS] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const [loginPortal, setLoginPortal] = useState(false);
  const [loggedin, setLoggedin] = useState(!!localStorage.getItem("userToken"));
  const [isLoading, setIsLoading] = useState(true);
  const [summaryData, setSummaryData] = useState({
    subtotal: 0,
    taxTotal: 0,
    discountTotal: 0,
    discountPercent: 0,
    shippingCharge: 0,
    total: 0,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCartItems();
  }, [loggedin]);

  function getLocalStorageSizeInMB() {
    let totalBytes = 0;
  
    for (let key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        const item = localStorage.getItem(key);
        totalBytes += key.length + (item ? item.length : 0);
      }
    }

    const totalMB = totalBytes / (1024 * 1024);
    return totalMB.toFixed(2); 
  }
  
  console.log("LocalStorage size: ", getLocalStorageSizeInMB(), "MB");
  

  function calculateLocalStorageSize() {
    let totalBytes = 0;
  
    for (let key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        const value = localStorage.getItem(key);
        totalBytes += key.length + (value ? value.length : 0);
      }
    }
  
    // Convert bytes to KB
    const usedKB = totalBytes / 1024;
    return usedKB.toFixed(2); 
  }
  
  console.log("LocalStorage usage: ", calculateLocalStorageSize(), "KB");
  


const fetchCartItems = async () => {
    setIsLoading(true);
    if (loggedin) {
      try {
        const response = await fetchDraftItems();
        setCartItems(response.line_items || []);
        const lineItems = response.line_items;
        localStorage.setItem("CartItemsintheapifromResponse", JSON.stringify(lineItems));
    
        updateSummaryData(response);
      } catch (error) {
        console.error("Error fetching draft items:", error);
      }
    } else {
      const localCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      setCartItems(localCartItems);
      updateSummaryData({ 
        subtotal: localCartItems.reduce((sum, item) => sum + item.salePrice * item.quantity, 0),
        // Add other calculations as needed for non-logged-in users
      });
    }
    setIsLoading(false);
  };

  const fetchDraftItems = async () => {
    const localCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    var itemList = [];
      let bucketId = null;
      for (var item of localCartItems) { // Use 'of' to iterate over array elements
        var obj = {};
        obj["item_id"] = item["productId"];
        console.log("Cart Items While Loggin in handleCreateOrder Function in CartPageSample.jsx")
        console.log("Quantity in localCartitems :  --------------> ", item["quantity"]);
        obj["quantity"] = item["quantity"];
        obj["bucket_id"] = item["bucketId"]; // Use bucket_id
  
        console.log("Bucketid in localCartitems :  --------------> ", item["bucketId"]);
  
        if (bucketId === null) {
          bucketId = item["bucketId"]; // Set bucketId if it's null
        }
  
        itemList.push(obj);
      }
  
   
      const ompuserId = localStorage.getItem("id");
      const customer_first_name = localStorage.getItem("firstName");
      const customer_last_name = localStorage.getItem("lastName");
      const customer_contact_no = localStorage.getItem("number");
      const customer_email = localStorage.getItem("emailId");
  
      const requestBody = {
        items: itemList,
        // Include bucket_id in the request body
        ompuserId,
        customer_first_name,
        customer_last_name,
        customer_contact_no,
        order_status: "Draft",
        order_ref_number: "",
        customer_email,
        input: {
          city: "",
          cityArea: "",
          companyName: "",
          country: "",
          countryArea: "",
          firstName: "",
          lastName: "  ",
          phone: "",
          postalCode: "",
          streetAddress1: " ",
          streetAddress2: "",
        },
        businessaddress: {
          city: "",
          cityArea: "",
          companyName: " ",
          country: "",
          countryArea: "",
          firstName: "",
          lastName: "",
          phone: "",
          postalCode: "",
          streetAddress1: "",
          streetAddress2: "",
        },
        gst_no: "",
      };

    const response = await fetch(
      "https://devextension.origa.market/sparesapi/create_order",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch draft items");
    }

    return response.json();
  };

  const updateSummaryData = (data) => {
    setSummaryData({
      subtotal: data.subtotal || 0,
      taxTotal: data.tax_total || 0,
      discountTotal: data.discount_total || 0,
      discountPercent: data.discount_percent || 0,
      shippingCharge: data.shipping_charge || 0,
      total: data.total || 0,
    });
  };

  const handleCreateOrder = async () => {
    if (!loggedin) {
      setLoginPortal(true);
      return;
    }

    try {
      const response = await fetchDraftItems();
      console.log("Created order with items:", response.line_items);
      localStorage.removeItem('cartItems');
      setCartItems(response.line_items || []);
      updateSummaryData(response);
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  const onLoginSuccess = async () => {
    setLoggedin(true);
    setLoginPortal(false);
    try {
      const response = await fetchDraftItems(); // This will now send local storage items to API
      setCartItems(response.line_items || []);
      updateSummaryData(response);
      localStorage.removeItem('cartItems'); // Clear local storage after successful API update
    } catch (error) {
      console.error("Error updating cart after login:", error);
    }
  };

  const handleProceedPayment = () => {
    if (loggedin) {
       localStorage.setItem("order_Status", "Draft");
      handleCreateOrder();
      navigate("/manageaddresspart");
    } else {
      setLoginPortal(true);
    }
  };

  const handleItemUpdate = async (updatedItem) => {
    setIsLoading(true);
    if (loggedin) {
      try {
        const response = await fetchDraftItems();
        setCartItems(response.line_items || []);
        updateSummaryData(response);
      } catch (error) {
        console.error("Error updating item:", error);
      }
    } else {
      const updatedLocalCart = cartItems.map(item => 
        item.productId === updatedItem.productId ? updatedItem : item
      );
      setCartItems(updatedLocalCart);
      localStorage.setItem("cartItems", JSON.stringify(updatedLocalCart));
      updateSummaryData({ 
        subtotal: updatedLocalCart.reduce((sum, item) => sum + item.salePrice * item.quantity, 0),
        // Add other calculations as needed
      });
    }
    setIsLoading(false);
  };


  const hasCartItems = cartItems && cartItems.length > 0;


  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="totalcartpagebuttop">
        <div className="centertotaldivcart">
          <div className="icon-containere">
            <span className="dilevery-address-1">
              {ellipsePurpleIcon({ width: 32, height: 32 })}
            </span>
            <span className="icon-number">1</span>
          </div>
          <span className="add-to-cart-text">Add to Cart</span>
          <div className="linebetweenicons">
            {vectorLineIcon({ width: 30, height: 1 })}&nbsp;
          </div>
          <div className="icon-containere rsbbref mr-3">
            <span className="order-sumary-2">
              {ellipseWhiteIcon({ width: 32, height: 32 })}
            </span>
            <span className="icon-number">2</span>
          </div>
          <span className="add-to-cart-text">Delivery Address</span>
          <div className="linebetweenicons">
            {vectorLineIcon({ width: 30, height: 1 })}&nbsp;
          </div>
          <div className="icon-containere ">
            <span className="order-sumary-2">
              {ellipseWhiteIcon({ width: 32, height: 32 })}
            </span>
            <span className="icon-number">3</span>
          </div>
          <span className="add-to-cart-text">Payment</span>
        </div>


      </div>




    
{/* 
      <div className="totalcartpage">
        
        <div className="leftsideBordertotaldiv">
          <div className="toppartofcartpage row justify-content-between align-items-center">
            <div className="leftheadingcart col-4">
              <h1 className="leftheadingcarttext">My Cart</h1>
            </div>
            <div className="rightheadingcart col-4">
              <h1 className="rightheadingcarttext">Price</h1>
            </div>
          </div>

          <div className="horizontallineofcartpage">
            <hr className="lineofcartunder" />
          </div>

          <div className="">
            {loggedin ? (
              <CartItemSampleLoggedIn 
                cartItems={cartItems} 
                onItemUpdate={handleItemUpdate}
              />
            ) : (
              cartItems.map((item, index) => (
                <CartItemSample
                  key={index}
                  {...item}
                  onItemUpdate={handleItemUpdate}
                />
              ))
            )}
          </div>
        </div>

        <div className="rightsideBordertotaldiv">
          {loggedin ? (
            <SummaryCartLoggedIn 
              summaryData={summaryData} 
              onProceedPayment={handleProceedPayment}
            />
          ) : (
            <SummaryCartLoggedNot 
              summaryData={summaryData} 
              onProceedPayment={handleProceedPayment}
            />
          )}
        </div>
      </div> */}
      



      <div className="totalcartpage">
      {hasCartItems ? (
        // Show this div when cartItems has items
        <>
          <div className="leftsideBordertotaldiv">
            <div className="toppartofcartpage row justify-content-between align-items-center">
              <div className="leftheadingcart col-4">
                <h1 className="leftheadingcarttext">My Cart</h1>
              </div>
              <div className="rightheadingcart col-4">
                <h1 className="rightheadingcarttext">Price</h1>
              </div>
            </div>

            <div className="horizontallineofcartpage">
              <hr className="lineofcartunder" />
            </div>

            <div>
              {loggedin ? (
                <CartItemSampleLoggedIn
                  cartItems={cartItems}
                  onItemUpdate={handleItemUpdate}
                />
              ) : (
                cartItems.map((item, index) => (
                  <CartItemSample
                    key={index}
                    {...item}
                    onItemUpdate={handleItemUpdate}
                  />
                ))
              )}
            </div>
          </div>

          <div className="rightsideBordertotaldiv">
            {loggedin ? (
              <SummaryCartLoggedIn
                summaryData={summaryData}
                onProceedPayment={handleProceedPayment}
              />
            ) : (
              <SummaryCartLoggedNot
                summaryData={summaryData}
                onProceedPayment={handleProceedPayment}
              />
            )}
          </div>
        </>
      ) : (
        // Show this div when cartItems is empty
        <>
          <div
            className="leftsideBordertotaldiv "
            style={{ width: "90%" ,margin:"0 auto"}}
          >
            <div className="toppartofcartpage row justify-content-between align-items-center">
              <div className="leftheadingcart col-4">
                <h1 className="leftheadingcarttext">My Cart</h1>
              </div>
              <div className="cart-empty-full-container">
          <img
              src="https://cdn-icons-png.flaticon.com/512/11329/11329060.png"
              className="imagesrbentzdg"
              alt="empty-cart"
          />
          <div className="cart-empty-message">
              <p className="cartttfsd">Your cart is empty.</p>
          </div>
      </div>
            </div>

            {/* <div className="horizontallineofcartpage">
              <hr className="lineofcartunder" />
            </div> */}

            <div>
              {loggedin ? (
                <CartItemSampleLoggedIn
                  cartItems={cartItems}
                  onItemUpdate={handleItemUpdate}
                />
              ) : (
                cartItems.map((item, index) => (
                  <CartItemSample
                    key={index}
                    {...item}
                    onItemUpdate={handleItemUpdate}
                  />
                ))
              )}
            </div>
          </div>
        </>
      )}
    </div>
      {loginPortal && (
        <LoginModel 
          onHide={() => setLoginPortal(false)} 
          onLoginSuccess={onLoginSuccess}
        />
      )}
      <Footer />
    </>
  );
};

export default CartPageSample;