import React, { useState, useEffect } from "react";
import MALeftsection from "./MALeftsection";
// import Button from "../../Button/Button";
import Button2 from "../../Button/Button2";
import ButtonOutline from "../../Button/ButtonOutline";
import ButtonOutline2 from "../../Button/ButtonOutline";
import { useLocation, useNavigate } from "react-router-dom";
import AccountFooter from "../AccountFooter";
import Breadcrumbs from "../../SubComponent/Breadcrumbs";
import useAddAddress from "../../SubComponent/useAddAddress";
import { locationIcon } from "../../../helpers/Icons";
import Loader from "../../SubComponent/Loader";
import axios from "axios";
import { gql } from 'graphql-tag';
import client from '../../Services/ServicesPopup/apolloclient'
import {Button,Container } from "react-bootstrap";
import "./MyAddressButton.css";


const CREATE_PAYMENT = gql`
mutation CreateAmc($amcPlanData: AMCPlanInput!) {
  createAmc(amcPlanData: $amcPlanData) {
    message
    success
    paymenturl
    referenceid
  }
}
`;


const AddAddressAmc = () => {

  const [loadinged, setLoadinged] = useState(false);

  useEffect(() => {
window.scrollTo(0,0)
  },[])
    const location = useLocation();
  const navigate = useNavigate();
  const {
    selectedCategory,
    selectedLocation,
    serialNo,
    modellNo,
    id,

    companyName: initialCompanyname,
    factoryName: initialFactoryname,
    firstname: initialFirstname,
    lastname: initialLastname,
    city: initialCity,
    state: initialState,
    secondgstno: initialsecondgstno,
    secondcity: initialsecondcity,
    secondstate: initialsecondstate,
    address1: initialaddress1,
    email: initialemail,
    formattedPhoneNumber:intialformattedPhoneNumber,
    formattedAlterPhoneNumber:intialformattedAlterPhoneNumber,
    pinCode: initialpincode,
    address2: initialaddress2,
    secondpinCode: initialsecondPincode,
    secondaddress1: initialsecondaddress1,
    secondaddress2: initialsecondaddress2,
    productId,
    productName,
    producttype,
    productPrice,
    productPlan,
    productGstAmount,
    productTotalAmount,
    productImage,
    productStatus,
    
  } = location.state || {};

  console.log("Model No in CartPageAmc : ",modellNo)
  const [isBillingSameAsShipping, setIsBillingSameAsShipping] = useState(false);

  const [stateOptions, setStateOptions] = useState([
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ]);
  const matchStateName = (apiState) => {
    return stateOptions.find(
      (option) =>
        option.replace(/\s+/g, "").toLowerCase() ===
        apiState.replace(/\s+/g, "").toLowerCase()
    );
  };

  const [firstname, setfirstname] = useState(initialFirstname);
  const [lastname, setlastname] = useState(initialLastname); 
  // const [phoneNumber, setPhoneNumber] = useState(initialformattedPhonenumber);
  // const [alterPhoneNumber, setAlterPhoneNumber] = useState(initialalteredPhoneNumber);

  
const [formattedPhoneNumber,setFormattedPhoneNumber] = useState(intialformattedPhoneNumber);
const [formattedAlterPhoneNumber,setAlteredFormattedPhoneNumber]=useState(intialformattedAlterPhoneNumber);


  const [pinCode, setPinCode] = useState(initialpincode);
  const [state, setState] = useState(initialState);
  const [address1, setAddress1] = useState(initialaddress1);
  const [address2, setAddress2] = useState(initialaddress2);
  const [city, setCity] = useState(initialCity);
  const [email, setEmail] = useState(initialemail);

  //seperate for billing addresss
  const [secondpinCode, secondsetPinCode] = useState(initialsecondPincode);
  const [secondstate, secondsetState] = useState(initialsecondstate);
  const [secondaddress1, secondsetAddress1] = useState(initialsecondaddress1);
  const [secondaddress2, secondsetAddress2] = useState(initialsecondaddress2);
  const [secondcity, secondsetCity] = useState(initialsecondcity);
  const [secondgstno, secondsetgstno] = useState(initialsecondgstno);
  const [companyName ,setCompanyName ] = useState(initialCompanyname);
  const [factoryName ,setFactoryName ] = useState(initialFactoryname);

  const [SuccessPopUp, setSuccessPopUp] = useState(false);
  const [PaymentLink, setPaymentLink] = useState([]);
  const product = location.state?.product; // Use optional chaining to safely access product
  const productData = location.state && location.state.product;
  const address = location.state?.address;
  
  
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  
  
  const [isSmallScreen] = useState(window.innerWidth <= 576);

  const [validation, setvalidaion] = useState({
    fvalidation: false,
    lvalidation: false,
    evalidation: false,
    MAdd1validation: false,
    MPinValidation: false,
    pvalidation: false,
    alterphovalidation: false,
    statevalidation: false,
    cityvalidation: false,
    companyNameValidation:false,
    factoryNameValidation:false
  });
  const [secondvalidation, setSecondValidation] = useState({
    secondfvalidation: false,
    secondlvalidation: false,
    secondevalidation: false,
    secondMAdd1validation: false,
    secondMPinValidation: false,
    secondpvalidation: false,
    secondalterphovalidation: false,
    secondstatevalidation: false,
    secondcityvalidation: false,
    secondgstnovalidation: false,
  });

  
  
  
  
  
  
  
  
  
  
  
  
  
  // const handleNameChange = (e) => {
  //   setFirstname(e.target.value);
  // };

  const boldtitle = location.state.status;
  const { createCustomerAddress, response } = useAddAddress(boldtitle);

  useEffect(() => {
    const fetchData = async () => {
      if (pinCode.length === 6) {
        try {
          const response = await fetch(
            `https://devextension.origa.market/api/getpincodestate`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ pincode: pinCode }),
            }
          );
          const responseData = await response.json();
          const matchedState = matchStateName(responseData.state);
          if (matchedState) {
            setState(matchedState);
            setCity(responseData.city);
          }
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    };

    fetchData();
  }, [pinCode]);

  useEffect(() => {
    const fetchSecondData = async () => {
      if (secondpinCode && secondpinCode.length === 6) {
        try {
          const response = await fetch(
            `https://devextension.origa.market/api/getpincodestate`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ pincode: secondpinCode }),
            }
          );
          const responseData = await response.json();
          const matchedState = matchStateName(responseData.state);
          if (matchedState) {
            secondsetState(matchedState);
            secondsetCity(responseData.city);
          }
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    };

    fetchSecondData();
  }, [secondpinCode]);

  const onAddressOneChange = (e) => {
    setAddress1(e.target.value);
  };

  const SecondonAddressOneChange = (e) => {
    secondsetAddress1(e.target.value);
  };

  const onAddressTwoChange = (e) => {
    setAddress2(e.target.value);
  };

  const SecondonAddressTwoChange = (e) => {
    secondsetAddress2(e.target.value);
  };

  const onCityChange = (e) => {
    const newInputString = e.target.value;
    const sanitizedInput = newInputString.replace(/[^a-zA-Z]/g, "");

    // Update the state only if the input is empty or contains valid characters
    if (newInputString === "" || sanitizedInput === newInputString) {
      setCity(sanitizedInput);
    }
  };

  const onSecondCityChange = (e) => {
    const newInputString = e.target.value;
    const sanitizedInput = newInputString.replace(/[^a-zA-Z]/g, "");

    // Update the state only if the input is empty or contains valid characters
    if (newInputString === "" || sanitizedInput === newInputString) {
      secondsetCity(sanitizedInput);
    }
    
  };


  
  const handleBillingSameAsShipping = (e) => {
    setIsBillingSameAsShipping(e.target.checked);
    if (e.target.checked) {
      setAddress1(secondaddress1);
      setAddress2(secondaddress2);
      setPinCode(secondpinCode);
      setState(secondstate);
      setCity(secondcity);
    } else {
      setAddress1('');
      setAddress2('');
      setPinCode('');
      setState('');
      setCity('');
    }
  };

  const onSecondGSTChange = (e) => {
    const newInputString = e.target.value;
    
    // Allow alphanumeric characters and limit the length to 15 characters
    const sanitizedInput = newInputString
        .replace(/[^a-zA-Z0-9]/g, '') // Allow only alphanumeric characters
        .slice(0, 15); // Limit to 15 characters

    // Update the state with the sanitized input
    secondsetgstno(sanitizedInput);
  };
  // const onSecondGSTChange = (e) => {
  //   const newInputString = e.target.value;
  //   const sanitizedInput = newInputString.replace(/[^a-zA-Z]/g, '');

  //   // Update the state only if the input is empty or contains valid characters
  //   if (newInputString === '' || sanitizedInput === newInputString) {
  //     secondsetgstno(sanitizedInput);
  //   }
  // };

  const onCompanyNameChange = (e) => {
   setCompanyName(e.target.value);
  };
  const onfactoryNameChange = (e) => {
    setFactoryName(e.target.value);
  };


  
  const onStateChange = (e) => {
    setState(e);
  };

  const onSecondStateChange = (e) => {
    secondsetState(e);
  };
  const onPinCodeChange = (e) => {
    const newInputString = e.target.value;
    const sanitizedInput = newInputString.replace(/[^0-9]/g, "");

    // Update the state only if the input is empty or contains valid characters
    if (
      (newInputString === "" || sanitizedInput === newInputString) &&
      sanitizedInput.length <= 6
    ) {
      setPinCode(sanitizedInput);
    }
  };

  const onSecondPinCodeChange = (e) => {
    const newInputString = e.target.value;
    const sanitizedInput = newInputString.replace(/[^0-9]/g, "");

    // Update the state only if the input is empty or contains valid characters
    if (
      (newInputString === "" || sanitizedInput === newInputString) &&
      sanitizedInput.length <= 6
    ) {
      secondsetPinCode(sanitizedInput);
    }
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onFirstnameChange = (e) => {
    const newInputString = e.target.value;
    const sanitizedInput = newInputString.replace(/[^a-zA-Z]/g, "");

    // Update the state only if the input is empty or contains valid characters
    if (newInputString === "" || sanitizedInput === newInputString) {
      setfirstname(sanitizedInput);
    }
  };

  const onPhoneNumberChange = (e) => {
    const newInputString = e.target.value;
    const sanitizedInput = newInputString.replace(/[^0-9]/g, "");

    // Update the state only if the input is empty or contains valid characters
    if (
      (newInputString === "" || sanitizedInput === newInputString) &&
      sanitizedInput.length <= 10
    ) {
      setFormattedPhoneNumber(sanitizedInput);
    }
  };
  const onAlterPhoneNumberChange = (e) => {
    const newInputString = e.target.value;
    const sanitizedInput = newInputString.replace(/[^0-9]/g, "");

    // Update the state only if the input is empty or contains valid characters
    if (
      (newInputString === "" || sanitizedInput === newInputString) &&
      sanitizedInput.length <= 10
    ) {
      setAlteredFormattedPhoneNumber(sanitizedInput);
    }
  };

  const onLastnameChange = (e) => {
    const newInputString = e.target.value;
    const sanitizedInput = newInputString.replace(/[^a-zA-Z]/g, "");

    if (newInputString === "" || sanitizedInput === newInputString) {
      setlastname(sanitizedInput);
    }
  };


  console.log(
    "Firstname  :  ", firstname, "\n",
    "Lastname  :  ", lastname, "\n",
    "Formatted Phone Number  :  ", formattedPhoneNumber, "\n",
    "Formatted Alternate Phone Number  :  ", formattedAlterPhoneNumber, "\n",
    "Pin Code  :  ", pinCode, "\n",
    "State  :  ", state, "\n",
    "Address 1  :  ", address1, "\n",
    "Address 2  :  ", address2, "\n",
    "City  :  ", city, "\n",
    "Second Pin Code  :  ", secondpinCode, "\n",
    "Second State  :  ", secondstate, "\n",
    "Second Address 1  :  ", secondaddress1, "\n",
    "Second City  :  ", secondcity, "\n",
    "Second GST No  :  ", secondgstno, "\n",
    "Company Name  :  ", companyName, "\n",
    "Factory Name  :  ", factoryName, "\n",
    "Product ID  :  ", productId, "\n",
    "Product Name  :  ", productName, "\n",
    "Product Type  :  ", producttype, "\n",
    "Product Price  :  ", productPrice, "\n",
    "Product Plan  :  ", productPlan, "\n",
    "Product GST Amount  :  ", productGstAmount, "\n",
    "Product Total Amount  :  ", productTotalAmount, "\n",
    "Product Status  :  ", productStatus
);


const breadcrumbsItems = [
    { name: "Home Page", link: "/" },
    { name: "My Account", link: "/myaccount" },
    { name: "Manage Address", link: "/manageaddress" },
  ];

  const title1 = "Point of Contact";
  const title2 = "Billing Address";
  const title3 = "Shipping Address";
  const title4 = "Company Details";
  const para1 =
    "We'd love to get in touch with you! Kindly share your contact details, and we'll reach out to discuss your machine";
  const para2 =
    "Please share the address where you'd like your machine to be delivered";
  const para3 =
    "Please share the address where you'd like your billing information to be sent";
  const para4 = "Please share the company name and factory name";


  const onSubmitHandler = async () => {
    if (
      firstname === "" ||
      lastname === "" ||
      formattedPhoneNumber === "" ||
      formattedPhoneNumber.length !== 10 ||
      pinCode === "" ||
      pinCode.length !== 6 ||
      secondpinCode === "" ||
      secondpinCode.length !== 6 ||
      state === "" ||
      secondstate === "" ||
      city === "" ||
      secondcity === "" ||
      // secondgstno === "" ||
      address1 === "" ||
      secondaddress1 === "" ||
      companyName === "" ||
      factoryName === ""
  ) {
      if (firstname === "")
          setvalidaion((prev) => ({ ...prev, fvalidation: true }));
      if (lastname === "")
          setvalidaion((prev) => ({ ...prev, lvalidation: true }));
      if (formattedPhoneNumber === "" || formattedPhoneNumber.length !== 10)
          setvalidaion((prev) => ({ ...prev, pvalidation: true }));
      if (pinCode === "" || pinCode.length !== 6)
          setvalidaion((prev) => ({ ...prev, MPinValidation: true }));
      if (state === "")
          setvalidaion((prev) => ({ ...prev, statevalidation: true }));
      if (address1 === "")
          setvalidaion((prev) => ({ ...prev, MAdd1validation: true }));
      if (city === "")
          setvalidaion((prev) => ({ ...prev, cityvalidation: true }));

      if (secondpinCode === "" || secondpinCode.length !== 6)
          setSecondValidation((prev) => ({
              ...prev,
              secondMPinValidation: true,
          }));
      if (secondstate === "")
          setSecondValidation((prev) => ({
              ...prev,
              secondstatevalidation: true,
          }));
      if (secondaddress1 === "")
          setSecondValidation((prev) => ({
              ...prev,
              secondMAdd1validation: true,
          }));
      if (secondcity === "")
          setSecondValidation((prev) => ({
              ...prev,
              secondcityvalidation: true,
          }));

      // if (secondgstno === "")
      //     setSecondValidation((prev) => ({
      //         ...prev,
      //         secondgstnovalidation: true,
      //     }));

      if (companyName === "")
          setvalidaion((prev) => ({
              ...prev,
              companyNameValidation: true,
          }));
      if (factoryName === "")
          setvalidaion((prev) => ({
              ...prev,
              factoryNameValidation: true,
          }));

     //  console.log(
     //      "Validation working===============================================",
     //      firstname,
     //      lastname,
     //      email,
     //      formattedPhoneNumber,
     //      formattedAlterPhoneNumber,
     //      pinCode,
     //      state,
     //      address1,
     //      address2,
     //      city,
     //      secondpinCode,
     //      secondstate,
     //      secondaddress1,
     //      secondcity,
     //      secondgstno,
     //      companyName,
     //      factoryName
     //  );

      return;
  }

  if (formattedAlterPhoneNumber !== "") {
      if (formattedAlterPhoneNumber.length !== 10) {
          setvalidaion((prev) => ({ ...prev, alterphovalidation: true }));
          return;
      }
  }

 //  var id;
 //  var customer_id;
 //  if (boldtitle === "Edit Address") {
 //      id = AddressId;
 //      customer_id = localStorage.getItem("id");
 //      console.log("Edit Address Mode, ID:", id, "Customer ID:", customer_id);
 //  } else {
 //      id = localStorage.getItem("id");
 //      console.log("New Address Mode, ID:", id);
 //  }

  // console.log('wwswswswswwsw');
  const id = localStorage.getItem('id');
  try {
    // console.log("Api is not called : ")
  //  const { data } = await client.mutate({
  //    mutation: CREATE_PAYMENT,
  //    variables: {
  //      "amcPlanData": {
  //        "appAmcPlanId": productId,  // Pass as an integer, not a string
  //        "customerId": id,
  //        "stage": "PAYMENT",
  //        "serviceType": "AMC",
  //        "category":selectedCategory,
  //        "location":selectedLocation,
  //        "serialNo":serialNo,
  //        "firstName":firstname,
  //        "companyName": companyName,
  //        "factoryName": factoryName,
  //        "address": {
  //          "billingAddress": {
  //            "city": city,
  //            "cityArea": "",
  //            "companyName":companyName ,
  //            "country": "IN",
  //            "countryArea": state,
  //            "firstName": firstname,
  //            "lastName": lastname,
  //            "phone": `${formattedPhoneNumber}`,
  //            "postalCode": `${pinCode}`,
  //            "streetAddress1": address1,
  //            "streetAddress2": address2,
  //            "gst": secondgstno
  //          },
  //          "shippingAddress": {
  //            "city": secondcity,
  //            "cityArea": "",
  //            "companyName": companyName,
  //            "country": "IN",
  //            "countryArea": secondstate,
  //            "firstName": firstname,
  //            "lastName": lastname,
  //            "phone": `${formattedPhoneNumber}`,
  //            "postalCode": `${secondpinCode}`,
  //            "streetAddress1": secondaddress1,
  //            "streetAddress2": secondaddress2,
  //            "gst": secondgstno
  //          }
  //        }
  //      }
  //    }
     // variables: {
     //   amcPlanData: {
     //     appAmcPlanId: productData?.id,
     //     customerId: customer_id,
     //     stage: "PAYMENT",
     //     serviceType: "AMC",
     //     category: selectedCategory,
     //     location: selectedLocation,
     //     serialNo: serialNo,
     //     firstName: firstname,
     //     companyName: companyName,
     //     factoryName: factoryName,
     //     address: {
     //       billingAddress: {
     //         city: city,
     //         cityArea: "",
     //         companyName: companyName,
     //         country: "IN",
     //         countryArea: state,
     //         firstName: firstname,
     //         lastName: lastname

     //       },
     //       shippingAddress: {
     //         city: secondcity,
     //         cityArea: "",
     //         companyName: companyName,
     //         country: "IN",
     //         countryArea: secondstate,
     //         firstName: firstname,
     //         lastName: lastname
            
     //       }
     //     }
     //   }
     // }
  //  });
   // console.log("API Response paymentgetway==>>>", data);
   // console.log("Create payment is happening : ------------------------>");
   // console.log("appAmcPlanId : ------------------------>", productId);
   // console.log('Selected Category:  ', selectedCategory);
   // console.log('Selected Location:  ', selectedLocation);
   // console.log('Selected Category:  ', firstname);
   // console.log('Selected Location:  ', serialNo);
 
//         console.log(
//           "Firstname  :  ", firstname, "\n",
//           "Lastname  :  ", lastname, "\n",
//           "Formatted Phone Number  :  ", formattedPhoneNumber, "\n",
//           "Formatted Alternate Phone Number  :  ", formattedAlterPhoneNumber, "\n",
//           "Pin Code  :  ", pinCode, "\n",
//           "State  :  ", state, "\n",
//           "Address 1  :  ", address1, "\n",
//           "Address 2  :  ", address2, "\n",
//           "City  :  ", city, "\n",
//           "Second Pin Code  :  ", secondpinCode, "\n",
//           "Second State  :  ", secondstate, "\n",
//           "Second Address 1  :  ", secondaddress1, "\n",
// "Second Address  2 :  ", secondaddress2, "\n",
//           "Second City  :  ", secondcity, "\n",
//           "Second GST No  :  ", secondgstno, "\n",
//           "Company Name  :  ", companyName, "\n",
//           "Factory Name  :  ", factoryName, "\n",
//           "Product ID  :  ", productId, "\n",
//           "Product Name  :  ", productName, "\n",
//           "Product Type  :  ", producttype, "\n",
//           "Product Price  :  ", productPrice, "\n",
//           "Product Plan  :  ", productPlan, "\n",
//           "Product GST Amount  :  ", productGstAmount, "\n",
//           "Product Total Amount  :  ", productTotalAmount, "\n",
//           "Product Status  :  ", productStatus
//       );
  //  setResponseData(data);

   
   
  setLoadinged(true);
  setTimeout(() => {
navigate("/cartpageofamcsimm", {
state: {
productId,
id,
selectedCategory,
selectedLocation,
serialNo,
modellNo,
firstname,
lastname,
companyName,
factoryName,
city,
state,
secondgstno,
secondcity,
secondstate,
formattedPhoneNumber,
formattedAlterPhoneNumber,
pinCode,
state,
address1,
address2,
city,
secondpinCode,
secondstate,
secondaddress1,
secondaddress2,
secondcity,
secondgstno,
companyName,
factoryName,
productId,
productName,
producttype,
productPrice,
productPlan,
productGstAmount,
productTotalAmount,
productImage,
productStatus
}
});
}, 300);
// console.log("Sending the STATES back  to /cartpageofamcsimm");
   // setSuccessPopUp(true);
   // setPaymentLink(data.createAmc.paymenturl);
   // console.log("Payment Link :  ---------------------------------> ", PaymentLink);
 } catch (error) {
   console.error("Error creating payment:", error);
 }
};
  


useEffect(() => {
  if (location.state.edit_address) {
    console.log("Location state has edit_address:", location);
    setfirstname(location.state.edit_address?.firstName);
    setlastname(location.state.edit_address?.lastName);
    const formattedPhoneNumber = location.state.edit_address?.phone;
    const truncatedPhoneNumber = formattedPhoneNumber.startsWith("+91")
      ? formattedPhoneNumber.substring(3)
      : formattedPhoneNumber;
      setFormattedPhoneNumber(truncatedPhoneNumber);
    console.log("Now truncated Phone number is:", truncatedPhoneNumber);
    setCity(location.state.edit_address?.city);
    setState(location.state.edit_address?.countryArea);
    setPinCode(location.state.edit_address?.postalCode);
    setAddress1(location.state.edit_address?.streetAddress1);
    setAddress2(location.state.edit_address?.streetAddress2);

    // Add second address details here
    secondsetPinCode(location.state.edit_address?.secondpinCode);
    secondsetState(location.state.edit_address?.secondstate);
    secondsetAddress1(location.state.edit_address?.secondaddress1);
    secondsetAddress2(location.state.edit_address?.secondaddress2);
    secondsetCity(location.state.edit_address?.secondcity);
    secondsetgstno(location.state.edit_address?.secondgstno);
  }
}, []);

const getLocation = async () => {
  try {
    if (navigator.geolocation) {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const { latitude, longitude } = position.coords;
      const response = await fetch(
        `https://devextension.origa.market/api/getlatlngpincode`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ latitude, longitude }),
        }
      );

      const responseData = await response.json();
      setPinCode(responseData?.postal_code);
      setState(responseData?.state);
      setCity(responseData?.city);
      //setFormData((prevData) => ({ ...prevData, pin: responseData?.postal_code }));
      //setFormData(...formData,formData.pin = responseData?.postal_code)
      //setDeliveryLocation(responseData?.postal_code)
      console.log("API response:", responseData?.postal_code);

      // Handle response data here
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  } catch (error) {
    console.error("Error getting location:", error.message);
  }
};

const getLocationpart = async () => {
  try {
    if (navigator.geolocation) {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const { latitude, longitude } = position.coords;
      const response = await fetch(
        `https://ension.origa.market/api/getlatlngpincode`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ latitude, longitude }),
        }
      );

      const responseData = await response.json();
      secondsetPinCode(responseData?.postal_code);
      secondsetState(responseData?.state);
      secondsetCity(responseData?.city);
      //setFormData((prevData) => ({ ...prevData, pin: responseData?.postal_code }));
      //setFormData(...formData,formData.pin = responseData?.postal_code)
      //setDeliveryLocation(responseData?.postal_code)
      console.log("API response:", responseData?.postal_code);

      // Handle response data here
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  } catch (error) {
    console.error("Error getting location:", error.message);
  }
};

  const onCancelHandler = () => {
    navigate(location.state.cancelNavi);
  };

  return (
   <>


{loadinged && <Loader />}

      {SuccessPopUp ? (
        <Container fluid className="p-fixed bg-blur hw-100 d-j-a">
          <div className='App'>
            <div className='success-login'>
              <div className='contents'>
                <h3 className='heading-600-28'>Confirm Payment</h3>
                <p className='heading-400-16 op-60'>Proceed to the payment</p>
                <div className="d-flex justify-content-between mt-3">
                  <a href={PaymentLink} rel="noreferrer" >
                    <Button variant="success" className="me-2">
                      Pay Now
                    </Button>
                  </a>
                  <Button variant="danger" style={{ marginLeft: '8px' }} onClick={() => setSuccessPopUp(false)}>
                    Cancel
                  </Button>
                </div>

              </div>
            </div>
          </div>
        </Container>
      ) : null}
{/* {isLoader && <Loader />} */}
<div className="container-fluid">
        <div className="max-container pt-5">
          <div className="container-fluid p-0 m-0 row pb-4">
            <div
               className={`col col-md-10 col-12 ${
                isSmallScreen ? "" : "pl-5 pr-5"
              } bg-F9F9F9 mx-auto`}
            >
              <div className="container pt-5 pb-5">
                <div className="row">
                  <div className="col heading-600-24 p-0">Edit Address</div>
                </div>
               




{/*Fourth Section :  */}
<div className="row pb-5 d-flex justify-content-between border-bottom">
                  <div className="col col-lg-4 col-12 pl-0 pt-30px">
                    <MALeftsection heading={title4} para={para4} />
                  </div>
                  <div className="col col-lg-7  col-12 p-0">
                    <div className="row">
                      <div className="col col-12">
                        <div
                          className={`bi-form-group ${
                            validation.MAdd1validation ? "error-red" : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="machine-add1"
                            id="machine-add1"
                            className={`bi-form-field bg-white ${
                              validation.companyNameValidation ? "error-red" : ""
                            }`}
                            value={companyName}
                            onChange={onCompanyNameChange}
                            onClick={() =>
                              setvalidaion((prev) => ({
                                ...prev,
                                companyNameValidation: false,
                              }))
                            }
                            placeholder="Machine Make"
                          />
                          <label
                            htmlFor="machine-add1"
                            className="heading-400-14-12 bi-form-label"
                          >
                            Company Name
                            {<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>
                  

                      <div className="col col-12">
                        <div
                          className={`bi-form-group ${
                            validation.factoryNameValidation ? "error-red" : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="machine-add1"
                            id="machine-add1"
                            className={`bi-form-field bg-white ${
                              validation.factoryNameValidation ? "error-red" : ""
                            }`}
                            value={factoryName}
                            onChange={onfactoryNameChange}
                            onClick={() =>
                              setvalidaion((prev) => ({
                                ...prev,
                                MAdd1validation: false,
                              }))
                            }
                            placeholder="Machine Make"
                          />
                          <label
                            htmlFor="machine-add1"
                            className="heading-400-14-12 bi-form-label"
                          >
                            Factory Name
                            {<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                    
                      </div>

                      <div className="col col-12">
                        <div
                          className={`bi-form-group ${
                            secondvalidation.secondgstnovalidation
                              ? "error-red"
                              : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="gstno"
                            id="gst"
                            className={`bi-form-field bg-white ${
                              secondvalidation.secondgstnovalidation
                                ? "error-red"
                                : ""
                            }`}
                            value={secondgstno}
                            onChange={onSecondGSTChange}
                            onClick={() =>
                              setSecondValidation((prev) => ({
                                ...prev,
                                secondgstnovalidation: false,
                              }))
                            }
                            placeholder="gst"
                          />
                          <label
                            htmlFor="city"
                            className="heading-400-14-12 bi-form-label"
                          >
                            Gst No
                          </label>
                        </div>
                      </div>
                  
                    </div>
                  </div>
                  
                </div>






               {/*First Section */}
               <div className="row pb-5 d-flex justify-content-between border-bottom">
                  <div className="col col-lg-4 col-12 pl-0 pt-30px ">
                    <MALeftsection heading={title1} para={para1} />
                  </div>
                  <div className="col col-lg-7 col-12 p-0">
                    <div className="row ">
                      <div className="col col-md-6 col-12">
                        <div
                          className={`bi-form-group ${
                            validation.fvalidation ? "error-red" : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="fname"
                            id="fname"
                            className={`bi-form-field bg-white ${
                              validation.fvalidation ? "error-red" : ""
                            }`}
                            placeholder="First Name"
                            value={firstname}
                            onChange={onFirstnameChange}
                            onClick={() =>
                              setvalidaion((prev) => ({
                                ...prev,
                                fvalidation: false,
                              }))
                            }
                          />
                          <label
                            htmlFor="fname"
                            className="heading-400-14-12 bi-form-label"
                          >
                            First Name
                            {<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>
                      <div className="col col-md-6 col-12 ">
                        <div
                          className={`bi-form-group ${
                            validation.lvalidation ? "error-red" : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="lname"
                            id="lname"
                            className={`bi-form-field bg-white ${
                              validation.lvalidation ? "error-red" : ""
                            }`}
                            placeholder="Last Name"
                            value={lastname}
                            onChange={onLastnameChange}
                            onClick={() =>
                              setvalidaion((prev) => ({
                                ...prev,
                                lvalidation: false,
                              }))
                            }
                          />
                          <label
                            htmlFor="lname"
                            className="heading-400-14-12 bi-form-label"
                          >
                            Last Name
                            {<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* <div className='col col-12 p-0'>
                      <div className={`bi-form-group ${validation.evalidation ? "error-red" : ""}`}>
                        <input type="email" name="aemail" id="aemail" className={`bi-form-field bg-white ${validation.evalidation ? "error-red" : ""}`} placeholder="Email" value={email}
                          onChange={onEmailChange} onClick={() => setvalidaion(prev => ({ ...prev, evalidation: false }))} />
                        <label htmlFor="aemail" className="heading-400-14-12 bi-form-label">Email Id{<span style={{ color: '#CB1923' }}>*</span>}</label>
                      </div>
                    </div> */}

                    <div className="row ">
                      <div className="col col-md-6 col-12">
                        <div
                          className={`bi-form-group ${
                            validation.pvalidation ? "error-red" : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            className={`bi-form-field bg-white ${
                              validation.pvalidation ? "error-red" : ""
                            }`}
                            placeholder="Phone Number"
                            value={formattedPhoneNumber}
                            onChange={onPhoneNumberChange}
                            onClick={() =>
                              setvalidaion((prev) => ({
                                ...prev,
                                pvalidation: false,
                              }))
                            }
                          />
                          <label
                            htmlFor="phone"
                            className="heading-400-14-12 bi-form-label"
                          >
                            Phone Number
                            {<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>
                      <div className="col col-md-6 col-12">
                        <div
                          className={`bi-form-group ${
                            validation.alterphovalidation ? "error-red" : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="altphone"
                            id="altphone"
                            className={`bi-form-field  bg-white ${
                              validation.alterphovalidation ? "error-red" : ""
                            }`}
                            placeholder="Alt Phone Number"
                            value={formattedAlterPhoneNumber}
                            onChange={onAlterPhoneNumberChange}
                            onClick={() =>
                              setvalidaion((prev) => ({
                                ...prev,
                                alterphovalidation: false,
                              }))
                            }
                          />
                          <label
                            htmlFor="altphone"
                            className="heading-400-14-12 bi-form-label"
                          >
                            Alternative Phone Number
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* third section */}
                <div className="row pb-5 d-flex justify-content-between  ">
                  <div className="col col-lg-4 col-12 pl-0 pt-30px">
                    <MALeftsection heading={title3} para={para3} />
                  </div>
                  <div className="col col-lg-7  col-12 p-0">
                    <div className="row">
                    <div className="col col-12">
                        <div
                          className={`bi-form-group ${
                            secondvalidation.secondMAdd1validation
                              ? "error-red"
                              : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="machine-add1"
                            id="machine-add1"
                            className={`bi-form-field bg-white ${
                              secondvalidation.secondMAdd1validation
                                ? "error-red"
                                : ""
                            }`}
                            value={secondaddress1}
                            onChange={SecondonAddressOneChange}
                            onClick={() =>
                              setSecondValidation((prev) => ({
                                ...prev,
                                secondMAdd1validation: false,
                              }))
                            }
                            placeholder="Machine Make"
                          />
                          <label
                            htmlFor="machine-add1"
                            className="heading-400-14-12 bi-form-label"
                          >
                            Address Line 1
                            {<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>
                      <div className="col col-12">
                        <div className={`bi-form-group `}>
                          <input
                            type="text"
                            name="machine-add2"
                            id="machine-add2"
                            className={`bi-form-field bg-white`}
                            value={secondaddress2}
                            onChange={SecondonAddressTwoChange}
                            placeholder="Address Line 2"
                          />
                          <label
                            htmlFor="machine-add2"
                            className="heading-400-14-12 bi-form-label"
                          >
                            Address Line 2
                          </label>
                        </div>
                      </div>
                      <div className="col col-12">
                        <div
                          className={`bi-form-group  ${
                            secondvalidation.secondMPinValidation
                              ? "error-red"
                              : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="pincode"
                            id="pincode"
                            className={`bi-form-field bg-white ${
                              secondvalidation.secondMPinValidation
                                ? "error-red"
                                : ""
                            }`}
                            value={secondpinCode}
                            onChange={onSecondPinCodeChange}
                            onClick={() =>
                              setSecondValidation((prev) => ({
                                ...prev,
                                secondMPinValidation: false,
                              }))
                            }
                            placeholder="Pin Code"
                          />
                          <label
                            htmlFor="pincode"
                            className="heading-400-14-12 bi-form-label"
                          >
                            Pin Code
                            {<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                          <div
                            onClick={getLocationpart}
                            className="text-end p-r heading-600-14 heading-600-14-12 curser-pointer"
                          >
                            Use My Current Location
                            <span
                              style={{
                                position: "absolute",
                                top: "-40px",
                                right: "0",
                              }}
                            >
                              {locationIcon({ width: 32, height: 32 })}
                            </span>
                          </div>
                        </div>
                        {/*update*/}
                      </div>
                      <div className="col col-12 ">
                        <div
                          className={`bi-form-group ${
                            secondvalidation.secondstatevalidation
                              ? "error-red"
                              : ""
                          }`}
                        >
                          {/* <input type="type" name="State" id="State" className={`bi-form-field bg-white ${validation.statevalidation ? "error-red":""}`} value={state} onChange={onStateChange} onClick={()=>setvalidaion(prev=>({...prev,statevalidation:false}))} placeholder="State"/>
                           */}
                          <select
                            className={`bi-form-field bi-select-dropdown ${
                              state ? "" : "empty"
                            }`}
                            value={secondstate}
                            placeholder="state"
                            onChange={(e) =>
                              onSecondStateChange(e.target.value)
                            }
                            autoCapitalize="off"
                          >
                            <option value=""></option>
                            <option value="Andhra Pradesh">
                              Andhra Pradesh
                            </option>
                            <option value="Arunachal Pradesh">
                              Arunachal Pradesh
                            </option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">
                              Himachal Pradesh
                            </option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Madhya Pradesh">
                              Madhya Pradesh
                            </option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Odisha">Odisha</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                            <option value="Uttarakhand">Uttarakhand</option>
                            <option value="West Bengal">West Bengal</option>
                          </select>
                          <label className="heading-400-14-12 bi-form-label">
                            State{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>
                      
                      <div className="col col-12">
                        <div
                          className={`bi-form-group ${
                            secondvalidation.secondcityvalidation
                              ? "error-red"
                              : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="city"
                            id="city"
                            className={`bi-form-field bg-white ${
                              secondvalidation.secondcityvalidation
                                ? "error-red"
                                : ""
                            }`}
                            value={secondcity}
                            onChange={onSecondCityChange}
                            onClick={() =>
                              setSecondValidation((prev) => ({
                                ...prev,
                                secondcityvalidation: false,
                              }))
                            }
                            placeholder="City"
                          />
                          <label
                            htmlFor="city"
                            className="heading-400-14-12 bi-form-label"
                          >
                            City{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>

                      <div className="d-flex align-items-center mt-4">
                        <input
                              className="form-check-input"
                              type="checkbox"
                              id="billingSameAsShipping"
                              checked={isBillingSameAsShipping}
                              onChange={handleBillingSameAsShipping}
                            />
                          &nbsp;
                          &nbsp;Billing address same as shipping address</div>
  
                      {/* <div className="col col-12">
                        <div
                          className={`bi-form-group ${
                            secondvalidation.secondgstnovalidation
                              ? "error-red"
                              : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="gstno"
                            id="gst"
                            className={`bi-form-field bg-white ${
                              secondvalidation.secondgstnovalidation
                                ? "error-red"
                                : ""
                            }`}
                            value={secondgstno}
                            onChange={onSecondGSTChange}
                            onClick={() =>
                              setSecondValidation((prev) => ({
                                ...prev,
                                secondgstnovalidation: false,
                              }))
                            }
                            placeholder="gst"
                          />
                          <label
                            htmlFor="city"
                            className="heading-400-14-12 bi-form-label"
                          >
                            Gst No{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div> */}
                    </div>
                   
              
                    
                  </div>
                </div>

{/* second section */}
<div className="row pb-5 d-flex justify-content-between border-bottom">
                  <div className="col col-lg-4 col-12 pl-0 pt-30px">
                    <MALeftsection heading={title2} para={para2} />
                  </div>
                  <div className="col col-lg-7  col-12 p-0">
                    <div className="row">
                    <div className="col col-12">
                        <div
                          className={`bi-form-group ${
                            validation.MAdd1validation ? "error-red" : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="machine-add1"
                            id="machine-add1"
                            className={`bi-form-field bg-white ${
                              validation.MAdd1validation ? "error-red" : ""
                            }`}
                            value={address1}
                            onChange={onAddressOneChange}
                            onClick={() =>
                              setvalidaion((prev) => ({
                                ...prev,
                                MAdd1validation: false,
                              }))
                            }
                            placeholder="Machine Make"
                          />
                          <label
                            htmlFor="machine-add1"
                            className="heading-400-14-12 bi-form-label"
                          >
                            Address Line 1
                            {<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>
                      <div className="col col-12">
                        <div className={`bi-form-group `}>
                          <input
                            type="text"
                            name="machine-add2"
                            id="machine-add2"
                            className={`bi-form-field bg-white`}
                            value={address2}
                            onChange={onAddressTwoChange}
                            placeholder="Address Line 2"
                          />
                          <label
                            htmlFor="machine-add2"
                            className="heading-400-14-12 bi-form-label"
                          >
                            Address Line 2
                          </label>
                        </div>
                      </div>
                      <div className="col col-12">
                        <div
                          className={`bi-form-group  ${
                            validation.MPinValidation ? "error-red" : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="pincode"
                            id="pincode"
                            className={`bi-form-field bg-white ${
                              validation.MPinValidation ? "error-red" : ""
                            }`}
                            value={pinCode}
                            onChange={onPinCodeChange}
                            onClick={() =>
                              setvalidaion((prev) => ({
                                ...prev,
                                MPinValidation: false,
                              }))
                            }
                            placeholder="Pin Code"
                          />
                          <label
                            htmlFor="pincode"
                            className="heading-400-14-12 bi-form-label"
                          >
                            Pin Code
                            {<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                          <div
                            onClick={getLocation}
                            className="text-end p-r heading-600-14 heading-600-14-12 curser-pointer"
                          >
                            Use My Current Location
                            <span
                              style={{
                                position: "absolute",
                                top: "-40px",
                                right: "0",
                              }}
                            >
                              {locationIcon({ width: 32, height: 32 })}
                            </span>
                          </div>
                        </div>
                        {/*update*/}
                      </div>
                      <div className="col col-12 ">
                        <div
                          className={`bi-form-group ${
                            validation.statevalidation ? "error-red" : ""
                          }`}
                        >
                          {/* <input type="type" name="State" id="State" className={`bi-form-field bg-white ${validation.statevalidation ? "error-red":""}`} value={state} onChange={onStateChange} onClick={()=>setvalidaion(prev=>({...prev,statevalidation:false}))} placeholder="State"/>
                           */}
                          <select
                            className={`bi-form-field bi-select-dropdown ${
                              state ? "" : "empty"
                            }`}
                            value={state}
                            placeholder="state"
                            onChange={(e) => onStateChange(e.target.value)}
                            autoCapitalize="off"
                          >
                            <option value=""></option>
                            <option value="Andhra Pradesh">
                              Andhra Pradesh
                            </option>
                            <option value="Arunachal Pradesh">
                              Arunachal Pradesh
                            </option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">
                              Himachal Pradesh
                            </option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Madhya Pradesh">
                              Madhya Pradesh
                            </option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Odisha">Odisha</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                            <option value="Uttarakhand">Uttarakhand</option>
                            <option value="West Bengal">West Bengal</option>
                          </select>
                          <label className="heading-400-14-12 bi-form-label">
                            State{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>
                      
                      <div className="col col-12">
                        <div
                          className={`bi-form-group ${
                            validation.cityvalidation ? "error-red" : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="city"
                            id="city"
                            className={`bi-form-field bg-white ${
                              validation.cityvalidation ? "error-red" : ""
                            }`}
                            value={city}
                            onChange={onCityChange}
                            onClick={() =>
                              setvalidaion((prev) => ({
                                ...prev,
                                cityvalidation: false,
                              }))
                            }
                            placeholder="City"
                          />
                          <label
                            htmlFor="city"
                            className="heading-400-14-12 bi-form-label"
                          >
                            City{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className={`buttontwoinaddresspage  gap-4 pt-4`}>
                      {/* <ButtonOutline message={"Cancel"} callFunction={onCancelHandler} /> */}
                      <Button2 message={"Save"} callFunction={onSubmitHandler} />
                    </div>

                  </div>
                </div>
                


                {/* <div className={`container-fluid p-0 m-0 row end-to-between gap-4 pt-4`}>
                      <ButtonOutline message={"Cancel"} callFunction={onCancelHandler} />
                      <Button2 message={"Save"} callFunction={onSubmitHandler} />
                    </div> */}
                 {/* <div
                      className={`container-fluid p-0 m-0 row end-to-between gap-4 pt-4`}
                    >
                      <ButtonOutline
                        message={"Cancel"}
                        callFunction={onCancelHandler}
                      />
                      <Button2 message={"Save"} callFunction={onSubmitHandler} />
                    </div> */}
                     {/* <div className={`container-fluid p-0 m-0 row end-to-between gap-4 pt-4`}>
                      <ButtonOutline message={"Cancel"} callFunction={onCancelHandler} />
                      <Button2 message={"Save"} callFunction={onSubmitHandler} />
                    </div> */}
              </div>
              
            </div>

            
          </div>

          <div className="p-r pb-5 d-flex justify-content-end">
            <img
              className="p-a pt-2 right-0"
              src="asset/Frame1000004018.png"
              alt="Frame1000004018.png"
            />
          </div>
        </div>

        
      </div>







      <AccountFooter />

   </>
  )
}

export default AddAddressAmc