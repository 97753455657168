import React, { useEffect } from 'react';
import { useState } from 'react';
import {
    secondClient,
    GET_SPARE_DETAILS,
  } from "../OrigaExtentionAPI/mutations";
import { useLocation } from 'react-router-dom';
import "./CartPageSampleStyles.css";

  
const client = secondClient;
const CartItem = ({ productId, productName, productBrand, productImage, salePrice }) => {
    const location = useLocation();//to access the current location
    const queryParams = new URLSearchParams(location.search);//to pass the query parameters to the url
    const groupId = queryParams.get('group_id');//to get the id from url
    const [productDETAILS, setProductDETAILS] = useState({});
    const [loading, setLoading] = useState(true);
    
    const updateFormattedPrice = () => {//is used to update how the price of a product is displayed on a webpage
        setProductDETAILS((prevDetails) => ({//it takes the current details of the product(prevdetails)and creates a new set of details with an updatted formatted price
            ...prevDetails,
            price_details: {
                ...prevDetails.price_details,
                //amc_cost: formatCurrency(prevDetails.price_details.amc_cost),
                //delivery_charges: formatCurrency(prevDetails.price_details.delivery_charges),
                machine_price: formatCurrency(prevDetails.sales_rate),
            },
        }));
    };


    const formatCurrency = (value) => { //like to show in indian rupees it adds commas where needed and shows the right number of decimal place
        const fractionDigits = value % 1 !== 0 ? 2 : 0;
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        }).format(value || 0);
    };

    useEffect(() => {
        window.scrollTo(0, 0)// Scrolls the window to the top on component mount or update
        const fetchData = async () => {
            let id = localStorage.getItem('id');
            if (id === null) {
                id = "";
            }
            try {
                 // Performs a GraphQL query using the Apollo client 'client'
                const { data } = await client.query({ query: GET_SPARE_DETAILS, variables: { groupId: groupId || '', itemId: productId || '' } });
                 // Sets the fetched data to state variable 'productDETAILS'
                setProductDETAILS(data?.inventoryItem?.response)
                 // Sets 'loading' state to false after data is fetched
                setLoading(false)
                // Updates formatted price
                updateFormattedPrice()
            } catch (error) {
            }
        }
        fetchData();
    }, [groupId, productId]);

    
    console.log(productId);
    console.log("product DETAILS  --> ", productDETAILS);
    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Product ID: {productId}</h5>
                <p>Product Name: {productName}</p>
                <p>Product Name: {productDETAILS[0]?.name || "N/A"}</p>
                <p>Product Brand: {productDETAILS[0]?.brand || "N/A"}</p>
                <p>Product Image: <img style={{width:"20%",height:"30%"}}src={productDETAILS[0]?.images?.productImages[0]?.url || "N/A"} alt="Product" /></p>
                <p>Sale Price: ${productDETAILS[0]?.saleprice || 0}</p>
                
            </div>
        </div>
    );
};

export default CartItem;


